import { useCallback, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { PaymentMethodEnum } from 'src/Constants/enums'
import {
  useCreateBillingAgreementMutation,
  useGetBillingAgreementQuery,
} from 'src/Redux/billingAgreementApi'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'
import { subscription } from 'src/Router/routes'

import { useChangeSubscription } from './useChangeSubscription'

interface UsePaySubscription {
  onPayPaypal: () => void
  hasBilling: boolean
  email?: string
  loading: boolean
}

export const usePayPaypalSubscription = (): UsePaySubscription => {
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()
  const { data: subscriptionPlan, isSuccess: isSubscriptionSucceeded } =
    useGetSubscriptionQuery()
  const hasActiveSubscription = Boolean(subscriptionPlan?.status === 'active')
  const { data: billingData, isSuccess: isFetchingBillingAgreement } =
    useGetBillingAgreementQuery()
  const [createBillingAgreement, { isLoading: isBillingLoading }] =
    useCreateBillingAgreementMutation()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = search.get('plan')
  const autopay = search.get('autopay')
  const paymentType = search.get('paymentType')

  const [change, isChangeLoading] = useChangeSubscription()

  const onPayPaypal = useCallback((): void => {
    if (!packageId || !price || !plan) {
      return
    }
    if (!billingData?.id) {
      createBillingAgreement({
        cancelUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&cashback=${cashback}&plan=${plan}&paymentType=${PaymentMethodEnum.PAYPAL}&error=save_token_error`,
        successUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&cashback=${cashback}&plan=${plan}&paymentType=${PaymentMethodEnum.PAYPAL}&autopay=true`,
      })
        .then((res) => {
          if (res.data?.link) {
            window.location.href = res.data?.link
          }
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${PaymentMethodEnum.PAYPAL}&error=subscription_error`,
          )
        })
      return
    }

    hasActiveSubscription && change(PaymentMethodEnum.PAYPAL, billingData?.id)
  }, [
    cashback,
    change,
    hasActiveSubscription,
    navigate,
    packageId,
    plan,
    price,
    createBillingAgreement,
    billingData?.id,
  ])
  const isOnceCalled = useRef<boolean>(false)
  const isAutoPaypalPayment =
    autopay === 'true' && paymentType === PaymentMethodEnum.PAYPAL
  const isDataPreloaded = isSubscriptionSucceeded && isFetchingBillingAgreement

  useEffect(() => {
    if (isAutoPaypalPayment && isDataPreloaded && !isOnceCalled.current) {
      onPayPaypal()
      isOnceCalled.current = true
    }
  }, [isAutoPaypalPayment, isDataPreloaded, onPayPaypal])
  return {
    onPayPaypal,
    loading: isBillingLoading || isChangeLoading,
    hasBilling: Boolean(billingData?.id),
    email: billingData?.paypalEmail,
  }
}
