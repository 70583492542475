import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'

export const AlertSubTitle = styled.div``

export const StyledAlert = styled(Alert)`
  margin-bottom: 0 !important;
  width: 100%;
`

export const SomethingWentWrongAlert: FC = () => {
  const { t } = useTranslation()

  return (
    <PrimaryAlert
      type={'danger'}
      hasCross={false}
      title={t('soundsPage.defaultErrorText')}
    />
  )
}
