import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { kFormatter } from 'src/Constants/functions'
import { FOLLOWERS_THRESHOLDS } from 'src/Constants/constants'

import { RewardWithPlaylist } from '../../useRewardsPage'
import { RewardPlaylistsBlock } from '../RewardPlaylistsBlock'

import {
  ContainerDesktop,
  ContainerMobile,
  FollowersMobile,
  ValueDesktop,
  ValueItemMobile,
  ValueMobile,
  ValuesListMobile,
} from './styles'

export const RewardListItem: FC<{ reward: RewardWithPlaylist }> = ({
  reward,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 })

  const { basicReward, followers, playlists } = reward

  const formatFollowersRange = (amount: number): string => {
    for (let i = 0; i < FOLLOWERS_THRESHOLDS.length - 1; i++) {
      if (
        amount >= FOLLOWERS_THRESHOLDS[i] &&
        amount < FOLLOWERS_THRESHOLDS[i + 1]
      ) {
        const min = kFormatter(FOLLOWERS_THRESHOLDS[i])
        const max = kFormatter(FOLLOWERS_THRESHOLDS[i + 1])
        return `${min} – ${max}`
      }
    }

    const last = kFormatter(
      FOLLOWERS_THRESHOLDS[FOLLOWERS_THRESHOLDS.length - 1],
    )
    return `${last}+`
  }

  const formattedFollowers = formatFollowersRange(followers)

  if (!isDesktop) {
    return (
      <ContainerMobile>
        <ValuesListMobile>
          <ValueItemMobile>
            <FollowersMobile>{formattedFollowers} followers</FollowersMobile>
            <ValueMobile>${basicReward.toFixed(2)}</ValueMobile>
          </ValueItemMobile>
        </ValuesListMobile>

        <RewardPlaylistsBlock playlists={playlists} />
      </ContainerMobile>
    )
  }

  return (
    <ContainerDesktop>
      <ValueDesktop>{formattedFollowers}</ValueDesktop>
      <ValueDesktop>${basicReward.toFixed(2)}</ValueDesktop>
      <RewardPlaylistsBlock playlists={playlists} />
    </ContainerDesktop>
  )
}
