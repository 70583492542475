import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getIsLanguageActive } from 'src/Pages/Artist/SpotifySchedulePage/helpers/helpers'
import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useBudgetRange } from 'src/Redux/spotifyBudgetRangeApi'
import {
  ScheduleTrackStepButton,
  StepButtonStatus,
} from 'src/Containers/ui/ScheduleTrackStepButton'
import { MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { ModalType, ScheduleSteps } from '../../../../Types'

import { SpotifySettingsBudget } from './SpotifySettingsBudget'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (min-width: 767px) {
    width: 324px;
  }
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: 400px;
  }
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const StepsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const SpotifyCampaignSteps: FC = () => {
  const { setSearch } = useCustomSearchParams()
  const { t } = useTranslation()
  const params = useParams()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const isGenresActive = !campaignDetails.genres.length
  const isLanguageActive = getIsLanguageActive(campaignDetails.languages)
  const { isError: budgetRangeError } = useBudgetRange()

  const handleClick = (type: ModalType): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: type,
    }
    setSearch(query)
  }
  const showSettings =
    !isGenresActive &&
    !isLanguageActive &&
    !budgetRangeError &&
    Number(campaignDetails.id) === Number(params.number)

  if (showSettings) {
    return <SpotifySettingsBudget />
  }

  const getLanguagesStatus = (): StepButtonStatus => {
    if (isGenresActive && isLanguageActive) {
      return StepButtonStatus.disabled
    }
    if (isLanguageActive) {
      return StepButtonStatus.active
    }
    return StepButtonStatus.completed
  }
  const genresStatus = isGenresActive
    ? StepButtonStatus.active
    : StepButtonStatus.completed
  return (
    <Container>
      <Title>{t('scheduleCampaignPage.ToSetUpBudget')}</Title>
      <StepsButtonContainer>
        <ScheduleTrackStepButton
          onClick={() => handleClick(ModalType.Genres)}
          status={genresStatus}
        >
          {t('scheduleCampaignPage.addTrackGenres').toLowerCase()}
        </ScheduleTrackStepButton>
        <ScheduleTrackStepButton
          onClick={() => handleClick(ModalType.Languages)}
          status={getLanguagesStatus()}
          disabled={isGenresActive}
        >
          {t('scheduleCampaignPage.addVocalLanguage').toLowerCase()}
        </ScheduleTrackStepButton>
      </StepsButtonContainer>
    </Container>
  )
}
