import {
  PayloadAction,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace, SliceStatus } from '../types'
import { RootState } from '../index'

import { getSpotifyCampaignMatchingData } from './api-actions'

export interface SpotifyCampaignMatchingProcessState {
  status: SliceStatus
  error: ApiError | null
  minCuratorsCount: number | null
  maxCuratorsCount: number | null
  maxPlaylistsCount: number | null
  totalPrice: number | null
}

const initialState: SpotifyCampaignMatchingProcessState = {
  status: SliceStatus.Idle,
  error: null,
  minCuratorsCount: null,
  maxCuratorsCount: null,
  maxPlaylistsCount: null,
  totalPrice: null,
}

export const spotifyCampaignMatchingProcess = createSlice({
  initialState,
  name: NameSpace.SPOTIFY_CAMPAIGN_MATCHING,
  reducers: {
    setCampaignBudget(state, action: PayloadAction<number>) {
      state.totalPrice = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getSpotifyCampaignMatchingData.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(
      getSpotifyCampaignMatchingData.fulfilled,
      (state, action) => {
        state.status = SliceStatus.Succeeded
        state.minCuratorsCount = action.payload.minCuratorsCount
        state.maxCuratorsCount = action.payload.maxCuratorsCount
        state.maxPlaylistsCount = action.payload.maxPlaylistsCount
        state.totalPrice = Number(action.payload.totalPrice.toFixed(0))
      },
    )
    builder.addCase(
      getSpotifyCampaignMatchingData.rejected,
      (state, action) => {
        state.status = SliceStatus.Failed
        state.error = setApiError(action)
      },
    )
  },
})

const getState = (state: RootState): SpotifyCampaignMatchingProcessState =>
  state.spotifyCampaignMatchingProcess

export const spotifyCampaignMinCuratorsCountSelector = createDraftSafeSelector(
  getState,
  ({ minCuratorsCount }) => minCuratorsCount,
)

export const spotifyMaxCuratorsCountSelector = createDraftSafeSelector(
  getState,
  ({ maxCuratorsCount }) => maxCuratorsCount,
)

export const spotifyMaxPlaylistsCountSelector = createDraftSafeSelector(
  getState,
  ({ maxPlaylistsCount }) => maxPlaylistsCount,
)

export const spotifyTotalPriceSelector = createDraftSafeSelector(
  getState,
  ({ totalPrice }) => totalPrice,
)

export const getSpotifyCampaignMatchingDataLoadingSelector =
  createDraftSafeSelector(
    getState,
    ({ status }) =>
      status === SliceStatus.Loading || status === SliceStatus.Idle,
  )

export const getIsEnoughBalanceSelector = createDraftSafeSelector(
  (state: RootState) => ({
    balance: Number(state.authentication.user.user.balance),
    price: Number(state.spotifyCampaignMatchingProcess.totalPrice),
  }),
  ({ balance, price }) => balance >= price && Boolean(price),
)

export const { setCampaignBudget } = spotifyCampaignMatchingProcess.actions
