import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGenresAndLanguagesAnalytics } from 'src/Hooks/useGenresAndLanguagesAnalytics'
import { changeCampaign } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/api-actions'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useLazyGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'
import { CampaignPlatformTypeEnum, SelectedLanguage } from 'src/Types'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.common.primaryBg};
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};
  div:last-child {
    width: fit-content;
    .ant-btn.ant-btn-default {
      padding: 0 40px;
    }
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

interface Props {
  selectedLanguage: SelectedLanguage | null
  targetLanguage?: number
}

export const LanguagesModalSpotifyButton: FC<Props> = ({
  selectedLanguage,
  targetLanguage,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [getCampaignBudgetRange] = useLazyGetBudgetRangeQuery()

  const { handleLanguageAddedEvent } = useGenresAndLanguagesAnalytics()

  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { setSearch } = useCustomSearchParams()

  const handleSave = (): void => {
    const languages =
      targetLanguage === 0
        ? [selectedLanguage?.value]
        : [selectedLanguage?.value, targetLanguage]

    handleLanguageAddedEvent({
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      languageId: String(selectedLanguage?.value),
      languageName: String(selectedLanguage?.label),
      isAnyLanguage: Boolean(languages.find((id) => id === ANY_LANGUAGE_ID)),
    })
    void dispatch(
      changeCampaign({
        campaign_id: campaignDetails?.id,
        languages_ids: languages,
        genres_ids: campaignDetails.genres.map((el) => Number(el.id)),
        moods_ids: null,
        max_price: null,
      }),
    ).then((res) => {
      setSearch({ modal: null })
      if (campaignDetails.genres.length) {
        getCampaignBudgetRange(String(campaignDetails?.id))
      }
      return res
    })
  }

  return (
    <Container translate='no'>
      <StyledPrimaryButton disabled={!selectedLanguage} onClick={handleSave}>
        {t('scheduleCampaignPage.addLanguage')}
      </StyledPrimaryButton>
    </Container>
  )
}
