import { FC } from 'react'

import styled from 'styled-components'

import { PrimaryInput } from 'src/Components/PrimaryInput'
import { TextArea } from 'src/Components/TextArea'
import { variables } from 'src/Styled/variables'
import { PrimaryRadio } from 'src/Components/PrimaryRadio'

const StyledRadio = styled(PrimaryRadio)`
  color: ${({ theme }) => theme.common.primaryText};
  gap: 16px;
  height: 40px;

  span {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 4px;
`

const StyledImage = styled.img`
  width: 16px;
  height: 16px;
`

const ItemName = styled.span`
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

export interface SurveyItemProps {
  image: string
  inputType?: 'input' | 'textarea'
  title: string
  placeholder?: string
  isChecked?: boolean
}

const TEXTAREA_MAX_LENGTH = 500

export const SurveyItem: FC<SurveyItemProps> = ({
  image,
  title,
  inputType,
  placeholder,
  isChecked,
  ...props
}) => (
  <Container>
    <StyledRadio value={title}>
      <StyledImage src={image} alt='error' />
      <ItemName>{title}</ItemName>
    </StyledRadio>

    {isChecked && inputType === 'input' && (
      <PrimaryInput {...props} placeholder={placeholder} />
    )}

    {isChecked && inputType === 'textarea' && (
      <TextArea
        {...props}
        placeholder={placeholder}
        style={{ height: 180, resize: 'none' }}
        count={{
          show: (count) =>
            count.value ? `${count.count}/${TEXTAREA_MAX_LENGTH}` : '',
        }}
        maxLength={TEXTAREA_MAX_LENGTH}
      />
    )}
  </Container>
)
