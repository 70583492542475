import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

type LastPaymentMethod = {
  type: 'paypal' | 'stripe' | null
}

const lastPaymentMethodApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getLastPaymentMethod: builder.query<LastPaymentMethod, void | null>({
      query: () => `${APP.TIKTOK_SERVER}/artist/payments/last-used-type`,
    }),
  }),
  overrideExisting: false,
})

export const { useGetLastPaymentMethodQuery } = lastPaymentMethodApi
