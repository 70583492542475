import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { WiseCurrency } from 'src/Types'

interface GetCurrenciesData {
  data: WiseCurrency[]
}

const wiseCurrenciesApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<GetCurrenciesData, void | null>({
      query: () => `${APP.TIKTOK_SERVER}/payout/transferwise/currencies`,
      providesTags: ['WiseCurrencies'],
    }),
  }),
})

export const { useGetCurrenciesQuery } = wiseCurrenciesApi
