import React, { FC } from 'react'

import styled from 'styled-components'

import { SmartImage } from 'src/Components/SmartImage'
import { CampaignPlatformLabel } from 'src/Components/CampaignPlatformLabel'
import TrackCoverIcon from 'src/Assets/Svg/pages-icons/TrackCover.svg'

import { SecondaryScheduleSummaryProps } from '../SecondaryScheduleSummary'

const Wrapper = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const Cover = styled(SmartImage)`
  width: 68px;
  height: 68px;
  border-radius: 16px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const TrackName = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const TrackArtist = styled(TrackName)`
  font-weight: 400;
`

type Props = Pick<
  SecondaryScheduleSummaryProps,
  'artistName' | 'trackName' | 'platform' | 'cover'
>

export const SecondaryScheduleSummaryDetailsHeader: FC<Props> = (props) => {
  const { artistName, trackName, platform, cover } = props
  return (
    <Wrapper>
      <Cover src={cover} fallbackSrc={TrackCoverIcon} />
      <Info>
        <TrackInfo>
          <TrackName>{trackName}</TrackName>
          <TrackArtist>{artistName}</TrackArtist>
        </TrackInfo>
        <CampaignPlatformLabel platform={platform} />
      </Info>
    </Wrapper>
  )
}
