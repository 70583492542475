import React, { FC, PropsWithChildren } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import { StyleSheetManager, ThemeProvider } from 'styled-components'

import { themeColors } from 'src/Styled/variables'

import { GlobalStyles } from './GlobalStyles/index'

const currentTheme = 'light'

export const StyleSheetManagerWrapper: FC<PropsWithChildren> = ({
  children,
}) => (
  <ThemeProvider theme={themeColors[currentTheme]}>
    <GlobalStyles />
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {children}
    </StyleSheetManager>
  </ThemeProvider>
)
// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: unknown): boolean {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}
