import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { CampaignVideos, InfluencerEventStatus, Meta } from 'src/Types'

import {
  CampaignPreModerationVideo,
  mapVideoToPreModerationStatus,
} from './mapVideoToPreModerationStatus'

interface GetCampaignTikTokVideosStatsData {
  statuses: Array<{
    status: InfluencerEventStatus
    count: number
  }>
}
export type GetCampaignVideosData = {
  videos: CampaignPreModerationVideo[]
  meta: Meta
}

type GetCampaignVideosResponse = {
  data: CampaignVideos
  meta: Meta
}

const allTabVideoStatuses = [
  InfluencerEventStatus.AWAITING_REVIEW,
  InfluencerEventStatus.APPROVED_AUTOMATICALLY,
  InfluencerEventStatus.APPROVED_BY_ARTIST,
  InfluencerEventStatus.APPROVED_BY_ADMIN,
  InfluencerEventStatus.AWAITING_MODERATOR_REVIEW,
  InfluencerEventStatus.DECLINED,
  InfluencerEventStatus.VIDEO_DISMISSED,
]

const awaitingTabVideoStatuses = [InfluencerEventStatus.AWAITING_REVIEW]

interface CampaignVideosCounters {
  all?: number
  awaiting?: number
}

export enum CampaignVideosTabs {
  ALL,
  AWAITING,
}

const transformStatusesToString = (
  statuses: Array<InfluencerEventStatus>,
): string =>
  statuses.map((value) => `status=${encodeURIComponent(value)}`).join('&')

const MAP_STATUSES_TO_TABS = {
  [CampaignVideosTabs.ALL]: transformStatusesToString(allTabVideoStatuses),
  [CampaignVideosTabs.AWAITING]: transformStatusesToString(
    awaitingTabVideoStatuses,
  ),
}

type GetCampaignVideosBody = {
  campaignId?: string
  pageNumber: number
  pageSize?: number
}

const DEFAULT_PAGE_SIZE = 10

const campaignVideosApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      getCampaignVideosStats: build.query<
        CampaignVideosCounters,
        string | undefined
      >({
        query: (campaignId) => ({
          url: `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/events/stats`,
        }),
        transformResponse: (response: GetCampaignTikTokVideosStatsData) => ({
          all: response?.statuses.reduce((acc, status) => {
            if (allTabVideoStatuses.includes(status.status)) {
              return acc + status.count
            }
            return acc
          }, 0),
          awaiting: response?.statuses.find(
            (status) => status.status === InfluencerEventStatus.AWAITING_REVIEW,
          )?.count,
        }),
      }),
      getCampaignVideos: build.query<
        GetCampaignVideosData,
        GetCampaignVideosBody
      >({
        query: ({ campaignId, pageNumber, pageSize = DEFAULT_PAGE_SIZE }) => {
          const statuses = MAP_STATUSES_TO_TABS[CampaignVideosTabs.ALL]
          return {
            url: `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/events?pageSize=${pageSize}&pageNumber=${pageNumber}&${statuses}`,
          }
        },
        transformResponse: (response: GetCampaignVideosResponse) => ({
          videos: response?.data.map(mapVideoToPreModerationStatus),
          meta: response?.meta,
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems) => ({
          videos: [...currentCache.videos, ...newItems.videos],
          meta: newItems.meta,
        }),
        forceRefetch(params) {
          return (
            params?.currentArg?.campaignId !== params?.previousArg?.campaignId
          )
        },
      }),
      getCampaignAwaitingVideos: build.query<
        GetCampaignVideosData,
        GetCampaignVideosBody
      >({
        query: ({ campaignId, pageNumber }) => {
          const statuses = MAP_STATUSES_TO_TABS[CampaignVideosTabs.AWAITING]
          return {
            url: `${APP.TIKTOK_SERVER}/artist/tiktok/campaigns/${campaignId}/events?pageSize=10&pageNumber=${pageNumber}&${statuses}`,
          }
        },
        transformResponse: (response: GetCampaignVideosResponse) => ({
          videos: response?.data.map(mapVideoToPreModerationStatus),
          meta: response?.meta,
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems) => ({
          videos: [...currentCache.videos, ...newItems.videos],
          meta: newItems.meta,
        }),
        forceRefetch(params) {
          return (
            params?.currentArg?.campaignId !== params?.previousArg?.campaignId
          )
        },
      }),
    }
  },
})

export const { useGetCampaignVideosQuery } = campaignVideosApi
