import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import { Tooltip } from 'src/Components/Tooltip'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { RewardPlaylist } from '../../types'
import { PlaylistCoverTooltipContent } from '../PlaylistCoverTooltipContent'

import {
  Container,
  CounterWrapper,
  CoverImageWrapper,
  DetailsContainer,
  ScoreValue,
} from './styles'

interface RewardPlaylistCardProps {
  playlist: RewardPlaylist
  isOpener: boolean
  showMorePlaylistsCount: number
}

export const RewardPlaylistCard: FC<RewardPlaylistCardProps> = ({
  playlist,
  isOpener,
  showMorePlaylistsCount,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
  const { imageUrl, playlistName, followers } = playlist

  const tooltipContent = !isMobile && !isTablet && (
    <PlaylistCoverTooltipContent playlist={playlist} />
  )

  const openerContent =
    isOpener && (!isMobile || !isTablet) ? (
      <CounterWrapper>+{showMorePlaylistsCount}</CounterWrapper>
    ) : null

  const formattedFollowers = followers && addSpaceToNumber(followers, ',')

  const detailContent =
    isMobile || isTablet ? (
      <DetailsContainer>
        <span>{playlistName}</span>
        <ScoreValue>{formattedFollowers} followers</ScoreValue>
      </DetailsContainer>
    ) : null
  // Don`t remove key prop from Tooltip
  // Because  Tooltip component  is not re-rendered when the content changes
  return (
    <Tooltip key={imageUrl + String(isOpener)} content={tooltipContent}>
      <Container>
        <CoverImageWrapper>
          <SmartImage src={imageUrl} alt='playlistCover' fallbackSrc={track} />
          {openerContent}
        </CoverImageWrapper>
        {detailContent}
      </Container>
    </Tooltip>
  )
}
