import { FC, useMemo } from 'react'

import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getCampaignToSchedule,
  getCampaignToScheduleLoading,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ScheduleSettingsPageSkeleton } from 'src/Components/Skeletons/ScheduleSettingsPageSkeleton'
import { ScheduleSettingsHeader } from 'src/Components/ScheduleSettingsHeader'
import { CampaignPlatformTypeEnum } from 'src/Types'
import _dayjs from 'src/Helpers/dayjs'
import { setStartDate } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { PayPalProcessingModalSpotify } from 'src/Pages/Artist/SpotifySchedulePage/PayPalProcessingModal'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

import { SpotifyTrackParameters } from './SpotifyTrackParametrs'
import { SpotifyCampaignSteps } from './SpotifyCampaignSteps'
import { SpotifyScheduleModals } from './SpotifyScheduleModals'

const Container = styled.div`
  display: flex;
  max-width: 1120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  @media screen and (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    align-self: stretch;
  }
`

export const SpotifyScheduleSettingsPage: FC = () => {
  const dispatch = useAppDispatch()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const loading = useAppSelector(getCampaignToScheduleLoading)
  const { data: subscription } = useGetSubscriptionQuery()
  const hasActivePlan = subscription?.status === 'active'

  const startDate = useMemo(() => {
    if (campaignDetails.start_date) {
      return _dayjs(campaignDetails.start_date)
    }
    return hasActivePlan ? _dayjs() : _dayjs().add(1, 'day')
  }, [campaignDetails.start_date, hasActivePlan])

  if (loading) {
    return (
      <>
        <ScheduleSettingsPageSkeleton />
        <PayPalProcessingModalSpotify />
      </>
    )
  }

  return (
    <>
      <PayPalProcessingModalSpotify />
      <SpotifyScheduleModals />
      <Container>
        <ScheduleSettingsHeader
          artistName={campaignDetails.track.author}
          trackCover={campaignDetails.track.cover}
          trackName={campaignDetails.track.title}
          platform={CampaignPlatformTypeEnum.SPOTIFY}
          startDate={startDate}
          todayEnable={hasActivePlan}
          onChangeDate={(date) => {
            dispatch(setStartDate(date.format()))
          }}
        />
        <Wrapper>
          <SpotifyTrackParameters />
          <SpotifyCampaignSteps />
        </Wrapper>
      </Container>
    </>
  )
}
