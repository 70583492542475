import React, { FC } from 'react'

import styled from 'styled-components'

import { useCheckTikTokCheckoutStepIsAllowed } from 'src/Pages/Artist/TiktokSchedulePage/helpers/useCheckTikTokCheckoutStepIsAllowed'
import { usePaymentCancelledTikTokEvents } from 'src/Hooks/usePaymentCancelledTikTokEvents'
import { SchedulePaymentMethod } from 'src/Components/SchedulePaymentMethod'
import { ScheduleSummary } from 'src/Components/ScheduleSummary'
import { ScheduleCheckoutErrors } from 'src/Components/ScheduleCheckoutErrors'
import { useAppSelector } from 'src/Hooks/redux'
import { getPaymentAlertError } from 'src/Redux/payment-process/selectors'
import { getIsProcessingPage } from 'src/Redux/paypalTransactions-process/selectors'
import { SchedulePaymentProcessingModal } from 'src/Components/SchedulePaymentProcessingModal'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { useTikTokScheduleSummary } from './useTikTokScheduleSummary'
import { useTikTokSchedulePaymentMethod } from './useTikTokSchedulePaymentMethod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    align-items: flex-start;
    flex-direction: row;
    align-self: stretch;
  }
`

export const TiktokScheduleCheckoutPage: FC = () => {
  useCheckTikTokCheckoutStepIsAllowed()
  usePaymentCancelledTikTokEvents()

  const tiktokSchedulePaymentMethodProps = useTikTokSchedulePaymentMethod()
  const tiktokScheduleSummaryProps = useTikTokScheduleSummary()

  const paymentError = useAppSelector(getPaymentAlertError)
  const isProcessingPageVisible = useAppSelector(getIsProcessingPage)
  return (
    <>
      {isProcessingPageVisible && <SchedulePaymentProcessingModal />}
      {paymentError && <ScheduleCheckoutErrors name={paymentError} />}
      <Wrapper>
        <SchedulePaymentMethod {...tiktokSchedulePaymentMethodProps} />
        <ScheduleSummary {...tiktokScheduleSummaryProps} />
      </Wrapper>
    </>
  )
}
