import React, { FC, PropsWithChildren } from 'react'

import { useMediaQuery } from 'react-responsive'

import { PageLayout } from 'src/Components/PageLayout'
import { Roles } from 'src/Constants/enums'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getSidebarVisibility,
  toggleSidebarVisibility,
} from 'src/Redux/pageLayout-process'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { HeaderContainer } from '../Components/HeaderContainer'

import { CreatorSidebarContainer } from './CreatorSidebarContainer'

export const CreatorPageLayoutContainer: FC<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useAppDispatch()
  const isSidebarCollapsed = useAppSelector(getSidebarVisibility)
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })

  const onMenuItemClick = (): void => {
    if (isMobile) {
      dispatch(toggleSidebarVisibility())
    }
  }
  const onSidebarToggleClick = (): void => {
    dispatch(toggleSidebarVisibility())
  }
  return (
    <PageLayout
      Header={HeaderContainer}
      Sidebar={CreatorSidebarContainer}
      isHeaderHidden={false}
      onSidebarToggleClick={onSidebarToggleClick}
      isSidebarCollapsed={isSidebarCollapsed}
      newUIPopup={false}
      role={Roles.curator}
      onMenuItemClick={onMenuItemClick}
    >
      {children}
    </PageLayout>
  )
}
