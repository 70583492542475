import { FC } from 'react'

import { useParams, useNavigate } from 'react-router-dom'

import { sounds } from 'src/Router/routes'
import { PrimaryTabs } from 'src/Components/PrimaryTabs'

import { TabKeysEnum } from '../../types'

import { useTracksTable } from './useTracksTable'

enum TabKeys {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  SUBMITTED = 'submitted',
}

export const TracksTable: FC = () => {
  const { activeTab } = useParams<{
    activeTab: TabKeys
  }>()

  const navigate = useNavigate()

  const { tabs } = useTracksTable()
  const defaultTab = activeTab || TabKeysEnum.NEW

  const onTabChange = (key: string): void => {
    navigate(`${sounds}/${key}`)
  }

  return (
    <PrimaryTabs
      items={tabs}
      defaultActiveKey={defaultTab}
      onChange={onTabChange}
    />
  )
}
