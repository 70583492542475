import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'
import { Roles } from 'src/Constants/enums'

import { PrimaryHeaderProps } from '../Header/Components/PrimaryHeader'
import { SidebarProps } from '../Sidebar'
import { NewUIPopup } from '../NewUIPopup'
import { NewBonusesPopup } from '../NewBonusesPopup'

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar header'
    'sidebar content';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    height: 100vh;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  > div:first-child {
    margin: 0 auto;
  }
`

const Content = styled.div<{ $isHeaderHidden?: boolean }>`
  grid-area: content;
  height: 100%;
  overflow: scroll;
  padding: 24px 40px;
  margin: 0 auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    padding: 24px 24px;
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: ${({ $isHeaderHidden }) =>
      $isHeaderHidden ? '24px 16px 24px' : '107px 16px 24px'};
  }
`

export type PageLayoutProps = PropsWithChildren & {
  Header: FC<Pick<PrimaryHeaderProps, 'withShadow' | 'onSidebarToggleClick'>>
  Sidebar: FC<
    Pick<SidebarProps, 'collapsed' | 'onLogoClick' | 'onMenuItemClick'>
  >
  isHeaderHidden?: boolean
  onSidebarToggleClick: () => void
  isSidebarCollapsed: boolean
  newUIPopup?: boolean
  handleClickNewUIPopup?: () => void
  role?: Roles
  onMenuItemClick?: () => void
  showNewBonusesPopup?: boolean
  onCloseNewBonusesPopup?: () => void
}

const HeaderWrapper = styled.div`
  grid-area: header;
  z-index: 3;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    position: fixed;
    width: 100%;
    top: 0;
  }
`

const SidebarWrapper = styled.div`
  grid-area: sidebar;
  z-index: 4;
`

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  Header,
  role,
  Sidebar,
  isHeaderHidden,
  onSidebarToggleClick,
  isSidebarCollapsed,
  newUIPopup,
  handleClickNewUIPopup,
  onMenuItemClick,
  showNewBonusesPopup,
  onCloseNewBonusesPopup,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isContentScrolled, setIsContentScrolled] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })

  useEffect(() => {
    const currentContent = isMobile ? window : contentRef.current

    const handleScroll = (): void => {
      if (currentContent && 'scrollY' in currentContent) {
        setIsContentScrolled(currentContent.scrollY > 0)
        return
      }
      currentContent && setIsContentScrolled(currentContent.scrollTop > 0)
    }
    currentContent?.addEventListener('scroll', handleScroll)
    currentContent?.scrollTo(0, 0)
    return () => {
      currentContent &&
        currentContent.removeEventListener('scroll', handleScroll)
    }
  }, [isMobile, children])

  return (
    <Container>
      {!isHeaderHidden && (
        <HeaderWrapper>
          <Header
            withShadow={isContentScrolled}
            onSidebarToggleClick={onSidebarToggleClick}
          />
        </HeaderWrapper>
      )}

      <SidebarWrapper>
        <Sidebar
          collapsed={isSidebarCollapsed}
          onLogoClick={onSidebarToggleClick}
          onMenuItemClick={onMenuItemClick}
        />
      </SidebarWrapper>
      {showNewBonusesPopup && (
        <NewBonusesPopup onClosePopup={onCloseNewBonusesPopup} />
      )}
      <NewUIPopup
        role={role}
        newUIPopup={newUIPopup}
        handleClickNewUIPopup={handleClickNewUIPopup}
      />
      <Content
        $isHeaderHidden={isHeaderHidden}
        ref={contentRef}
        id='scrollableDiv'
      >
        <Wrapper>{children}</Wrapper>
      </Content>
    </Container>
  )
}
