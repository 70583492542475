import {
  combineReducers,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { RootState } from '..'

import { mainCampaignProcess } from './spotifyCampaignSchedule-process'

const curatorsCountExp = createSlice({
  name: 'curatorsCountExp',
  initialState: 0,
  reducers: {
    setCuratorsCountExp: (_, action) => action.payload,
  },
})

export const { setCuratorsCountExp } = curatorsCountExp.actions

const getState = (state: RootState): number => state.campaigns.curatorsCountExp

export const getCuratorsCountExp = createDraftSafeSelector(
  getState,
  (count) => count,
)

export const campaignProcess = combineReducers({
  curatorsCountExp: curatorsCountExp.reducer,
  main: mainCampaignProcess.reducer,
})
