import { FC, ReactNode } from 'react'

import styled from 'styled-components'
import { Modal } from 'antd'

import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'

const StyledModal = styled(Modal)`
  max-width: 600px;
  width: 100% !important;

  .ant-modal-body {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative !important;
    padding-top: 51px;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
  }

  .ant-modal-content {
    width: 100% !important;
    padding: 0;
    border-radius: 16px;
    border: 1px solid #e7e7e7;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
  }

  .ant-modal-close-icon {
    svg {
      path {
        fill: ${({ theme }) => theme.common.thirdBg};
      }
    }
  }
  top: 9vh;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    top: 9vh;
    max-width: 288px !important;
    width: 100% !important;
    height: calc(100vh - 18vh) !important;
    border-radius: 16px;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    animation: fadeInModal 0.3s ease-out 0.1s forwards;
  }
  @keyframes fadeInModal {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.8);
      visibility: hidden;
    }
    1% {
      visibility: visible;
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      visibility: visible;
    }
  }
`

const Title = styled.span`
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize18};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const TitleWrapper = styled.div`
  height: 54px;
  position: absolute;
  top: 0px;
  padding-left: 24px;
  padding-top: 16px;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    position: fixed;
    z-index: 4;
    background-color: ${({ theme }) => theme.common.primaryBg};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`

const HeaderBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.common.primaryBorder};
  position: absolute;
  top: 54px;
  left: 0;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    position: fixed;
  }
`

export interface PrimaryInfoModalProps {
  children: ReactNode
  onClose: () => void
  isOpen: boolean
  title: string
  className?: string
}

export const PrimaryInfoModal: FC<PrimaryInfoModalProps> = ({
  children,
  onClose,
  isOpen,
  title,
  className,
}) => (
  <StyledModal
    open={isOpen}
    footer={null}
    onCancel={onClose}
    className={className}
  >
    <HeaderBorder />
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    {children}
  </StyledModal>
)
