import styled from 'styled-components'

import { device } from 'src/Styled/variables'

export const CardsImgWrapper = styled.div`
  display: flex;
  padding: 12px 0 4px 8px;
  flex-wrap: wrap;
  gap: 4px;
  @media ${device.smallScreen} {
    padding: 12px 0 12px 4px;
  }
`
export const Card = styled.div`
  display: flex;
  width: 48px;
  height: 24px;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div<{ active?: boolean }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  font-size: 16px !important;
`
