import styled from 'styled-components'

import { MAX_MOBILE_WIDTH, colors, variables } from 'src/Styled/variables'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 24px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-bottom: 80px;
    gap: 40px;
  }
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize20};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
export const Container = styled.div`
  padding-bottom: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  width: 100%;
`
export const Text = styled.span`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
`
export const SubTitle = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
