import { FunctionComponent, ReactNode } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

import InfinityLoader from 'src/Components/InfinityLoader'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'

import { TabKeysEnum } from '../../types'

import { LoaderWrapper, SoundsListContainer, SoundsListWrapper } from './styles'

interface SoundsListProps {
  soundsAmount: number
  onLoadMore: () => void
  isLoading: boolean
  hasNextPage: boolean
  children: ReactNode
  tabType: TabKeysEnum
  isTikTokProfileError?: boolean
}

export const SoundsList: FunctionComponent<SoundsListProps> = ({
  soundsAmount,
  onLoadMore,
  isLoading,
  hasNextPage,
  children,
  tabType,
  isTikTokProfileError,
}) => {
  const loadMore = (): void => {
    onLoadMore()
  }

  if (soundsAmount === 0 && isLoading) {
    return (
      <SoundsListContainer>
        <LoaderWrapper>
          <InfinityLoader isLoading={true} />
        </LoaderWrapper>
      </SoundsListContainer>
    )
  }

  if (
    isTikTokProfileError &&
    [TabKeysEnum.NEW, TabKeysEnum.IN_PROGRESS].includes(tabType)
  ) {
    return (
      <SoundsListContainer>
        <ProblemsWithTikTokErrorAlert />
      </SoundsListContainer>
    )
  }

  return (
    <SoundsListContainer>
      <InfiniteScroll
        scrollableTarget='scrollableDiv'
        dataLength={soundsAmount}
        next={loadMore}
        hasMore={hasNextPage}
        loader={
          <LoaderWrapper>
            <InfinityLoader isLoading={true} />
          </LoaderWrapper>
        }
      >
        <SoundsListWrapper>{children}</SoundsListWrapper>
      </InfiniteScroll>
    </SoundsListContainer>
  )
}
