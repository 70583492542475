import SpotifyIcon from 'mdi-react/SpotifyIcon'
import { t } from 'i18next'

import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { ReviewDetails } from 'src/Redux/transactions-process/curatorBalanceHistorySlice'
import { ReviewerBalanceHistory } from 'src/Constants/enums'

export const getDescriptionCellContent = (
  type: ReviewerBalanceHistory,
  description?: string,
  reviewDetails?: ReviewDetails,
): {
  story: string | undefined
  subtext: string | undefined
  showSpotifyIcon: boolean
  showGiftIcon: boolean
  showWithdrawText: boolean
  icon: typeof SpotifyIcon
} => {
  const reviewType = t('balancePage.reviewFor')

  const getTextByType = (
    arg: ReviewerBalanceHistory,
    args?: ReviewDetails,
  ): string | undefined => {
    if (arg === ReviewerBalanceHistory.REVIEW_BONUS_PLACEMENT) {
      return `Discover bonus for “${getValueOrEmptyString(args?.trackName)}” by ${getValueOrEmptyString(args?.artistName)}`
    }

    if (arg === ReviewerBalanceHistory.REVIEW_BONUS_LIKE) {
      return `Top Review bonus for  “${getValueOrEmptyString(args?.trackName)}” by ${getValueOrEmptyString(args?.artistName)}`
    }

    if (reviewDetails) {
      return `${reviewType} “${getValueOrEmptyString(args?.trackName)}” by ${getValueOrEmptyString(args?.artistName)}`
    }

    return description
  }

  const showSpotifyIcon = [
    ReviewerBalanceHistory.PAYMENT,
    ReviewerBalanceHistory.REWARD_WITHOUT_PLACEMENT,
    ReviewerBalanceHistory.REWARD_WITH_PLACEMENT,
    ReviewerBalanceHistory.WITHDRAW,
  ].includes(type)

  const showGiftIcon = [
    ReviewerBalanceHistory.REVIEW_BONUS_PLACEMENT,
    ReviewerBalanceHistory.REVIEW_BONUS_LIKE,
  ].includes(type)

  const showWithdrawText = type === ReviewerBalanceHistory.WITHDRAW

  const getSubtextByType = (arg?: string): string => {
    if (arg === ReviewerBalanceHistory.REVIEW_BONUS_LIKE) {
      return 'Artist selected your review as one of the best'
    }

    if (arg === ReviewerBalanceHistory.REVIEW_BONUS_PLACEMENT) {
      return "Your review increased track's reach"
    }

    if (arg === ReviewerBalanceHistory.CANCELED) {
      return 'Payout was canceled by the admin, please contact support'
    }

    return ''
  }

  return {
    showSpotifyIcon,
    showGiftIcon,
    showWithdrawText,
    subtext: getSubtextByType(type),
    story: getTextByType(type, reviewDetails),
    icon: SpotifyIcon,
  }
}
