import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  getIsPayPalTransactionsLoading,
  getRememberPayPalForFutureFlag,
} from 'src/Redux/paypalTransactions-process/selectors'
import { PaymentMethodEnum } from 'src/Constants/enums'
import {
  getPaymentLoading,
  getPaymentMethod,
} from 'src/Redux/payment-process/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import i18n from 'src/Localization/i18n'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import { getAccessToken } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'

import { usePayPalPayment } from './usePayPalPayment'
import { useStripePayment } from './useStripePayment'
import { usePayPalTransactionPayment } from './usePayPalTransactionPayment'
import { useCheckoutEvents } from './useCheckoutEvents'
import { useCreditsPayment } from './useCreditsPayment'
import { useAppDispatch, useAppSelector } from './redux'

const MAP_PAYMENT_METHOD_TO_BUTTON_TYPE: Record<
  PaymentMethodEnum,
  'green' | 'paypal'
> = {
  [PaymentMethodEnum.STRIPE]: 'green',
  [PaymentMethodEnum.Credits]: 'green',
  [PaymentMethodEnum.PAYPAL]: 'paypal',
  [PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT]: 'paypal',
}

const MAP_PAYMENT_METHOD_TO_BUTTON_TEXT: Record<string, string> = {
  default: i18n.t('scheduleCampaignPage.confirm&Pay'),
  [PaymentMethodEnum.Credits]: i18n.t('scheduleCampaignPage.launchCampaign'),
}

export const useCheckoutButton = (
  platformType: CampaignPlatformTypeEnum,
): {
  isLoading: boolean
  buttonType: 'green' | 'paypal'
  onClickContinue: () => void
  text: string
} => {
  const {
    handleSpotifyCheckoutConfirmedPaymentEvent,
    handleTiktokCheckoutConfirmedPaymentEvent,
    sendPaymentDataToWebhook,
  } = useCheckoutEvents()

  const { pay: chargePayPal, isLoading: isPaypalLoading } =
    usePayPalPayment(platformType)
  const { pay: chargeStripe } = useStripePayment(platformType)
  const { pay: chargePayPalTransaction } =
    usePayPalTransactionPayment(platformType)
  const { pay: chargeCredits } = useCreditsPayment(platformType)
  const dispatch = useAppDispatch()

  const isRememberPayPalForFutureFlag = useSelector(
    getRememberPayPalForFutureFlag,
  )
  const paymentMethod = useSelector(getPaymentMethod)
  const isPaymentLoading = useSelector(getPaymentLoading)
  const isPayPalTransactionsLoading = useSelector(
    getIsPayPalTransactionsLoading,
  )
  const param = useParams()
  const campaignId = Number(param.number)
  const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))
  const token = useAppSelector(getAccessToken)
  const onClickContinue = (): void => {
    // send sourcebuster event for purchase_started
    void dispatch(
      sendSourceBusterEvent({
        token,
        campaignId,
        campaignPlatform: platformType,
        eventName: 'purchase_started',
        gclid: getValueOrEmptyString(utmObj.gclid),
      }),
    )
    if (paymentMethod === PaymentMethodEnum.PAYPAL) {
      chargePayPal()
    }

    if (paymentMethod === PaymentMethodEnum.STRIPE) {
      chargeStripe()
    }

    if (paymentMethod === PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT) {
      chargePayPalTransaction()
    }
    if (paymentMethod === PaymentMethodEnum.Credits) {
      chargeCredits()
    }
    if (platformType === CampaignPlatformTypeEnum.TIKTOK) {
      handleTiktokCheckoutConfirmedPaymentEvent()
    } else {
      handleSpotifyCheckoutConfirmedPaymentEvent()
    }

    if (paymentMethod !== PaymentMethodEnum.Credits) {
      const formattedPaymentMethod =
        isRememberPayPalForFutureFlag &&
        paymentMethod === PaymentMethodEnum.PAYPAL
          ? PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
          : paymentMethod

      sendPaymentDataToWebhook({
        platform: platformType,
        selectedPaymentMethod: formattedPaymentMethod as PaymentMethodEnum,
      })
    }
  }

  const buttonType =
    MAP_PAYMENT_METHOD_TO_BUTTON_TYPE[paymentMethod] ||
    MAP_PAYMENT_METHOD_TO_BUTTON_TYPE[PaymentMethodEnum.STRIPE]

  const isLoading =
    isPaymentLoading || isPayPalTransactionsLoading || isPaypalLoading

  const text =
    MAP_PAYMENT_METHOD_TO_BUTTON_TEXT[paymentMethod] ||
    MAP_PAYMENT_METHOD_TO_BUTTON_TEXT.default

  return {
    text,
    isLoading,
    buttonType,
    onClickContinue,
  }
}
