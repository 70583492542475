import { useCallback, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { getPlaylists } from 'src/Redux/playlists-process/api-actions'
import { RewardData, useGetRewardDataQuery } from 'src/Redux/rewardApi'
import { useAppDispatch } from 'src/Hooks/redux'
import { getPlaylist } from 'src/Redux/playlists-process/selectors'

import { RewardPlaylist } from './types'

const NEGATIVE_INDEX = -1

export type RewardWithPlaylist = RewardData & {
  playlists: Array<RewardPlaylist>
}

export const useRewardPage = (): {
  rewardsWithPlaylists: Array<RewardWithPlaylist>
} => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getPlaylists())
  }, [dispatch])

  const playlists = useSelector(getPlaylist)

  const { data: rewardsData } = useGetRewardDataQuery()

  const getRewardIndexForFollowers = useCallback(
    (followers: number): number =>
      rewardsData?.findLastIndex((reward) => followers >= reward.followers) ??
      NEGATIVE_INDEX,
    [rewardsData],
  )

  const rewardsWithPlaylists = useMemo(() => {
    if (!rewardsData) {
      return []
    }

    const rewardsDataWithPlaylists: Array<RewardWithPlaylist> =
      rewardsData?.map((rewardItem) => ({
        ...rewardItem,
        playlists: [],
      }))

    playlists.forEach(
      (playlist) => {
        const index = getRewardIndexForFollowers(playlist.followers)
        if (index !== NEGATIVE_INDEX) {
          const modifiedPlaylist = {
            id: Number(playlist.id),
            imageUrl: playlist.playlist_cover,
            linkUrl: playlist.playlist_url,
            playlistName: playlist.playlist_name,
            followers: playlist.followers,
          }

          rewardsDataWithPlaylists[index]?.playlists?.push(modifiedPlaylist)
        }
      },
      [playlists, rewardsData],
    )

    return rewardsDataWithPlaylists
  }, [getRewardIndexForFollowers, playlists, rewardsData])

  return {
    rewardsWithPlaylists,
  }
}
