import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const ContainerDesktop = styled.div`
  padding: 20px 24px 14px 24px;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`

export const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 16px 0px;
  border-bottom: 1px dashed #e7e7e7;
  &:last-child {
    border-bottom: none;
    padding: 16px 0px 13px 0px;
  }
`

export const ContainerMobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

export const ValueItemMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px 11px 0px;
  border-radius: 4px;
`
export const ValuesListMobile = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`

export const ValueDesktop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 150%;
`

export const FollowersMobile = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

export const ValueMobile = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight400};
`
