import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

export type SubscriptionStatus = 'active' | 'canceled' | 'ended'

interface Package {
  cashbackPercentage: number
  id: number
  name: string
  paymentAmount: number
}
export interface Subscription {
  lastPaymentDate: string
  id: number
  nextPaymentDate: string
  canceledAt: string
  userId: number
  packageId: number
  paymentType: string
  status: SubscriptionStatus
  paymentAmount: number
  activeUserId: number
  package: Package | null
}

interface SubscriptionPayload {
  fullPaymentAmount: number
  packageId: number
  paymentAmount: number
  paymentType: string
  userPaymentMethodId: number
}

const subscriptionApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscription: builder.query<Subscription, void | null>({
      query: () => `${APP.TIKTOK_SERVER}/subscriptions/user-last`,
      providesTags: ['Subscription'],
    }),
    cancelSubscription: builder.mutation<unknown, number>({
      query: (id) => ({
        url: `${APP.TIKTOK_SERVER}/subscriptions/${id}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Subscription'],
    }),
    changeSubscription: builder.mutation<
      Omit<Subscription, 'package'>,
      SubscriptionPayload
    >({
      query: (body) => ({
        url: `${APP.TIKTOK_SERVER}/subscriptions/change`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Subscription'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSubscriptionQuery,
  useCancelSubscriptionMutation,
  useChangeSubscriptionMutation,
} = subscriptionApi
