import React, { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { ScheduleSteps } from 'src/Components/PrimaryScheduleTabs'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { SimilarGenre } from 'src/Redux/similarGenres-process'
import { getSimilarGenres } from 'src/Redux/similarGenres-process/api-actions'
import { getGroupedGenres } from 'src/Redux/similarGenres-process/selectors'
import { ProperGenreOrLanguage, Genre } from 'src/Types'
import { ScheduleModalsTitle } from 'src/Components/ScheduleModalsTitle'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { GenresModalSpotifyList } from './GenresModalSpotifyList'
import { GenresModalSpotifyButton } from './GenresModalSpotifyButton'

const ListWrapper = styled.div`
  padding: 0px 16px 16px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow-x: scroll;
  height: calc(100vh - 214px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    padding: 0px 0px 16px;
    height: calc(100vh - 255px);
  }
`
interface Props {
  genres?: ProperGenreOrLanguage[]
  onSave: (genres: Genre[]) => void
  campaignId?: number
  isLoading: boolean
}

const INPUT_DELAY = 300

export const GenresModalSpotify: FC<Props> = ({
  onSave,
  genres,
  campaignId,
  isLoading,
}) => {
  const { setSearch } = useCustomSearchParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')
  const [selectedGenres, setSelectedGenres] = useState<SimilarGenre[]>([])
  const groupedOptions = useAppSelector(getGroupedGenres)

  useEffect(() => {
    if (genres?.length) {
      const updatedGenres = genres.map((item) => ({
        id: Number(item.id),
        genreName: item.name,
      }))
      setSelectedGenres(updatedGenres)
    }
  }, [genres])
  useEffect(() => {
    if (campaignId) {
      void dispatch(getSimilarGenres(campaignId))
    }
  }, [dispatch, campaignId])

  const handleClose = (): void => {
    setSearch({ step: ScheduleSteps.SETTINGS, modal: null })
  }
  const handleSetValue = useDebouncedCallback((text: string) => {
    setValue(text)
  }, INPUT_DELAY)

  const handleClickGenre = (id: number): void => {
    setSelectedGenres((prev) => {
      const isGenreSelected = prev.some((item) => item.id === id)
      if (isGenreSelected) {
        return prev.filter((item) => item.id !== id)
      }
      const genre = groupedOptions[1].options.find((item) => item.id === id)
      if (!genre) {
        return prev
      }
      return [...prev, genre]
    })
  }

  const handleSave = (): void => {
    const selected = selectedGenres.map((el) => ({
      id: el.id,
      genre_name: el.genreName,
    }))
    onSave(selected)
  }

  return (
    <FullScreenModal
      showCloseButton
      onCancel={handleClose}
      open
      prefixCls='genres'
    >
      <ScheduleModalsTitle
        title={t('scheduleCampaignPage.trackGenres')}
        placeholder={t('scheduleCampaignPage.enterGenreName')}
        setValue={handleSetValue}
      />
      <ListWrapper>
        <GenresModalSpotifyList
          selected={selectedGenres}
          onClick={handleClickGenre}
          searchValue={value}
        />
      </ListWrapper>
      <GenresModalSpotifyButton
        onClick={handleSave}
        isDisabled={!selectedGenres.length || isLoading}
      />
    </FullScreenModal>
  )
}
