import { FC } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import {
  setClosedMessageCampaignId,
  getClosedMessageCampaignIds,
} from 'src/Redux/alert-process'
import { colors, variables } from 'src/Styled/variables'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import {
  requestCampaignAnalysisZeroPlacements,
  requestCampaignAnalysisZeroReviews,
} from 'src/Helpers/TagManager/SpotifyCTAEvents'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'

import { useCampaignMessageType } from './useCampaignMessageType'

const Text = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.button.orangeText} !important;

  div {
    border-bottom-color: ${({ theme }) => theme.button.orangeText};
  }
`

export const MessageBlock: FC = () => {
  const dispatch = useDispatch()
  const { show } = useIntercom()
  const { campaignDetails } = useSpotifyCampaignDetails()
  const closedIds = useAppSelector(getClosedMessageCampaignIds)

  const messageType = useCampaignMessageType(campaignDetails, closedIds)
  const userId = useSelector(getUserId)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)

  const campaignData = {
    userId: String(userId),
    campaignId: campaignDetails?.id,
    campaignNumber: amountOfCampaigns,
    campaignType: campaignDetails?.type,
  }

  if (messageType === 'noReviewsNoPlacements') {
    return (
      <PrimaryAlert
        type='warning'
        hasCross
        title='Hold tight!'
        onClose={() =>
          campaignDetails &&
          dispatch(setClosedMessageCampaignId(campaignDetails.id))
        }
        renderButtons={() => (
          <StyledPrimaryDottedButton
            onClick={() => {
              show()
              requestCampaignAnalysisZeroReviews(campaignData)
            }}
            color={colors.mainText}
          >
            Request Campaign analysis
          </StyledPrimaryDottedButton>
        )}
      >
        <Text>
          We’re actively connecting with the best curators for your track.
          Meanwhile, explore ways to fine-tune your campaign with our support
          team.
        </Text>
      </PrimaryAlert>
    )
  }

  if (messageType === 'hasReviewsNoPlacements') {
    return (
      <PrimaryAlert
        type='warning'
        hasCross
        title='Hold tight!'
        onClose={() =>
          campaignDetails &&
          dispatch(setClosedMessageCampaignId(campaignDetails.id))
        }
        renderButtons={() => (
          <StyledPrimaryDottedButton
            onClick={() => {
              show()
              requestCampaignAnalysisZeroPlacements(campaignData)
            }}
            color={colors.mainText}
          >
            Request Campaign analysis
          </StyledPrimaryDottedButton>
        )}
      >
        <Text>
          Your track is sparking interest, though placements are still pending.
          Here’s what might be affecting it:
          <div>&nbsp; &#183; &nbsp;Unique or niche genre</div>
          <div>&nbsp; &#183; &nbsp;Experimental style</div>
          <div>&nbsp; &#183; &nbsp;Production quality considerations</div>
          Let’s dive deeper to help you make the most of your campaign!
        </Text>
      </PrimaryAlert>
    )
  }

  if (messageType === 'hasPlacements') {
    return (
      <PrimaryAlert
        type='success'
        hasCross
        title='Great news!'
        onClose={() =>
          campaignDetails &&
          dispatch(setClosedMessageCampaignId(campaignDetails.id))
        }
      >
        <Text>
          Your track just got a placement! Give it some time to gather reviews
          and see how it performs.
        </Text>
      </PrimaryAlert>
    )
  }

  return null
}
