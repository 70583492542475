import { FunctionComponent, PropsWithChildren } from 'react'

import { useSelector } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'
import { createGlobalStyle } from 'styled-components'

import { APP } from 'src/Configs/App'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { IntercomSetup } from '../IntercomSetup'

const GlobalStyles = createGlobalStyle`
  .intercom-launcher-frame {
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      display: none !important;
    }
  }

  .intercom-lightweight-app-launcher {
    display: none;
    @media (min-width: ${MAX_MOBILE_WIDTH}) {
      display: block;
      bottom: 20px !important;
    }
  }
`

export const IntercomWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const user = useSelector(getUser)

  return (
    <IntercomProvider appId={APP.INTERCOM_APP_ID} initializeDelay={1000}>
      <GlobalStyles />
      <IntercomSetup name={user.first_name || 'there'} />
      <div>{children}</div>
    </IntercomProvider>
  )
}
