import { combineReducers } from '@reduxjs/toolkit'

import { rtkApi } from 'src/Services/rtkApi'

import { campaignProcess } from './campaign-process'
import { genresProcess } from './genres-process'
import { spotifyTokenProcess } from './spotifyToken-process'
import { trackProcess } from './track-process'
import { sidebarProcess } from './sidebar-process'
import { playlistsProcess } from './playlists-process'
import { artistsProcess } from './artists-process'
import { wiseProcess } from './wise-process'
import { reviewsProcess } from './reviews-process'
import { spotifyTracksProcess } from './spotifyTracks-process'
import { settingsProcess } from './settings-process'
import { notificationProcess } from './notification-process'
import { sessionNotificationsProcess } from './sessionNotification-process'
import { scheduleProcess } from './schedule-process'
import { authProcess } from './auth-process'
import { alertProcess } from './alert-process'
import { transactions } from './transactions-process/reducer'
import { paymentProcess } from './payment-process'
import { paypalTransactionsProcess } from './paypalTransactions-process'
import { sourceBusterProcess } from './sourcebuster-process'
import { analyticsProcess } from './analytics-process'
import { levelsProcess } from './levels-procces'
import { getPremium } from './getPremiumProcess'
import { referral } from './referral-process'
import { searchPlaylistProcess } from './searchPlaylist-process'
import { similarGenresProcess } from './similarGenres-process'
import { subscriptionsProcess } from './subscription-process'
import { creatorRegister } from './creatorRegister-process'
import { typeformProcess } from './typeform-process'
import { tikTokTrackProcess } from './tiktokTrack-process'
import { checkoutTimer } from './checkoutCountdown-process'
import { firstTrackApi } from './firstTrackApi'
import { spotifyApi } from './spotifyTracksApi'
import { campaignListProcess } from './campaignList-process'
import { googleAnalytics4Process } from './ga4-process'
import { googleAnalyticsProcess } from './googleAnalytics-process'
import { campaignsActionsProcess } from './campaignsActions-process'
import { pageLayoutProcess } from './pageLayout-process'
import { canceledCreatorAlerts } from './canceledCreatorAlertsProcess'
import { spotifyCampaignMatchingProcess } from './spotifyCampaignMatchingProcess'

const rootReducer = combineReducers({
  transactions,
  referral,
  [rtkApi.reducerPath]: rtkApi.reducer,
  [firstTrackApi.reducerPath]: firstTrackApi.reducer,
  [spotifyApi.reducerPath]: spotifyApi.reducer,
  subscriptions: subscriptionsProcess,
  campaignsActions: campaignsActionsProcess.reducer,
  authentication: authProcess,
  campaignList: campaignListProcess.reducer,
  scheduling: scheduleProcess,
  notifications: notificationProcess.reducer,
  sessionNotifications: sessionNotificationsProcess.reducer,
  campaigns: campaignProcess,
  artists: artistsProcess.reducer,
  spotifyTracks: spotifyTracksProcess.reducer,
  tikTokTrack: tikTokTrackProcess.reducer,
  alerts: alertProcess,
  settings: settingsProcess,
  transferwise: wiseProcess.reducer,
  reviews: reviewsProcess.reducer,
  playlists: playlistsProcess.reducer,
  sidebar: sidebarProcess.reducer,
  layout: pageLayoutProcess.reducer,
  spotifyTrack: trackProcess.reducer,
  spotifyToken: spotifyTokenProcess.reducer,
  genres: genresProcess.reducer,
  payment: paymentProcess.reducer,
  paypalTransactions: paypalTransactionsProcess.reducer,
  sourcebuster: sourceBusterProcess.reducer,
  analytics: analyticsProcess.reducer,
  getPremium: getPremium.reducer,
  levels: levelsProcess.reducer,
  searchPlaylist: searchPlaylistProcess.reducer,
  similarGenres: similarGenresProcess.reducer,
  creatorRegister: creatorRegister.reducer,
  typeform: typeformProcess.reducer,
  checkoutTimer: checkoutTimer.reducer,
  googleAnalytics: googleAnalyticsProcess.reducer,
  ga4: googleAnalytics4Process.reducer,
  canceledCreatorAlerts: canceledCreatorAlerts.reducer,
  spotifyCampaignMatchingProcess: spotifyCampaignMatchingProcess.reducer,
})

export default rootReducer
