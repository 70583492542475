import { FC, ReactElement } from 'react'

import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'

import { Container, LinkWrapper, ListContainer, SubTitle } from '../../styles'
import { TextList } from '../TextList'

interface InfoBlockLink {
  title: string
  url: string
}

interface InfoBlock {
  title: string
  listItems: string[]
  content?: ReactElement
  link?: InfoBlockLink
}

interface InfoBlockProps {
  data: InfoBlock
}

export const InfoBlock: FC<InfoBlockProps> = ({ data }) => {
  const { listItems, title, link, content } = data

  return (
    <Container>
      <ListContainer>
        <SubTitle>{title}</SubTitle>
        <TextList listItems={listItems} />
      </ListContainer>

      {link && (
        <LinkWrapper>
          <a href={link.url} target='_blank' rel='noopener noreferrer'>
            <DottedBtn content={link.title} />
          </a>
        </LinkWrapper>
      )}

      {content}
    </Container>
  )
}
