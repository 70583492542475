import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import i18n from 'src/Localization/i18n'
import { colors, variables } from 'src/Styled/variables'
import { TextList } from 'src/Containers/Monetization/components/TextList'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

import { PrimaryAlert } from '../PrimaryAlert'

const Text = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.button.redText} !important;

  div {
    border-bottom-color: ${({ theme }) => theme.button.redText};
  }
`

export const ProblemsWithTikTokErrorAlert: FC<{
  $width?: string
  className?: string
}> = ({ $width, className }) => {
  const { t } = useTranslation()

  const { show } = useIntercom()

  const listItems = [
    t('monetization.stayActiveError'),
    t('monetization.ensureYourProfile'),
  ]

  const handleClickSupport = (): void => {
    show()
  }

  return (
    <PrimaryAlert
      className={className}
      type='danger'
      $width={$width}
      hasCross={false}
      title={i18n.t('monetization.problemsWithProfile')}
      renderButtons={() => (
        <StyledPrimaryDottedButton
          onClick={handleClickSupport}
          color={colors.mainText}
        >
          {t('balancePage.contactSupport')}
        </StyledPrimaryDottedButton>
      )}
    >
      <Text>{t('monetization.profileNotMeet')}</Text>
      <TextList listItems={listItems} />
      <Text>{t('monetization.faceChallenges')}</Text>
    </PrimaryAlert>
  )
}
