import React, { FC } from 'react'

import styled from 'styled-components'

import { useCheckSpotifyCheckoutStepIsAllowed } from 'src/Pages/Artist/SpotifySchedulePage/helpers/useCheckSpotifyCheckoutStepIsAllowed'
import { usePaymentCancelledSpotifyEvents } from 'src/Hooks/usePaymentCancelledSpotifyEvents'
import { SchedulePaymentMethod } from 'src/Components/SchedulePaymentMethod'
import { useExistingBillingAgreement } from 'src/Hooks/useExistingBillingAgreement'
import { ScheduleSummary } from 'src/Components/ScheduleSummary'
import { useAppSelector } from 'src/Hooks/redux'
import { getIsProcessingPage } from 'src/Redux/paypalTransactions-process/selectors'
import { getPaymentAlertError } from 'src/Redux/payment-process/selectors'
import { ScheduleCheckoutErrors } from 'src/Components/ScheduleCheckoutErrors'
import { SchedulePaymentProcessingModal } from 'src/Components/SchedulePaymentProcessingModal'

import { useSpotifyScheduleSummary } from './useSpotifyScheduleSummary'
import { useSpotifySchedulePaymentMethod } from './useSpotifySchedulePaymentMethod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    align-self: stretch;
  }
`
export const SpotifyScheduleCheckoutPage: FC = () => {
  useCheckSpotifyCheckoutStepIsAllowed()
  usePaymentCancelledSpotifyEvents()
  useExistingBillingAgreement()
  const spotifySchedulePaymentMethodProps = useSpotifySchedulePaymentMethod()
  const spotifyScheduleSummaryProps = useSpotifyScheduleSummary()

  const paymentError = useAppSelector(getPaymentAlertError)
  const isProcessingPageVisible = useAppSelector(getIsProcessingPage)

  return (
    <>
      {isProcessingPageVisible && <SchedulePaymentProcessingModal />}
      {paymentError && <ScheduleCheckoutErrors name={paymentError} />}
      <Wrapper>
        <SchedulePaymentMethod {...spotifySchedulePaymentMethodProps} />
        <ScheduleSummary {...spotifyScheduleSummaryProps} />
      </Wrapper>
    </>
  )
}
