import React, { FC, useState } from 'react'

import styled from 'styled-components'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

import { SecondaryScheduleSummaryProps } from '../SecondaryScheduleSummary'
import { ScheduleSummaryPromocodePopup } from '../ScheduleSummaryPromocode/ScheduleSummaryPromocodePopup'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`
const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  height: 40px;
`
type Props = Pick<SecondaryScheduleSummaryProps, 'onApplyPromocode'>

export const SecondaryScheduleSummaryPromocode: FC<Props> = ({
  onApplyPromocode,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <ScheduleSummaryPromocodePopup
        open={open}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        onApplyPromocode={(values) =>
          onApplyPromocode(values).then((data) => {
            setOpen(false)
            return data
          })
        }
      />
      <StyledPrimaryDottedButton onClick={() => setOpen(true)}>
        Have a Promo Code?
      </StyledPrimaryDottedButton>
    </Container>
  )
}
