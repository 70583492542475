import { useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { CampaignPlatformTypeEnum, ProperGenreOrLanguage } from 'src/Types'
import {
  ANY_LANGUAGE_ID,
  DEBOUNCE_TIMER_300,
  SPOTIFY_BUDGET_STEP,
} from 'src/Constants/constants'
import { DIVIDE_BY_TWO } from 'src/Constants/numeric'
import { useScheduleEvents } from 'src/Hooks/useScheduleEvents'
import { useBudgetRange } from 'src/Redux/spotifyBudgetRangeApi'
import { useGetCurrencyInfoQuery } from 'src/Redux/userExchangeRateApi'
import { getSpotifyCampaignMatchingDataLoadingSelector } from 'src/Redux/spotifyCampaignMatchingProcess'
import { getSpotifyCampaignMatchingData } from 'src/Redux/spotifyCampaignMatchingProcess/api-actions'

export const getIsLanguageActive = (
  languages: ProperGenreOrLanguage[],
): boolean => {
  const isEmpty = !languages.length
  const IsOnlyAnyLanguage =
    languages.some((language) => language.id === ANY_LANGUAGE_ID) &&
    languages.length === 1
  return Boolean(isEmpty || IsOnlyAnyLanguage)
}

interface UseCampaignPrice {
  savePrice: (value: number) => void
  min: number
  max: number
  valueInput: number
  valueSlider: number
  loading: boolean
  setValueInput: (value: number) => void
  setValueSlider: (value: number) => void
  currencySymbol: string
  rate: number
}

// This hook is used to get the price of the campaign
// and can be used only for budget slider
export const useCampaignPrice = (): UseCampaignPrice => {
  const dispatch = useAppDispatch()
  const { handleBudgetEvents } = useScheduleEvents()
  const params = useParams()
  const campaignId = params.number

  const { data: budgetRange } = useBudgetRange()
  const loading = useAppSelector(getSpotifyCampaignMatchingDataLoadingSelector)

  const max = Number(budgetRange?.max?.toFixed(0) ?? 0)
  const min = Number(budgetRange?.min?.toFixed(0) ?? 0)

  const { data: currantCurrency } = useGetCurrencyInfoQuery()
  const currencyRate = Number(currantCurrency?.currencyRate ?? 1)
  const currencySymbol = currantCurrency?.currencySymbol ?? '$'

  const [valueInput, setValueInput] = useState<number>(0)
  const [valueSlider, setValueSlider] = useState<number>(0)

  const validateValue = (value: number): number => {
    if (value > max) {
      return max
    }
    if (value < min) {
      return min
    }
    return value
  }

  const savePrice = useDebouncedCallback((value: number) => {
    void dispatch(
      getSpotifyCampaignMatchingData({
        campaignId: Number(campaignId),
        budget: validateValue(value),
      }),
    )
      .unwrap()
      .then(({ totalPrice }) => {
        handleBudgetEvents({
          platformName: CampaignPlatformTypeEnum.SPOTIFY,
          resultPrice: totalPrice,
        })
        setValueInput(Math.round(totalPrice * currencyRate))
        setValueSlider(totalPrice)
        return totalPrice
      })
  }, DEBOUNCE_TIMER_300)

  useEffect(() => {
    if (min && max) {
      savePrice((min + max) / DIVIDE_BY_TWO)
    }
  }, [max, min, savePrice])

  return {
    max,
    min,
    savePrice,
    valueInput,
    setValueInput,
    setValueSlider,
    valueSlider,
    currencySymbol,
    loading,
    rate: currencyRate,
  }
}
export const roundPriceValue = (price: number | string): number =>
  Math.round(Number(price) / SPOTIFY_BUDGET_STEP) * SPOTIFY_BUDGET_STEP
