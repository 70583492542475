import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const WithdrawText = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: ${colors.messegesRed};
`

export const StyledDate = styled.div`
  width: 100%;
  line-height: 150%;
`

export const SupportButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`
export const EmptyDiv = styled.div`
  width: 16px;
  height: 16px;
`

export const Description = styled.div<{ isTop?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  line-height: 150%;
  align-items: flex-start;

  & svg {
    flex-shrink: 0;
  }
`
export const IconWrapper = styled.div`
  margin-top: 4px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Text = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${colors.mainText};
`

export const SubText = styled.div`
  font-family: 'Segoe UI';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${colors.mainText};
`

export const Amount = styled.div<{ isGreen?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
  color: ${({ isGreen }) => (isGreen ? colors.green : colors.mainText)};
`

export const EmptyBalance = styled.div`
  padding: 16px !important;
  display: grid;
  justify-content: center;
  font-size: 14px;
  line-height: 150%;
  @media ${device.mobileMin} {
    padding: 8px !important;
  }
`

export const BalanceTableContainer = styled.div<{
  $empty?: boolean
}>`
  width: 100%;
  padding: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  position: relative;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  .ant-table-wrapper table {
    border-collapse: collapse;
  }
  .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
    background-color: transparent;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    background-color: ${colors.white};
    color: ${colors.mainText};
    line-height: 21px;
    border: none;
    &::before {
      display: none;
    }
    padding: 14px 12px 16px 12px;
    &:first-child {
      padding: 14px 12px 16px 24px;
    }
    &:last-child {
      padding: 14px 24px 16px 12px;
    }
  }

  .ant-table-tbody .ant-table-cell {
    padding: 16px 12px 16px 12px;

    &:first-child {
      padding: 16px 12px 16px 24px;
      width: 157px;
    }
    &:nth-child(2) {
      width: 184px;
    }
    &:last-child {
      padding: 16px 24px 16px 12px;
    }
  }
  .ant-table-tbody {
    border-spacing: 0 2px;
  }
  .ant-table-row {
    border-bottom: 3px solid white;
    border-top: 3px solid white;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: ${({ $empty }) => $empty && '0px !important'};
    border-bottom: none !important;
  }
  .ant-table-row .ant-table-cell {
    border: none;
    background-color: ${colors.greyBg};

    &:first-of-type {
      width: 157px;
    }
    &:nth-child(2) {
      width: 184px;
    }
    padding: 12px 12px 18px 12px;

    &:first-child {
      padding: 12px 12px 18px 24px;
    }
    &:last-child {
      padding: 12px 24px 18px 12px;
    }
  }
`

export const AmountHeaderCell = styled.div`
  text-align: right;
`
export const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  border-radius: 4px;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const AmountWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`
export const Title = styled.div`
  font-size: ${variables.fontSize10};
`
export const MobileStatusWrapper = styled.div`
  margin-left: 24px;
`

export const DateWrapper = styled.div`
  padding-left: 24px;
`
export const AmountContainer = styled.div`
  text-align: right;
`
