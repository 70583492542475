import { FunctionComponent } from 'react'

import { MonetizationPageTitle } from './components/MonetizationPageTitle'
import { InfoBlock } from './components/InfoBlock'
import { infoBlocks } from './pageData'
import { EarningBlock } from './components/EarningBlock'

import { MonetizationContainer } from './styles'

export const MonetizationPage: FunctionComponent = () => (
  <MonetizationContainer>
    <MonetizationPageTitle />
    <EarningBlock />

    {infoBlocks.map((infoBlock) => (
      <InfoBlock key={infoBlock.title} data={infoBlock} />
    ))}
  </MonetizationContainer>
)
