import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { RewardListItem } from '../RewardListItem'
import { RewardWithPlaylist } from '../../useRewardsPage'

import {
  RewardTableHeaderContainer,
  RewardTableHeaderCell,
  RewardTableList,
} from './styles'

export const RewardTable: FC<{
  rewardsWithPlaylists: Array<RewardWithPlaylist>
}> = ({ rewardsWithPlaylists }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 })

  const headers = ['Followers', 'Basic Reward', 'Active Playlists']

  return (
    <>
      {isDesktop && (
        <RewardTableHeaderContainer>
          {headers.map((header) => (
            <RewardTableHeaderCell key={header}>{header}</RewardTableHeaderCell>
          ))}
        </RewardTableHeaderContainer>
      )}

      <RewardTableList>
        {rewardsWithPlaylists?.map((item) => (
          <RewardListItem key={item.basicReward} reward={item} />
        ))}
      </RewardTableList>
    </>
  )
}
