import React, { FC } from 'react'

import Joyride, { Step } from 'react-joyride'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { useNewBonusSystem } from 'src/Hooks/useNewBonusSystem'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getBonusGuideRun,
  getBonusGuideStep,
  setBonusGuideRun,
  setBonusGuideStep,
} from 'src/Redux/alert-process'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { Step3Content } from './Step3Content'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
`
const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`
const TitleStep2 = styled(Text)`
  font-weight: 600;
`

const ContainerStep2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

export const END_BONUSES_GUIDE_STEP = 999

export const BonusesGuide: FC = () => {
  const { isNewBonusSystem } = useNewBonusSystem()
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })
  const isTablet = useMediaQuery({ maxWidth: MAX_TABLET_WIDTH })
  const step = useAppSelector(getBonusGuideStep)
  const run = useAppSelector(getBonusGuideRun)
  const dispatch = useAppDispatch()

  const handleSetStep = (nextStep: number): void => {
    dispatch(setBonusGuideStep(nextStep))
    dispatch(setBonusGuideRun(false))
  }

  if (!isNewBonusSystem) {
    return null
  }

  const steps: Step[] = [
    {
      target: '#step1',
      content: (
        <Container>
          <Text>
            In this section, you will see the bonuses you’ve earned in the past
            30 days.
          </Text>
          <StyledPrimaryButton onClick={() => handleSetStep(1)}>
            Got it
          </StyledPrimaryButton>
        </Container>
      ),
      hideBackButton: true,
      hideFooter: true,
      showSkipButton: false,
      placement: isMobile || isTablet ? 'bottom-end' : 'left',
      disableCloseOnEsc: true,
      disableOverlayClose: true,
      disableBeacon: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: 16,
        },
        tooltip: {
          padding: 24,
          borderRadius: 16,
          maxWidth: 280,
        },
        tooltipContainer: {
          padding: 0,
        },
        tooltipContent: {
          padding: 0,
        },
      },
    },
    {
      target: '#step2',
      content: (
        <ContainerStep2>
          <TitleStep2>Want to see your rewards in action?</TitleStep2>
          <Text>Click ‘Review’ to check out the updated reward breakdown.</Text>
        </ContainerStep2>
      ),
      hideBackButton: true,
      hideFooter: true,
      showSkipButton: false,
      placement: isMobile || isTablet ? 'top' : 'left',
      disableBeacon: true,
      spotlightClicks: true,
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: 40,
        },
        tooltip: {
          padding: 24,
          borderRadius: 16,
          maxWidth: 280,
        },
        tooltipContainer: {
          padding: 0,
        },
        tooltipContent: {
          padding: 0,
        },
      },
    },
    {
      target: '#step3',
      content: (
        <Container>
          <Step3Content />
          <StyledPrimaryButton
            onClick={() => handleSetStep(END_BONUSES_GUIDE_STEP)}
          >
            Got it
          </StyledPrimaryButton>
        </Container>
      ),
      hideBackButton: true,
      hideFooter: true,
      showSkipButton: false,
      disableScrollParentFix: true,
      placement: isMobile ? ('bottom' as const) : ('right' as const),
      disableBeacon: true,
      spotlightClicks: true,
      spotlightPadding: 16,
      styles: {
        spotlight: {
          borderRadius: 16,
        },
        tooltip: {
          padding: 24,
          borderRadius: 16,
          maxWidth: 280,
        },
        tooltipContainer: {
          padding: 0,
        },
        tooltipContent: {
          padding: 0,
        },
      },
    },
  ]
  return (
    <Joyride
      disableCloseOnEsc
      hideCloseButton
      stepIndex={step}
      disableOverlayClose
      disableScrollParentFix
      run={run}
      floaterProps={{
        styles: {
          arrow: {
            length: 11,
            spread: 22,
          },
        },
      }}
      steps={steps}
    />
  )
}
