import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'

export const CoverImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  @media ${device.tabletMax} {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    & img {
      width: 48px;
      height: 48px;
    }
  }
`
export const CounterWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${variables.fontSize10};
  font-weight: ${variables.fontWeight600};
  color: ${colors.white};
  backdrop-filter: blur(1.5px);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s linear;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(1px);
    color: ${colors.green};
    transition: all 0.3s linear;
    transition-property: backdrop-filter, color, background;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media ${device.mobileMax} {
    align-items: center;
    gap: 16px;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const ScoreValue = styled.div`
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
`
