import { FC } from 'react'

import styled from 'styled-components'

import { Roles } from 'src/Constants/enums'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { variables } from 'src/Styled/variables'

const Container = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: ${({ theme }) => theme.common.secondaryBg};
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.fourthText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const SubText = styled(Text)`
  font-weight: ${variables.fontWeight400};
`

export interface SidebarUserInfoProps {
  firstName: string
  lastName: string
  role: Roles
}

export const SidebarUserInfo: FC<SidebarUserInfoProps> = ({
  firstName,
  lastName,
  role,
}) => {
  const formattedFirstName = capitalizeFirstLetter(firstName)
  const formattedLastName = capitalizeFirstLetter(lastName)
  const formattedRole = capitalizeFirstLetter(role)

  const roleMap = {
    [Roles.artist]: 'Artist',
    [Roles.curator]: 'Playlist Curator',
    [Roles.influencer]: 'Creator',
    [Roles.admin]: 'Admin',
    [Roles.agent]: 'Agent',
    [Roles.label]: 'Label',
    [Roles.manager]: 'Manager',
    [Roles.publisher]: 'Publisher',
  }

  const roleText = roleMap[role] ?? formattedRole

  return (
    <Container>
      <Text>{`${formattedFirstName} ${formattedLastName}`}</Text>
      <SubText>{roleText}</SubText>
    </Container>
  )
}
