import { FC, useMemo } from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PrimaryScheduleSteps,
  PrimaryScheduleTabs,
} from 'src/Components/PrimaryScheduleTabs'
import { useScheduleSpotifyCampaign } from 'src/Pages/Artist/SpotifySchedulePage/useSchaduleSpotifyCampaign'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { CampaignPlatformTypeEnum, ScheduleSteps } from 'src/Types'
import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ScheduleSuccessPage } from 'src/Components/ScheduleSuccessPage'
import { useSimplifiedCheckoutExperiment } from 'src/Hooks/Experiments/useSimplifiedCheckoutExperiment'

import { SpotifyScheduleSettingsPage } from './SpotifyScheduleSettingsPage'
import { SecondarySpotifyScheduleCheckoutPage } from './SecondarySpotifyScheduleCheckoutPage'
import { SpotifyScheduleCheckoutPage } from './SpotifyScheduleCheckoutPage'

export const SpotifySchedulePage: FC = () => {
  useScheduleSpotifyCampaign()
  const { t } = useTranslation()
  const { number } = useParams()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const isVariant = useSimplifiedCheckoutExperiment()
  const steps: PrimaryScheduleSteps[] = [
    {
      title: t('spotifySchedulePage.campaigns'),
      path: ArtistRoutesEnum.CampaignsList,
    },
    {
      title: t('spotifySchedulePage.campaignSettings'),
      path: `${ArtistRoutesEnum.SpotifySchedule}/${number}?step=${ScheduleSteps.SETTINGS}`,
    },
    {
      title: t('spotifySchedulePage.campaignCheckout'),
      path: `${ArtistRoutesEnum.SpotifySchedule}/${number}?step=${ScheduleSteps.CHECKOUT}`,
    },
  ]

  const scheduleCheckout = useMemo(
    () =>
      isVariant ? (
        <SecondarySpotifyScheduleCheckoutPage />
      ) : (
        <SpotifyScheduleCheckoutPage />
      ),
    [isVariant],
  )
  return (
    <>
      <ScheduleSuccessPage
        startDate={campaignDetails.start_date}
        platformName={CampaignPlatformTypeEnum.SPOTIFY}
      />
      <PrimaryScheduleTabs steps={steps}>
        {/* first must be settings second checkout */}
        <SpotifyScheduleSettingsPage />
        {scheduleCheckout}
        {/* for future experiment */}
      </PrimaryScheduleTabs>
    </>
  )
}
