import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Meta } from 'src/Types'

import { Sound } from '../types'
enum UiSoundStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  MONITORING = 'MONITORING',
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
}

export interface NewSound extends Sound {
  trackTiktokId: string
  status: UiSoundStatus.NEW
  id: number
  trackPlayUrl: string
  comment: string
}

interface NewSoundsBody {
  creatorId: number
  pageNumber?: number
}

interface NewSoundsResponse {
  data: NewSound[]
  meta: Meta
}

interface NewSoundByIdBody {
  creatorId: number
  soundId: number
}

interface AcceptSoundBody {
  soundId: number
  minViews: number
  maxViews: number
}

interface AcceptSoundResponse {
  eventId: number
  campaignId: number
  trackId: string
  uploadVideoDeadlineDate: string
  paidAmount: number
}

export type NewSoundByIdResponse = NewSound

const creatorNewSoundsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    const pageSize = 10

    return {
      newSounds: build.query<NewSoundsResponse, NewSoundsBody>({
        query: ({ creatorId, pageNumber = 1 }) => ({
          url: `${APP.TIKTOK_SERVER}/creators/${creatorId}/sounds?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        }),
        providesTags: ['newSound'],
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, meta) => {
          if (meta.arg.pageNumber === 1) {
            // If pageNumber is 1, replace the current cache with new items
            return {
              data: newItems.data,
              meta: newItems.meta,
            }
          }
          // Otherwise, append the new items to the current cache
          return {
            data: [...currentCache.data, ...newItems.data],
            meta: newItems.meta,
          }
        },
        forceRefetch({ currentArg, previousArg }) {
          return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
        },
      }),

      newSoundById: build.query<NewSoundByIdResponse, NewSoundByIdBody>({
        query: ({ creatorId, soundId }) => ({
          url: `${APP.TIKTOK_SERVER}/creators/${creatorId}/sounds/${soundId}`,
        }),
      }),

      acceptSound: build.mutation<AcceptSoundResponse, AcceptSoundBody>({
        query: ({ soundId, minViews, maxViews }) => ({
          url: `${APP.TIKTOK_SERVER}/influencer/tiktok/v2/accept-new-track`,
          method: 'POST',
          body: {
            campaignId: soundId,
            minViews,
            maxViews,
          },
        }),
        invalidatesTags: ['newSound', 'inProgressSound'],
      }),
    }
  },
})

export const selectNewSoundsCount = ({
  data,
  isError,
}: {
  isError: boolean
  data?: NewSoundsResponse
}): { newSoundsCount: number; isError: boolean } => ({
  isError,
  newSoundsCount: data?.meta.itemCount || 0,
})

export const {
  useNewSoundsQuery,
  useLazyNewSoundsQuery,
  useNewSoundByIdQuery,
  useAcceptSoundMutation,
} = creatorNewSoundsApi
