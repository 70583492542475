import React, { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import { useNewBonusSystem } from 'src/Hooks/useNewBonusSystem'
import { Sidebar, SidebarProps } from 'src/Components/Sidebar'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserSelector,
  getVerified,
} from 'src/Redux/auth-process/userSlice/selectors'
import { Roles } from 'src/Constants/enums'

import { useRequestFeature } from '../../Components/SidebarContainer/useRequestFeature'

const StyledSidebar = styled(Sidebar)`
  height: 100%;
`

export type SidebarContainerProps = Pick<
  SidebarProps,
  'collapsed' | 'onLogoClick'
>

export const CuratorSidebarContainer: FC<SidebarContainerProps> = ({
  collapsed,
  onLogoClick,
}) => {
  const { show } = useIntercom()
  const firstName = useAppSelector(getUserFirstNameSelector)
  const lastName = useAppSelector(getUserLastNameSelector)
  const isVerified = useAppSelector(getVerified)
  const user = useAppSelector(getUserSelector)
  const { attributeValue, onFeatureClick } = useRequestFeature()
  const onChatClick = (): void => {
    show()
  }
  const { isNewBonusSystem } = useNewBonusSystem()

  return (
    <StyledSidebar
      userCover={user.user_photo_url}
      curatorVerified={typeof isVerified === 'boolean' ? isVerified : null}
      firstName={firstName}
      lastName={lastName}
      collapsed={collapsed}
      role={Roles.curator}
      showFeatureRequest
      attributeValue={attributeValue}
      onFeatureClick={onFeatureClick}
      onChatClick={onChatClick}
      onLogoClick={onLogoClick}
      isNewBonusSystem={isNewBonusSystem}
    />
  )
}
