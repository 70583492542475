import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import avatar from 'src/Assets/Png/ava.png'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import Logo from 'src/Assets/Svg/Logo/Logo.svg?react'
import SoundCampaignText from 'src/Assets/Svg/sidebar-icons/SoundCampaignText.svg?react'

const Container = styled.div`
  display: flex;
  width: 400px;
  height: 400px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.common.secondaryBg};
  color: white;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    width: auto;
  }
`

const TrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
const TrackInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const TrackNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const CuratorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const CuratorNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

const TrackName = styled.span`
  font-family: 'Segoe UI';
  align-self: flex-start;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.fourthText};
`

const PrimaryText = styled.span`
  font-family: 'Segoe UI';
  align-self: flex-start;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.fourthText};
`

const SecondaryText = styled.span`
  font-family: 'Segoe UI';
  align-self: flex-start;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.fourthText};
  word-break: break-word;
`

export const TrackCover = styled(SmartImage)`
  width: 68px;
  height: 68px;
  border-radius: 16px;
`

export const UserPic = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 40px;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export interface SharePreviewProps {
  trackCoverUrl?: string
  userPhotoUrl?: string
  firstName?: string
  lastName?: string
  trackName?: string
  trackAuthorName?: string
  comment?: string
}

export const SharePreview: FC<SharePreviewProps> = ({
  trackCoverUrl,
  userPhotoUrl,
  firstName,
  lastName,
  trackName,
  trackAuthorName,
  comment,
}) => {
  const { t } = useTranslation()

  const name = `${firstName ?? ''} ${lastName ?? ''}`

  return (
    <Container id='shareImage'>
      <LogoWrapper>
        <Logo />
        <SoundCampaignText />
      </LogoWrapper>
      <TrackContainer>
        <TrackInfoContainer>
          <TrackCover
            fallbackSrc={track}
            src={trackCoverUrl}
            alt='Track cover'
          />
          <TrackNameContainer>
            <TrackName>{trackName ?? ''}</TrackName>
            <PrimaryText>{trackAuthorName ?? ''}</PrimaryText>
          </TrackNameContainer>
        </TrackInfoContainer>

        <SecondaryText>{comment ?? ''}</SecondaryText>
      </TrackContainer>
      <CuratorContainer>
        <UserPic fallbackSrc={avatar} src={userPhotoUrl} alt='Userpic' />
        <CuratorNameContainer>
          <SecondaryText>{name}</SecondaryText>
          <PrimaryText>{t('campaignDetailsPage.curator')}</PrimaryText>
        </CuratorNameContainer>
      </CuratorContainer>
    </Container>
  )
}
