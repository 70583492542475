import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import sbjs from 'sourcebuster'
import dayjs from 'dayjs'

import { APP } from 'src/Configs/App'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getCookieByName } from 'src/Constants/functions'

import { ErrorCode, NameSpace } from '../types'
import { clientId, gaSession } from '../ga4-process/helpers'

export interface SourceBusterCollectedData {
  firstUtmSource: string
  firstUtmMedium: string
  firstUtmCampaign: string
  firstUtmContent: string
  firstUtmTerm: string
  firstLandingPage: string
  lastUtmSource: string
  lastUtmMedium: string
  lastUtmCampaign: string
  lastUtmContent: string
  lastUtmTerm: string
  lastLandingPage: string
  visits: number
  clientId: string
  sessionId: number
  eventTimestamp: string
  fbp: string
  fbc: string
}

export const collectDataFromSourceBuster = (): SourceBusterCollectedData => ({
  firstUtmSource: sbjs.get?.first?.src,
  firstUtmMedium: sbjs.get?.first?.mdm,
  firstUtmCampaign: sbjs?.get?.first?.cmp,
  firstUtmTerm: sbjs?.get?.first?.trm,
  firstUtmContent: sbjs.get?.first?.cnt,
  firstLandingPage: sbjs.get?.first_add?.ep,
  lastUtmSource: sbjs.get?.current?.src,
  lastUtmMedium: sbjs.get?.current?.mdm,
  lastUtmCampaign: sbjs.get?.current?.cmp,
  lastUtmTerm: sbjs.get?.current?.trm,
  lastUtmContent: sbjs.get?.current?.cnt,
  lastLandingPage: sbjs.get?.current_add?.ep,
  visits: Number(sbjs.get?.udata?.vst),
  clientId: clientId || '',
  sessionId: Number(gaSession),
  eventTimestamp: new Date().toISOString(),
  fbp: getCookieByName('_fbp') || '',
  fbc: getCookieByName('_fbc') || '',
})

type SendSourceBusterEventBody = {
  campaignId?: number
  campaignPlatform?: CampaignPlatformTypeEnum
  eventName: string
  token: string
  gclid: string
  savedData?: SourceBusterCollectedData | null
}

export const sendSourceBusterEvent = createAsyncThunk<
  unknown,
  SendSourceBusterEventBody
>(
  `${NameSpace.SourceBusterEvent}/sendSourceBusterData`,
  async ({ token, savedData, ...body }, { rejectWithValue }) => {
    try {
      const sourceBusterData = savedData || collectDataFromSourceBuster()

      const urlParams = new URL(sourceBusterData?.lastLandingPage)?.searchParams

      const gclid = body.gclid || urlParams.get('gclid') || ''

      const fbclidMatch =
        sourceBusterData.lastLandingPage?.match(/[?&]fbclid=([^&]+)/)

      const fbclid = fbclidMatch ? fbclidMatch[1] : null

      const timestamp = dayjs(sourceBusterData.eventTimestamp).unix()

      const generatedFbc = fbclid ? `fb.1.${timestamp}.${fbclid}` : ''

      // code for fix broken fbc from landing pale ex.fb.1.1726992490281.https://soundcamps.com
      const isUrlContainsHttps = sourceBusterData.fbc.includes('https')

      const fbc =
        sourceBusterData.fbc && !isUrlContainsHttps
          ? sourceBusterData.fbc
          : generatedFbc
      const { data } = await axios.post<unknown>(
        `${APP.TIKTOK_SERVER}/sourcebuster/events`,
        { ...body, ...sourceBusterData, gclid, fbc },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>

      return rejectWithValue({
        message: response?.data.message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
