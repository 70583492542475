import { useEffect } from 'react'

import { ScheduleSummaryProps } from 'src/Components/ScheduleSummary'
import { HUNDRED, HUNDREDTHS } from 'src/Constants/numeric'
import { couponAppliedEvent } from 'src/Helpers/TagManager'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'
import { useCheckoutButton } from 'src/Hooks/useCheckoutButton'
import { usePaymentAmountsWithCurrency } from 'src/Hooks/usePaymentAnountsWithCurrency'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { getFormattedCheckoutTimer } from 'src/Redux/checkoutCountdown-process'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import {
  getIsEnoughBalanceSelector,
  spotifyTotalPriceSelector,
} from 'src/Redux/spotifyCampaignMatchingProcess'

export const useSpotifyScheduleSummary = (): ScheduleSummaryProps => {
  const dispatch = useAppDispatch()
  const coupon = useAppSelector(getCoupon)
  const { startNextTimeBonusCountdown, isNextTimeBonus } =
    useBonusTooltipTimer()

  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const spotifyTotalPrice = useAppSelector(spotifyTotalPriceSelector)

  const {
    currencyDiscount,
    campaignCurrencyBudget,
    currencyVat,
    currencyBalance,
    currencyTotal,
    isVATCountry,
  } = usePaymentAmountsWithCurrency(CampaignPlatformTypeEnum.SPOTIFY)
  const { isLoading, onClickContinue } = useCheckoutButton(
    CampaignPlatformTypeEnum.SPOTIFY,
  )

  const showPromocode = useAppSelector(getIsEnoughBalanceSelector)
  const countdown = useAppSelector(getFormattedCheckoutTimer)

  useEffect(() => {
    startNextTimeBonusCountdown()
  }, [startNextTimeBonusCountdown])

  const onApplyPromocode = async (value: string): Promise<void> => {
    const data = await dispatch(checkCoupon(value)).unwrap()

    if (data && spotifyTotalPrice) {
      const memoDiscount = (
        spotifyTotalPrice *
        (data.coupon.discount / HUNDRED)
      ).toFixed(HUNDREDTHS)

      couponAppliedEvent(
        spotifyCampaignDetails.type || CampaignTypeEnum.BASIC,
        data.coupon,
        memoDiscount ? Number(memoDiscount) : 0,
      )
    }
  }

  return {
    onApplyPromocode,
    countdown,
    onConfirm: onClickContinue,
    paymentLoading: isLoading,
    price: campaignCurrencyBudget,
    discount: currencyDiscount,
    discountPercentage: coupon?.discount ? String(coupon.discount) : '',
    vat: isVATCountry ? currencyVat : '',
    balance: currencyBalance,
    total: currencyTotal,
    showPromocode: !showPromocode && !coupon?.discount,
    showTooltip: isNextTimeBonus,
    artistName: spotifyCampaignDetails.track.author,
    trackName: spotifyCampaignDetails.track.title,
    platform: CampaignPlatformTypeEnum.SPOTIFY,
  }
}
