import { FunctionComponent, useState } from 'react'

import { useLocation } from 'react-router-dom'

import Add from 'src/Assets/Svg/common/addIcon.svg?react'
import { useSubmittedSoundsQuery } from 'src/Redux/creatorSubmittedSoundsApi'

import { SoundsList } from '../index'
import { SubmittedSoundsListItem } from '../../SoundsListItem/components/SubmittedSoundsListItem'
import { TabKeysEnum } from '../../../types'
import { SoonContainer, Text } from '../styles'

export const SubmittedSoundsList: FunctionComponent = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const { pathname } = useLocation()
  const isInSubmittedTab = pathname.includes('submitted')

  const {
    data: submittedSoundsData,
    isLoading,
    isFetching,
  } = useSubmittedSoundsQuery(pageNumber)

  const submittedSounds = submittedSoundsData?.data || []
  const hasNextPage = submittedSoundsData?.meta.hasNextPage || false
  const hasSounds = submittedSounds.length > 0

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  return (
    <SoundsList
      soundsAmount={submittedSounds.length}
      hasNextPage={hasNextPage && isInSubmittedTab}
      isLoading={isLoading || isFetching}
      onLoadMore={loadMore}
      tabType={TabKeysEnum.SUBMITTED}
    >
      {!hasSounds && (
        <SoonContainer>
          <Add />
          <Text>You'll get new sounds for video creation soon</Text>
        </SoonContainer>
      )}

      {submittedSounds.map((item) => (
        <SubmittedSoundsListItem
          key={item.id}
          id={item.id}
          trackAuthorName={item.trackAuthorName}
          trackCoverUrl={item.trackCoverUrl}
          trackTiktokId={item.trackTiktokId}
          trackTitle={item.trackTitle}
          currentViews={item.views}
          reward={item.reward}
          status={item.status}
          isPaymentReceived={item.isPaymentReceived}
          minViews={item.minViews}
        />
      ))}
    </SoundsList>
  )
}
