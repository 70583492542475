import { useCallback, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getCardInfo,
  getStripeSetupIntent,
  useGetSavedCardsQuery,
} from 'src/Redux/savedCardsApi'
import { useCreateSetupIntentMutation } from 'src/Redux/setupIntentApi'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'
import { subscription } from 'src/Router/routes'

import { useChangeSubscription } from './useChangeSubscription'

interface UseStripeIntent {
  onPayStripe: () => Promise<void>
  hasIntent: boolean
  cardInfo?: string
  loading?: boolean
}

export const usePayStripeSubscription = (): UseStripeIntent => {
  const { data, isSuccess } = useGetSavedCardsQuery()
  const navigate = useNavigate()
  const setupIntent = getStripeSetupIntent(data)
  const cardInfo = getCardInfo(data)
  const {
    data: subscriptionPlan,
    isSuccess: isSubscriptionSucceeded,
    isLoading,
  } = useGetSubscriptionQuery()
  const hasActiveSubscription = Boolean(subscriptionPlan?.status === 'active')
  const [change, isChangeLoading] = useChangeSubscription()
  const [createSetupIntent] = useCreateSetupIntentMutation()
  const { search } = useCustomSearchParams()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = String(search.get('plan'))
  const autopay = search.get('autopay')
  const paymentType = search.get('paymentType')

  const onPayStripe = useCallback(async (): Promise<void> => {
    if (!packageId || !price || !plan) {
      return
    }
    if (!setupIntent?.id) {
      await createSetupIntent({
        successUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&plan=${plan}&cashback=${cashback}&paymentType=${PaymentMethodEnum.STRIPE}&autopay=true`,
        cancelUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&plan=${plan}&cashback=${cashback}&paymentType=${PaymentMethodEnum.STRIPE}&error=save_token_error`,
      })
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${PaymentMethodEnum.STRIPE}&error=subscription_error`,
          )
        })
      return
    }
    hasActiveSubscription && change(PaymentMethodEnum.STRIPE, setupIntent?.id)
  }, [
    cashback,
    change,
    createSetupIntent,
    hasActiveSubscription,
    navigate,
    packageId,
    plan,
    price,
    setupIntent?.id,
  ])
  const isOnceCalled = useRef<boolean>(false)
  const isAutoStripePayment =
    autopay === 'true' && paymentType === PaymentMethodEnum.STRIPE
  const isDataPreloaded = isSuccess && isSubscriptionSucceeded
  useEffect(() => {
    if (isAutoStripePayment && isDataPreloaded && !isOnceCalled.current) {
      onPayStripe()
      isOnceCalled.current = true
    }
  }, [isAutoStripePayment, isDataPreloaded, onPayStripe])

  return {
    cardInfo,
    onPayStripe,
    hasIntent: Boolean(setupIntent),
    loading: isLoading || isChangeLoading,
  }
}
