import { useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PaymentMethodEnum } from 'src/Constants/enums'
import { paymentMethodChanged } from 'src/Helpers/TagManager'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { HUNDRED } from 'src/Constants/numeric'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'
import { spotifyTotalPriceSelector } from 'src/Redux/spotifyCampaignMatchingProcess'

interface PaymentMethodChanged {
  setIsPaymentMethodChangedSent: (
    currentPaymentMethod: PaymentMethodEnum,
    currentPlatform: CampaignPlatformTypeEnum,
  ) => void
}

export const usePaymentMethodChanged = (): PaymentMethodChanged => {
  const params = useParams()
  const { data: plan } = useGetSubscriptionQuery()
  const scheduledTikTokCampaign = useSelector(getScheduledTikTokCampaign)
  const scheduledSpotifyCampaign = useSelector(getCampaignToSchedule)
  const spotifyTotalPrice = useAppSelector(spotifyTotalPriceSelector)

  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const tikTokCampaignId = params.number ? Number(params.number) : 0
  const paymentMethod = useSelector(getPaymentMethod)
  const user = useSelector(getUserSelector)
  const price = useAppSelector(getTiktokPrice)
  const spotifyCampaignPrice = spotifyTotalPrice || 0

  const setIsPaymentMethodChangedSent = useCallback(
    (
      currentPaymentMethod: PaymentMethodEnum,
      currentPlatform: CampaignPlatformTypeEnum,
    ) => {
      // payment_method_changed
      currentPlatform === CampaignPlatformTypeEnum.TIKTOK
        ? paymentMethodChanged({
            campaignId: tikTokCampaignId,
            campaignNumber: amountOfCampaigns,
            campaignPlatform: CampaignPlatformTypeEnum.TIKTOK,
            campaignType:
              scheduledTikTokCampaign.type || CampaignTypeEnum.BASIC,
            genreList: scheduledTikTokCampaign?.genres
              .map((genre) => genre.label)
              .join(),
            languageList: scheduledTikTokCampaign?.languages
              .map((language) => language.label)
              .join(),
            paymentMethod: currentPaymentMethod,
            prevPaymentMethod:
              paymentMethod === PaymentMethodEnum.STRIPE
                ? PaymentMethodEnum.PAYPAL
                : PaymentMethodEnum.STRIPE,
            resultPrice: price || HUNDRED,
            userId: user.id,
            is_feed: false,
            subscriptionPlan: plan?.package?.name || '',
          })
        : paymentMethodChanged({
            campaignId: Number(scheduledSpotifyCampaign.id),
            campaignNumber: amountOfCampaigns,
            campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
            campaignType:
              scheduledSpotifyCampaign.type || CampaignTypeEnum.BASIC,
            genreList: scheduledSpotifyCampaign?.genres
              .map((genre) => genre.name)
              .join(),
            languageList: scheduledSpotifyCampaign?.languages
              .map((language) => language.name)
              .join(),
            paymentMethod: currentPaymentMethod,
            prevPaymentMethod:
              currentPaymentMethod === PaymentMethodEnum.STRIPE
                ? PaymentMethodEnum.PAYPAL
                : PaymentMethodEnum.STRIPE,
            resultPrice: spotifyCampaignPrice,
            userId: user.id,
            is_feed: scheduledSpotifyCampaign?.is_feed || false,
            isDanceRequired: false,
            subscriptionPlan: plan?.package?.name || '',
          })
    },
    [
      amountOfCampaigns,
      paymentMethod,
      price,
      scheduledSpotifyCampaign?.genres,
      scheduledSpotifyCampaign.id,
      scheduledSpotifyCampaign?.is_feed,
      scheduledSpotifyCampaign?.languages,
      spotifyCampaignPrice,
      scheduledSpotifyCampaign.type,
      scheduledTikTokCampaign?.genres,
      scheduledTikTokCampaign?.languages,
      scheduledTikTokCampaign.type,
      tikTokCampaignId,
      user.id,
      plan?.package?.name,
    ],
  )

  return { setIsPaymentMethodChangedSent }
}
