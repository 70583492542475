import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import {
  Track,
  useGetFirstSpotifyTrackQuery,
  useGetFirstTikTokTrackQuery,
} from 'src/Redux/firstTrackApi'
import { useGetFreeSpotifyTokenQuery } from 'src/Redux/freeSpotifyTokenApi'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'

export interface FirstChosenTrackParams {
  [key: string]: string | undefined
  platform?: CampaignPlatformTypeEnum
  trackId?: string
}

type UseFirstChosenTrack = {
  track: Track | undefined
  loading?: boolean
  isSuccess?: boolean
  platform: CampaignPlatformTypeEnum | undefined
}

export const useFirstChosenTrack = (): UseFirstChosenTrack => {
  const { platform, trackId } = useParams<FirstChosenTrackParams>()

  const { data: tokenData, isLoading: isTokenLoading } =
    useGetFreeSpotifyTokenQuery()
  const navigate = useNavigate()
  const {
    data: spotifyTrack,
    isLoading: isSpotifyLoading,
    isError: isSpotifyError,
    isSuccess: isSpotifySuccess,
  } = useGetFirstSpotifyTrackQuery(
    {
      trackId,
      token: tokenData?.accessToken,
    },
    {
      skip:
        !trackId ||
        !tokenData?.accessToken ||
        platform !== CampaignPlatformTypeEnum.SPOTIFY,
    },
  )
  const {
    data: tiktokTrack,
    isLoading: isTiktokLoading,
    isError: isTiktokError,
    isSuccess: isTiktokSuccess,
  } = useGetFirstTikTokTrackQuery(trackId || '', {
    skip: !trackId || platform !== CampaignPlatformTypeEnum.TIKTOK,
  })

  const track =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? spotifyTrack : tiktokTrack

  useEffect(() => {
    if (isSpotifyError || isTiktokError) {
      navigate(NAVIGATE_BACK_INDEX)
    }
  }, [isSpotifyError, isTiktokError, navigate])

  return {
    track,
    platform,
    isSuccess: isSpotifySuccess || isTiktokSuccess,
    loading: isSpotifyLoading || isTiktokLoading || isTokenLoading,
  }
}
