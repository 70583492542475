import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dayJs from 'src/Helpers/dayjs'
import { variables } from 'src/Styled/variables'

import { PlanStatusEnum } from '../../../helpers'

interface Props {
  status: PlanStatusEnum
  date?: string
}
const Container = styled.div`
  margin-bottom: 10px;
`
const Text = styled.div`
  font-size: ${variables.fontSize14};
`
const StyledDate = styled.div`
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const BillingDate: FC<Props> = ({ status, date }) => {
  const { t } = useTranslation()
  if (status === PlanStatusEnum.ACTIVE && date) {
    const dateFormatted = dayJs(date).format('DD MMM, YYYY')
    return (
      <Container>
        <Text>{t('getPremiumPage.nextBillingDate')}</Text>
        <StyledDate>{dateFormatted}</StyledDate>
      </Container>
    )
  }
  return null
}
