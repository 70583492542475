import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

import { SurveyForm } from './SurveyForm'

const Wrapper = styled.div`
  display: flex;
  padding: 64px 0px 80px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 0 auto;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 24px 16px 80px 16px;
    align-items: flex-start;
  }
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-size: ${variables.fontSize20};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    text-align: unset;
  }
`

export const Survey: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onCloseModal = (): void => {
    navigate(ArtistRoutesEnum.CampaignsList)
  }

  return (
    <FullScreenModal open showCloseButton onCancel={onCloseModal}>
      <Wrapper>
        <Title>{t('feedBackPage.title')}</Title>
        <SurveyForm onCloseModal={onCloseModal} />
      </Wrapper>
    </FullScreenModal>
  )
}
