import { createGlobalStyle } from 'styled-components'
import 'src/Localization/i18n'
import 'antd/dist/reset.css'
import './fonts/fonts.css'
export const GlobalStyles = createGlobalStyle`
body {
  -webkit-text-size-adjust: none;
  height: auto !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:  'Segoe UI', 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #646877;
}
button {
  border: 0;
}
a {
  text-decoration: inherit;
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}
.ant-message {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  top: 0 !important;
  .ant-message-notice {
    .ant-message-notice-content {
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      width: 100%;
    }
  }
}

.ant-message-custom-content {
  display: flex;
  justify-content: center;
}
`
