import React, { FC, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { useHint } from 'src/Hooks/useHint'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePaymentCompletedEvents } from 'src/Hooks/usePaymentCompletedEvents'
import { balance, campaignsList } from 'src/Router/routes'
import SuccessIcon from 'src/Assets/Svg/pages-icons/Success.svg?react'
import ExclamationIcon from 'src/Assets/Svg/pages-icons/ExclamationPayment.svg?react'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { getIsUserReferral } from 'src/Redux/auth-process/userSlice/selectors'

import { FullScreenModal } from '../FullScreenModal'
import { useSuccessPage } from '../SuccessPage/useSuccessPage'

import { ScheduleSuccessPageButtons } from './ScheduleSuccessPageButtons'

interface Props {
  startDate?: string | Date
  platformName: CampaignPlatformTypeEnum
}

const Container = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 358px;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Text = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

export const ScheduleSuccessPage: FC<Props> = ({ startDate, platformName }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setIsOpen } = useHint()
  const initialized = useRef<boolean>(false)
  const isUserReferral = useSelector(getIsUserReferral)

  const {
    isLoading,
    isUpsellButtonVisible,
    upsellButtonText,
    onlyDate,
    isGoToBalanceButtonVisible,
    onUpsellButtonClick,
    isSuccessPageVisible,
    paidCampaignsAmount,
  } = useSuccessPage(startDate)

  const { handlePaymentCompletedEvents } = usePaymentCompletedEvents()
  const isNextTimeBonusReceived = paidCampaignsAmount === 1 && !isUserReferral

  useEffect(() => {
    if (isSuccessPageVisible && !initialized.current) {
      initialized.current = true
      handlePaymentCompletedEvents({ platformName })
    }
  }, [
    initialized,
    isSuccessPageVisible,
    handlePaymentCompletedEvents,
    platformName,
  ])

  if (!isSuccessPageVisible) {
    return null
  }

  const title = isLoading
    ? t('scheduleCampaignPage.processing')
    : `${t('scheduleCampaignPage.awesome')} 🎉`

  const message = isNextTimeBonusReceived
    ? t('spotifySchedulePage.successWithBonus', { date: onlyDate })
    : t('spotifySchedulePage.success', { date: onlyDate })

  const text = isLoading ? t('scheduleCampaignPage.paymentProcessing') : message
  return (
    <FullScreenModal open>
      <Wrapper>
        <Container>
          {isLoading ? (
            <ExclamationIcon width={40} height={40} />
          ) : (
            <SuccessIcon width={40} height={40} />
          )}
          <TextContainer>
            <Title>{title}</Title>
            <Text>{text}</Text>
          </TextContainer>

          <ScheduleSuccessPageButtons
            hasUpsellButton={isUpsellButtonVisible}
            hasBalanceButton={isGoToBalanceButtonVisible}
            upsellButtonText={upsellButtonText}
            onCampaignsButtonClick={() => {
              navigate(`${campaignsList}`)
              setIsOpen()
            }}
            onBalanceButtonClick={() => {
              navigate(`${balance}`)
              setIsOpen()
            }}
            onUpsellButtonClick={onUpsellButtonClick}
          />
        </Container>
      </Wrapper>
    </FullScreenModal>
  )
}
