import React, { FC } from 'react'

import { Spin } from 'antd'
import styled, { useTheme } from 'styled-components'
import { SpinProps } from 'antd/lib'
import { LoadingOutlined } from '@ant-design/icons'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export type PrimaryLoadingProps = SpinProps & {
  fontSize?: number | string
  color?: string
}

export const PrimaryLoading: FC<PrimaryLoadingProps> = (props) => {
  const theme = useTheme()
  const {
    fontSize,
    color = theme.common.secondaryBorder,
    indicator = <LoadingOutlined style={{ fontSize, color }} spin />,
    ...rest
  } = props
  return (
    <Container>
      <Spin indicator={indicator} {...rest} />
    </Container>
  )
}
