import { useGetUserFeaturesQuery } from 'src/Redux/A-BFeaturesApi'

interface UseNewBonusSystem {
  isNewBonusSystem: boolean
  isSuccess: boolean
}

export const useNewBonusSystem = (): UseNewBonusSystem => {
  const { data, isSuccess } = useGetUserFeaturesQuery()

  const isNewBonusSystem = data?.features?.find(
    (feature) => feature.name === 'bonus_system_curator',
  )?.variation

  return {
    isSuccess,
    isNewBonusSystem: Boolean(isNewBonusSystem),
  }
}
