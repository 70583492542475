import { useEffect } from 'react'

import { useExistingBillingAgreement } from 'src/Hooks/useExistingBillingAgreement'
import { useSidebarVisibility } from 'src/Hooks/useSidebarVisibility'
import { useCheckoutTimerFeatureFlag } from 'src/Hooks/useCheckoutTimerFeratureFlag'
import { updateCountdown } from 'src/Redux/checkoutCountdown-process'
import { useAppDispatch } from 'src/Hooks/redux'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'

import { useCheckTikTokCampaignStatus } from './useCheckTikTokCampaignStatus'
import { useDefaultTikTokCampaignStartDate } from './useDefaultTikTokCampaignStartDate'
import { useBackFromPaymentPageTikTok } from './useBackFromPaymentPageTikTok'
import { useScheduleStartEvent } from './useScheduleStartEvent'

export const useScheduleTikTokCampaign = (): void => {
  const dispatch = useAppDispatch()

  useSidebarVisibility()
  useExistingBillingAgreement()
  useCheckTikTokCampaignStatus()
  useDefaultTikTokCampaignStartDate()
  useBackFromPaymentPageTikTok()
  useScheduleStartEvent()

  useBonusTooltipTimer()
  useCheckoutTimerFeatureFlag()

  useEffect(
    () => () => {
      dispatch(updateCountdown(null))
    },
    [dispatch],
  )
}
