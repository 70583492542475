import React, { FC } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'
import { PrimaryTrackCover } from 'src/Components/PrimaryTrackCover'

const Container = styled.div`
  display: flex;
  padding: 8px 17px 8px 12px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  align-self: stretch;
  border: 1px solid transparent;
  background: ${({ theme }) => theme.common.primaryBg};
  transition: border 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    transition: border 0.2s ease-in-out;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.common.secondaryBorder};
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

const TrackName = styled.span`
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Text = styled.span`
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

interface TrackItemProps {
  onItemClick: () => void
  trackName: string
  artistName: string
  trackDuration: string
  trackCoverUrl: string
  loading: boolean
  onTrackControlClick: (
    e: React.MouseEvent<HTMLDivElement>,
    trackUrl?: string,
  ) => void
  isPlaying: boolean
  show: boolean
}

export const TrackItem: FC<TrackItemProps> = ({
  trackName,
  onItemClick,
  artistName,
  trackDuration,
  trackCoverUrl,
  loading,
  onTrackControlClick,
  isPlaying,
  show,
}) => (
  <Container onClick={onItemClick}>
    <PrimaryTrackCover
      isPlaying={isPlaying}
      loading={loading}
      onTrackControlClick={onTrackControlClick}
      show={show}
      trackCoverUrl={trackCoverUrl}
    />

    <InfoContainer>
      <TrackName>{trackName}</TrackName>
      <Text>{artistName}</Text>
    </InfoContainer>
    <Text>{trackDuration}</Text>
  </Container>
)
