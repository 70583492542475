import { FC } from 'react'

import styled from 'styled-components'
import { Tabs, TabsProps } from 'antd'

import { colors, variables } from 'src/Styled/variables'
type TabItem = NonNullable<TabsProps['items']>[number]

export type PrimaryTabsProps = Omit<TabsProps, 'items'> & {
  items: Array<TabItem & { count?: number | null; badge?: React.ReactNode }>
}
export const StyledTabs = styled(Tabs)`
  width: 100%;

  &.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid transparent;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.common.primaryText} !important;
  }

  .ant-tabs-nav-wrap {
    box-shadow: none !important;
  }

  .ant-tabs-tab {
    padding: 0;
    color: ${({ theme }) => theme.common.primaryPlaceholder} !important;
    border-bottom: 2px solid ${({ theme }) => theme.common.primaryBorder};
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.common.secondaryBorder};
    border-radius: 1px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }

  .ant-tabs-tab-btn {
    box-shadow: none !important;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tab-btn:active {
    color: ${({ theme }) => theme.common.primaryText} !important;
  }

  .ant-tabs-tab-btn:focus-visible {
    outline: none !important;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }
`

const Tab = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
const Text = styled.div`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const Count = styled.div`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 0 4px;
`

const Badge = styled.div`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize10};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${colors.badgeGreen};
  border-radius: 4px;
  background: ${colors.progressMain};
  padding: 0px 4px;
`

export const PrimaryTabs: FC<PrimaryTabsProps> = (props) => (
  <StyledTabs
    {...props}
    items={props.items.map((item) => ({
      ...item,
      label: (
        <Tab>
          <Text>{item.label}</Text>
          {item.count && <Count>{item.count}</Count>}
          {item.badge && <Badge>New</Badge>}
        </Tab>
      ),
    }))}
  />
)
