import { createSlice } from '@reduxjs/toolkit'

import { TransferwiseRequirement } from 'src/Types/index'
import { setApiError } from 'src/Hooks/redux'

import { ApiError, NameSpace } from '../types'

import {
  getTransferwiseAccountRequirements,
  specifyTransferwiseAccountRequirements,
} from './api-actions'

export interface WiseState {
  requirements: Array<TransferwiseRequirement>
  loading: boolean
  error: ApiError | null
}

const initialState: WiseState = {
  requirements: [],
  loading: false,
  error: null,
}

export const wiseProcess = createSlice({
  initialState,
  name: NameSpace.Wise,
  reducers: {
    setRequirementsToInitialState(state) {
      state.requirements = []
    },
  },
  extraReducers: (builder) => {
    // getTransferwiseAccountRequirements
    builder.addCase(getTransferwiseAccountRequirements.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getTransferwiseAccountRequirements.fulfilled,
      (state, { payload }) => {
        state.loading = false
        state.requirements = payload.data
      },
    )
    builder.addCase(
      getTransferwiseAccountRequirements.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )

    // specifyTransferwiseAccountRequirements
    builder.addCase(specifyTransferwiseAccountRequirements.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      specifyTransferwiseAccountRequirements.fulfilled,
      (state, { payload }) => {
        state.loading = false
        state.requirements = payload.data
      },
    )
    builder.addCase(
      specifyTransferwiseAccountRequirements.rejected,
      (state, action) => {
        state.loading = false
        state.error = setApiError(action)
      },
    )
  },
})

export const { setRequirementsToInitialState } = wiseProcess.actions
