import { useCallback, useEffect, useState } from 'react'

import { setPaymentMethod } from 'src/Redux/payment-process'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'
import { getIsEnoughBalanceSelector } from 'src/Redux/spotifyCampaignMatchingProcess'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useGetLastPaymentMethodQuery } from 'src/Redux/lastPaymentMethodApi'
import { changeRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process'

type InitialPaymentMethod = {
  hasNotInitPaymentMethod: boolean
  isLoading: boolean
  setHasNotInitPaymentMethod: (value: boolean) => void
}

export const useInitialPaymentMethod = (): InitialPaymentMethod => {
  const dispatch = useAppDispatch()

  const [hasNotInitPaymentMethod, setHasNotInitPaymentMethod] = useState(false)

  const {
    data: billingData,
    isSuccess: isSuccessBilling,
    isLoading: isLoadingBilling,
  } = useGetBillingAgreementQuery()

  const isEnoughBalance = useAppSelector(getIsEnoughBalanceSelector)

  const {
    data: initPaymentMethodData,
    isSuccess: isSuccessLast,
    isLoading: isLoadingLast,
  } = useGetLastPaymentMethodQuery()

  const initPaymentMethod = initPaymentMethodData?.type

  const getDefaultValue = useCallback(() => {
    setHasNotInitPaymentMethod(!initPaymentMethod)
    if (isEnoughBalance) {
      dispatch(setPaymentMethod(PaymentMethodEnum.Credits))
      return
    }
    if (initPaymentMethod === 'stripe') {
      dispatch(setPaymentMethod(PaymentMethodEnum.STRIPE))
      return
    }
    if (initPaymentMethod === 'paypal' && billingData?.paypalEmail) {
      dispatch(setPaymentMethod(PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT))
      return
    }
    if (initPaymentMethod === 'paypal' && !billingData?.paypalEmail) {
      dispatch(changeRememberPayPalForFutureFlag(false))
      dispatch(setPaymentMethod(PaymentMethodEnum.PAYPAL))
      return
    }
    dispatch(setPaymentMethod(PaymentMethodEnum.STRIPE))
  }, [isEnoughBalance, initPaymentMethod, billingData?.paypalEmail, dispatch])

  useEffect(() => {
    if (isSuccessBilling && isSuccessLast) {
      getDefaultValue()
    }
  }, [getDefaultValue, isSuccessBilling, isSuccessLast])

  return {
    isLoading: isLoadingBilling || isLoadingLast,
    hasNotInitPaymentMethod,
    setHasNotInitPaymentMethod,
  }
}
