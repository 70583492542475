import { FC } from 'react'

import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import { getIsBlockedCreatorSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { useCreatorOrderPayoutMutation } from 'src/Redux/orderPayoutApi'
import { ErrorCode } from 'src/Redux/types'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { useGetRecipientBankDetailsQuery } from 'src/Redux/wise-process/recipientApi'
import { CuratorsRouteEnum } from 'src/Router/CuratorComponentsNewUI'

const StyledAlert = styled(PrimaryAlert)`
  margin-top: 10px;
`

const SupportButton = styled(PrimaryDottedButton)`
  color: ${({ theme }) => theme.button.redText};
  div {
    border-bottom-color: ${({ theme }) => theme.button.redText};
  }
`
const BankDetailsButton = styled(PrimaryDottedButton)`
  color: ${({ theme }) => theme.button.orangeText};
  div {
    border-bottom-color: ${({ theme }) => theme.button.orangeText};
  }
`

export const InfluencerBlockedError: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isBlocked = useAppSelector(getIsBlockedCreatorSelector)
  const { data: recipient } = useGetRecipientBankDetailsQuery()
  const [_, { error }] = useCreatorOrderPayoutMutation({
    fixedCacheKey: 'CreatorOrderPayout',
  })
  const { show } = useIntercom()

  const hasProblemsWithTikTok =
    error && 'status' in error && error.status === ErrorCode.Forbidden
  const hasDefaultError =
    error && 'status' in error && error.status === ErrorCode.InternalServerError
  if (hasDefaultError) {
    return (
      <StyledAlert type='danger' hasCross={false}>
        {t('balancePage.Oops')}
      </StyledAlert>
    )
  }
  const handleClickSupport = (): void => {
    show()
  }
  const handleNavigateToSettings = (): void => {
    navigate(CuratorsRouteEnum.Settings)
  }
  if (isBlocked || hasProblemsWithTikTok) {
    return (
      <StyledAlert
        type='danger'
        hasCross={false}
        title={t('balancePage.accountBlocked')}
        renderButtons={() => (
          <SupportButton onClick={handleClickSupport}>
            {t('balancePage.contactSupport')}
          </SupportButton>
        )}
      >
        {t('balancePage.blockedDesc')}
      </StyledAlert>
    )
  }
  if (!recipient) {
    return (
      <StyledAlert
        type='warning'
        title='Please add your bank details to request payouts'
        hasCross={false}
        renderButtons={() => (
          <BankDetailsButton onClick={handleNavigateToSettings}>
            Add bank details
          </BankDetailsButton>
        )}
      >
        Once you have added your banking details, you will be able to request
        payouts.
      </StyledAlert>
    )
  }
  return null
}
