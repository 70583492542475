import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useGetLanguagesForExistingPlaylistsQuery } from 'src/Redux/languagesApi'
import { GroupedOption, SelectedLanguage } from 'src/Types'

import {
  LanguageListLabel,
  LanguagesModalSpotifyGroupedItem,
} from './LanguagesModalSpotifyGroupedItem'
import { LanguagesModalSpotifySelectedLanguage } from './LanguagesModalSpotifySelectedLanguage'

const ListComponent = styled.ul<{ $hasMargin?: boolean }>`
  padding: 0;
  ${LanguageListLabel}:first-child {
    margin-top: ${({ $hasMargin }) => ($hasMargin ? '16px' : '0')};
  }
`

const NoDataText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

interface Props {
  selectedLanguage: SelectedLanguage | null
  searchValue: string
  setValue: (value: string) => void
  targetLanguage?: number
  onClick: (item: SelectedLanguage) => void
  onChange: (id: number) => void
}

const getNewFilteredLanguages = (
  groupedOptions: GroupedOption[],
  searchValue: string,
): GroupedOption[] =>
  groupedOptions.map((item) => ({
    ...item,
    options: item.options.filter(({ label }) =>
      label?.toLowerCase().includes(searchValue.toLowerCase()),
    ),
  }))

export const LanguagesModalSpotifyWrapperList: FC<Props> = ({
  selectedLanguage,
  searchValue,
  setValue,
  targetLanguage,
  onClick,
  onChange,
}) => {
  const { t } = useTranslation()
  const { data: groupedOptions } = useGetLanguagesForExistingPlaylistsQuery()

  const filteredOptions = getNewFilteredLanguages(
    groupedOptions || [],
    searchValue,
  )

  const showNoData =
    !filteredOptions[0]?.options?.length && !filteredOptions[1]?.options?.length

  if (selectedLanguage) {
    return (
      <>
        <LanguagesModalSpotifySelectedLanguage
          selectedLanguage={selectedLanguage}
          targetLanguage={targetLanguage}
          onClick={() => {
            onClick(selectedLanguage)
          }}
          onChange={onChange}
          searchValue={searchValue}
        />
        {searchValue && (
          <ListComponent $hasMargin={Boolean(selectedLanguage)}>
            {filteredOptions.map((item) => (
              <LanguagesModalSpotifyGroupedItem
                key={item.label}
                searchValue={searchValue}
                item={item}
                onClick={onClick}
              />
            ))}
          </ListComponent>
        )}
      </>
    )
  }

  if (showNoData) {
    return (
      <ListComponent>
        <NoDataText>{t('spotifySchedulePage.noData')}</NoDataText>
      </ListComponent>
    )
  }

  return (
    <ListComponent $hasMargin={Boolean(selectedLanguage)}>
      {filteredOptions.map((item) => (
        <LanguagesModalSpotifyGroupedItem
          key={item.label}
          searchValue={searchValue}
          item={item}
          onClick={(v) => {
            onClick(v)
            setValue('')
          }}
        />
      ))}
    </ListComponent>
  )
}
