import { FunctionComponent, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import {
  getUser,
  getUserIntercomHash,
} from 'src/Redux/auth-process/userSlice/selectors'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { getIsAuth } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { IntercomSetupProps } from './props'

export const IntercomSetup: FunctionComponent<IntercomSetupProps> = ({
  name,
}) => {
  const { boot, update } = useIntercom()
  const isAuth = useSelector(getIsAuth)
  const { email, first_name } = useSelector(getUser)
  const intercomHash = useSelector(getUserIntercomHash)

  const greetingName = useMemo(
    () => capitalizeFirstLetter(first_name),
    [first_name],
  )

  useEffect(() => {
    if (isAuth) {
      boot({
        customAttributes: {
          email,
          name: greetingName,
          user_hash: intercomHash,
        },
      })
    }
  }, [boot, greetingName, isAuth, email, intercomHash])

  useEffect(() => {
    if (isAuth) {
      update({
        customAttributes: {
          name,
          email,
          user_hash: intercomHash,
        },
      })
    }
  }, [name, update, isAuth, email, intercomHash])

  return null
}
