import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import {
  CampaignPlatformTypeEnum,
  CampaignTypeEnum,
  TiktokCampaign,
  TiktokCampaignStatus,
} from 'src/Types'
import { setApiError } from 'src/Hooks/redux'
import { DEFAULT_PERIOD_TO_START_CAMPAIGN } from 'src/Constants/constants'
import { formatDate } from 'src/Constants/formatDate'

import {
  getCampaignTikTokCampaignToScheduleDetails,
  updateTikTokCampaign,
} from './api-actions'

export interface TiktokCampaignState {
  campaign: TiktokCampaign
  error: ApiError | null
  status: SliceStatus
}

export const getDefaultStartDate = (isToday?: boolean): string =>
  dayjs()
    .add(isToday ? 0 : DEFAULT_PERIOD_TO_START_CAMPAIGN, 'days')
    .format(formatDate.formatDateMonthFull)

const initialState: TiktokCampaignState = {
  campaign: {
    id: '',
    type: CampaignTypeEnum.BASIC,
    platformType: CampaignPlatformTypeEnum.TIKTOK,
    trackDuration: 0,
    totalVideos: 0,
    trackTitle: '',
    totalShares: 0,
    totalViews: 0,
    trackAuthorName: '',
    trackId: 0,
    status: TiktokCampaignStatus.PENDING,
    startDate: getDefaultStartDate(),
    submittedVideosAmount: 0,
    influencersAcceptedAmount: 0,
    endDate: '',
    genres: [],
    languages: [],
    isCurrent: false,
    totalRefunded: 0,
    relaunched: false,
    reviews: {
      done: 0,
      influencersAcceptedCount: 0,
    },
  },
  error: null,
  status: SliceStatus.Idle,
}

export const tiktokCampaignSlice = createSlice({
  initialState,
  name: NameSpace.TiktokCampaign,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateTikTokCampaign.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(updateTikTokCampaign.fulfilled, (state, { payload }) => {
      const mappedGenres = payload.genres.map((genre) => ({
        label: genre.name,
        value: genre.id,
      }))

      const mappedLanguages = payload.languages.map((language) => ({
        label: language.name,
        value: language.id,
      }))

      const prevTrackDuration = state.campaign?.trackDuration

      state.campaign = {
        ...payload,
        type: state.campaign?.type,
        genres: mappedGenres,
        languages: mappedLanguages,
        trackDuration: prevTrackDuration,
        startDate: payload.startDate,
      }
      state.status = SliceStatus.Succeeded
    })
    builder.addCase(updateTikTokCampaign.rejected, (state, action) => {
      state.error = setApiError(action)
      state.status = SliceStatus.Failed
    })

    // getCampaignTikTokCampaignToScheduleDetails
    builder.addCase(
      getCampaignTikTokCampaignToScheduleDetails.pending,
      (state) => {
        state.status = SliceStatus.Loading
      },
    )
    builder.addCase(
      getCampaignTikTokCampaignToScheduleDetails.fulfilled,
      (state, { payload }) => {
        const mappedGenres = payload.genres?.map((genre) => ({
          value: genre.id,
          label: genre.name,
        }))

        const mappedLanguages = payload.languages?.map((language) => ({
          value: language.id,
          label: language.name,
        }))
        const prevType = state.campaign?.type
        const prevTrackDuration = state.campaign?.trackDuration
        state.campaign = {
          ...payload,
          genres: mappedGenres,
          languages: mappedLanguages,
          type: payload.type || prevType,
          trackDuration: payload.track?.duration || prevTrackDuration || 0,
        }
        state.status = SliceStatus.Succeeded
      },
    )
    builder.addCase(
      getCampaignTikTokCampaignToScheduleDetails.rejected,
      (state, action) => {
        state.error = setApiError(action)
        state.status = SliceStatus.Failed
      },
    )
  },
})
