import React, { FC } from 'react'

import { usePaymentCancelledSpotifyEvents } from 'src/Hooks/usePaymentCancelledSpotifyEvents'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getPaymentAlertError,
  getPaymentMethod,
} from 'src/Redux/payment-process/selectors'
import {
  getIsProcessingPage,
  getRememberPayPalForFutureFlag,
} from 'src/Redux/paypalTransactions-process/selectors'
import { SchedulePaymentProcessingModal } from 'src/Components/SchedulePaymentProcessingModal'
import { ScheduleCheckoutErrors } from 'src/Components/ScheduleCheckoutErrors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { PaymentMethodBlock } from 'src/Components/PaymentMethodBlock'
import { usePaymentMethodChanged } from 'src/Components/PaymentMethods/usePaymentMethodChanged'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { setPaymentMethod } from 'src/Redux/payment-process'
import { changeRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process'
import { ScheduleSettingsPageSkeleton } from 'src/Components/Skeletons/ScheduleSettingsPageSkeleton'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'
import { spotifyTotalPriceSelector } from 'src/Redux/spotifyCampaignMatchingProcess'
import { continueCheckoutEvent } from 'src/Helpers/TagManager/CheckoutEvents'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

import { useCheckSpotifyCheckoutStepIsAllowed } from '../helpers/useCheckSpotifyCheckoutStepIsAllowed'

import { Summary } from './Summary'
import { useInitialPaymentMethod } from './useInitialPaymentMethod'

export const SecondarySpotifyScheduleCheckoutPage: FC = () => {
  useCheckSpotifyCheckoutStepIsAllowed()
  usePaymentCancelledSpotifyEvents()
  const { hasNotInitPaymentMethod, setHasNotInitPaymentMethod, isLoading } =
    useInitialPaymentMethod()
  const userId = useAppSelector(getUserId)
  const { data: billingData } = useGetBillingAgreementQuery()

  const dispatch = useAppDispatch()

  const paymentMethod = useAppSelector(getPaymentMethod)
  const isRememberPayPalForFuture = useAppSelector(
    getRememberPayPalForFutureFlag,
  )
  const paymentError = useAppSelector(getPaymentAlertError)
  const isProcessingPageVisible = useAppSelector(getIsProcessingPage)
  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const amountOfCampaigns = useAppSelector(getAmountOfCampaigns)
  const { data: plan } = useGetSubscriptionQuery()
  const spotifyTotalPrice = useAppSelector(spotifyTotalPriceSelector)

  const handlePaymentMethodChange = (): void => {
    if (hasNotInitPaymentMethod && spotifyTotalPrice) {
      continueCheckoutEvent({
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
        campaignId: spotifyCampaignDetails.id,
        campaignType: spotifyCampaignDetails.type,
        userId: String(userId),
        campaignNumber: amountOfCampaigns || 1,
        resultPrice: spotifyTotalPrice,
        paymentMethod: paymentMethod || PaymentMethodEnum.STRIPE,
        subscriptionPlan: plan?.package?.name || '',
      })
    }
    setHasNotInitPaymentMethod(!hasNotInitPaymentMethod)
  }

  const { setIsPaymentMethodChangedSent } = usePaymentMethodChanged()

  const handleChangePaymentMethod = (value: PaymentMethodEnum): void => {
    dispatch(setPaymentMethod(value))

    localStorage.setItem('currentPaymentMethod', value)

    setIsPaymentMethodChangedSent(value, CampaignPlatformTypeEnum.SPOTIFY)
  }
  const handleChangeHasSavePaypal = (value: boolean): void => {
    dispatch(changeRememberPayPalForFutureFlag(value))
  }

  if (isLoading) {
    return <ScheduleSettingsPageSkeleton />
  }

  return (
    <>
      {isProcessingPageVisible && <SchedulePaymentProcessingModal />}
      {paymentError && <ScheduleCheckoutErrors name={paymentError} />}
      {!hasNotInitPaymentMethod && (
        <Summary onPaymentMethodChange={handlePaymentMethodChange} />
      )}
      {hasNotInitPaymentMethod && (
        <PaymentMethodBlock
          paymentMethod={paymentMethod}
          paypalEmail={billingData?.paypalEmail}
          hasSavePaypal={isRememberPayPalForFuture}
          onContinueClick={handlePaymentMethodChange}
          onChangeHasSavePaypal={handleChangeHasSavePaypal}
          onChange={handleChangePaymentMethod}
        />
      )}
    </>
  )
}
