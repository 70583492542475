import React, { forwardRef } from 'react'

import { Input as AntdInput } from 'antd'
import styled, { css } from 'styled-components'
import type { TextAreaProps } from 'rc-textarea/lib/interface'
import { TextAreaRef } from 'antd/es/input/TextArea'

import { variables } from 'src/Styled/variables'

const { TextArea: AntdTextArea } = AntdInput

const commonStyles = css`
  font-size: ${variables.fontSize12};
  opacity: 1;
  top: 7px;
  left: 16px;
  transition: all 0.2s ease;
  margin: 0;
  z-index: 2;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:focus-within p {
    ${commonStyles}
  }

  &:has(textarea:not(:empty)) p {
    ${commonStyles}
  }

  &:has(textarea:not(:empty)) span {
    padding-top: 27px !important;
  }
`

const Label = styled.p`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.primaryPlaceholder};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  opacity: 0;
  transition: all 0.2s ease;
  position: absolute;
  top: 27px;
  left: 18px;
  z-index: 0;
`

const ErrorMessage = styled.div`
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.common.dangerText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  margin-top: 5px;
`

const StyledTextArea = styled(AntdTextArea)<{
  $isError?: boolean
}>`
  color: ${({ theme }) => theme.common.primaryText};
  border-radius: 16px;
  padding: 16px !important;
  padding-top: 24px !important;

  border: ${({ theme, $isError }) =>
    $isError
      ? `1px solid ${theme.common.dangerBorder}`
      : `1px solid ${theme.common.primaryBorder}`} !important;
  position: relative;

  .ant-input {
    padding: 0;
  }

  &.ant-input-outlined {
    box-shadow: none;
  }

  &:not([value='']) {
    padding-top: 17px !important;
    padding-bottom: 7px !important;
  }

  ::placeholder {
    color: ${({ theme }) => theme.common.primaryPlaceholder};
    font-family: 'Segoe UI';
    line-height: 150%;
    font-size: ${variables.fontSize14};
    font-weight: ${variables.fontWeight400};
    transition: all 0.2s ease;
  }

  &.ant-input-affix-wrapper-focused {
    border: ${({ theme, $isError }) =>
      $isError
        ? `1px solid ${theme.common.dangerBorder}`
        : `1px solid ${theme.common.thirdBorder}`} !important;

    padding-top: 27px !important;

    textarea {
      &::placeholder {
        opacity: 0;
        transition: all 0.2s ease;
      }
    }
  }

  .ant-input-data-count {
    color: ${({ theme }) => theme.common.primaryText};
    font-family: 'Segoe UI';
    line-height: 150%;
    font-size: ${variables.fontSize12};
    font-weight: ${variables.fontWeight400};
  }

  &.ant-input-affix-wrapper-disabled {
    color: ${({ theme }) => theme.common.thirdText};
    border: ${({ theme }) =>
      `1px solid ${theme.common.primaryBorder}`} !important;
    background-color: ${({ theme }) => theme.common.primaryBg};

    textarea {
      &::placeholder {
        color: ${({ theme }) => theme.common.thirdText};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.common.primaryBg};
    }
  }

  &.ant-input-affix-wrapper-disabled:hover {
    background-color: ${({ theme }) => theme.common.primaryBg} !important;
  }
`

export type TextareaProps = TextAreaProps & {
  error?: string
}

export const TextArea = forwardRef<TextAreaRef, TextareaProps>((props, ref) => {
  const { placeholder, error } = props

  return (
    <Wrapper>
      <Label>{placeholder}</Label>
      <StyledTextArea {...props} $isError={Boolean(error)} ref={ref} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  )
})

TextArea.displayName = 'TextArea'
