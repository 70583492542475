import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const TabTriggersContainer = styled.div`
  display: flex;
  overflow: hidden;
  scrollbar-width: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 576px) {
    position: relative;
    width: 100vw;
  }
`

export const TabTrigger = styled.p<{ active?: boolean }>`
  flex-shrink: 0;
  margin: 0 0 24px 0 !important;
  padding: 10px 24px;
  border-bottom: 2px solid
    ${({ active }) => (active ? colors.green : colors.greyStroke)};
  color: ${({ active, theme }) =>
    active
      ? theme.common.primaryText
      : theme.common.primaryPlaceholder} !important;
  font-size: ${variables.fontSize14};
  cursor: pointer;

  span {
    margin-left: 8px;
    color: ${({ active, theme }) =>
      active ? theme.common.primaryText : theme.common.primaryPlaceholder};
    font-size: ${variables.fontSize14};
  }
  &:hover {
    border-color: ${({ active }) =>
      active ? colors.green : colors.greyTextBlackBg};
  }
`
