import { usePaymentMethodChanged } from 'src/Components/PaymentMethods/usePaymentMethodChanged'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useExistingBillingAgreement } from 'src/Hooks/useExistingBillingAgreement'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'
import { setPaymentMethod } from 'src/Redux/payment-process'
import { getPaymentMethod } from 'src/Redux/payment-process/selectors'
import { changeRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process'
import { getRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { getIsEnoughBalanceSelector } from 'src/Redux/spotifyCampaignMatchingProcess'

type UseSpotifySchedulePaymentMethod = {
  onChange: (value: PaymentMethodEnum) => void
  onChangeHasSavePaypal: (value: boolean) => void
  hasSavePaypal: boolean
  paymentMethod: PaymentMethodEnum
  paypalEmail: string | undefined
  isEnoughBalance: boolean
}

export const useSpotifySchedulePaymentMethod =
  (): UseSpotifySchedulePaymentMethod => {
    useExistingBillingAgreement()
    const dispatch = useAppDispatch()
    const { data: billingData } = useGetBillingAgreementQuery()
    const paymentMethod = useAppSelector(getPaymentMethod)
    const isRememberPayPalForFuture = useAppSelector(
      getRememberPayPalForFutureFlag,
    )
    const isEnoughBalance = useAppSelector(getIsEnoughBalanceSelector)
    const { setIsPaymentMethodChangedSent } = usePaymentMethodChanged()

    const handleChangePaymentMethod = (value: PaymentMethodEnum): void => {
      dispatch(setPaymentMethod(value))

      localStorage.setItem('currentPaymentMethod', value)

      setIsPaymentMethodChangedSent(value, CampaignPlatformTypeEnum.SPOTIFY)
    }
    const handleChangeHasSavePaypal = (value: boolean): void => {
      dispatch(changeRememberPayPalForFutureFlag(value))
    }
    return {
      paymentMethod,
      isEnoughBalance,
      hasSavePaypal: isRememberPayPalForFuture,
      paypalEmail: billingData?.paypalEmail,
      onChangeHasSavePaypal: handleChangeHasSavePaypal,
      onChange: handleChangePaymentMethod,
    }
  }
