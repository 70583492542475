import styled from 'styled-components'

import {
  variables,
  colors,
  device,
  MAX_MOBILE_WIDTH,
} from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { CrossButton } from 'src/Components/Buttons/CrossButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 600px;
`

export const Content = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  padding-bottom: 64px;
`

export const Title = styled.h3`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const Description = styled.p`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`

export const StyledButton = styled(Button)`
  width: 203px;
`

export const WrapperContainer = styled.div`
  @media ${device.mobileMin} {
    padding: 65px 0;
    width: 600px;
  }
`

export const Wrapper = styled.div`
  @media ${device.mobileMin} {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    overflow-y: scroll;
  }
`
export const CloseCrossButton = styled(CrossButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`
export const RangeWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`
