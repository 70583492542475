import { captureException } from '@sentry/react'
import axios from 'axios'

import i18n from 'src/Localization/i18n'

type Params = {
  email?: string
  planName?: string
  reason?: string
  reasonDetails?: string
}

export const sendCancelSubscriptionData = async (
  params: Params,
): Promise<void> => {
  try {
    await axios.post(
      'https://hook.eu1.make.com/qfz7w8ox5b6e4n7f1xgmi14d9uu94elf',
      {
        email: params?.email,
        cancelled_plan: params?.planName,
        cancelled_reason: `${i18n.t(params?.reason, { lng: 'en' })} ${
          params?.reasonDetails || ''
        }`,
      },
    )
  } catch (error) {
    captureException(new Error(JSON.stringify(error)))
  }
}
