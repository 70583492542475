import { FC, ReactNode, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { withErrorBoundary } from '@sentry/react'

import { getPlaylist } from 'src/Redux/playlists-process/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getPlaylists } from 'src/Redux/playlists-process/api-actions'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { Playlist } from 'src/Types'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { pageViewEvent } from 'src/Helpers/TagManager'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { MAX_TABLET_WIDTH } from 'src/Styled/variables'
import { ErrorComponent } from 'src/Components/ErrorBoundary/ErrorComponent'

import { PlayList } from './components/PlayList'
import PlayListComponentEmptyPage from './components/PlayListComponentEmptyPage'
import { LanguagePreferenceAlert } from './components/LanguagePreferenceAlert'
import { LanguageSetModal } from './components/LanguageSetModal'

const PlaylistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const LanguagePreferenceAlertWrapper = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  color: #646877;
  font-family: 'Segoe UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    border-radius: 16px;
    border: 1px solid #e7e7e7;
    background: #fff;
    box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  }
`

const PlayListPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const playlists = useSelector(getPlaylist)
  const userID = useAppSelector(getUserId)

  useEffect(() => {
    void dispatch(getPlaylists())
    void dispatch(getSpotifyAccessToken())
  }, [dispatch])

  useEffect(() => {
    userID && pageViewEvent({ userID, currentPageInfo: pageInfo.playlists })
  }, [userID])

  const checkEmptiness = (list: Array<Playlist>): ReactNode => {
    if (list.length) {
      return <PlayList />
    }
    return <PlayListComponentEmptyPage />
  }

  return (
    <PlaylistContainer>
      <Title>{t('playlistsPage.pageTitle')}</Title>

      <LanguagePreferenceAlertWrapper>
        <LanguagePreferenceAlert />
      </LanguagePreferenceAlertWrapper>

      <Container>{checkEmptiness(playlists)}</Container>
      <LanguageSetModal />
    </PlaylistContainer>
  )
}

export default withErrorBoundary(PlayListPage, {
  fallback: <ErrorComponent />,
})
