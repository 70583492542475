import { SubscriptionStatus } from 'src/Redux/subscription-process/subscriptionApi'

export enum PlanStatusEnum {
  ACTIVE = 'active',
  DOWNGRADE = 'downgrade',
  UPGRADE = 'upgrade',
  DEACTIVATED = 'deactivated',
  CANCELED = 'canceled',
  DEFAULT = 'default',
}
export interface Plan {
  name: string
  date?: string
  price: number
  features: string[]
  status: PlanStatusEnum
  isRecommended: boolean
  value: number
  cashback: number
}
const MAP: Record<SubscriptionStatus, PlanStatusEnum> = {
  active: PlanStatusEnum.ACTIVE,
  canceled: PlanStatusEnum.CANCELED,
  ended: PlanStatusEnum.DEACTIVATED,
}

interface Params {
  id: number
  packageId: number
  status?: SubscriptionStatus
}

export const getPlanStatus = ({
  id,
  packageId,
  status,
}: Params): PlanStatusEnum => {
  if (packageId === id) {
    return MAP[status || 'active']
  }
  if (Number(packageId) > id && status !== 'canceled') {
    return PlanStatusEnum.DOWNGRADE
  }
  if (Number(packageId) < id && status !== 'canceled') {
    return PlanStatusEnum.UPGRADE
  }
  return PlanStatusEnum.DEFAULT
}
