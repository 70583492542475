import { FC } from 'react'

import styled from 'styled-components'

import SafetyIcon from 'src/Assets/Svg/pages-icons/Safety.svg?react'

const StyledTopConfirmEmailBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 8px;
  max-width: 400px;
  background: ${({ theme }) => theme.common.primaryBg};
  line-height: 150%;
`

const StyledIcon = styled.div`
  grid-row: span 2;
  align-self: start;
  margin-top: 4px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`

export const TopConfirmEmailBlock: FC = () => (
  <StyledTopConfirmEmailBlock>
    <StyledIcon>
      <SafetyIcon />
    </StyledIcon>

    <TextContainer>
      <Text>
        Once you've completed this transaction, your payment method will be
        debited, and you'll receive an email message confirming receipt of your
        purchase.
      </Text>
    </TextContainer>
  </StyledTopConfirmEmailBlock>
)
