import { useParams } from 'react-router-dom'
import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react'

import { APP } from 'src/Configs/App'
import { useAppSelector } from 'src/Hooks/redux'
import { baseQuery, rtkApi } from 'src/Services/rtkApi'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import {
  getCampaignToSchedule,
  getGenresListSelector,
  getIsSelectedLanguagesWithoutAny,
} from '../campaign-process/spotifyCampaignSchedule-process/selectors'

interface BudgetRangeResponse {
  minBudget?: number
  maxBudget?: number
  curatorsCount?: number
  playlistsCount?: number
}

interface BudgetRange {
  min?: number
  max?: number
  absoluteCuratorsCount?: number
}

const spotifyBudgetRangeApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetRange: builder.query<BudgetRange, string | undefined>({
      query: (campaignId) =>
        `${APP.TIKTOK_SERVER}/artist/spotify-campaigns/${campaignId}/budget-range`,
      transformResponse(response: BudgetRangeResponse) {
        return {
          min: response?.minBudget,
          max: response?.maxBudget,
          absoluteCuratorsCount: response?.curatorsCount,
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetBudgetRangeQuery, useLazyGetBudgetRangeQuery } =
  spotifyBudgetRangeApi

export const useBudgetRange = (): TypedUseQueryStateResult<
  BudgetRange,
  string | undefined,
  typeof baseQuery
> => {
  const { number: campaignId } = useParams()
  const { search } = useCustomSearchParams()
  const isSelectedLanguagesWithoutAny = useAppSelector(
    getIsSelectedLanguagesWithoutAny,
  )
  const genreList = useAppSelector(getGenresListSelector)
  const campaign = useAppSelector(getCampaignToSchedule)

  const notValidCampaignId = !campaignId || campaign.id !== campaignId
  const skipBudgetRange =
    notValidCampaignId ||
    Boolean(search.get('paymentStatus')) ||
    !genreList.length ||
    !isSelectedLanguagesWithoutAny

  return useGetBudgetRangeQuery(campaignId, {
    skip: skipBudgetRange,
  })
}
