import { FC } from 'react'

import { SmartImage } from 'src/Components/SmartImage'
import track from 'src/Assets/Png/track.png'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { RewardPlaylist } from '../../types'

import {
  Container,
  CoverImageWrapper,
  InfoContainer,
  ScoreInfo,
} from './styles'

export const PlaylistCoverTooltipContent: FC<{
  playlist: RewardPlaylist
}> = ({ playlist }) => {
  const { imageUrl, playlistName, followers } = playlist
  const formattedFollowers = followers && addSpaceToNumber(followers, ',')

  return (
    <Container>
      <CoverImageWrapper>
        <SmartImage src={imageUrl} alt='playlistCover' fallbackSrc={track} />
      </CoverImageWrapper>
      <InfoContainer>
        <span>{playlistName}</span>
        <ScoreInfo>{formattedFollowers} followers</ScoreInfo>
      </InfoContainer>
    </Container>
  )
}
