import { FunctionComponent } from 'react'

import { Route, Routes } from 'react-router-dom'
import { withErrorBoundary } from '@sentry/react'

import { CampaignsListPage } from 'src/Pages/Artist/CampaignsListPage'
import { SpotifyCampaignDetailsPage } from 'src/Pages/Artist/SpotifyCampaignDetailsPage'
import { SpotifySchedulePage } from 'src/Pages/Artist/SpotifySchedulePage'
import { TiktokSchedulePage } from 'src/Pages/Artist/TiktokSchedulePage'
import { TiktokCampaignDetailsPage } from 'src/Pages/Artist/TiktokCampaignDetailsPage'
import { TikTokVideoDetailsPage } from 'src/Pages/Artist/TikTokVideoDetailsPage'
import { ArtistBalancePage } from 'src/Pages/Artist/ArtistBalancePage'
import { Subscriptions } from 'src/Pages/Artist/SubscriptionsPage'
import { PaySubscription } from 'src/Pages/Artist/SubscriptionsPage/PaySubscription/PaySubscription'
import { SuccessPaymentSubscription } from 'src/Pages/Artist/SubscriptionsPage/SuccessPaymentSubscription'
import { CancelSubscription } from 'src/Pages/Artist/SubscriptionsPage/CancelSubscription'
import { ReferAndEarnPage } from 'src/Pages/Artist/ReferAndEarnPage'
import { SettingsPage } from 'src/Pages/Artist/SettingsPage'
import { ExpiredLinkPage } from 'src/Pages/Artist/ExpiredLinkPage'
import { ChoosePlatformAuthPage } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/ChoosePlatformAuthPage'
import { SearchTrackAuth } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/SearchTrackAuth'
import { Survey } from 'src/Pages/Artist/Survey'
import { SuccessCreatedFirst } from 'src/Pages/Artist/SuccessCreatedFirst'
import { SelectedTrackAuth } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/SelectedTrackAuth'
import { ErrorComponent } from 'src/Components/ErrorBoundary/ErrorComponent'

const CampaignsListPageWithErrorBoundary = withErrorBoundary(
  CampaignsListPage,
  {
    fallback: <ErrorComponent />,
  },
)
const SpotifyCampaignDetailsPageWithErrorBoundary = withErrorBoundary(
  SpotifyCampaignDetailsPage,
  {
    fallback: <ErrorComponent />,
  },
)
const SpotifySchedulePageWithErrorBoundary = withErrorBoundary(
  SpotifySchedulePage,
  {
    fallback: <ErrorComponent />,
  },
)
const TiktokSchedulePageWithErrorBoundary = withErrorBoundary(
  TiktokSchedulePage,
  {
    fallback: <ErrorComponent />,
  },
)
const TiktokCampaignDetailsPageWithErrorBoundary = withErrorBoundary(
  TiktokCampaignDetailsPage,
  {
    fallback: <ErrorComponent />,
  },
)
const TikTokVideoDetailsPageWithErrorBoundary = withErrorBoundary(
  TikTokVideoDetailsPage,
  {
    fallback: <ErrorComponent />,
  },
)
const PaySubscriptionWithErrorBoundary = withErrorBoundary(PaySubscription, {
  fallback: <ErrorComponent />,
})
const ArtistBalancePageWithErrorBoundary = withErrorBoundary(
  ArtistBalancePage,
  {
    fallback: <ErrorComponent />,
  },
)
const SubscriptionsWithErrorBoundary = withErrorBoundary(Subscriptions, {
  fallback: <ErrorComponent />,
})
const CancelSubscriptionWithErrorBoundary = withErrorBoundary(
  CancelSubscription,
  {
    fallback: <ErrorComponent />,
  },
)
const SuccessPaymentSubscriptionWithErrorBoundary = withErrorBoundary(
  SuccessPaymentSubscription,
  {
    fallback: <ErrorComponent />,
  },
)
const ExpiredLinkPageWithErrorBoundary = withErrorBoundary(ExpiredLinkPage, {
  fallback: <ErrorComponent />,
})
const ReferAndEarnPageWithErrorBoundary = withErrorBoundary(ReferAndEarnPage, {
  fallback: <ErrorComponent />,
})
const SettingsPageWithErrorBoundary = withErrorBoundary(SettingsPage, {
  fallback: <ErrorComponent />,
})
const SelectedTrackAuthWithErrorBoundary = withErrorBoundary(
  SelectedTrackAuth,
  {
    fallback: <ErrorComponent />,
  },
)
const SuccessCreatedFirstWithErrorBoundary = withErrorBoundary(
  SuccessCreatedFirst,
  {
    fallback: <ErrorComponent />,
  },
)
const SurveyWithErrorBoundary = withErrorBoundary(Survey, {
  fallback: <ErrorComponent />,
})
const SearchTrackAuthWithErrorBoundary = withErrorBoundary(SearchTrackAuth, {
  fallback: <ErrorComponent />,
})
const ChoosePlatformAuthPageWithErrorBoundary = withErrorBoundary(
  ChoosePlatformAuthPage,
  {
    fallback: <ErrorComponent />,
  },
)

export enum ArtistRoutesEnum {
  Root = '/',
  ChooseCreateCampaignPlatform = '/campaigns/new',
  ChooseCreateCampaignPlatformSpotify = '/campaigns/new/spotify',
  ChooseCreateCampaignPlatformTikTok = '/campaigns/new/tiktok',
  CampaignsList = '/campaigns',
  TiktokDetails = '/campaigns/details',
  SpotifyDetails = '/campaigns/view',
  SpotifySchedule = '/campaigns/schedule',
  TiktokSchedule = '/campaigns/scheduleTikTok',
  New = '/new',
  Settings = '/settings',
  ReferAndEarn = '/refer-and-earn',
  Balance = '/balance',
  Subscription = '/subscription',
  SubscriptionPayment = '/subscription/pay',
  SubscriptionPaymentSuccess = '/subscription/pay/success',
  SubscriptionCancel = '/subscription/cancel',
  Errors = '/errors',
  Create = '/create',
  CreateSpotify = '/create/spotify',
  CreateTikTok = '/create/tiktok',
  CreatedFirst = '/created-first',
  Feedback = '/feedback',
  SuccessCreated = '/created-first/:campaignType',
  ReferralLinkNotExist = '/referral-link-not-exist',
}

export const ArtistComponentsNewUI: FunctionComponent = () => (
  <Routes>
    <Route
      path={`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/:platform/:trackId`}
      Component={SelectedTrackAuthWithErrorBoundary}
    />
    <Route
      path={`${ArtistRoutesEnum.Create}/:platform/:trackId`}
      Component={SelectedTrackAuthWithErrorBoundary}
    />
    <Route
      path={`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/:platform`}
      Component={SearchTrackAuthWithErrorBoundary}
    />
    <Route
      Component={ChoosePlatformAuthPageWithErrorBoundary}
      path={`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}`}
    />
    <Route
      path={ArtistRoutesEnum.SuccessCreated}
      Component={SuccessCreatedFirstWithErrorBoundary}
    />
    <Route
      Component={ExpiredLinkPageWithErrorBoundary}
      path={ArtistRoutesEnum.Errors}
    />

    <Route
      Component={SettingsPageWithErrorBoundary}
      path={ArtistRoutesEnum.Settings}
    >
      <Route path=':page' Component={SettingsPageWithErrorBoundary} />
    </Route>

    <Route
      Component={ReferAndEarnPageWithErrorBoundary}
      path={ArtistRoutesEnum.ReferAndEarn}
    />

    <Route
      Component={ArtistBalancePageWithErrorBoundary}
      path={ArtistRoutesEnum.Balance}
    />
    <Route
      Component={SubscriptionsWithErrorBoundary}
      path={ArtistRoutesEnum.Subscription}
    />
    <Route
      path={ArtistRoutesEnum.SubscriptionPayment}
      Component={PaySubscriptionWithErrorBoundary}
    />
    <Route
      path={ArtistRoutesEnum.SubscriptionPaymentSuccess}
      Component={SuccessPaymentSubscriptionWithErrorBoundary}
    />
    <Route
      path={ArtistRoutesEnum.SubscriptionCancel}
      Component={CancelSubscriptionWithErrorBoundary}
    />

    <Route
      Component={TiktokSchedulePageWithErrorBoundary}
      path={`${ArtistRoutesEnum.TiktokSchedule}/:number`}
    />
    <Route
      Component={SpotifySchedulePageWithErrorBoundary}
      path={`${ArtistRoutesEnum.SpotifySchedule}/:number`}
    />
    <Route
      Component={SpotifyCampaignDetailsPageWithErrorBoundary}
      path={`${ArtistRoutesEnum.SpotifyDetails}/:campaignID`}
    />

    <Route path={`${ArtistRoutesEnum.TiktokDetails}`}>
      <Route
        path=':campaignID'
        Component={TiktokCampaignDetailsPageWithErrorBoundary}
      />
      <Route
        path=':campaignID/:videoID'
        Component={TikTokVideoDetailsPageWithErrorBoundary}
      />
    </Route>

    <Route Component={CampaignsListPageWithErrorBoundary} path='*' />
    <Route
      Component={CampaignsListPageWithErrorBoundary}
      path={ArtistRoutesEnum.Root}
    />
    <Route
      Component={CampaignsListPageWithErrorBoundary}
      path={ArtistRoutesEnum.CampaignsList}
    />
    <Route
      path={`${ArtistRoutesEnum.CreatedFirst}/:campaignType${ArtistRoutesEnum.Feedback}`}
      Component={SurveyWithErrorBoundary}
    />
  </Routes>
)
