import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import { RootState } from '..'
import { NameSpace } from '../types'

interface SoundLinkHintInterface {
  closedDate: string
  closedState: boolean
}

interface AlertState {
  approvedAlertsIds: Array<number>
  soundLinkHint: SoundLinkHintInterface
  newUIPopup: boolean
  bonusGuideStep: number
  run: boolean
  closedMessageCampaignIds: Array<number>
  newBonusPopup: boolean
}

const initialState: AlertState = {
  approvedAlertsIds: [],
  soundLinkHint: {
    closedDate: new Date().toISOString() || '',
    closedState: true,
  },
  newUIPopup: true,
  bonusGuideStep: 0,
  run: false,
  closedMessageCampaignIds: [],
  newBonusPopup: true,
}

const alerts = createSlice({
  initialState,
  name: NameSpace.Alert,
  reducers: {
    setBonusGuideStep: (state, { payload }: PayloadAction<number>) => {
      state.bonusGuideStep = payload
    },
    setBonusGuideRun: (state, { payload }: PayloadAction<boolean>) => {
      state.run = payload
    },
    setApprovedAlertsList: (state, { payload }: PayloadAction<number[]>) => {
      state.approvedAlertsIds = payload
    },
    setSoundLinkHint: (
      state,
      { payload }: PayloadAction<SoundLinkHintInterface>,
    ) => {
      state.soundLinkHint = payload
    },
    setNewUIPopupState: (state, { payload }: PayloadAction<boolean>) => {
      state.newUIPopup = payload
    },
    setClosedMessageCampaignId: (state, { payload }: PayloadAction<number>) => {
      state.closedMessageCampaignIds = [
        ...state.closedMessageCampaignIds,
        payload,
      ]
    },
    closeNewBonusPopup: (state) => {
      state.newBonusPopup = false
    },
  },
})

const persistConfig = {
  storage,
  key: 'alerts',
  blacklist: ['run'],
}
export const alertProcess = persistReducer(persistConfig, alerts.reducer)

export const {
  setApprovedAlertsList,
  setSoundLinkHint,
  setNewUIPopupState,
  setBonusGuideStep,
  setBonusGuideRun,
  setClosedMessageCampaignId,
  closeNewBonusPopup,
} = alerts.actions

const getState = (state: RootState): AlertState => state.alerts
export const getBonusGuideStep = createDraftSafeSelector(
  getState,
  ({ bonusGuideStep }) => bonusGuideStep,
)
export const getBonusGuideRun = createDraftSafeSelector(
  getState,
  ({ run }) => run,
)

export const getApprovedAlertsIds = createDraftSafeSelector(
  getState,
  ({ approvedAlertsIds }) => approvedAlertsIds,
)

export const getSoundLinkHint = createDraftSafeSelector(
  getState,
  ({ soundLinkHint }) => soundLinkHint,
)

export const getNewUIPopupState = createDraftSafeSelector(
  getState,
  ({ newUIPopup }) => newUIPopup,
)
export const getClosedMessageCampaignIds = createDraftSafeSelector(
  getState,
  ({ closedMessageCampaignIds }) => closedMessageCampaignIds,
)
export const getNewBonusPopupState = createDraftSafeSelector(
  getState,
  ({ newBonusPopup }) => newBonusPopup,
)
