import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const IconContainer = styled.div`
  display: flex;
`

export const RefreshBtnMobile = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  border: none;
  background-color: ${colors.white};
`
export const ButtonBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`
