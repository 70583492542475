import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const MobileHeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
  display: grid;
  grid-template-columns: 14.4px 1fr;
  column-gap: 8px;
  & svg {
    width: 14.4px;
    height: 16px;
    margin-top: 4px;
  }
`

export const MobileCard = styled.div`
  padding: 16px;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  border-radius: 4px;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`

export const MobileCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const MobileItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  padding: 0 0 60px 0;
`

export const MobileLoadingWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const MobileNoItemsTextWrapper = styled.div`
  display: block;
  text-align: center;
  margin-top: 16px;
`

export const MobileInfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`
export const HeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
`

export const InfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`

export const Amount = styled.span<{ green?: boolean }>`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const AmountWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding-left: 24px;
`

export const AmountContainer = styled.div`
  text-align: right;
`
export const DateWrapper = styled.div`
  padding-left: 24px;
`

export const StatusWrapper = styled.div``

export const StyledDate = styled.div``

export const Title = styled.div`
  font-size: ${variables.fontSize10};
`
