import React, { FC } from 'react'

import styled from 'styled-components'

import { device } from 'src/Styled/variables'

import { PrimaryButton } from '../Buttons/PrimaryButton'
import { FullScreenModal } from '../FullScreenModal'
import { PrimaryLabel } from '../PrimaryLabel'

type NewBonusesPopupProps = {
  onClosePopup?: () => void
}

const Container = styled.div`
  display: flex;
  max-width: 289px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.tabletMin} {
    max-width: 600px;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin: 0 auto;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Text = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.thirdBg};
`

export const NewBonusesPopup: FC<NewBonusesPopupProps> = ({ onClosePopup }) => (
  <FullScreenModal open>
    <Wrapper>
      <Container>
        <TextContainer>
          <PrimaryLabel>Beta</PrimaryLabel>
          <Title>We`ve improved how extra rewards work</Title>
          <Text>
            Extra rewards are now called Bonuses. You can earn them by providing
            more value to artists through high-quality reviews and better
            exposure for their music. This update makes Bonuses clearer and
            easier to understand.
            <br />
            <br />
            Let’s take a quick look at what’s new.
          </Text>
        </TextContainer>
        <StyledPrimaryButton onClick={onClosePopup}>
          Show me what’s new
        </StyledPrimaryButton>
      </Container>
    </Wrapper>
  </FullScreenModal>
)
