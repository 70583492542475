import React, { ReactNode } from 'react'

import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  selectNewSoundsCount,
  useNewSoundsQuery,
} from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import {
  selectInProgressSoundsCount,
  useInProgressSoundsQuery,
} from 'src/Redux/creatorInProgressSoundsApi'
import {
  selectSubmittedSoundsCount,
  useSubmittedSoundsQuery,
} from 'src/Redux/creatorSubmittedSoundsApi'

import { NewSoundsList } from '../SoundsList/NewSoundsList'
import { InProgressSoundsList } from '../SoundsList/InProgressSoundsList'
import { SubmittedSoundsList } from '../SoundsList/SubmittedSoundsList'
import { TabKeysEnum } from '../../types'

export interface Tab {
  key: string
  label: React.ReactNode
  children: React.ReactNode
}

interface UseTracksTableI {
  tabs: Tab[]
}

export const useTracksTable = (): UseTracksTableI => {
  const influencerId = useAppSelector(getInfluencerId)

  const { newSoundsCount, isError } = useNewSoundsQuery(
    {
      creatorId: influencerId,
      pageNumber: 1,
    },
    { selectFromResult: selectNewSoundsCount, skip: !influencerId },
  )

  const { inProgressSoundsCount } = useInProgressSoundsQuery(1, {
    selectFromResult: selectInProgressSoundsCount,
  })

  const { submittedSoundsCount } = useSubmittedSoundsQuery(1, {
    selectFromResult: selectSubmittedSoundsCount,
  })

  const getTabs = (): Array<{
    key: string
    label: string
    badge?: JSX.Element | string
    count: number | null
    children: ReactNode
  }> => [
    {
      key: TabKeysEnum.NEW,
      label: 'New sounds',
      count: isError ? null : newSoundsCount,
      children: <NewSoundsList />,
    },
    {
      key: TabKeysEnum.IN_PROGRESS,
      label: 'In progress',
      count: inProgressSoundsCount || null,
      children: <InProgressSoundsList />,
    },
    {
      key: TabKeysEnum.SUBMITTED,
      label: 'Submitted',
      count: submittedSoundsCount || null,
      children: <SubmittedSoundsList />,
    },
  ]

  return {
    tabs: getTabs(),
  }
}
