import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { getIsLanguageActive } from 'src/Pages/Artist/SpotifySchedulePage/helpers/helpers'
import { useAppSelector } from 'src/Hooks/redux'
import { useCheckoutEvents } from 'src/Hooks/useCheckoutEvents'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useBudgetRange } from 'src/Redux/spotifyBudgetRangeApi'
import { CampaignPlatformTypeEnum, ScheduleSteps } from 'src/Types'
import {
  getSpotifyCampaignMatchingDataLoadingSelector,
  spotifyTotalPriceSelector,
} from 'src/Redux/spotifyCampaignMatchingProcess'

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

export const SpotifySettingsContinueButton: FC = () => {
  const { t } = useTranslation()
  const { handleCheckoutStartEvent } = useCheckoutEvents()

  const spotifyTotalPrice = useAppSelector(spotifyTotalPriceSelector)
  const isUpdateCampaignLoading = useAppSelector(
    getSpotifyCampaignMatchingDataLoadingSelector,
  )
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { isError: budgetRangeError } = useBudgetRange()

  const { setSearch } = useCustomSearchParams()
  const isLanguageActive = getIsLanguageActive(campaignDetails.languages)
  if (!campaignDetails.genres.length || isLanguageActive) {
    return null
  }
  const handleClick = (): void => {
    setSearch({ step: ScheduleSteps.CHECKOUT })

    spotifyTotalPrice &&
      handleCheckoutStartEvent({
        platformName: CampaignPlatformTypeEnum.SPOTIFY,
        id: Number(campaignDetails.id),
        campaignType: campaignDetails.type,
        isFeed: campaignDetails.is_feed,
        genres: campaignDetails.genres.map((genre) => genre.name),
        languages: campaignDetails.languages.map((language) => language.name),
        startDate: (campaignDetails.start_date || '').toString(),
        resultPrice: spotifyTotalPrice,
      })
  }
  const hasPrice = spotifyTotalPrice && spotifyTotalPrice > 0

  const isDisabled = budgetRangeError || !hasPrice || isUpdateCampaignLoading

  return (
    <StyledPrimaryButton onClick={handleClick} disabled={isDisabled}>
      {t('scheduleCampaignPage.continue')}
    </StyledPrimaryButton>
  )
}
