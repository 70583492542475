import React, { FC } from 'react'

import { UpsellType } from '../..'

import { CampaignUpsellNegative } from './CampaignUpsellNegative'
import { CampaignUpsellPositive } from './CampaignUpsellPositive'
import { CampaignUpsellExcellent } from './CampaignUpsellExcellent'

export enum IntercomMessageEnum {
  REPORT_CONCERN = 'Report a Concern',
  REQUEST_ANALYSIS = 'Request Campaign Analysis',
}

export type CampaignUpsellProps = {
  onUpsellTiktok: (type: UpsellType) => void
  onPromoteNewTrack: () => void
  onOpenIntercom: (message: IntercomMessageEnum) => void
  onRelaunch: (isCTA?: boolean) => void
  placementsCount: number
  isRelaunchAvailable?: boolean
}

export const CampaignUpsell: FC<CampaignUpsellProps> = (props) => {
  const {
    placementsCount,
    onUpsellTiktok,
    onPromoteNewTrack,
    onOpenIntercom,
    onRelaunch,
    isRelaunchAvailable,
  } = props

  if (placementsCount === 0) {
    return <CampaignUpsellNegative onOpenIntercom={onOpenIntercom} />
  }

  if (placementsCount <= 2) {
    return (
      <CampaignUpsellPositive
        onUpsellTiktok={onUpsellTiktok}
        onPromoteNewTrack={onPromoteNewTrack}
      />
    )
  }
  return (
    <CampaignUpsellExcellent
      onRelaunch={onRelaunch}
      onUpsellTiktok={onUpsellTiktok}
      isRelaunchAvailable={isRelaunchAvailable}
    />
  )
}
