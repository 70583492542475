import { FC } from 'react'

import styled from 'styled-components'

import Dislike from 'src/Assets/Svg/campaignDetails/dislike.svg?react'
import DislikeChecked from 'src/Assets/Svg/campaignDetails/dislike_checked.svg?react'

import { PrimaryButton } from '../Buttons/PrimaryButton'

const Container = styled.div`
  display: inline-flex;
  height: 32px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  cursor: pointer;
`

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background: ${({ theme }) => theme.common.primaryBorder};
`

const DislikeButton = styled(PrimaryButton)`
  background-color: unset;
  padding: 7px;
  height: unset;
  border: none;

  &:focus-visible {
    outline: none;
    outline-offset: -1px;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.common.secondaryBg};
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    outline: none;
    border: none;
    svg {
      path {
        fill: ${({ theme }) => theme.common.secondaryBg};
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  &:disabled {
    color: unset;
    border: unset;

    svg {
      path {
        fill: ${({ theme }) => theme.common.secondaryBg};
      }
    }
  }
`

const ButtonWrapper = styled.div`
  svg:first-child {
    display: none;
  }

  &:hover {
    svg:first-child {
      display: block;
    }
    svg:nth-child(2) {
      display: none;
    }
  }
`

const LikeButton = styled(DislikeButton)`
  transform: rotate(180deg);
`

export interface LikeDislikeButtonsProps {
  onLike: () => void
  onDislike: () => void
  isLiked: boolean
  isDisliked: boolean
}

export const LikeDislikeButtons: FC<LikeDislikeButtonsProps> = ({
  onLike,
  onDislike,
  isLiked,
  isDisliked,
}) => (
  <Container>
    <LikeButton onClick={onLike}>
      {isLiked ? (
        <DislikeChecked />
      ) : (
        <ButtonWrapper>
          <DislikeChecked />
          <Dislike />
        </ButtonWrapper>
      )}
    </LikeButton>

    <Divider />

    <DislikeButton onClick={onDislike}>
      {isDisliked ? (
        <DislikeChecked />
      ) : (
        <ButtonWrapper>
          <DislikeChecked />
          <Dislike />
        </ButtonWrapper>
      )}
    </DislikeButton>
  </Container>
)
