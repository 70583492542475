import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'
import { Roles } from 'src/Constants/enums'

import {
  changeEmail,
  createCampaignWithoutSignup,
  setRoleToUser,
  signIn,
  signUpArtist,
} from './api-actions'

export interface CredentialsState {
  AccessToken: string
  role: Roles | null
  status: SliceStatus
  error: ApiError | null
}
const initialState: CredentialsState = {
  AccessToken: '',
  role: null,
  status: SliceStatus.Idle,
  error: null,
}

const getRoleByToken = (token: string): Roles =>
  jwtDecode<Record<string, unknown>>(token).role as Roles

export const credentialsSlice = createSlice({
  initialState,
  name: NameSpace.Credentials,
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<{ token: string }>) => {
      state.AccessToken = payload.token
      state.role = getRoleByToken(payload.token)
    },
    logOut: (state) => {
      state.AccessToken = ''
      state.role = null
      state.status = SliceStatus.Idle
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.AccessToken = action.payload.token
      state.role = action.payload.role
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // createCampaignWithoutSignup
    builder.addCase(createCampaignWithoutSignup.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(
      createCampaignWithoutSignup.fulfilled,
      (state, { payload }) => {
        state.status = SliceStatus.Succeeded
        state.AccessToken = payload.token
        state.role = payload.role
      },
    )
    builder.addCase(createCampaignWithoutSignup.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // signUpArtist
    builder.addCase(signUpArtist.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(signUpArtist.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.AccessToken = action.payload.token
      state.role = action.payload.role
    })
    builder.addCase(signUpArtist.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // changeEmail
    builder.addCase(changeEmail.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(changeEmail.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.AccessToken = action.payload.token
    })
    builder.addCase(changeEmail.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // setRoleToUser
    builder.addCase(setRoleToUser.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(setRoleToUser.fulfilled, (state, action) => {
      state.status = SliceStatus.Succeeded
      state.AccessToken = action.payload.token
      state.role = action.payload.role
    })
    builder.addCase(setRoleToUser.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

export const { setAccessToken, logOut } = credentialsSlice.actions
