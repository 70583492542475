import { FC } from 'react'

import { Radio } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'

import { device } from 'src/Styled/variables'

import { SurveyFormValues, surveyFormSchema, SURVEY_VARIANTS } from './helpers'
import { SurveyButtons } from './SurveyButtons'
import { useSendFeedBack } from './useSendFeedBack'
import { SurveyItem } from './SurveyItem'

const Form = styled.form`
  height: 100%;
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  @media ${device.tabletMin} {
    margin: 0 auto;
    max-width: 600px;
    min-width: 600px;
    gap: 16px;
  }
`

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SurveyForm: FC<{
  onCloseModal: () => void
}> = ({ onCloseModal }) => {
  const { loading, onSendFeedback } = useSendFeedBack()
  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm<SurveyFormValues>({
    resolver: yupResolver(surveyFormSchema),
  })
  const handleSave = async (data: SurveyFormValues): Promise<void> => {
    await onSendFeedback(data)
  }
  return (
    <Form onSubmit={handleSubmit(handleSave)} translate='no'>
      <Controller
        control={control}
        name='resource'
        render={({ field: { onChange, value } }) => (
          <StyledRadioGroup
            onChange={(e) => {
              onChange(e)
              setValue('description', '')
            }}
            value={value}
          >
            {SURVEY_VARIANTS.map(({ title, image, placeholder, inputType }) => (
              <Controller
                key={title}
                control={control}
                name='description'
                render={({ field }) => (
                  <SurveyItem
                    image={image}
                    inputType={inputType}
                    key={title}
                    placeholder={placeholder}
                    title={title}
                    isChecked={value === title}
                    {...field}
                  />
                )}
              />
            ))}
          </StyledRadioGroup>
        )}
      />
      <SurveyButtons
        disabled={!isValid}
        loading={loading}
        onCloseModal={onCloseModal}
      />
    </Form>
  )
}
