import { FC, useState } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { subscription } from 'src/Router/routes'
import WarningIcon from 'src/Assets/Svg/warning-circle.svg?react'
import { useAppSelector } from 'src/Hooks/redux'
import { FullScreenModal } from 'src/Components/FullScreenModal'
import {
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
} from 'src/Redux/subscription-process/subscriptionApi'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'

import { CancelButtons } from './CancelButtons'
import {
  CancelSubscriptionForm,
  CancelPremiumFormValues,
} from './CancelSubscriptionForm/CancelSubscriptionForm'
import { SuccessCancel } from './SuccessCancel/SuccessCancel'
import { sendCancelSubscriptionData } from './helper'

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

const Container = styled.div`
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  @media (min-width: 567px) {
    width: 560px;
    padding: 40px 0px;
    justify-content: center;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Text = styled.div`
  font-size: 14px;
  text-align: center;
`
const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
`

const Parent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 16px 0px;
`

export const CancelSubscription: FC = () => {
  const { t } = useTranslation()
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation()
  const email = useAppSelector(getUserEmail)
  const { data: plan } = useGetSubscriptionQuery()
  const navigate = useNavigate()
  const [showForm, setShowForm] = useState(false)

  const onClose = (): void => {
    navigate(subscription)
  }

  const handleCancel = (data?: CancelPremiumFormValues): void => {
    if (!showForm) {
      setShowForm(true)
      return
    }
    if (data && plan?.id && plan?.package?.name) {
      cancelSubscription(plan?.id)
        .unwrap()
        .then(() => {
          sendCancelSubscriptionData({
            email,
            planName: plan?.package?.name,
            reason: data.reason,
            reasonDetails: data.reasonDetails,
          })
        })
    }
  }

  const text = showForm
    ? t('getPremiumPage.cancelDesc1')
    : t('getPremiumPage.cancelDesc2')

  if (plan?.status === 'canceled' && plan?.nextPaymentDate) {
    return <SuccessCancel date={plan?.nextPaymentDate} />
  }

  return (
    <FullScreenModal open>
      <Parent>
        <CloseButton onClick={onClose}>
          <CloseCross width='16px' height='16px' />
        </CloseButton>
        <Container>
          <WarningIcon width={60} height={40} />
          <Wrapper>
            <Title>{t('getPremiumPage.cancelTitle')}</Title>
            <Text>{text}</Text>
            {showForm && (
              <CancelSubscriptionForm
                loading={isLoading}
                onSave={handleCancel}
              />
            )}
          </Wrapper>
          {!showForm && <CancelButtons onCancel={handleCancel} />}
        </Container>
      </Parent>
    </FullScreenModal>
  )
}
