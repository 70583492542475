import styled from 'styled-components'

import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'

export const SoundsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 24px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    margin-top: 40px;
  }
`

export const SoundsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SoonContainer = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const Text = styled.span`
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.common.primaryText};
`
