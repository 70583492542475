import { useCallback, useEffect } from 'react'

import { setPaymentMethod } from 'src/Redux/payment-process'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { getIsEnoughBalanceForTiktok } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'
import { getIsEnoughBalanceSelector } from 'src/Redux/spotifyCampaignMatchingProcess'

import { useAppDispatch, useAppSelector } from './redux'

export const useExistingBillingAgreement = (): void => {
  const dispatch = useAppDispatch()

  const { data: billingData } = useGetBillingAgreementQuery()
  const isEnoughBalance = useAppSelector(getIsEnoughBalanceSelector)
  const isEnoughBalanceForTiktok = useAppSelector(getIsEnoughBalanceForTiktok)
  const getDefaultValue = useCallback(() => {
    if (isEnoughBalance || isEnoughBalanceForTiktok) {
      dispatch(setPaymentMethod(PaymentMethodEnum.Credits))
      return
    }
    if (billingData?.paypalEmail) {
      dispatch(setPaymentMethod(PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT))
      return
    }
    dispatch(setPaymentMethod(PaymentMethodEnum.STRIPE))
  }, [
    dispatch,
    isEnoughBalance,
    isEnoughBalanceForTiktok,
    billingData?.paypalEmail,
  ])

  useEffect(() => {
    getDefaultValue()
  }, [getDefaultValue])
}
