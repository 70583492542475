import { useEffect } from 'react'

import { setStartDate } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getDefaultStartDate } from 'src/Constants/functions'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

export const useDefaultSpotifyCampaignStartDate = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()

  const urlStartDate = search.get('startDate')
  const { data } = useGetSubscriptionQuery()
  const hasActiveOrCanceledSubscription = Boolean(
    data?.status === 'active' || data?.status === 'canceled',
  )

  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)

  useEffect(() => {
    if (!spotifyCampaignDetails.start_date && !urlStartDate) {
      const startDate = getDefaultStartDate(hasActiveOrCanceledSubscription)
      dispatch(setStartDate(startDate))
    }
  }, [
    dispatch,
    spotifyCampaignDetails.start_date,
    urlStartDate,
    hasActiveOrCanceledSubscription,
  ])
}
