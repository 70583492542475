import { FC, useEffect } from 'react'

import { mapScheduledSpotifyCampaignForUpdate } from 'src/Pages/Artist/TiktokSchedulePage/helpers/mapScheduledSpotifyCampaignForUpdate'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGenresAndLanguagesAnalytics } from 'src/Hooks/useGenresAndLanguagesAnalytics'
import { getAllGenres } from 'src/Redux/genres-process/api-actions'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { CampaignPlatformTypeEnum, Genre, ModalType } from 'src/Types'

import { TiktokScheduleCommentModal } from './TiktokScheduleCommentModal'
import { TiktokScheduleGenresModal } from './TiktokScheduleGenresModal'

export const TiktokScheduleSettingsModals: FC = () => {
  const { search, setSearch } = useCustomSearchParams()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const dispatch = useAppDispatch()

  const { handleGenreAddedEvent } = useGenresAndLanguagesAnalytics()

  useEffect(() => {
    void dispatch(getAllGenres())
  }, [dispatch])

  const handleSave = (selectedGenres: Genre[]): void => {
    const mappedCampaign =
      mapScheduledSpotifyCampaignForUpdate(scheduledCampaign)

    handleGenreAddedEvent({
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      genreId: selectedGenres.map((el) => Number(el.id)).join(),
      genreName: selectedGenres.map((el) => String(el.genre_name)).join(),
    })

    void dispatch(
      updateTikTokCampaign({
        ...mappedCampaign,
        genres: selectedGenres.map((el) => Number(el.id)),
      }),
    )
      .unwrap()
      .finally(() => {
        setSearch({ modal: null })
      })
  }
  const modalType = search.get('modal')

  if (modalType === ModalType.Genres) {
    return (
      <TiktokScheduleGenresModal
        genres={scheduledCampaign?.genres}
        onSave={handleSave}
      />
    )
  }
  if (modalType === ModalType.Comment) {
    return <TiktokScheduleCommentModal />
  }
  return null
}
