import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'

import { StyledMonetizationPageTitle } from './styles'

export const MonetizationPageTitle: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <StyledMonetizationPageTitle>
      {t('monetization.monetizationTitle')}
    </StyledMonetizationPageTitle>
  )
}
