import React, { FC } from 'react'

import styled from 'styled-components'

import SuccessIcon from 'src/Assets/Svg/pages-icons/Success.svg?react'

import { SecondaryScheduleSummaryProps } from '../SecondaryScheduleSummary'

type Props = Pick<SecondaryScheduleSummaryProps, 'discountPercentage'>

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
`

const Text = styled.span`
  color: ${({ theme }) => theme.common.sixthText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const SecondaryScheduleSummaryDiscount: FC<Props> = ({
  discountPercentage,
}) => (
  <Wrapper>
    <SuccessIcon width={16} height={16} />
    <Text>{discountPercentage}% discount was succesfully applied</Text>
  </Wrapper>
)
