import styled from 'styled-components'
import Arrow from 'mdi-react/ChevronRightIcon'

import { colors, variables } from 'src/Styled/variables'
export const Text = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  margin-top: 36px;
  justify-content: space-around;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-size: 16px;
    font-style: normal;
    font-weight: ${variables.fontWeight600};
    line-height: 150%;
    color: ${colors.mainText} !important;
    span {
      color: ${colors.green};
    }
  }
`
export const AccountOr = styled.div`
  text-align: center;
  margin: 16px 0;
  position: relative;

  p {
    color: black !important;
    font-size: ${variables.fontSize16};
    font-style: normal;
    font-weight: ${variables.fontWeight600};
    line-height: 150%;
  }

  &:before,
  &:after {
    content: '';
    height: 1px;
    width: calc(50% - 90px);
    background: #eee;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`
export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`
export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`
export const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: auto;

  input {
    height: 43px;
    background-color: ${colors.greyBg};
    border: 2px solid ${colors.greyTextLight} !important;
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    transition: border 0.3s;
    color: #646877;
    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
`
export const ArrowRight = styled(Arrow)`
  height: 24px !important;
  width: 24px !important;
  margin-right: 0 !important;
`
