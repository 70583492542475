import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Meta } from 'src/Types'
import { SoundStatus } from 'src/Pages/Creator/SoundsPage/hooks/useSoundStatus'

import { Sound } from '../types'

export enum EventDismissReasonsEnum {
  PROFILE_BUSINESS = 'profile_business',
  PROFILE_DELETED = 'profile_deleted',
  PROFILE_PRIVATE = 'profile_private',
  VIDEO_DELETED = 'video_deleted',
  VIEWS_FAILED = 'views_failed',
}

export interface SubmittedSound extends Sound {
  status: SoundStatus
  id: number
  views: number
  reward: number
  trackTiktokId: string
  trackPlayUrl: string
  videoLink: string
  isMilestoneDaysPassed: boolean
  dismissReason: EventDismissReasonsEnum
  isPaymentReceived: boolean
  maxCreatorReward: number
  minCreatorReward: number
  minViews: number
  maxViews: number
  presenceMilestoneDate: string
  comment: string
  viewsMilestoneDate: string
}

interface SubmittedResponse {
  data: SubmittedSound[]
  meta: Meta
}

interface UploadVideoData {
  campaignId: number
  paidAmount: number
  trackId: string
  presenceMilestoneDate: string
}

interface UploadVideoBody {
  videoTiktokUrl: string
  eventId: number
}

const creatorSubmittedSoundsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    const pageSize = 10

    const statuses = [
      'submission_awaiting',
      'submission_rejected',
      'approved_by_artist',
      'approved_automatically',
      'awaiting_moderator_review',
      'awaiting_review',
      'approved_by_admin',
      'declined',
      'video_dismissed',
    ]

    const statusesQuery = statuses
      .map((status) => `statuses=${status}`)
      .join('&')

    return {
      submittedSounds: build.query<SubmittedResponse, number>({
        query: (pageNumber) => ({
          url: `${APP.TIKTOK_SERVER}/creators/events?pageSize=${pageSize}&pageNumber=${pageNumber}&${statusesQuery}&sortBy=videoUploadDate.DESC`,
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, meta) => {
          if (meta.arg === 1) {
            // If pageNumber is 1, replace the current cache with new items
            return {
              data: newItems.data,
              meta: newItems.meta,
            }
          }
          // Otherwise, append the new items to the current cache
          return {
            data: [...currentCache.data, ...newItems.data],
            meta: newItems.meta,
          }
        },
        forceRefetch({ currentArg, previousArg }) {
          return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
        },
        providesTags: ['submittedSound'],
      }),
      submittedSoundById: build.query<SubmittedSound, number>({
        query: (soundId) => ({
          url: `${APP.TIKTOK_SERVER}/creators/events/${soundId}`,
        }),
      }),
      submitVideo: build.mutation<UploadVideoData, UploadVideoBody>({
        query: ({ videoTiktokUrl, eventId }) => ({
          url: `${APP.TIKTOK_SERVER}/influencer/tiktok/upload-video/${eventId}`,
          method: 'PUT',
          body: {
            videoTiktokUrl,
          },
        }),
        invalidatesTags: ['submittedSound'],
      }),
    }
  },
})

export const selectSubmittedSoundsCount = ({
  data,
}: {
  data?: SubmittedResponse
}): { submittedSoundsCount: number } => ({
  submittedSoundsCount: data?.meta.itemCount || 0,
})

export const {
  useSubmittedSoundsQuery,
  useSubmittedSoundByIdQuery,
  useSubmitVideoMutation,
} = creatorSubmittedSoundsApi
