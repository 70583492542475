import { FC } from 'react'

import { object, string as yupString } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { MAX_COMMENT_LENGTH } from 'src/Constants/numeric'
import { mapScheduledSpotifyCampaignForUpdate } from 'src/Pages/Artist/TiktokSchedulePage/helpers/mapScheduledSpotifyCampaignForUpdate'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { updateTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import InfoIcon from 'src/Assets/Svg/pages-icons/Info.svg?react'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { TextArea } from 'src/Components/TextArea'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 24px 16px 65px 16px;
  flex-direction: column;
  gap: 8px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    display: flex;
    margin: 0 auto;
    width: 600px;
    padding: 64px 0px 65px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: scroll;
  height: calc(100vh - 118px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    height: calc(100vh - 200px);
  }
`

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: start;
  width: 100%;
  font-family: 'Segoe UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const TitleSubText = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`
const Message = styled(TitleSubText)`
  width: fit-content;
`

const IconContainer = styled.div`
  padding-top: 4px;
`
const Form = styled.form`
  width: 100%;
`
const TextareaContainer = styled.div`
  width: 100%;
`
const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: fixed;
  z-index: 2;
  left: 0px;
  bottom: 0px;
  border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

interface CommentFormValues {
  comment: string
}
const commentSchema = object().shape({
  comment: yupString().max(MAX_COMMENT_LENGTH).required('Comment is required'),
})
export const TiktokScheduleCommentModal: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const scheduledCampaign = useAppSelector(getScheduledTikTokCampaign)
  const { setSearch } = useCustomSearchParams()
  const handleClose = (): void => {
    setSearch({ modal: null })
  }

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<CommentFormValues>({
    mode: 'onChange',
    values: { comment: scheduledCampaign.comment ?? '' },
    resolver: yupResolver(commentSchema),
  })

  const onSubmit = ({ comment }: CommentFormValues): void => {
    const mappedCampaign = mapScheduledSpotifyCampaignForUpdate({
      ...scheduledCampaign,
      comment,
    })

    void dispatch(updateTikTokCampaign(mappedCampaign))
      .unwrap()
      .then((res) => {
        handleClose()
        return res
      })
  }
  return (
    <FullScreenModal
      showCloseButton
      open
      onCancel={handleClose}
      prefixCls='comment'
    >
      <Container translate='no'>
        <Title>{t('scheduleCampaignPage.commentToCreators')}</Title>
        <SubContainer>
          <TitleSubText>{t('tiktokSchedulePage.clearly')}</TitleSubText>
          <MessageContainer>
            <IconContainer>
              <InfoIcon />
            </IconContainer>
            <Message>{t('tiktokSchedulePage.example')}</Message>
          </MessageContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name='comment'
              render={({ field }) => (
                <TextareaContainer>
                  <TextArea
                    {...field}
                    role='textarea'
                    placeholder={t('tiktokSchedulePage.commentPlaceholder')}
                    maxLength={MAX_COMMENT_LENGTH}
                    autoSize={{ minRows: 6 }}
                    count={{
                      show: true,
                      max: MAX_COMMENT_LENGTH,
                    }}
                  />
                </TextareaContainer>
              )}
            />
            <FooterContainer>
              <StyledPrimaryButton type='submit' disabled={!isValid}>
                {t('scheduleCampaignPage.addComment')}
              </StyledPrimaryButton>
            </FooterContainer>
          </Form>
        </SubContainer>
      </Container>
    </FullScreenModal>
  )
}
