import { FC } from 'react'

import { Roles } from 'src/Constants/enums'
import { PrimaryHeaderProps } from 'src/Components/Header/Components/PrimaryHeader'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { ArtistHeaderContainer } from '../ArtistHeaderContainer'
import { CuratorHeaderContainer } from '../CuratorHeaderContainer'
import { CreatorHeaderContainer } from '../CreatorHeaderContainer'

export type HeaderContainerProps = Pick<
  PrimaryHeaderProps,
  'withShadow' | 'onSidebarToggleClick'
>

export const HeaderContainer: FC<HeaderContainerProps> = ({
  withShadow,
  onSidebarToggleClick,
}) => {
  const role = useAppSelector(getUserRole)

  if (role === Roles.influencer) {
    return (
      <CreatorHeaderContainer
        withShadow={withShadow}
        onSidebarToggleClick={onSidebarToggleClick}
      />
    )
  }

  if (role === Roles.curator) {
    return (
      <CuratorHeaderContainer
        withShadow={withShadow}
        onSidebarToggleClick={onSidebarToggleClick}
      />
    )
  }

  if (role === Roles.artist) {
    return (
      <ArtistHeaderContainer
        withShadow={withShadow}
        onSidebarToggleClick={onSidebarToggleClick}
      />
    )
  }

  return null
}
