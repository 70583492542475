import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { Playlist } from 'src/Types'

import { RootState } from '..'

import { PlaylistsState } from '.'

enum PlaylistsTabsEnum {
  All = 1,
  OnReview = 2,
  Active = 3,
  Inactive = 4,
}

export const removeDuplicatedPlaylists = (
  array: Playlist[],
): (Playlist | undefined)[] =>
  Array.from(
    new Set(
      array
        .map((el) => el.id)
        .map((id) => array.find((item) => item.id === id)),
    ),
  )

const getState = (state: RootState): PlaylistsState => state.playlists

export const getPlaylist = createDraftSafeSelector(
  getState,
  ({ playlists }) => playlists,
)
export const getHasPlaylists = createDraftSafeSelector(
  getState,
  ({ playlists }) => playlists?.length > 0,
)
export const getPlaylistsLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getPlaylistsWithNoPref = createDraftSafeSelector(
  getState,
  ({ playlists }) => {
    if (playlists) {
      return playlists.filter(
        (el) =>
          el.meets_conditions &&
          (el.PlaylistLanguages.length === 0 ||
            (el.PlaylistLanguages[0].language_id === 1 &&
              el.PlaylistLanguages.length === 1)),
      )
    }
    return []
  },
)

export const getPendingPlaylists = createDraftSafeSelector(
  getState,
  ({ playlists }) =>
    playlists.filter(
      ({ meets_conditions, status, PlaylistGenres }) =>
        meets_conditions && status === 'on_review' && PlaylistGenres.length,
    ),
)
export const getActivePlaylists = createDraftSafeSelector(
  getState,
  ({ playlists }) =>
    playlists?.filter(
      ({ meets_conditions, status, PlaylistGenres }) =>
        meets_conditions && status === 'activated' && PlaylistGenres.length,
    ),
)
export const getInactivePlaylists = createDraftSafeSelector(
  getState,
  ({ playlists }) => {
    const inactive = playlists.filter(
      ({ status }) => status === 'deactivated' || status === 'pending',
    )
    const inappropriate = playlists.filter(
      ({ meets_conditions }) => !meets_conditions,
    )
    const empty = playlists.filter(
      ({ meets_conditions, PlaylistGenres }) =>
        meets_conditions && !PlaylistGenres.length,
    )

    return [...empty, ...inactive, ...inappropriate]
  },
)

export const getIsShowLangPreferenceAlert = createDraftSafeSelector(
  getState,
  ({ playlists }) => {
    const noPreferenceExists = playlists.find(
      (el) =>
        el.meets_conditions &&
        (el.PlaylistLanguages.length === 0 ||
          (el.PlaylistLanguages[0].language_id === 1 &&
            el.PlaylistLanguages.length === 1)),
    )
    return Boolean(noPreferenceExists)
  },
)

export const getAllPlaylistsSpotifyIds = createDraftSafeSelector(
  getState,
  ({ playlists }) =>
    playlists?.map((playlist) => {
      const arr = playlist?.playlist_url.split('/')
      return arr[arr.length - 1]
    }),
)

export const getPlaylistById = (id: number) => (state: RootState) =>
  createDraftSafeSelector(getPlaylist, (playlists) =>
    playlists.find((playlist) => playlist.id === id),
  )(state)
type Selector<S> = (state: RootState) => S
export const getFilteredPlaylists = (
  active: PlaylistsTabsEnum,
): Selector<(Playlist | undefined)[]> =>
  createDraftSafeSelector(
    (state: RootState) => state,
    (state) => {
      const activePlaylists = getActivePlaylists(state)
      const pendingPlaylists = getPendingPlaylists(state)
      const inactivePlaylists = getInactivePlaylists(state)
      const all = removeDuplicatedPlaylists([
        ...activePlaylists,
        ...pendingPlaylists,
        ...inactivePlaylists,
      ])
      switch (active) {
        case PlaylistsTabsEnum.All:
          return all
        case PlaylistsTabsEnum.OnReview:
          return pendingPlaylists
        case PlaylistsTabsEnum.Active:
          return activePlaylists
        case PlaylistsTabsEnum.Inactive:
          return inactivePlaylists
        default:
          return all
      }
    },
  )
