import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Avatar } from 'src/Components/Avatar'
import { ShareButton } from 'src/Components/Buttons/ShareButton'
import { variables } from 'src/Styled/variables'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import {
  LikeDislikeBlock,
  LikeDislikeButtonProps,
} from 'src/Components/LikeDislikeBlock'
import { Review } from 'src/Types'

import { AddedPlaylistInfo } from '../AddedPlaylistInfo'
import { MatchedPlaylists } from '../MatchedPlaylistsInfo'
import { ReviewLabel } from '../ReviewLabel'

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.common.primaryBg};
`

const CuratorInfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const CuratorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

export const AccentText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const ReviewDate = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

export const CommonText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  word-break: break-word;
`

const SubText = styled(CommonText)`
  @media screen and (min-width: 767px) and (max-width: 800px) {
    max-width: 146px;
  }
`

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledReviewLabel = styled(ReviewLabel)`
  position: absolute;
  top: 0;
  right: 0;
`

const ActionsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export interface ReviewPlaylist {
  playlistName: string
  playlistUrl?: string
  playlistCoverUrl?: string
}

export interface ReviewBlockProps {
  userCover: string
  reviewId: number
  firstName: string
  lastName: string
  playlistsCount: number
  followersCount: number
  comment: string
  addedTrackPlaylist?: ReviewPlaylist | null
  matchedTrackPlaylists: Array<ReviewPlaylist>
  onShareClick: () => void
  reviewDate?: string
  onSubmitLikeDislike: LikeDislikeButtonProps['onSubmitLikeDislike']
  reviewComplain: Review['artist_feedback_status']
}

export const ReviewBlock: React.FC<ReviewBlockProps> = ({
  userCover,
  firstName,
  lastName,
  playlistsCount,
  followersCount,
  comment,
  addedTrackPlaylist,
  matchedTrackPlaylists,
  onShareClick,
  reviewDate,
  onSubmitLikeDislike,
  reviewId,
  reviewComplain,
}) => {
  const { t } = useTranslation()

  const playlistsText = `${playlistsCount} ${t('campaignDetailsPage.playlists')}`
  const followersText = `${addSpaceToNumber(followersCount, ',')} ${t('campaignDetailsPage.followers')}`
  const formattedDate = reviewDate
    ? dayJs(reviewDate).format(formatDate.formatDateFirst2)
    : ''

  return (
    <Container>
      <StyledReviewLabel withPlacement={Boolean(addedTrackPlaylist)} />
      <CuratorInfoBlock>
        <Avatar width={40} height={40} src={userCover} />

        <CuratorInfo>
          <AccentText>{`${firstName} ${lastName}`}</AccentText>
          <SubText>{`${playlistsText}, ${followersText}`}</SubText>
        </CuratorInfo>
      </CuratorInfoBlock>

      <CommentWrapper>
        <ReviewDate>{formattedDate}</ReviewDate>
        <CommonText>{comment}</CommonText>
      </CommentWrapper>

      {addedTrackPlaylist && (
        <AddedPlaylistInfo
          playlistName={addedTrackPlaylist.playlistName}
          playlistUrl={addedTrackPlaylist.playlistUrl}
          playlistCoverUrl={addedTrackPlaylist.playlistCoverUrl}
        />
      )}
      {matchedTrackPlaylists && !addedTrackPlaylist && (
        <MatchedPlaylists matchedTrackPlaylists={matchedTrackPlaylists} />
      )}
      <ActionsBlock>
        <LikeDislikeBlock
          onSubmitLikeDislike={onSubmitLikeDislike}
          reviewId={reviewId}
          reviewComplain={reviewComplain}
        />
        <ShareButton onClick={onShareClick} data-testid='share-button' />
      </ActionsBlock>
    </Container>
  )
}
