import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { ProblemsWithTikTokErrorAlert } from 'src/Components/ProblemsWithTikTokErrorAlert'
import Button from 'src/Components/Buttons/Button'
import { colors, variables } from 'src/Styled/variables'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { sounds } from 'src/Router/routes'
import { getValueOrZero } from 'src/Constants/functions'

import { Container, ListContainer } from '../../styles'
import { TextList } from '../TextList'
import { EarningRange } from '../EarningRange'

export const Title = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const SubTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const DescriptionText = styled.div`
  font-size: ${variables.fontSize14};
  line-height: 150%;
`

export const StyledButton = styled(Button)`
  align-self: center;
  width: 160px;
  margin: 0 auto;
`
export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export const EarningBlock: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isError: isMonetizationError } = useGetMonetizationDataQuery()

  const { data } = useGetMonetizationDataQuery()

  const formattedMinViews = addSpaceToNumber(data?.minViews || 0, ',')
  const formattedMaxCreatorReward = `$${data?.maxCreatorReward?.toFixed(2) || 0}`

  const listItems = [
    t('monetization.youPersonalized'),
    t('monetization.yourFinalRewardDetermined'),
  ]

  if (isMonetizationError) {
    return <ProblemsWithTikTokErrorAlert $width={'inherit'} />
  }

  const onStartEarningClick = (): void => {
    navigate(`${sounds}`)
  }

  return (
    <Container>
      <ListContainer>
        <Title>{t('monetization.howAreMyEarnings')}</Title>
        <TextList listItems={listItems} />
      </ListContainer>

      <div style={{ width: '100%' }}>
        <RangeContainer>
          <div>
            <SubTitle>{t('monetization.currentEarningRange')}</SubTitle>
            <DescriptionText>
              {t('monetization.startEarningDescription', {
                formattedMinViews,
                formattedMaxCreatorReward,
              })}
            </DescriptionText>
          </div>

          <EarningRange
            maxCreatorReward={getValueOrZero(data?.maxCreatorReward)}
            minCreatorReward={getValueOrZero(data?.minCreatorReward)}
            maxViews={getValueOrZero(data?.maxViews)}
            minViews={getValueOrZero(data?.minViews)}
          />
        </RangeContainer>
      </div>
      <StyledButton type={'green'} onClick={onStartEarningClick}>
        {t('monetization.startEarning')}
      </StyledButton>
    </Container>
  )
}
