import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import { useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'
import { useBudgetRange } from 'src/Redux/spotifyBudgetRangeApi'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

import { SpotifyScheduleGenres } from './SpotifyScheduleGenres'
import { SpotifyScheduleLanguage } from './SpotifyScheduleLanguage'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 50%;
  }

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    width: 100%;
  }
`

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.button.orangeText} !important;

  div {
    border-bottom-color: ${({ theme }) => theme.button.orangeText};
  }
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const AlertList = styled.ul`
  padding-inline-start: 20px;
  margin-bottom: 16px;
`

export const SpotifyTrackParameters: FC = () => {
  const { t } = useTranslation()
  const { show } = useIntercom()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { isError: budgetRangeError } = useBudgetRange()
  const isGenresActive = !campaignDetails.genres.length
  const hasSelectedLanguages = Boolean(
    campaignDetails.languages.filter((lang) => lang.id !== ANY_LANGUAGE_ID)
      .length,
  )
  const showError = hasSelectedLanguages && budgetRangeError
  return (
    <Container>
      <Title>{t('scheduleCampaignPage.chooseTrackParameters')}</Title>
      <SpotifyScheduleGenres genres={campaignDetails.genres} />
      <SpotifyScheduleLanguage
        isAddButtonDisabled={isGenresActive}
        languages={campaignDetails.languages}
      />
      {showError && (
        <PrimaryAlert
          type='warning'
          hasCross={false}
          title='Almost there! Just a few more curators needed'
          renderButtons={() => (
            <StyledPrimaryDottedButton onClick={() => show()}>
              Contact support
            </StyledPrimaryDottedButton>
          )}
        >
          <div>
            Your track needs a few more curators before the campaign can start.
            Try these quick fixes:
          </div>
          <AlertList>
            <li>
              Pick genres with more available curators, which are shown in
              brackets next to each genre.
            </li>
            <li>
              Choose "Match selected and other languages" for a wider reach.
            </li>
          </AlertList>
          <div>
            Need help adjusting your campaign? Our support team is here to
            assist!
          </div>
        </PrimaryAlert>
      )}
    </Container>
  )
}
