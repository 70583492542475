import { useEffect } from 'react'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

export const useRedirectFromEmail = (): void => {
  const { search, setSearch } = useCustomSearchParams()
  const { data: subscription } = useGetSubscriptionQuery()
  const price = subscription?.paymentAmount
  const plan = subscription?.package?.name
  const cashback = subscription?.package?.cashbackPercentage
  const priceUrl = search.get('price')
  const planUrl = search.get('plan')
  const cashbackUrl = search.get('cashback')
  const hasQuery = Boolean(priceUrl && planUrl && cashbackUrl)

  useEffect(() => {
    if (!hasQuery && price && plan && cashback) {
      setSearch({
        plan,
        price: price.toString(),
        cashback: cashback.toString(),
      })
    }
  }, [hasQuery, plan, price, cashback, setSearch])
}
