import { useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import {
  useAddFeatureForUserMutation,
  useGetUserFeaturesQuery,
} from 'src/Redux/A-BFeaturesApi'
import {
  getIsArtistNotFromInvitedLink,
  useGetArtistBalanceHistoryQuery,
} from 'src/Redux/artistBalanceHistoryApi'
import {
  EXPIRED_TIMER_VALUE,
  getCheckoutTimer,
} from 'src/Redux/checkoutCountdown-process'
import {
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from 'src/Redux/metadataApi'

import { useCustomSearchParams } from './useCustomSearchParams'

export const QUICK_CHECKOUT_FEATURE_NAME = 'quick_checkout'
export const EXPIRED_TIMER_FEATURE_VALUE = 4

export const useCheckoutTimerFeatureFlag = (): void => {
  const { data } = useMetadataQuery(null)

  const { search } = useCustomSearchParams()
  const countdown = useSelector(getCheckoutTimer)
  const paymentStatus = search.get('paymentStatus')
  const isFirstPaid = getHasPaidCampaignMetadata(data)

  const { data: featureValue, isSuccess: featuresIsSucceeded } =
    useGetUserFeaturesQuery(null, {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.features?.find(
          (item) => item?.name === QUICK_CHECKOUT_FEATURE_NAME,
        )?.variation,
      }),
    })
  const [addFeatureForUser] = useAddFeatureForUserMutation()
  const { isArtistNotFromInvitedLink } = useGetArtistBalanceHistoryQuery(null, {
    selectFromResult: getIsArtistNotFromInvitedLink,
  })

  const isNoFeature = featuresIsSucceeded && !featureValue && featureValue !== 0

  const isFeatureConditions =
    isNoFeature || featureValue === EXPIRED_TIMER_FEATURE_VALUE

  const isQuickCheckoutFeatureAvailable = useMemo(
    () => isFeatureConditions && isArtistNotFromInvitedLink && !isFirstPaid,
    [isArtistNotFromInvitedLink, isFeatureConditions, isFirstPaid],
  )

  const isSuccessPageVisible = paymentStatus === 'success'

  // In case payment is successful, we need to drop the feature for the user
  useEffect(() => {
    if (isSuccessPageVisible) {
      addFeatureForUser({
        featureName: QUICK_CHECKOUT_FEATURE_NAME,
        variation: 0,
      })
    }
  }, [addFeatureForUser, isSuccessPageVisible])

  useEffect(() => {
    if (
      isQuickCheckoutFeatureAvailable &&
      countdown !== null &&
      countdown !== EXPIRED_TIMER_VALUE
    ) {
      addFeatureForUser({
        featureName: QUICK_CHECKOUT_FEATURE_NAME,
        variation: 1,
      })
    }
  }, [addFeatureForUser, isQuickCheckoutFeatureAvailable, countdown])
}
