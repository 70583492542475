import { Roles } from 'src/Constants/enums'
import { ScheduleSteps } from 'src/Types'

interface UserFromUrl {
  user: {
    email: string
    first_name: string
    last_name: string
    user_photo_url: null | string
    are_terms_accepted: boolean
    is_first_campaign: number
    is_email_confirmed: number
    role: string
    id: number
  }
  token: string
  type: string
  isArtist: boolean
  schedule: boolean
  campaign_id: string
  role: Roles
}

export const getUserFromUrl = (user: string | null): UserFromUrl | null => {
  if (user) {
    const parsedUser = JSON.parse(user) as UserFromUrl
    if (parsedUser?.token) {
      return parsedUser
    }
  }
  return null
}

export const getTokenFromUser = (user: string | null): string | null => {
  if (user) {
    const parsedUser = getUserFromUrl(user)
    if (parsedUser?.token) {
      return parsedUser.token
    }
  }
  return null
}
export const getScheduleRedirectUrl = (search: URLSearchParams): string => {
  const user = getUserFromUrl(search.get('user'))
  const utmSource = search.get('utm_source')
  const utmCampaign = search.get('utm_campaign')
  const utmContent = search.get('utm_content')
  const triggerSource = search.get('trigger_source')
  const triggerSubject = search.get('trigger_subject')

  if (triggerSource) {
    return new URLSearchParams({
      trigger_source: triggerSource,
      trigger_subject: triggerSubject || '',
      userid: user?.user.id.toString() || '',
      step: ScheduleSteps.SETTINGS,
    }).toString()
  }

  return new URLSearchParams({
    utm_source: utmSource || '',
    utm_campaign: utmCampaign || '',
    utm_content: utmContent || '',
    step: ScheduleSteps.SETTINGS,
  }).toString()
}

export const getTokenFromUrl = (search: URLSearchParams): string | null => {
  const tokenFromToken = search.get('sc_token')
  const tokenFromAuthToken = search.get('authToken')
  const hasStatus = search.has('status')
  const influencerToken = hasStatus ? '' : tokenFromAuthToken
  const tokenFromUser = getTokenFromUser(search.get('user'))
  return tokenFromToken || influencerToken || tokenFromUser
}
