import { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'

import WarningIcon from 'src/Assets/Svg/pages-icons/ExclamationPayment.svg?react'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

const Container = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const Description = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`
const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
export const ErrorComponent: FC = () => {
  const { show } = useIntercom()
  return (
    <Container>
      <WarningIcon />
      <TextBlock>
        <Title>Oops! Something went wrong</Title>
        <Description>
          This page is temporarily unavailable due to technical issues. Try
          reloading or check back later.
        </Description>
      </TextBlock>

      <ButtonsBlock>
        <PrimaryDottedButton
          onClick={() => {
            window.location.reload()
          }}
        >
          Try reload page
        </PrimaryDottedButton>
        <PrimaryButton onClick={() => show()}>
          Please contact support
        </PrimaryButton>
      </ButtonsBlock>
    </Container>
  )
}
