import styled from 'styled-components'

import { variables, colors, device } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media ${device.mobileMax} {
    width: 100%;
  }
`

export const MainTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  align-self: stretch;
`

export const RulesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
export const ContentWrapper = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  padding-bottom: 24px;
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const StyledButton = styled(Button)`
  width: 130px;
`

export const Link = styled.a`
  color: ${colors.badgeGreen};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    color: ${colors.badgeGreen};
  }
`

export const Label = styled.span`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  white-space: nowrap;
  margin-bottom: 2px;

  @media ${device.mobileMax} {
    white-space: normal;
  }
`
export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
