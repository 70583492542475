import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { newReleasedPromoteEvent } from 'src/Helpers/TagManager/CampaignsEvents'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'
import { useCreateNewSpotifyCampaignMutation } from 'src/Redux/createNewCampaignApi'
import { CampaignPlatformTypeEnum, ScheduleSteps } from 'src/Types'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import {
  getAccessToken,
  getIsArtist,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { campaignsList, scheduleCampaign } from 'src/Router/routes'

import { useCreateCampaignEvents } from './Analytics/useCreateCampaignEvents'

interface CreateAndStartScheduleEvents {
  createAndStartSchedule: (isTop?: boolean) => void
  loading: boolean
}

export const useCreateAndStartScheduleNewRelease =
  (): CreateAndStartScheduleEvents => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(getAccessToken)
    const userId = useAppSelector(getUserId)
    const isArtist = useSelector(getIsArtist)
    const navigate = useNavigate()
    const {
      createCampaignEventHandler,
      campaignDetailsEventHandler,
      createdSpotifyCampaignEventHandler,
    } = useCreateCampaignEvents()
    const [createSpotifyCampaign, { isLoading }] =
      useCreateNewSpotifyCampaignMutation()
    const { data, refetch } = useGetNewReleaseQuery(null, {
      skip: !isArtist,
    })
    const sendSourceBusterData = useCallback(
      (id: number, campaignPlatform: CampaignPlatformTypeEnum): void => {
        const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

        void dispatch(
          sendSourceBusterEvent({
            token,
            campaignPlatform,
            campaignId: id,
            eventName: 'create_basic_campaign',
            gclid: utmObj.gclid ?? '',
          }),
        )
      },
      [dispatch, token],
    )

    const createAndStartSchedule = useCallback(
      (isTop?: boolean) => {
        if (data?.trackSpotifyId) {
          createSpotifyCampaign({
            trackName: data.trackName,
            trackImage: data.trackImageUrl,
            trackLength: data.trackDuration,
            artistName: data.artistName,
            artistSpotifyId: data.artistSpotifyId,
            trackSpotifyId: data.trackSpotifyId,
            withApprovalStep: false,
            pageUrl: window.location.href,
            trackUrl: `https://api.spotify.com/v1/tracks/${data.trackSpotifyId}`,
            artistUrl: `https://open.spotify.com/artist/${data.artistSpotifyId}`,
          })
            .unwrap()
            .then((response) => {
              createCampaignEventHandler({ campaignId: response?.id })

              createdSpotifyCampaignEventHandler({
                campaignId: response?.id,
                isFeedCampaign: response?.is_feed,
                spotifyTrackId: response?.Track?.track_id,
              })

              campaignDetailsEventHandler({
                campaignType: response?.type,
                platformType: CampaignPlatformTypeEnum.SPOTIFY,
              })
              sendSourceBusterData(
                response?.id,
                CampaignPlatformTypeEnum.SPOTIFY,
              )
              isTop &&
                newReleasedPromoteEvent({
                  userId,
                  trackId: data.trackSpotifyId,
                })
              refetch()
              navigate(
                `${campaignsList}${scheduleCampaign}/${response.id}?step=${ScheduleSteps.SETTINGS}`,
              )
            })
        }
      },
      [
        campaignDetailsEventHandler,
        createCampaignEventHandler,
        createSpotifyCampaign,
        createdSpotifyCampaignEventHandler,
        data?.artistName,
        data?.artistSpotifyId,
        data?.trackDuration,
        data?.trackImageUrl,
        data?.trackName,
        data?.trackSpotifyId,
        navigate,
        refetch,
        sendSourceBusterData,
        userId,
      ],
    )

    return { createAndStartSchedule, loading: isLoading }
  }
