export const reviewed = 'reviewed'
export const payment = 'payment'
export const payout = 'payout'
export const withdraw = 'withdraw'
export const canceled = 'canceled'
export const rewardWithPlacement = 'reward_with_placement'
export const rewardWithoutPlacement = 'reward_without_placement'
export const reviewBonusPlacement = 'review_bonus_placement'
export const reviewBonusLike = 'review_bonus_like'
export const thankValue = 'thank'
export const offensiveValue = 'offensive'
export const poorValue = 'poor'
export const gaReferrerBlacklist = ['google', 'soundcamps', 'spotify']
export const DEBOUNCE_TIMER_300 = 300
export const DEBOUNCE_TIMER_500 = 500
export const DEBOUNCE_TIMER_1000 = 1000
export const MIN_PAYOUT_AMOUNT = 20
export const API_TIMEOUT_15000 = 15000
export const NOT_FIRST_ITEM = 2
export const GA_LENGTH = 6
export const SEARCH_ERROR_BY_NAME =
  'Search by name temporary unavailable. Please, use search by URL'
export const SEARCH_ERROR_SERVICE_UNAVAILABLE =
  'Oops! The service is temporarily unavailable. Please try again later.'
export const SEARCH_ERROR_NOT_OFFICIAL_TRACK =
  'Oops! The link you used is not the official one for this track. Please enter the official song link provided by your distributor, such as Tunecore, Distrokid, or another similar service.'
export const SEARCH_ERROR_SAME_TIKTOK_TRACK =
  "Oops! Looks like you've already created a campaign for this track. Go back to the Campaigns list to find it, or search for a different track to promote."
export const NO_TRACKS_WERE_FOUND = 'No tracks were found'
export const DEFAULT_PERIOD_TO_START_CAMPAIGN = 1
export const FIFTY_PERCENT = 50
export const EIGHTY_PERCENT = 80
export const ANY_LANGUAGE_ID = 3
export const NO_VOCAL = 2
export const DEBOUNCE_DELAY_100 = 100
export const DEBOUNCE_DELAY_500 = 500
export const DEBOUNCE_DELAY_1000 = 1000
export const HUNDRED_PERCENT = 100
export const VAT_PERCENT = 0.18
export const FIXED_BY = 2
export const SPOTIFY_BUDGET_STEP = 10
export const NAVIGATE_BACK_INDEX = -1
export const PAYPAL_SAVED = 'PayPal_saved'
export const NOTIFICATIONS_WIDTH_MOBILE = 374
export const NOTIFICATIONS_WIDTH_DESKTOP = 480
export const HORIZONTAL_AMOUNT = 3

const THRESHOLD_1000 = 1000
const THRESHOLD_4500 = 4500
const THRESHOLD_15000 = 15000
const THRESHOLD_30000 = 30000
const THRESHOLD_75000 = 75000
const THRESHOLD_150000 = 150000
const THRESHOLD_225000 = 225000
const THRESHOLD_300000 = 300000
const THRESHOLD_450000 = 450000
const THRESHOLD_600000 = 600000
const THRESHOLD_750000 = 750000
const THRESHOLD_900000 = 900000
const THRESHOLD_1200000 = 1200000
const THRESHOLD_1500000 = 1500000
const THRESHOLD_1950000 = 1950000

export const FOLLOWERS_THRESHOLDS = [
  THRESHOLD_1000,
  THRESHOLD_4500,
  THRESHOLD_15000,
  THRESHOLD_30000,
  THRESHOLD_75000,
  THRESHOLD_150000,
  THRESHOLD_225000,
  THRESHOLD_300000,
  THRESHOLD_450000,
  THRESHOLD_600000,
  THRESHOLD_750000,
  THRESHOLD_900000,
  THRESHOLD_1200000,
  THRESHOLD_1500000,
  THRESHOLD_1950000,
]
