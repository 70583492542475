import { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export type PrimaryLabelProps = PropsWithChildren & {
  className?: string
}

const Container = styled.div`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
  border-radius: 16px;
  background: ${({ theme }) => theme.common.sixthBg};
  text-align: center;
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  gap: 4px;
`

export const PrimaryLabel: FC<PrimaryLabelProps> = ({
  children,
  className,
}) => <Container className={className}>{children}</Container>
