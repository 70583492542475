import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

export interface RewardData {
  basicReward: number
  followers: number
}

const rewardApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getRewardData: builder.query<Array<RewardData>, void>({
      query: () => `${APP.TIKTOK_SERVER}/curator/review-rewards/bonus-system`,
    }),
  }),
  overrideExisting: true,
})

export const { useGetRewardDataQuery } = rewardApi
