import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'

import { GetPremiumState } from '.'

const getState = (state: RootState): GetPremiumState =>
  state.canceledCreatorAlerts

export const closedAlertsWithCanceledEventIdsSelector = createDraftSafeSelector(
  getState,
  ({ closedEventIds }) => closedEventIds,
)
