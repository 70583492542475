import { FC } from 'react'

import { message } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useCreateCampaignLayout } from 'src/Pages/Artist/CreateCampaignPage/useCreateCampaignLayout'
import Button from 'src/Components/Buttons/Button'
import { forgotPass } from 'src/Router/routes'
import { SmartInput } from 'src/Components/SmartInput'
import { loginEvent } from 'src/Helpers/TagManager'
import { FullWideNotification } from 'src/Components/FullWideNotification'
import { User } from 'src/Types/index'
import { colors } from 'src/Styled/variables'
import { signIn } from 'src/Redux/auth-process/credentialsSlice/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getLoading } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getAuthEmail } from 'src/Redux/auth-process/authTypeSlice/selectors'
import { useSourceBusterEvents } from 'src/Hooks/useSourceBusterEvents'
import { PrimaryLoading } from 'src/Components/PrimaryLoading'

import { SendLoginLink } from '../SendLoginLink'
import { PlatformLinks } from '../PlatformLinks/PlatformLinks'

import { schemaForRegularLogin } from './schema'

import {
  ArrowRight,
  BlockOr,
  FieldWrapper,
  ForgotPassword,
  Form,
  TextEmail,
} from './styles'

interface FormState {
  email: string
  password: string
}

export const RegularLogin: FC = () => {
  const loading = useAppSelector(getLoading)
  const [messageApi, contextHolder] = message.useMessage()
  const defaultEmail = useAppSelector(getAuthEmail)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { resetSearchTracksToInitialState } = useCreateCampaignLayout()
  const { sourceBusterLoginEvent } = useSourceBusterEvents()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: {
      email: defaultEmail,
    },
    mode: 'all',
    resolver: yupResolver(schemaForRegularLogin),
  })

  const onSubmit = (data: FormState): void => {
    messageApi.destroy()
    void dispatch(signIn(data))
      .unwrap()
      .then((res) => {
        loginEvent(res.user as User)
        sourceBusterLoginEvent(res.token)
        resetSearchTracksToInitialState()
        return res
      })
      .catch((error) => {
        const errorText =
          error === 'Curator in blacklist' || error === 'Country in blacklist'
            ? t('login.authErrorBlacklistRegistered')
            : t('login.authError')

        void messageApi.open({
          content: <FullWideNotification message={errorText} />,
          style: { backgroundColor: colors.badgeRed },
          icon: null,
        })
      })
  }

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper>
          <SmartInput
            {...register('email')}
            error={errors.email}
            placeholder={t('login.email') || 'Email'}
            type='email'
            data-cy='input-render-field-email'
            autoFocus
          />
        </FieldWrapper>
        <FieldWrapper>
          <SmartInput
            {...register('password')}
            error={errors.password}
            placeholder={t('login.password') || 'Password'}
            type='password'
            data-cy='input-render-field-password'
          />
        </FieldWrapper>
        <ForgotPassword>
          <Link to={forgotPass}>{t('login.forgot')}</Link>
        </ForgotPassword>
        <Button disabled={loading} htmlType='submit' type='green'>
          {loading ? (
            <PrimaryLoading fontSize={24} color='#fff' />
          ) : (
            <>
              {t('login.login')} <ArrowRight />
            </>
          )}
        </Button>
        <BlockOr>
          <p>{t('login.easilyUsing')}</p>
        </BlockOr>
        <PlatformLinks />
      </Form>
      <BlockOr>
        <p>{t('login.easilyUsing')}</p>
      </BlockOr>
      <SendLoginLink email={watch().email} />
      <TextEmail data-cy='login-form-regular-p-we-can-email'>
        {t('login.weCanEmail')}
      </TextEmail>
    </>
  )
}
