import { FunctionComponent, ReactElement } from 'react'

import { useSelector } from 'react-redux'

import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { BonusBlock } from 'src/Components/BonusBlock'
import {
  getTransactionsPaidOut,
  getTransactionsPayment,
} from 'src/Redux/transactions-process/selectors'
import { getUser } from 'src/Redux/auth-process/userSlice/selectors'
import { HUNDREDTHS } from 'src/Constants/numeric'

import { InfluencerBalanceRequestButton } from '../InfluencerBalanceRequestButton'

import { BlockOfCards } from './styles'

export const InfluencerBalanceTopBlock: FunctionComponent =
  (): ReactElement => {
    const user = useSelector(getUser)
    const transactionsPayed = useSelector(getTransactionsPayment)
    const transactionsPaidOut = useSelector(getTransactionsPaidOut)

    const totalEarned = `$ ${Number(
      addSpaceToNumber(transactionsPayed || 0, ','),
    ).toFixed(HUNDREDTHS)}`

    const totalPaidOut = `$ ${Number(
      addSpaceToNumber(transactionsPaidOut || 0, ','),
    ).toFixed(HUNDREDTHS)}`

    const balance = `$ ${addSpaceToNumber(user?.balance ?? 0, ',', true)}`

    return (
      <BlockOfCards>
        <BonusBlock title='Total earned' value={totalEarned} />
        <BonusBlock title='Paid out' value={totalPaidOut} />
        <BonusBlock $isGreen title='Balance' value={balance} />

        <InfluencerBalanceRequestButton />
      </BlockOfCards>
    )
  }
