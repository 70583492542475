import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { VAT_PERCENT, FIXED_BY } from 'src/Constants/constants'
import { PaymentMethodEnum } from 'src/Constants/enums'
import { subscriptionPaymentCompletedEvent } from 'src/Helpers/TagManager'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { checkIsVATCountry } from 'src/Hooks/usePayment'
import { getUserInfo } from 'src/Redux/auth-process/userSlice/api-actions'
import {
  getCountryName,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { useChangeSubscriptionMutation } from 'src/Redux/subscription-process/subscriptionApi'
import { subscription } from 'src/Router/routes'

type onChangeSubscription = (paymentType: string, id?: number) => void

export const useChangeSubscription = (): [
  onChangeSubscription,
  loading: boolean,
] => {
  const navigate = useNavigate()
  const userId = useAppSelector(getUserId)
  const dispatch = useAppDispatch()
  const [changeSubscription, { isLoading }] = useChangeSubscriptionMutation()
  const { search, setSearch } = useCustomSearchParams()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const plan = search.get('plan')
  const country = useAppSelector(getCountryName)

  const handleChangeSubscription = useCallback(
    (paymentType: string, id?: number) => {
      if (!packageId || !price || !plan || !id) {
        return
      }
      const isVATCountry = checkIsVATCountry(country)
      const preceWithVAT = Number(
        (price + price * VAT_PERCENT).toFixed(FIXED_BY),
      )
      changeSubscription({
        packageId,
        paymentType,
        userPaymentMethodId: id,
        paymentAmount: price,
        fullPaymentAmount: isVATCountry ? preceWithVAT : price,
      })
        .unwrap()
        .then((res) => {
          navigate(`${subscription}/pay/success`)
          subscriptionPaymentCompletedEvent({
            userId,
            planName: plan,
            paymentMethod:
              paymentType === PaymentMethodEnum.PAYPAL
                ? 'PayPal_saved'
                : 'Stripe_saved',
          })
          void dispatch(getUserInfo())
          return res
        })
        .catch(() => {
          setSearch({ error: 'subscription_error', autopay: null })
        })
    },
    [
      packageId,
      price,
      plan,
      country,
      changeSubscription,
      navigate,
      userId,
      dispatch,
      setSearch,
    ],
  )
  return [handleChangeSubscription, isLoading]
}
