import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PrimaryHeader } from 'src/Components/Header/Components/PrimaryHeader'
import Exit from 'src/Assets/Svg/header-icons/exit.svg?react'
import Settings from 'src/Assets/Svg/header-icons/settingsNew.svg?react'
import { useLogOut } from 'src/Hooks/useLogOut'
import { CreatorsRouteEnum } from 'src/Router/CreatorComponentsNewUI'
import {
  getArtistBalance,
  getIsUserLoading,
} from 'src/Redux/auth-process/userSlice/selectors'
import { useAppSelector } from 'src/Hooks/redux'

import { HeaderContainerProps } from '../HeaderContainer'

export const CreatorHeaderContainer: FC<HeaderContainerProps> = ({
  withShadow,
  onSidebarToggleClick,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const balance = useAppSelector(getArtistBalance)
  const isBalanceLoading = useAppSelector(getIsUserLoading)
  const { handleLogOut } = useLogOut()
  const onSettingsClick = (): void => {
    navigate(CreatorsRouteEnum.Settings)
  }

  const onBalanceClick = (): void => {
    navigate(CreatorsRouteEnum.Balance)
  }

  const menuItems = [
    {
      title: t('header.settings'),
      Icon: Settings,
      onClick: onSettingsClick,
    },
    {
      title: t('header.logout'),
      Icon: Exit,
      onClick: handleLogOut,
    },
  ]

  return (
    <PrimaryHeader
      withShadow={withShadow}
      isBalanceButtonLoading={isBalanceLoading}
      balance={balance}
      onSidebarToggleClick={onSidebarToggleClick}
      onBalanceButtonClick={onBalanceClick}
      menuItems={menuItems}
      hasNewNotifications={false}
      notifications={[]}
      isHeaderNotificationsPopupVisible={false}
    ></PrimaryHeader>
  )
}
