import styled from 'styled-components'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { variables } from 'src/Styled/variables'

export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
`
export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
`

export const WrapperWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
  }
`
export const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.button.thirdText} !important;
`
