import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Recipient } from 'src/Types'
import { BankDetails } from 'src/Components/WisePayout/helpers'

interface AddWiseRecipientBody {
  currency: string
  type: string
  details: BankDetails
}

const recipientApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getRecipientBankDetails: build.query<Recipient, void | null>({
      query: () => ({
        url: `${APP.TIKTOK_SERVER}/payout/transferwise/recipient`,
        method: 'GET',
      }),
      providesTags: ['Recipient'],
    }),
    addTransferwiseRecipient: build.mutation<Recipient, AddWiseRecipientBody>({
      query: (body) => ({
        url: `${APP.TIKTOK_SERVER}/payout/transferwise/recipient`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Recipient'],
    }),
  }),
})

export const {
  useGetRecipientBankDetailsQuery,
  useAddTransferwiseRecipientMutation,
} = recipientApi
