import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { PrimarySkeleton } from 'src/Components/Skeletons/PrimarySkeleton'

import { CampaignListHeaderActionBlock } from '../CampaignListHeaderActionBlock/CampaignListHeaderActionBlock'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  position: relative;
`

const Title = styled.h1`
  color: ${(props) => props.theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  white-space: nowrap;
`
const StyledSkeleton = styled(PrimarySkeleton)`
  width: 120px;
  border-radius: 16px;
  min-width: 120px;
  height: 48px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    width: 240px;
  }
`

interface CampaignListHeaderProps {
  loading: boolean
}
export const CampaignListHeader: FC<CampaignListHeaderProps> = ({
  loading,
}) => {
  const { t } = useTranslation()
  const actionBlock = useMemo(() => {
    if (loading) {
      return <StyledSkeleton data-testid='campaignListHeaderSkeleton' />
    }
    return <CampaignListHeaderActionBlock />
  }, [loading])

  return (
    <Container>
      <Title>{t('campaignsListPage.campaigns')}</Title>
      {actionBlock}
    </Container>
  )
}
