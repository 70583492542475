import React, { FC } from 'react'

import { formatDate } from 'src/Constants/formatDate'
import dayJs from 'src/Helpers/dayjs'
import { CuratorTransaction } from 'src/Redux/transactions-process/curatorBalanceHistorySlice'

import { StatusCell } from './StatusCell/StatusCell'
import { AmountCell } from './AmountCell/AmountCell'
import { DescriptionCell } from './DescriptionCell/DescriptionCell'

import {
  AmountContainer,
  AmountWrapper,
  Container,
  DateWrapper,
  MobileStatusWrapper,
  StyledDate,
  Title,
  Wrapper,
} from './styles'

interface MobileBalanceItemProps {
  transaction: CuratorTransaction
}

export const MobileBalanceItem: FC<MobileBalanceItemProps> = ({
  transaction,
}) => (
  <Wrapper>
    <Container>
      <DescriptionCell
        description={transaction.description}
        type={transaction.type}
        reviewDetails={transaction?.reviewDetails}
      />
      <MobileStatusWrapper>
        <Title>Status</Title>
        <StatusCell type={transaction.type} />
      </MobileStatusWrapper>

      <AmountWrapper>
        <DateWrapper>
          <Title>Date</Title>
          <StyledDate>
            {dayJs(transaction.createdAt).format(
              formatDate.formatDateMonthFull,
            )}
          </StyledDate>
        </DateWrapper>

        <AmountContainer>
          <Title>Amount</Title>
          <AmountCell
            type={transaction.type}
            amount={Number(transaction.amount)}
          />
        </AmountContainer>
      </AmountWrapper>
    </Container>
  </Wrapper>
)
