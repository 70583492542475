import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MAX_TABLET_WIDTH, colors } from 'src/Styled/variables'
import { useTiktokPrice } from 'src/Pages/Artist/TiktokSchedulePage/TiktokScheduleSettingsPage/helpers'
import { useGetTikTokCampaignBudgetRangeQuery } from 'src/Redux/artistPaymentSettingsApi'
import { BudgetSlider } from 'src/Containers/ui/BudgetSlider'
import { APP } from 'src/Configs/App'
import { ScheduleBudgetInput } from 'src/Components/ScheduleBudgetInput'
import { PrimaryScheduleSubscription } from 'src/Components/PrimaryScheduleSubscription'

import { TiktokScheduleBudgetEstimation } from './TiktokScheduleBudgetEstimation'
import { TiktokScheduleContinueButton } from './TiktokScheduleContinueButton'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: 400px;
  }
`
const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const BudgetSliderWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 100%;
`
const SliderContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 37px;
  margin-right: 37px;

  .ant-slider {
    padding-inline: 0px;
  }
`
const BudgetSliderSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
`

const StyledBudgetSlider = styled(BudgetSlider)`
  &.ant-slider-vertical {
    width: 6px;
    margin: 0;
  }
  & > .ant-slider-rail {
    height: 100%;
    width: 6px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.progress.primaryBg};
  }

  .ant-slider-mark {
    margin-left: -12px;
    inset-inline-start: 0;
    white-space: nowrap;
    .ant-slider-mark-text {
      bottom: 97% !important;
      line-height: 150%;

      &:first-child {
        width: auto;
        transform: translate(-10%, 100%) !important;
        bottom: -6% !important;
        color: ${({ theme }) => theme.common.primaryText};
      }
      &:last-child {
        width: auto;
        transform: translate(-10%, 100%) !important;
        bottom: 130% !important;
        color: ${({ theme }) => theme.common.primaryText};
      }
    }
  }
`
const SliderTrackStyles = {
  width: 6,
  borderRadius: 6,
  backgroundColor: colors.green,
}

const SliderHandleStyles = {
  marginLeft: -4.5,
  height: 16,
  width: 16,
  border: 'none',
  borderRadius: 12,
  backgroundColor: colors.white,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
}

export const TiktokScheduleBudget: FC = () => {
  const { data: budgetRange } = useGetTikTokCampaignBudgetRangeQuery()
  const MIN = Number(budgetRange?.campaignMinBudget ?? 0)
  const MAX = Number(budgetRange?.campaignMaxBudget ?? 0)

  const { t } = useTranslation()
  const {
    rate,
    currencySymbol,
    valueInput,
    valueSlider,
    setValueInput,
    setValueSlider,
    savePrice,
  } = useTiktokPrice()

  const marks = {
    [MIN]: `${currencySymbol}${rate && Math.round(MIN * rate).toLocaleString('en-US')}`,
    [MAX]: `${currencySymbol}${rate && Math.round(MAX * rate).toLocaleString('en-US')}`,
  }
  const handleSliderChange = (value: number): void => {
    rate && setValueInput(Math.round(value * rate))
    setValueSlider(value)
  }
  const handleSliderChangeCompleted = (value: number): void => {
    savePrice(value)
  }
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const substr =
      currencySymbol &&
      event.target.value.substring(
        currencySymbol?.length,
        event.target.value.length,
      )
    const numberValue = Number(substr)
    if (isNaN(numberValue)) {
      return
    }
    setValueInput(numberValue)
    rate && savePrice(Math.round(numberValue / rate))
  }

  return (
    <Container>
      <Title>{t('scheduleCampaignPage.SetUp')}</Title>
      <BudgetSliderWrapper>
        <SliderContainer>
          <StyledBudgetSlider
            value={valueSlider}
            vertical
            marks={marks}
            tooltip={{ formatter: null }}
            step={APP.REACH_STEP_BUDGET_TIKTOK_CAMPAIGN}
            max={MAX}
            min={MIN}
            styles={{
              track: SliderTrackStyles,
              handle: SliderHandleStyles,
            }}
            onChange={handleSliderChange}
            onChangeComplete={handleSliderChangeCompleted}
          />
        </SliderContainer>
        <BudgetSliderSideContainer>
          <ScheduleBudgetInput
            role='budget-input'
            value={`${currencySymbol}${valueInput}`}
            onChange={handleInputChange}
          />
          <PrimaryScheduleSubscription
            symbol={currencySymbol}
            price={valueInput}
          />
          <TiktokScheduleBudgetEstimation />
        </BudgetSliderSideContainer>
      </BudgetSliderWrapper>
      <TiktokScheduleContinueButton />
    </Container>
  )
}
