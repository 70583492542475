import React, { ReactElement } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { sounds } from 'src/Router/routes'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { SuccessSubmitModal } from '../../../SuccessSubmitModal'
import { CloseCrossButton, Wrapper, WrapperContainer } from '../../styles'
import { InProgressSoundDetails } from '../InProgressSoundDetails'

export const InProgressSoundDetailsModal = (): ReactElement => {
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()
  const { soundId } = useParams<{ soundId: string }>()

  const soundStatus = search.get('soundStatus')

  const isSoundSubmitted = soundStatus === 'submitted'

  const onClickCloseModal = (): void => {
    navigate(`${sounds}/inProgress`)
  }

  return (
    <Wrapper>
      <WrapperContainer>
        <InProgressSoundDetails />

        {isSoundSubmitted && <SuccessSubmitModal soundId={Number(soundId)} />}
        <CloseCrossButton onClick={onClickCloseModal} />
      </WrapperContainer>
    </Wrapper>
  )
}
