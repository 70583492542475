import { FC, useEffect } from 'react'

import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Breadcrumb } from 'src/Components/Breadcrumb'
import { create, signUpArtist } from 'src/Router/routes'
import { ChosenTrack } from 'src/Components/ChosenTrack'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { useFirstChosenTrack } from 'src/Hooks/useFirstChosenTrack'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { getValueOrZero } from 'src/Constants/functions'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'

import { NoAuthPageLayout } from '../../Components/NoAuthPageLayout'

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 960px;
  flex-direction: column;
  margin: 0 auto;
  align-items: flex-start;
  gap: 40px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    gap: 16px;
  }
`

export const SelectedTrackNoAuth: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { trackId } = useParams()
  const { track, loading, platform, isSuccess } = useFirstChosenTrack()
  const { search } = useCustomSearchParams()
  const { trackAddedEventHandler } = usePageViewEvent()

  const handleCreate = (): void => {
    navigate(
      `${create}/${platform}/${trackId}${signUpArtist}?${search.toString()}`,
    )
  }

  const handleBackClick = (): void => {
    navigate(create)
  }

  useEffect(() => {
    if (platform && track?.artistName && isSuccess) {
      trackAddedEventHandler({
        artistName: getValueOrEmptyString(track?.artistName),
        platformType: platform,
        trackDuration: getValueOrZero(track?.trackDurationInMs),
        trackId: getValueOrEmptyString(track?.trackId),
        trackName: getValueOrEmptyString(track?.trackName),
      })
      toggleActivatedEvent({
        platformName: platform,
        campaignType: CampaignTypeEnum.FIRST,
        campaignNumber: 1,
      })
    }
  }, [
    isSuccess,
    platform,
    track?.artistName,
    track?.trackDurationInMs,
    track?.trackId,
    track?.trackName,
    trackAddedEventHandler,
  ])

  const items = [
    {
      path: create,
      title: t('chosenTrack.campaign'),
    },
    {
      path: `${create}/${platform}`,
      title: t('chosenTrack.search'),
    },
  ]

  const title =
    platform === CampaignPlatformTypeEnum.SPOTIFY
      ? t('chosenTrack.spotifyTitle')
      : t('chosenTrack.tiktokTitle')

  return (
    <NoAuthPageLayout>
      <ContentContainer>
        <Breadcrumb onButtonClick={handleBackClick} items={items} />
        <ChosenTrack
          title={title}
          loading={loading}
          artist={track?.artistName}
          trackName={track?.trackName}
          trackUrl={track?.trackPreviewUrl}
          image={track?.trackCoverUrl}
          duration={track?.trackDuration}
          onCreateClick={handleCreate}
          onChangeClick={handleBackClick}
        />
      </ContentContainer>
    </NoAuthPageLayout>
  )
}
