import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const DescriptionCardText = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageContentContainer = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
  position: relative;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  .ant-table-wrapper table {
    border-collapse: collapse;
  }
  .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    font-size: ${variables.fontSize14};
    line-height: 150%;
    color: ${colors.mainText};
    background-color: transparent;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: ${variables.fontWeight400};
    background-color: ${colors.white};
    color: ${colors.mainText};
    line-height: 21px;
    border: none;
    &::before {
      display: none;
    }
  }
  .ant-table-tbody {
    border-spacing: 0 2px;
  }
  .ant-table-row {
    border-bottom: 3px solid white;
    border-top: 3px solid white;
  }
  .ant-table-row .ant-table-cell {
    border: none;
    background-color: ${colors.greyBg};
  }
`

export const MobileInfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`

export const HeaderTitle = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.mainText};
`

export const InfoText = styled.span`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
`

export const DescriptionCard = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: 14.4px 1fr;

  & svg {
    width: 14.4px;
    height: 16px;
    margin-top: 4px;
  }
`

export const Amount = styled.span<{ green?: boolean }>`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
`
