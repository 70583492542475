import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { ReviewerBalanceHistory } from 'src/Constants/enums'

interface StatusCellProps {
  type: string
  isBonusSystemRewardReview: boolean
}

export const StatusCell: FC<StatusCellProps> = ({
  type,
  isBonusSystemRewardReview,
}) => {
  const { t } = useTranslation()

  const BASIC_REWARD = 'Basic reward received'
  const STANDARD_REWARD = 'Reward received'
  const BONUS = 'Bonus received'

  const rewardReceived = isBonusSystemRewardReview
    ? BASIC_REWARD
    : STANDARD_REWARD

  const MAP_STATUS_CELL: Record<string, string> = {
    [ReviewerBalanceHistory.PAYMENT]: rewardReceived,
    [ReviewerBalanceHistory.REWARD_WITH_PLACEMENT]: rewardReceived,
    [ReviewerBalanceHistory.REWARD_WITHOUT_PLACEMENT]: rewardReceived,
    [ReviewerBalanceHistory.PAYOUT]: t('balancePage.payoutApproved'),
    [ReviewerBalanceHistory.WITHDRAW]: t('balancePage.rewardCanceled'),
    [ReviewerBalanceHistory.REVIEW_BONUS_PLACEMENT]: BONUS,
    [ReviewerBalanceHistory.REVIEW_BONUS_LIKE]: BONUS,

    default: t('balancePage.payoutCanceled'),
  }

  return <span>{MAP_STATUS_CELL[type] || MAP_STATUS_CELL.default}</span>
}
