import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import TrackCoverIcon from 'src/Assets/Svg/pages-icons/TrackCover.svg'
import RightArrowIcon from 'src/Assets/Svg/pages-icons/RightArrow.svg?react'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { VideoStatusBadge } from 'src/Components/VideoStatus'
import { PremoderationStatus, VideoStatus } from 'src/Constants/enums'

import { IconButton } from '../Buttons/IconButton'
import { SmartImage } from '../SmartImage'
import { PrimaryButton } from '../Buttons/PrimaryButton'

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: repeat(4, minmax(0, auto));
  grid-row-gap: 24px;
  grid-column-gap: 16px;
  align-items: start;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.button.primaryBg};
  border-radius: 4px;
  width: 100%;

  @media screen and (min-width: 1184px) {
    grid-template-columns: 40px 1fr 1fr 48px;
    grid-template-rows: none;
    align-items: center;
    margin-bottom: 2px;
  }
`
export const StyledIcon = styled.div`
  display: grid;
  gap: 8px;
  justify-items: center;
  align-items: center;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row: 1 / -1;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    grid-auto-flow: column;
    gap: 16px;
  }
`
const Avatar = styled(SmartImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  grid-row: span 2;
`

const CreatorDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const CreatorName = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.common.primaryText};
`

const Followers = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    align-items: flex-start;
  }
`

const ViewsCount = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 24px;
  color: ${({ theme }) => theme.common.primaryText};
`

const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin-left: auto;
  grid-row: span 2;
  @media screen and (max-width: 1183px) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  color: ${({ theme }) => theme.button.thirdText};
  width: 160px;
  margin: 0 auto;
  @media screen and (min-width: 1184px) {
    display: none;
  }
`

export interface VideoDetailsCardProps {
  avatarUrl?: string
  followers?: number
  views?: number
  userName: string
  videoStatus: VideoStatus
  onDetailsClick: () => void
  videoPreModerationStatus?: PremoderationStatus
}

const notShowedViewsStatuses = [
  VideoStatus.DECLINED,
  VideoStatus.REJECTED,
  VideoStatus.DISMISSED,
]

export const VideoDetailsCard: React.FC<VideoDetailsCardProps> = ({
  avatarUrl,
  userName,
  followers,
  videoStatus,
  views,
  onDetailsClick,
  videoPreModerationStatus,
}) => {
  const { t } = useTranslation()
  const formattedFollowers = followers && addSpaceToNumber(followers, ',')

  const formattedViews = notShowedViewsStatuses.includes(videoStatus)
    ? '-'
    : views && addSpaceToNumber(views || 0, ',')

  return (
    <CardContainer>
      <StyledIcon>
        <Avatar src={avatarUrl} alt='Profile' fallbackSrc={TrackCoverIcon} />
      </StyledIcon>

      <CreatorDetails>
        <CreatorName>{userName}</CreatorName>
        <Followers>
          {formattedFollowers} {t('campaignDetailsPage.followers')}
        </Followers>
      </CreatorDetails>

      <StatusSection>
        <VideoStatusBadge videoPreModerationStatus={videoPreModerationStatus} />
        <ViewsCount>
          {formattedViews} {t('campaignDetailsPage.viewsCount')}
        </ViewsCount>
      </StatusSection>

      <StyledPrimaryButton onClick={onDetailsClick}>
        {t('campaignDetailsPage.details')}
      </StyledPrimaryButton>
      <StyledIconButton onClick={onDetailsClick}>
        <RightArrowIcon />
      </StyledIconButton>
    </CardContainer>
  )
}
