import { Slider } from 'antd'
import styled from 'styled-components'

import { colors } from 'src/Styled/variables'

export const BudgetSlider = styled(Slider)`
  .ant-slider-rail {
    height: 100%;
    width: 12px;
    border-radius: 6px;
    background-color: ${colors.greyBg};
    border: 1px solid ${colors.greyStroke};
  }
  .ant-slider-handle {
    inset-inline-start: auto;
    &:after {
      display: none;
    }
  }
  .ant-slider-step {
    .ant-slider-dot {
      display: none;
    }
  }
  .ant-slider-mark {
    margin-left: -12px;
    inset-inline-start: 0;
    white-space: nowrap;

    .ant-slider-mark-text {
      bottom: 97% !important;
      line-height: 150%;
      &:first-child {
        width: 100%;
        white-space: nowrap;
        transform: translateY(36px) !important;
        bottom: 5px !important;
        color: ${colors.mainText};
      }
      &:last-child {
        width: 100%;
        white-space: nowrap;
        margin-bottom: 0 !important;
        transform: translateY(-16px) !important;
        color: ${colors.mainText};
      }
    }
  }
`
