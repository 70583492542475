import { FunctionComponent, useState } from 'react'

import { useLocation } from 'react-router-dom'

import Add from 'src/Assets/Svg/common/addIcon.svg?react'
import { useNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserSelector } from 'src/Redux/auth-process/userSlice/selectors'
import { useGetMonetizationDataQuery } from 'src/Redux/monetizationApi'
import { CreatorsRouteEnum } from 'src/Router/CreatorComponentsNewUI'

import { SoundsList } from '../index'
import { NewSoundsListItem } from '../../SoundsListItem/components/NewSoundsListItem'
import { TabKeysEnum } from '../../../types'
import { SoonContainer, Text } from '../styles'

export const NewSoundsList: FunctionComponent = () => {
  const user = useAppSelector(getUserSelector)
  const [page, setPage] = useState(1)
  const { pathname } = useLocation()
  const isNewTab =
    pathname.includes(TabKeysEnum.NEW) ||
    pathname === `${CreatorsRouteEnum.Influencer}${CreatorsRouteEnum.Root}` ||
    pathname === CreatorsRouteEnum.Sounds

  const {
    data: newSoundsData,
    isLoading,
    isFetching,
    isError,
  } = useNewSoundsQuery(
    {
      creatorId: user.influencerId,
      pageNumber: page,
    },
    {
      skip: !user.influencerId,
    },
  )

  const { data: monetizationData } = useGetMonetizationDataQuery()

  const newSounds = isError ? [] : newSoundsData?.data || []
  const hasNextPage = newSoundsData?.meta.hasNextPage || false
  const hasSounds = newSounds.length > 0

  const loadMore = (): void => {
    setPage(page + 1)
  }

  return (
    <SoundsList
      hasNextPage={hasNextPage && isNewTab}
      isLoading={isLoading || isFetching}
      onLoadMore={loadMore}
      soundsAmount={newSounds.length}
      tabType={TabKeysEnum.NEW}
      isTikTokProfileError={isError}
    >
      {!hasSounds && (
        <SoonContainer>
          <Add />
          <Text>You'll get new sounds for video creation soon</Text>
        </SoonContainer>
      )}

      {newSounds.map((item) => (
        <NewSoundsListItem
          key={item.id}
          campaignId={item.id}
          trackAuthorName={item.trackAuthorName}
          trackCoverUrl={item.trackCoverUrl}
          trackTiktokId={item.trackTiktokId}
          trackTitle={item.trackTitle}
          maxCreatorReward={monetizationData?.maxCreatorReward}
        />
      ))}
    </SoundsList>
  )
}
