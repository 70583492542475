import { ReactElement } from 'react'

import styled from 'styled-components'

import { TransactionTypeEnum } from 'src/Types'
import { HUNDREDTHS } from 'src/Constants/numeric'

export const AmountValue = styled.span`
  margin-left: 10px;
`
export const PrefixValue = styled.span`
  position: absolute;
  left: 0;
  top: -2px;
`
export const AmountWrapper = styled.span<{ hiddenPrefix?: boolean }>`
  position: relative;
`

interface UseArtistBalancePageResponse {
  getAmountValue: (
    transactionType: TransactionTypeEnum,
    amount: number,
  ) => ReactElement
}

export const useBalancePage = (): UseArtistBalancePageResponse => {
  const getAmountValue = (
    transactionType: TransactionTypeEnum,
    amount: number,
  ): ReactElement => {
    const prefixes: Record<TransactionTypeEnum, string> = {
      [TransactionTypeEnum.RECEIVED]: '+',
      [TransactionTypeEnum.BONUS]: '+',
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_FUTURE]: '+',
      [TransactionTypeEnum.QUICK_CHECKOUT_BONUS_CURRENT]: '+',
      [TransactionTypeEnum.SUBSCRIPTION]: '+',
      [TransactionTypeEnum.REFERRAL_BONUS]: '+',
      [TransactionTypeEnum.CASHBACK]: '+',
      [TransactionTypeEnum.INVITED_BONUS]: '+',
      [TransactionTypeEnum.PAYMENT]: '',
      [TransactionTypeEnum.USED]: '-',
      [TransactionTypeEnum.CHARGEBACK]: '-',
      [TransactionTypeEnum.SUBSCRIPTION_PAYMENT_FAILED]: ' ',
    }

    let prefix: string

    if (transactionType === TransactionTypeEnum.PAYMENT) {
      prefix = ''
    } else if (transactionType in prefixes) {
      prefix = prefixes[transactionType]
    } else {
      prefix = amount > 0 ? '-' : ''
    }

    return (
      <AmountWrapper>
        <PrefixValue>{prefix}</PrefixValue>
        <AmountValue>{`$${Number(amount).toFixed(HUNDREDTHS)}`}</AmountValue>
      </AmountWrapper>
    )
  }

  return {
    getAmountValue,
  }
}
