import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { TransferwiseRequirement, WiseCurrency } from 'src/Types'
import { APP } from 'src/Configs/App'

import { NameSpace } from '../types'

interface GetCurrenciesData {
  data: WiseCurrency[]
}

export const getCurrencies = createAsyncThunk<GetCurrenciesData>(
  `${NameSpace.Wise}/getCurrencies`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<GetCurrenciesData>(
        `${APP.TIKTOK_SERVER}/payout/transferwise/currencies`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data?.message,
        code: response?.status,
      })
    }
  },
)

interface WiseAccountRequirementsData {
  data: Array<TransferwiseRequirement>
}
export const getTransferwiseAccountRequirements = createAsyncThunk<
  WiseAccountRequirementsData,
  string
>(
  `${NameSpace.Wise}/getTransferwiseAccountRequirements`,
  async (currency, { rejectWithValue }) => {
    try {
      const { data } = await api.get<WiseAccountRequirementsData>(
        `${APP.TIKTOK_SERVER}/payout/transferwise/account-requirements/${currency}`,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data?.message,
        code: response?.status,
      })
    }
  },
)

interface SpecifyTransferwiseAccountBody {
  currency: string
  type: string
  details: Record<string, string | Record<string, string>>
}

export const specifyTransferwiseAccountRequirements = createAsyncThunk<
  WiseAccountRequirementsData,
  SpecifyTransferwiseAccountBody
>(
  `${NameSpace.Wise}/specifyTransferwiseAccountRequirements`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await api.post<WiseAccountRequirementsData>(
        `${APP.TIKTOK_SERVER}/payout/transferwise/account-requirements/quote`,
        body,
      )
      return data
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>
      return rejectWithValue({
        message: response?.data?.message,
        code: response?.status,
      })
    }
  },
)
