import { useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  NewReleaseResponse,
  useGetNewReleaseQuery,
} from 'src/Redux/newReleaseApi'
import { Roles } from 'src/Constants/enums'
import { notificationClickEvent } from 'src/Helpers/TagManager'
import { UserNotification } from 'src/Types'
import { useCreateAndStartScheduleNewRelease } from 'src/Hooks/useCreateAndStartScheduleNewRelease'
import {
  useGetArtistNotificationsQuery,
  useMarkArtistNotificationsAsReadMutation,
} from 'src/Redux/artistNotificationsApi'

export const useNotifications = (): {
  sortedNotifications: UserNotification[]
  hasNewNotifications: boolean
  isHeaderNotificationsPopupVisible: boolean
  onNotificationsButtonClick: () => void
  releaseData: NewReleaseResponse | undefined
  handleCreateAndStartSchedule: () => void
} => {
  const userId = useSelector(getUserId)

  const role = useSelector(getUserRole)

  const { data: notifications } = useGetArtistNotificationsQuery()
  const [markArtistNotificationsAsRead] =
    useMarkArtistNotificationsAsReadMutation()

  const { data: releaseData } = useGetNewReleaseQuery(null, {
    skip: role !== Roles.artist,
  })

  const [
    isHeaderNotificationsPopupVisible,
    setHeaderNotificationsPopupVisible,
  ] = useState(false)

  const hasNewNotifications = Boolean(
    notifications?.some(({ isNew }) => isNew) || releaseData,
  )

  const { createAndStartSchedule } = useCreateAndStartScheduleNewRelease()

  const handleCreateAndStartSchedule = (): void => {
    createAndStartSchedule(true)
    setHeaderNotificationsPopupVisible(false)
  }

  const sortedNotifications = useMemo(() => {
    const releaseNotification = releaseData
      ? [
          {
            id: 'new-release',
            date: releaseData.releaseDate,
            isRelease: true,
            trackName: releaseData.trackName,
            artistName: releaseData.artistName,
          },
        ]
      : []

    return [...(notifications || []), ...releaseNotification].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    )
  }, [notifications, releaseData])

  const onNotificationsButtonClick = (): void => {
    if (!isHeaderNotificationsPopupVisible) {
      notificationClickEvent({
        userId,
        accountType: role ?? Roles.artist,
      })
    }

    setHeaderNotificationsPopupVisible(!isHeaderNotificationsPopupVisible)

    if (isHeaderNotificationsPopupVisible && hasNewNotifications) {
      void markArtistNotificationsAsRead()
    }
  }

  return {
    sortedNotifications,
    hasNewNotifications,
    isHeaderNotificationsPopupVisible,
    onNotificationsButtonClick,
    releaseData,
    handleCreateAndStartSchedule,
  }
}
