import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { Meta } from 'src/Types'
import { SoundStatus } from 'src/Pages/Creator/SoundsPage/hooks/useSoundStatus'

import { Sound } from '../types'

export interface InProgressSound extends Sound {
  status: SoundStatus.RUNNING
  id: number
  uploadVideoDeadlineDate: string
  maxCreatorReward: number
}

interface InProgressSoundsResponse {
  data: InProgressSound[]
  meta: Meta
}

interface InProgressSoundByIdResponse {
  id: number
  status: SoundStatus.RUNNING
  trackCoverUrl: string
  trackTitle: string
  trackAuthorName: string
  trackTiktokId: string
  trackPlayUrl: string
  comment: string
  maxCreatorReward: number
  minCreatorReward: number
  maxViews: number
  minViews: number
  views: number
  videoLink: string
  uploadVideoDeadlineDate: string
  trackTiktokUrl: string
}

const creatorInProgressSoundsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    const pageSize = 10

    return {
      inProgressSounds: build.query<InProgressSoundsResponse, number>({
        query: (pageNumber = 1) => ({
          url: `${APP.TIKTOK_SERVER}/creators/events?pageSize=${pageSize}&pageNumber=${pageNumber}&statuses=accepted`,
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, newItems, meta) => {
          if (meta.arg === 1) {
            // If pageNumber is 1, replace the current cache with new items
            return {
              data: newItems.data,
              meta: newItems.meta,
            }
          }
          // Otherwise, append the new items to the current cache
          return {
            data: [...currentCache.data, ...newItems.data],
            meta: newItems.meta,
          }
        },
        forceRefetch({ currentArg, previousArg }) {
          return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
        },
        providesTags: ['inProgressSound'],
      }),

      inProgressSoundById: build.query<InProgressSoundByIdResponse, number>({
        query: (soundId) => ({
          url: `${APP.TIKTOK_SERVER}/creators/events/${soundId}`,
        }),
      }),
    }
  },
})

export const selectInProgressSoundsCount = ({
  data,
}: {
  data?: InProgressSoundsResponse
}): { inProgressSoundsCount: number } => ({
  inProgressSoundsCount: data?.meta.itemCount || 0,
})

export const { useInProgressSoundsQuery, useInProgressSoundByIdQuery } =
  creatorInProgressSoundsApi
