import styled from 'styled-components'

import { device, variables } from 'src/Styled/variables'

export const MonetizationContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin: 0 auto 70px;
  @media ${device.mobileMin} {
    margin: 0 auto;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgb(231, 231, 231);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(51, 51, 51, 0.08) 0px 0px 32px 0px;
`
export const SubTitle = styled.span`
  font-size: ${variables.fontSize18};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`
export const LinkWrapper = styled.div`
  align-self: center;
  margin-top: 12px;
  margin-bottom: 9px;
`
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
