import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { StarIcon } from 'src/Assets/Svg'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

import { PrimaryHint } from '../PrimaryHint'

import { getCashback } from './helpers'
interface Props {
  price: number
  symbol: string
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  svg {
    fill: ${({ theme }) => theme.common.sixthText};
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const PrimaryScheduleSubscriptionCashback: FC<Props> = ({
  price,
  symbol,
}) => {
  const { t } = useTranslation()
  const { data: plan } = useGetSubscriptionQuery()
  const { name, cashbackPercentage } = plan?.package || {}
  if (!cashbackPercentage || !name) {
    return null
  }
  const cashback = getCashback({
    price,
    percentage: Number(cashbackPercentage) / 100,
  })
  const text = `${t('scheduleSubscription.cashback')} ${symbol}${cashback}`
  const hintText = `${t(
    'scheduleSubscription.withTheSubscriptionPlanYouReceiveCashbackOnTheCampaignBudget',
    {
      planName: capitalizeFirstLetter(name),
      cashbackPercentage,
    },
  )}`

  return (
    <>
      <Container>
        <StarIcon width={16} height={16} />
        <Text>{text}</Text>
      </Container>
      <PrimaryHint>{hintText}</PrimaryHint>
    </>
  )
}
