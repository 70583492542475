import { FC } from 'react'

import { Modal } from 'antd'
import styled from 'styled-components'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'

const StyledModal = styled(Modal)`
  width: fit-content !important;
  width: 280px;

  .ant-modal-content {
    display: flex;
    width: 400px;
    padding: 14px 23px 23px;
    flex-direction: column;
    gap: 25px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);

    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      max-width: 358px;
      width: 100%;
    }
  }
`

const ModalTitle = styled.h2`
  font-family: 'Segoe UI';
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const ModalText = styled.span`
  font-family: 'Segoe UI';
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 15px;
`

const ModalStyledButton = styled(PrimaryButton)`
  min-width: 153px;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.button.primaryBorder};
  position: absolute;
  top: 53px;
  left: 0;
`

interface RemoveDislikeModalProps {
  open: boolean
  onCancel: () => void
  onLike: () => void
}

export const RemoveDislikeModal: FC<RemoveDislikeModalProps> = ({
  open,
  onCancel,
  onLike,
}) => (
  <StyledModal footer={null} open={open} centered onCancel={onCancel}>
    <Separator />
    <ModalTitle>Do you want to make a change?</ModalTitle>
    <ModalContentContainer>
      <ModalText>
        Are you sure you want to remove your dislike? Your feedback is valuable
        for making your experience better.
      </ModalText>
      <ModalButtonsWrapper>
        <PrimaryDottedButton onClick={onCancel}>Cancel</PrimaryDottedButton>
        <ModalStyledButton onClick={onLike}>Yes, remove</ModalStyledButton>
      </ModalButtonsWrapper>
    </ModalContentContainer>
  </StyledModal>
)
