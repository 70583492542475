import { FC } from 'react'

import styled from 'styled-components'
import { withErrorBoundary } from '@sentry/react'

import { device, MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { ErrorComponent } from 'src/Components/ErrorBoundary/ErrorComponent'

import { RewardTable } from './components/RewardTable'
import { useRewardPage } from './useRewardsPage'
const RewardPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 80px;
  }
`

const Container = styled.div`
  display: flex;
  padding: 40px 40px 25px 40px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  border-radius: 16px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    border: none;
    box-shadow: unset;
    padding: 0;
  }
`
const RewardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  height: 42px;
  margin-bottom: 40px;

  @media ${device.tabletMax} {
    margin-bottom: 21px;
  }
`

const RewardHeaderTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize28};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Reward: FC = () => {
  const { rewardsWithPlaylists } = useRewardPage()

  return (
    <RewardPageContainer>
      <Container>
        <RewardHeaderContainer>
          <RewardHeaderTitle>Rewards</RewardHeaderTitle>
        </RewardHeaderContainer>
        <RewardTable rewardsWithPlaylists={rewardsWithPlaylists} />
      </Container>
    </RewardPageContainer>
  )
}

export default withErrorBoundary(Reward, {
  fallback: <ErrorComponent />,
})
