import { memo } from 'react'

import styled from 'styled-components'

import { CollapsedSearchInput } from 'src/Components/CollapsedSearchInput'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  background: ${({ theme }) => theme.common.primaryBg};
`

const DesktopInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  gap: 24px;
  margin: 0 auto;
  padding-top: 64px;
  background: ${({ theme }) => theme.common.primaryBg};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const StyledSearchInput = styled(CollapsedSearchInput)`
  max-width: 600px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  &.ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 8px;
  }
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    border-radius: 40px;
    width: 100%;
    input::placeholder {
      visibility: visible;
    }
  }
  &:focus-visible,
  &:focus,
  &:focus-within {
    width: 100%;
    @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
      position: relative;
      width: 100%;
      input::placeholder {
        visibility: visible;
      }
    }
  }
  input::placeholder {
    color: ${({ theme }) => theme.common.primaryPlaceholder};
  }
`

interface Props {
  title: string
  placeholder: string
  setValue: (value: string) => void
  value: string
}

export const ScheduleModalsTitle = memo((props: Props) => {
  const { title, placeholder, setValue, value } = props

  return (
    <Container>
      <DesktopInputWrapper>
        <Title>{title}</Title>
        <StyledSearchInput
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          placeholder={placeholder}
        />
      </DesktopInputWrapper>
    </Container>
  )
})

ScheduleModalsTitle.displayName = 'ScheduleModalsTitle'
