import React, { FC } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'

export const AlertTitle = styled.div`
  font-weight: ${variables.fontWeight600};
  margin-bottom: 5px;
`

export const CampaignCanceledAlert: FC<{
  className?: string
  artistName: string
  trackName: string
  onClose: (eventId: number) => void
  eventId: number
}> = ({ className, artistName, trackName, onClose, eventId }) => {
  const onAlertClose = (): void => {
    onClose(eventId)
  }

  return (
    <PrimaryAlert
      type={'warning'}
      hasCross={true}
      className={className}
      onClose={onAlertClose}
    >
      <AlertTitle>{`Campaign for "${trackName}" Canceled`}</AlertTitle>
      <span>
        {`Unfortunately, the artist ${artistName} has decided to cancel the Random Access Memories campaign. As a result, you won't be able to submit your video using this sound.`}
      </span>
    </PrimaryAlert>
  )
}
