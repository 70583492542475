import TagManager from 'react-gtm-module'

import { EventTypeEnum } from '../tagManagerEnums'

type Params = {
  userId?: string
  campaignNumber?: number
  campaignId?: number
  campaignPlatform?: string
  campaignType?: string
}

enum CTAEventNameEnum {
  REQUEST_CAMPAIGN_ANALYSIS_CLICKED = 'request_campaign_analysis_clicked',
  REPORT_CONCERN_CLICKED = 'report_concern_clicked',
  PROMOTE_NEW_TRACK_CLICKED = 'promote_new_track_clicked',
  EXPAND_REACH_TIKTOK_CLICKED = 'expand_reach_tiktok_clicked',
  REACH_NEW_CURATORS_CLICKED = 'reach_new_curators_clicked',
  BOOST_REACH_TIKTOK_CLICKED = 'boost_reach_tiktok_clicked',
  REQUEST_CAMPAIGN_ANALYSIS_ZERO_REVIEWS = 'request_campaign_analysis_zero_reviews',
  REQUEST_CAMPAIGN_ANALYSIS_ZERO_PLACEMENTS = 'request_campaign_analysis_zero_placements',
}

const callCTAEvent = (eventName: CTAEventNameEnum, params: Params): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: eventName,
        'campaign-type': params.campaignType ?? 'basic',
        'campaign-platform': params.campaignPlatform ?? 'spotify',
        'campaign-number': params.campaignNumber ?? 1,
        'campaign-id': String(params.campaignId),
      },
      user_properties: {
        ...(params.userId ? { user_id: params.userId } : {}),
      },
    },
  })
}

export const requestCampaignAnalysisClicked = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.REQUEST_CAMPAIGN_ANALYSIS_CLICKED, params)
}
export const reportConcernClicked = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.REPORT_CONCERN_CLICKED, params)
}
export const promoteNewTrackClicked = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.PROMOTE_NEW_TRACK_CLICKED, params)
}
export const expandReachTiktokClicked = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.EXPAND_REACH_TIKTOK_CLICKED, params)
}
export const reachNewCuratorsClicked = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.REACH_NEW_CURATORS_CLICKED, params)
}
export const boostReachTiktokClicked = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.BOOST_REACH_TIKTOK_CLICKED, params)
}

export const requestCampaignAnalysisZeroReviews = (params: Params): void => {
  callCTAEvent(CTAEventNameEnum.REQUEST_CAMPAIGN_ANALYSIS_ZERO_REVIEWS, params)
}

export const requestCampaignAnalysisZeroPlacements = (params: Params): void => {
  callCTAEvent(
    CTAEventNameEnum.REQUEST_CAMPAIGN_ANALYSIS_ZERO_PLACEMENTS,
    params,
  )
}
