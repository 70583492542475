import React, { FC } from 'react'

import styled from 'styled-components'

import { PurchasingBlock } from 'src/Components/PurchasingBlock'
import { SecondaryScheduleSummary } from 'src/Components/ScheduleSummary/SecondaryScheduleSummary'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { useSpotifySecondaryScheduleSummary } from './useSpotifySecondaryScheduleSummary'

type SummaryProps = {
  onPaymentMethodChange: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    align-self: stretch;
  }
`

export const Summary: FC<SummaryProps> = ({ onPaymentMethodChange }) => {
  const secondaryScheduleSummary = useSpotifySecondaryScheduleSummary()
  return (
    <Wrapper>
      <SecondaryScheduleSummary
        {...secondaryScheduleSummary}
        onPaymentMethodChange={onPaymentMethodChange}
      />
      <PurchasingBlock currentPlatform={CampaignPlatformTypeEnum.SPOTIFY} />
    </Wrapper>
  )
}
