import React, { FC } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--Neutral-Dark-Grey, #333);
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: start;
`

export const Step3Content: FC = () => (
  <Container>
    <div>Total rewards are now split into two amounts:</div>
    <ul>
      <li>Basic reward</li>
      <li>Bonuses</li>
    </ul>
    <div>
      Bonuses are earned for friendly and insightful reviews that artists find
      valuable. The more helpful and meaningful your review is, the higher your
      potential bonus.
    </div>
  </Container>
)
