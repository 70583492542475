import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Collapse } from 'src/Components/Collapse'

import { PlaylistInfo } from '../PlaylistInfo'
import { AccentText, ReviewPlaylist } from '../ReviewBlock'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export interface MatchedPlaylistsProps {
  matchedTrackPlaylists: Array<ReviewPlaylist>
}

export const MatchedPlaylists: React.FC<MatchedPlaylistsProps> = ({
  matchedTrackPlaylists,
}) => {
  const { t } = useTranslation()

  return (
    <Collapse
      items={[
        {
          label: (
            <AccentText>{t('campaignDetailsPage.trackMatches')}</AccentText>
          ),
          children: (
            <Container>
              {matchedTrackPlaylists.map((playlist) => (
                <PlaylistInfo
                  key={playlist.playlistName}
                  playlistName={playlist.playlistName}
                  playlistUrl={playlist.playlistUrl}
                />
              ))}
            </Container>
          ),
        },
      ]}
    />
  )
}
