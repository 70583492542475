import { FC } from 'react'

import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { SidebarMenuItemProps } from '.'

interface LinkProps {
  $isFeatured: boolean
  $collapsed: boolean
  $disabled?: boolean
}
const commonStyles = css<LinkProps>`
  background-color: #000;
  color: ${({ $isFeatured, theme }) =>
    $isFeatured ? theme.common.primaryDetails : theme.common.secondaryText};
  display: flex;
  align-items: center;
  height: 40px;
  padding: ${({ $collapsed }) => ($collapsed ? '0px 20px' : '0px 16px;')};
  position: relative;
  font-family: 'Segoe UI';
  text-decoration: none;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ $isFeatured, theme }) =>
      $isFeatured ? theme.common.primaryDetails : theme.common.secondaryText};
  }
  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.common.thirdBg};

    color: ${({ $isFeatured, theme }) =>
      $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
    svg {
      fill: ${({ $isFeatured, theme }) =>
        $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
      transition: all 0.3s ease-in-out;
    }
  }
  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.common.secondaryBorder};
    outline-offset: -1px;
    background-color: ${({ theme }) => theme.common.secondaryBg};
  }
  transition: all 0.3s ease-in-out;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.common.primaryText : ''};

  svg {
    fill: ${({ $disabled, theme }) =>
      $disabled ? theme.common.primaryText : ''};
  }
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`

const Link = styled(NavLink)<LinkProps>`
  ${commonStyles}
  &:visited {
    color: ${({ theme, $isFeatured }) =>
      $isFeatured ? theme.common.primaryDetails : theme.common.secondaryText};
  }
  &.active {
    color: ${({ theme, $isFeatured }) =>
      $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
    svg {
      fill: ${({ theme, $isFeatured }) =>
        $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
      path {
        fill: ${({ theme, $isFeatured }) =>
          $isFeatured ? theme.common.primaryDetails : theme.common.fourthText};
      }
    }
  }
  &:active {
    color: ${({ theme }) => theme.common.fourthText};
    svg {
      fill: ${({ theme }) => theme.common.fourthText};
    }
  }
`

const Button = styled.button<LinkProps>`
  ${commonStyles}
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
`
const Text = styled.div<LinkProps>`
  ${commonStyles}
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
`

type SidebarMenuItemTagProps = Pick<
  SidebarMenuItemProps,
  | 'children'
  | 'collapsed'
  | 'target'
  | 'isFeatured'
  | 'path'
  | 'active'
  | 'disabled'
  | 'onClick'
  | 'type'
> & {
  onFocus?: () => void
  onBlur?: () => void
  onMouseLeave?: () => void
  onMouseEnter?: () => void
}

export const SidebarMenuItemTag: FC<SidebarMenuItemTagProps> = (props) => {
  const {
    children,
    collapsed,
    target,
    isFeatured,
    path,
    active,
    type,
    disabled,
    onClick,
    onFocus,
    onBlur,
    onMouseLeave,
    onMouseEnter,
  } = props
  if (type === 'button') {
    return (
      <Button
        $collapsed={Boolean(collapsed)}
        $isFeatured={Boolean(isFeatured)}
        $disabled={disabled}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </Button>
    )
  }
  if (type === 'text') {
    return (
      <Text
        $collapsed={Boolean(collapsed)}
        $isFeatured={Boolean(isFeatured)}
        $disabled={disabled}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </Text>
    )
  }
  return (
    <Link
      target={target}
      $collapsed={Boolean(collapsed)}
      $isFeatured={Boolean(isFeatured)}
      $disabled={disabled}
      to={path ?? '/'}
      className={active ? 'active' : ''}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </Link>
  )
}
