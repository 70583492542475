import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import PlusIcon from 'src/Assets/Svg/roundedPlus.svg?react'
import { getIsLanguageActive } from 'src/Pages/Artist/SpotifySchedulePage/helpers/helpers'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { ModalType, ProperGenreOrLanguage, ScheduleSteps } from 'src/Types'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

const ParametersButton = styled(PrimaryButton)`
  display: flex;
  height: 48px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
`
const SubTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const LanguagesList = styled(SubTitle)`
  font-weight: 400;
`

interface ScheduleLanguagesProps {
  languages: ProperGenreOrLanguage[]
  isAddButtonDisabled?: boolean
}

export const SpotifyScheduleLanguage: FC<ScheduleLanguagesProps> = ({
  languages,
  isAddButtonDisabled,
}) => {
  const { setSearch } = useCustomSearchParams()
  const { t } = useTranslation()

  const isLanguageActive = getIsLanguageActive(languages)

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Languages,
    }
    setSearch(query)
  }

  if (isLanguageActive) {
    return (
      <ParametersButton disabled={isAddButtonDisabled} onClick={handleClick}>
        <PlusIcon width={10} height={10} />
        {t('scheduleCampaignPage.addVocalLanguage')}
      </ParametersButton>
    )
  }

  const lang = languages.find((item) => item.id !== ANY_LANGUAGE_ID)
  const isHaveAnyLanguage = languages.some(
    (item) => item.id === ANY_LANGUAGE_ID,
  )
  const matchText = isHaveAnyLanguage
    ? t('scheduleCampaignPage.matchLowerOther')
    : t('scheduleCampaignPage.matchLowerOnly')

  const language = `${capitalizeFirstLetter(lang?.name ?? '')} ${matchText}`
  return (
    <Container>
      <Wrapper>
        <SubTitle>{t('scheduleCampaignPage.vocalLanguage')}</SubTitle>
        <LanguagesList translate='no'>{language}</LanguagesList>
      </Wrapper>
      <PrimaryDottedButton onClick={handleClick}>
        {t('scheduleCampaignPage.edit')}
      </PrimaryDottedButton>
    </Container>
  )
}
