import { FC } from 'react'

import { t } from 'i18next'

import { GiftIcon } from 'src/Assets/Svg'
import SpotifyIcon from 'src/Assets/Svg/spotify.svg?react'
import { ReviewDetails } from 'src/Redux/transactions-process/curatorBalanceHistorySlice'
import { ReviewerBalanceHistory } from 'src/Constants/enums'

import {
  Description,
  WithdrawText,
  TextWrapper,
  Text,
  SubText,
  IconWrapper,
} from '../styles'

import { getDescriptionCellContent } from './helpers'

interface DescriptionCellProps {
  type?: string
  description?: string
  reviewDetails: ReviewDetails | undefined
}

export const DescriptionCell: FC<DescriptionCellProps> = ({
  type,
  description,
  reviewDetails,
}) => {
  const { story, showSpotifyIcon, showGiftIcon, showWithdrawText, subtext } =
    getDescriptionCellContent(type, description, reviewDetails)

  return (
    <Description isTop={type === ReviewerBalanceHistory.WITHDRAW}>
      {showGiftIcon && (
        <IconWrapper>{showGiftIcon && <GiftIcon width={'16px'} />}</IconWrapper>
      )}

      {showSpotifyIcon && <SpotifyIcon width={'16px'} />}

      <TextWrapper>
        <Text>{story}</Text>
        {showWithdrawText && (
          <WithdrawText>{t('balancePage.theArtistClaimed')}</WithdrawText>
        )}
        {subtext && <SubText>{subtext}</SubText>}
      </TextWrapper>
    </Description>
  )
}
