import { useEffect } from 'react'

import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { ScheduleSteps } from 'src/Types'
import { spotifyTotalPriceSelector } from 'src/Redux/spotifyCampaignMatchingProcess'

export const useCheckSpotifyCheckoutStepIsAllowed = (): void => {
  const { search, setSearch } = useCustomSearchParams()

  const step = search.get('step')
  const paymentStatus = search.get('paymentStatus')
  const successPaymentStatus = paymentStatus === 'success'
  const spotifyTotalPrice = useAppSelector(spotifyTotalPriceSelector)
  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)

  useEffect(() => {
    const isSpotifyCampaignIsNotValid =
      (!spotifyCampaignDetails?.id || spotifyCampaignDetails?.id === 0) &&
      spotifyTotalPrice === null

    if (
      step === ScheduleSteps.CHECKOUT &&
      isSpotifyCampaignIsNotValid &&
      !successPaymentStatus
    ) {
      setSearch({ step: ScheduleSteps.SETTINGS })
    }
  }, [
    spotifyTotalPrice,
    successPaymentStatus,
    step,
    setSearch,
    spotifyCampaignDetails?.id,
  ])
}
