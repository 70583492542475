import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import Button from 'src/Components/Buttons/Button'
import { playlistRequirementsLink } from 'src/Constants/links'
import track from 'src/Assets/Png/track.png'
import { isEmpty } from 'src/Helpers/layoutHelpers'
import { MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { PlayListCardProps } from './props'
import { PlaylistGenres } from './PlaylistGenres'
import { PlaylistStatus } from './PlaylistStatus'
import { PlaylistStats } from './PlaylistStats'

import {
  PlaylistCover,
  MainInfo,
  Text,
  TableRow,
  TableData,
  TableDataMobile,
  MobileCard,
  MobileCardRightContent,
  ButtonWrapper,
  ContactSupportBtn,
} from './styles'

const PlayListCard: FC<PlayListCardProps> = ({
  playListName,
  followers,
  onGenresEdit,
  playlistID,
  selectedGenres,
  meetConditions,
  playlistCover,
  score,
  stats,
  extraScore,
  status,
  isNewBonusSystem,
}) => {
  const { t } = useTranslation()

  const isDesktop = useMediaQuery({ minWidth: MAX_TABLET_WIDTH })

  const playlistValue = useMemo(
    () => (isNewBonusSystem ? followers : score + extraScore),
    [isNewBonusSystem, score, extraScore, followers],
  )

  const playlistValueName = isNewBonusSystem ? 'Followers' : 'Score'

  if (isDesktop) {
    return (
      <TableRow>
        <TableData width='56px'>
          <PlaylistCover
            alt='playlist-cover'
            src={playlistCover}
            fallbackSrc={track}
          />
        </TableData>
        <TableData width='154px'>
          <MainInfo>
            <Text>{playListName}</Text>
            <Text>
              <span>{playlistValueName}: </span>
              {playlistValue}
            </Text>
          </MainInfo>
        </TableData>
        <TableData width='152px'>
          {isNewBonusSystem ? null : (
            <PlaylistStats stats={stats} followers={followers} />
          )}
        </TableData>
        <TableData fillProp>
          <PlaylistGenres
            selectedGenres={selectedGenres}
            meetConditions={meetConditions}
          />
        </TableData>
        <TableData width='104px'>
          <PlaylistStatus
            meetConditions={meetConditions}
            selectedGenres={selectedGenres}
            status={status}
          />
        </TableData>
        <TableData width='120px' center>
          {meetConditions ? (
            <Button
              type='white'
              onClick={() => onGenresEdit(playlistID, false)}
            >
              {t('playlistsPage.edit')}
            </Button>
          ) : (
            <ContactSupportBtn href={playlistRequirementsLink} target='_blank'>
              {t('playlistsPage.learnMore')}
            </ContactSupportBtn>
          )}
        </TableData>
      </TableRow>
    )
  }

  return (
    <MobileCard>
      <PlaylistCover
        alt='playlist-cover'
        src={playlistCover}
        fallbackSrc={track}
      />
      <MobileCardRightContent>
        <TableDataMobile>
          <Text>{playListName}</Text>
          <Text>
            <span>{playlistValueName}: </span>
            {playlistValue}
          </Text>
        </TableDataMobile>

        {isNewBonusSystem ? null : (
          <TableDataMobile>
            <PlaylistStats stats={stats} followers={followers} />
          </TableDataMobile>
        )}

        <TableDataMobile>
          <PlaylistGenres
            selectedGenres={selectedGenres}
            meetConditions={meetConditions}
          />
        </TableDataMobile>
        {!isEmpty(selectedGenres) && meetConditions && (
          <TableDataMobile>
            <PlaylistStatus
              meetConditions={meetConditions}
              selectedGenres={selectedGenres}
              status={status}
            />
          </TableDataMobile>
        )}
        <TableDataMobile>
          {meetConditions ? (
            <ButtonWrapper>
              <Button
                type='white'
                onClick={() => onGenresEdit(playlistID, false)}
              >
                {t('playlistsPage.edit')}
              </Button>
            </ButtonWrapper>
          ) : (
            <ContactSupportBtn href={playlistRequirementsLink} target='_blank'>
              {t('playlistsPage.contactSupport')}
            </ContactSupportBtn>
          )}
        </TableDataMobile>
      </MobileCardRightContent>
    </MobileCard>
  )
}

export default PlayListCard
