import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { IconButton } from 'src/Components/Buttons/IconButton'
import RefreshIcon from 'src/Assets/Svg/refresh.svg?react'
import { PrimaryLoading } from 'src/Components/PrimaryLoading'

import { TracksTable } from './components/TracksTable'
import { useSoundsPageAnalytics } from './useSoundsPageAnalytics'
import { WarningModal } from './components/WarningModal'
import { ErrorModal } from './components/ErrorModal'
import { useRefreshData } from './hooks/useRefreshData'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 80px;
  }
`
const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize28};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  border-radius: 16px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    border: none;
    box-shadow: unset;
    padding: 0;
    gap: 40px;
  }
`

export const SoundsPage: FC = () => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()
  const isErrorModalVisible = search.get('errorModalVisible') === 'true'

  useSoundsPageAnalytics()

  const [onRefreshClick, loading] = useRefreshData()

  return (
    <PageContainer>
      <Container>
        <TitleWrapper>
          <Title>{t('soundsPage.pageTitle')}</Title>
          <IconButton onClick={onRefreshClick}>
            {loading && <PrimaryLoading fontSize={16} />}
            {!loading && <RefreshIcon width={16} height={16} />}
          </IconButton>
        </TitleWrapper>
        {isErrorModalVisible && <ErrorModal />}
        <TracksTable />
      </Container>
      <WarningModal />
    </PageContainer>
  )
}
