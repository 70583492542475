import styled from 'styled-components'
import { Modal } from 'antd'

import { colors, device, variables } from 'src/Styled/variables'

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 16.5px 24px;
    background-color: ${colors.messegesRed};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-title {
    color: ${colors.white};
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ModalBody = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 20px;
`
export const BoldText = styled.span`
  font-weight: ${variables.fontWeight600};
`

export const FormGroup = styled.div`
  margin-bottom: 0;
  width: 100%;
  position: relative;
`
export const FormGroupLabel = styled.span`
  margin-bottom: 8px;
  display: inline-block;
  color: ${colors.mainText};
`
export const ButtonWrapper = styled.div`
  padding: 20px 20px 40px;
  margin-left: auto;
  width: 100%;
  margin-top: 0;
  display: flex;
  gap: 15px;
  justify-content: flex-end;

  flex-direction: column;
  @media ${device.mobileMin} {
    flex-flow: row nowrap !important;
    width: 50%;
    & > div:first-child {
      width: 60%;
    }
  }
`

export const FormGroupField = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: auto;
  input {
    width: 100%;
    border: 1px solid ${colors.greyStroke};
    border-radius: 8px;
    height: 47px;
    background-color: ${colors.white};
    padding: 13px 16px;
    font-size: ${variables.fontSize14};
    line-height: 21px;
  }
`
