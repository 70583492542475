import { useCallback, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { couponCheckStatusIsSucceededSelector } from 'src/Redux/schedule-process/couponSlice/selectors'
import { checkCoupon } from 'src/Redux/schedule-process/couponSlice/api-actions'
import { usePayPalTransactionPayment } from 'src/Hooks/usePayPalTransactionPayment'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { CampaignPlatformTypeEnum, ScheduleSteps } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { updateCampaignPrice } from 'src/Redux/schedule-process/audienceTiktokSlice'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { useGetBillingAgreementQuery } from 'src/Redux/billingAgreementApi'

import { useChangeStartDate } from './useChangeStartDate'

export const usePayPalFirstTransactionPaymentTikTok = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()
  const params = useParams()

  const autopay = search.get('autopay')
  const startDate = search.get('startDate')
  const coupon = search.get('coupon')
  const error = search.get('error')
  const price = search.get('price')
  const token = Boolean(search.get('token'))

  const { pay } = usePayPalTransactionPayment(CampaignPlatformTypeEnum.TIKTOK)
  const { handleChangeDate } = useChangeStartDate()

  const campaignId = Number(params.number)
  const checkoutStepUrl = `/campaigns/scheduleTikTok/${campaignId}?step=${ScheduleSteps.CHECKOUT}`

  const couponCheckStatusIsSucceeded = useAppSelector(
    couponCheckStatusIsSucceededSelector,
  )
  const scheduledTikTokCampaign = useAppSelector(getScheduledTikTokCampaign)
  const { data: billingData } = useGetBillingAgreementQuery()
  const tiktokPrice = useAppSelector(getTiktokPrice)

  const setInitCampaignDataFromUrl = useCallback(() => {
    // set start date from url
    !scheduledTikTokCampaign.startDate &&
      startDate &&
      handleChangeDate(startDate)

    // set coupon from url
    coupon &&
      !couponCheckStatusIsSucceeded &&
      void dispatch(checkCoupon(coupon))

    dispatch(updateCampaignPrice(Number(price)))
  }, [
    coupon,
    couponCheckStatusIsSucceeded,
    dispatch,
    handleChangeDate,
    price,
    scheduledTikTokCampaign.startDate,
    startDate,
  ])

  // If BA creation was canceled or failed
  useEffect(() => {
    if (error === 'save_token_error_campaign_payment') {
      setInitCampaignDataFromUrl()
      navigate(
        `${checkoutStepUrl}&payment=cancelled&token=${token}&isPayPalSaved=true`,
      )
    }
  }, [checkoutStepUrl, error, navigate, setInitCampaignDataFromUrl, token])

  // If BA token created and campaign should be paid
  useEffect(() => {
    if (autopay) {
      setInitCampaignDataFromUrl()

      const hasDataForPayment =
        Boolean(billingData?.id) && scheduledTikTokCampaign.id

      const isConditionsForPayment =
        hasDataForPayment && scheduledTikTokCampaign.startDate && tiktokPrice

      isConditionsForPayment && pay()
    }
  }, [
    autopay,
    billingData?.id,
    pay,
    scheduledTikTokCampaign.id,
    scheduledTikTokCampaign.startDate,
    setInitCampaignDataFromUrl,
    tiktokPrice,
  ])
}
