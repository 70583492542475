import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  getIsRecipient,
  getTransactionsError,
} from 'src/Redux/transactions-process/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { useCuratorOrderPayoutMutation } from 'src/Redux/orderPayoutApi'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'

import { StyledPrimaryDottedButton } from './styles'

export const BalanceAlerts: FC = () => {
  const { t } = useTranslation()
  const transactionsError = useAppSelector(getTransactionsError)
  const [_, { isError }] = useCuratorOrderPayoutMutation({
    fixedCacheKey: 'CuratorOrderPayout',
  })

  const recipient = useSelector(getIsRecipient)
  const navigate = useNavigate()

  const handleAddBankDetails = (): void => {
    navigate('/settings')
  }

  if (transactionsError || isError) {
    return (
      <PrimaryAlert type='danger' hasCross={false}>
        <div>{t('balancePage.Oops')}</div>
      </PrimaryAlert>
    )
  }
  if (!recipient && recipient !== null) {
    return (
      <PrimaryAlert
        type='warning'
        hasCross={false}
        title='Please add your bank details to request payouts.'
        renderButtons={() => (
          <StyledPrimaryDottedButton onClick={handleAddBankDetails}>
            Add bank details
          </StyledPrimaryDottedButton>
        )}
      >
        <div>{t('balancePage.onceYouHave')}</div>
      </PrimaryAlert>
    )
  }
  return null
}
