import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { campaignsList, influencerBoard, tracksFeed } from 'src/Router/routes'

import { SidebarMenuItem } from '../SidebarMenuItem'
import { SidebarProps } from '../..'

import { MenuItemsFactory } from './menuItemsFactory'

const Container = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  transform: ${({ $collapsed }) =>
    $collapsed ? 'translateY(-220px)' : 'translateY(0)'};
  transition: transform 1s ease-out;
`
interface SidebarMenuContainerProps
  extends Pick<SidebarProps, 'collapsed' | 'role'> {
  onMenuItemClick?: () => void
  hasPlan: boolean
  isNewBonusSystem: boolean
}

export const SidebarMenuContainer: FC<SidebarMenuContainerProps> = (props) => {
  const { collapsed, onMenuItemClick, hasPlan, role, isNewBonusSystem } = props
  const menuItems = useMemo(
    () => new MenuItemsFactory(role, hasPlan, isNewBonusSystem).getItems(),
    [role, hasPlan, isNewBonusSystem],
  )
  const { pathname } = useLocation()
  return (
    <Container $collapsed={collapsed}>
      {menuItems.map((item) => {
        const isRoute = [campaignsList, influencerBoard, tracksFeed].includes(
          String(item.path),
        )
        const active = (pathname === '/' || pathname === '/login/') && isRoute
        return (
          <SidebarMenuItem
            key={item.path}
            active={active}
            collapsed={collapsed}
            onClick={onMenuItemClick}
            {...item}
          >
            {item.name}
          </SidebarMenuItem>
        )
      })}
    </Container>
  )
}
