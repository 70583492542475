import { createSlice } from '@reduxjs/toolkit'

import { setApiError } from 'src/Hooks/redux'
import { PlaylistIconData } from 'src/Pages/Curator/RankPage/types'

import { ApiError, NameSpace } from '../types'

import { getLevelsWithPlaylists } from './api-actions'
export interface Level {
  position: number
  score: number
  reward: number
  playlists: Array<PlaylistIconData>
}

export interface LevelsState {
  levels: Array<Level>

  loading: boolean
  error: ApiError | null
}

const initialState: LevelsState = {
  levels: [],

  loading: false,
  error: null,
}

export const levelsProcess = createSlice({
  initialState,
  name: NameSpace.CURATOR_LEVELS,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLevelsWithPlaylists.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLevelsWithPlaylists.fulfilled, (state, action) => {
      state.loading = false
      state.levels = action.payload
    })
    builder.addCase(getLevelsWithPlaylists.rejected, (state, action) => {
      state.loading = false
      state.error = setApiError(action)
    })
  },
})
