import { FunctionComponent, ReactElement, useEffect } from 'react'

import styled from 'styled-components'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import {
  getCreatorTransactions,
  getTransactionsDetails,
} from 'src/Redux/transactions-process/api-actions'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'

import { InfluencerBalanceTitle } from './InfluencerBalanceTitle'
import { InfluencerBalanceTopBlock } from './InfluencerBalanceTopBlock'
import { InfluencerBalanceTable } from './InfluencerBalanceTable'
import { InfluencerBlockedError } from './InfluencerBlockedError/InfluencerBlockedError'

const Container = styled.div`
  display: flex;
  max-width: 1040px;
  padding: 24px 0px;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 42px 0px;
  }
`

export const InfluencerBalancePage: FunctionComponent = (): ReactElement => {
  const dispatch = useAppDispatch()

  const userID = useAppSelector(getUserId)

  useEffect(() => {
    if (userID) {
      pageViewEvent({ userID, currentPageInfo: pageInfo.balance })
      locationEvent()
    }
  }, [userID])

  useEffect(() => {
    void dispatch(getCreatorTransactions(1))
    void dispatch(getTransactionsDetails())
  }, [dispatch])

  return (
    <Container>
      <InfluencerBalanceTitle />
      <InfluencerBlockedError />

      <InfluencerBalanceTopBlock />

      <InfluencerBalanceTable />
    </Container>
  )
}
