import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAppDispatch } from 'src/Hooks/redux'
import { genreAddedEvent, languageAddedEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import {
  genreAddedMpEvent,
  languageAddedMpEvent,
} from 'src/Redux/ga4-process/api-actions'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

interface GenresAnalytics {
  handleGenreAddedEvent: (params: {
    platformName: string
    genreId: string
    genreName: string
  }) => void

  handleLanguageAddedEvent: (params: {
    platformName: string
    languageId: string
    languageName: string
    isAnyLanguage?: boolean
  }) => void
}

export const useGenresAndLanguagesAnalytics = (): GenresAnalytics => {
  const dispatch = useAppDispatch()
  const param = useParams()
  const { data: plan } = useGetSubscriptionQuery()
  const campaignId = Number(param.number)
  const userId = useSelector(getUserId)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const campaignDetails = useSelector(getCampaignToSchedule)
  const scheduledCampaign = useSelector(getScheduledTikTokCampaign)

  const handleGenreAddedEvent = (params: {
    platformName: string
    genreId: string
    genreName: string
  }): void => {
    const campaignType =
      params.platformName === CampaignPlatformTypeEnum.TIKTOK
        ? scheduledCampaign.type
        : campaignDetails.type

    genreAddedEvent({
      campaignId,
      campaignType,
      platformName: params.platformName,
      genreId: params.genreId,
      genreName: params.genreName,
      userId: String(userId),
      campaignNumber: amountOfCampaigns || 1,
      subscriptionPlan: plan?.package?.name || '',
    })

    void dispatch(
      genreAddedMpEvent({
        campaignId,
        campaignPlatform: params.platformName,
      }),
    )
  }

  const handleLanguageAddedEvent = (params: {
    platformName: string
    languageId: string
    languageName: string
    isAnyLanguage?: boolean
  }): void => {
    const campaignType =
      !amountOfCampaigns || amountOfCampaigns === 1
        ? CampaignTypeEnum.FIRST
        : campaignDetails.type

    languageAddedEvent({
      campaignId,
      campaignType,
      platformName: params.platformName,
      languageId: params.languageId,
      languageName: params.languageName,
      userId: String(userId),
      languageOptions: params.isAnyLanguage,
    })

    void dispatch(
      languageAddedMpEvent({
        campaignId: Number(campaignDetails.id) || campaignId,
        campaignPlatform: CampaignPlatformTypeEnum.SPOTIFY,
      }),
    )
  }

  return {
    handleGenreAddedEvent,
    handleLanguageAddedEvent,
  }
}
