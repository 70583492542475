import { FC } from 'react'

import styled from 'styled-components'

import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsEmailBounced,
  getIsEmailConfirmed,
} from 'src/Redux/auth-process/userSlice/selectors'
import { settings } from 'src/Router/routes'
import { PrimarySkeleton } from 'src/Components/Skeletons/PrimarySkeleton'

import { CampaignApprovedAlert } from './CampaignApprovedAlert'
import { EmailConfirmationWarning } from './EmailConfirmationWarning'
import { EmailConfirmationError } from './EmailConfirmationError'

export const CHANGE_EMAIL_LINK = `${settings}/email`
const StyledSkeleton = styled(PrimarySkeleton)`
  border-radius: 16px;
  height: 120px;
`

interface AlertsBlockProps {
  loading: boolean
}

export const AlertsBlock: FC<AlertsBlockProps> = ({ loading }) => {
  const isEmailConfirmed = useAppSelector(getIsEmailConfirmed)
  const isEmailBounced = useAppSelector(getIsEmailBounced)

  if (loading) {
    return <StyledSkeleton data-testid='alertsBlockSkeleton' />
  }

  if (!isEmailConfirmed) {
    return <EmailConfirmationWarning />
  }

  if (isEmailBounced) {
    return <EmailConfirmationError />
  }

  return <CampaignApprovedAlert />
}
