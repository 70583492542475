import React, { FC } from 'react'

import styled from 'styled-components'

import { PrimaryScheduleSubscriptionCashback } from 'src/Components/ScheduleSubscription/PrimaryScheduleSubscriptionCashback'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
`
interface Props {
  price: number
  symbol: string
}
export const PrimaryScheduleSubscription: FC<Props> = ({ price, symbol }) => {
  const { data } = useGetSubscriptionQuery()
  const hasActivePlan = Boolean(
    data?.status === 'active' || data?.status === 'canceled',
  )

  if (hasActivePlan) {
    return (
      <Wrapper>
        <PrimaryScheduleSubscriptionCashback price={price} symbol={symbol} />
      </Wrapper>
    )
  }
  return null
}
