import { FunctionComponent, useState, useMemo } from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { useInProgressSoundsQuery } from 'src/Redux/creatorInProgressSoundsApi'
import { useStoppedSoundsQuery } from 'src/Redux/creatorStoppedSoundsApi'
import Add from 'src/Assets/Svg/common/addIcon.svg?react'
import { closedAlertsWithCanceledEventIdsSelector } from 'src/Redux/canceledCreatorAlertsProcess/selectors'
import { setClosedAlertEventId } from 'src/Redux/canceledCreatorAlertsProcess'

import { SoundsList } from '../index'
import { InProgressSoundsListItem } from '../../SoundsListItem/components/InProgressSoundsListItem'
import { TabKeysEnum } from '../../../types'
import { CampaignCanceledAlert } from '../../CampaignCanceledAlert'
import { SoonContainer, Text } from '../styles'

const StyledCampaignCanceledAlert = styled(CampaignCanceledAlert)`
  margin-bottom: 24px;
`

export const InProgressSoundsList: FunctionComponent = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isInProgressTab = pathname.includes('inProgress')
  const closedAlertsWithCanceledEventIds = useSelector(
    closedAlertsWithCanceledEventIdsSelector,
  )
  const { data: stoppedSoundsData } = useStoppedSoundsQuery()

  const {
    data: inProgressSoundsData,
    isLoading,
    isFetching,
    isError,
  } = useInProgressSoundsQuery(pageNumber)

  const inProgressSounds = inProgressSoundsData?.data || []
  const hasNextPage = inProgressSoundsData?.meta.hasNextPage || false

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  const dontDisplayedAlertsCampaign = useMemo(() => {
    const filteredVideos = stoppedSoundsData?.data.filter(
      (event) => !closedAlertsWithCanceledEventIds.includes(event.id),
    )
    return filteredVideos?.reverse()[0]
  }, [closedAlertsWithCanceledEventIds, stoppedSoundsData?.data])

  const hasSounds = inProgressSounds.length > 0

  const onCanceledAlertClose = (eventId: number): void => {
    dispatch(setClosedAlertEventId(eventId))
  }

  return (
    <SoundsList
      hasNextPage={hasNextPage && isInProgressTab}
      isLoading={isLoading || isFetching}
      onLoadMore={loadMore}
      soundsAmount={inProgressSounds.length}
      tabType={TabKeysEnum.IN_PROGRESS}
      isTikTokProfileError={isError}
    >
      {dontDisplayedAlertsCampaign && (
        <StyledCampaignCanceledAlert
          trackName={dontDisplayedAlertsCampaign?.trackTitle}
          artistName={dontDisplayedAlertsCampaign?.trackAuthorName}
          eventId={dontDisplayedAlertsCampaign?.id}
          onClose={onCanceledAlertClose}
        />
      )}

      {!hasSounds && (
        <SoonContainer>
          <Add />
          <Text>You haven't accepted any sounds yet</Text>
        </SoonContainer>
      )}

      {inProgressSounds.map((item) => (
        <InProgressSoundsListItem
          key={item.id}
          id={item.id}
          trackAuthorName={item.trackAuthorName}
          trackCoverUrl={item.trackCoverUrl}
          trackTitle={item.trackTitle}
          deadline={item.uploadVideoDeadlineDate}
          maxCreatorReward={item.maxCreatorReward}
        />
      ))}
    </SoundsList>
  )
}
