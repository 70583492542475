import dayjs from 'src/Helpers/dayjs'
import { CampaignStatusEnum, Review } from 'src/Types'

export type CampaignMessageType =
  | 'noReviewsNoPlacements'
  | 'hasReviewsNoPlacements'
  | 'hasPlacements'
  | null

interface CampaignDetails {
  id: number
  start_date: string
  Reviews?: Array<Review>
  playlists_adds?: number
  status?: CampaignStatusEnum
}

export const FORTH_DAY = 3

export const useCampaignMessageType = (
  campaignDetails: CampaignDetails | undefined,
  closedIds: number[],
): CampaignMessageType => {
  if (
    !campaignDetails ||
    campaignDetails.status !== CampaignStatusEnum.RUNNING
  ) {
    return null
  }

  const isAfterThreshold =
    dayjs().diff(dayjs(campaignDetails.start_date), 'day') >= FORTH_DAY
  const isClosed = closedIds.includes(campaignDetails.id)
  const hasReviews = Boolean(campaignDetails.Reviews?.length)
  const hasPlacements = Boolean(campaignDetails.playlists_adds)

  if (isClosed) {
    return null
  }

  if (hasPlacements) {
    return 'hasPlacements'
  }

  if (!isAfterThreshold) {
    return null
  }

  if (!hasReviews) {
    return 'noReviewsNoPlacements'
  }

  return 'hasReviewsNoPlacements'
}

