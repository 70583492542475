import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from 'src/Redux'

import { ga } from './api'

export const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers, { getState, endpoint }) => {
    const isCookies = ga && !endpoint?.startsWith
    const token = (getState() as RootState).authentication?.credentials
      ?.AccessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    if (isCookies) {
      headers.set('Cookies', ga)
    }
    return headers
  },
})

export const rtkApi = createApi({
  baseQuery,
  tagTypes: [
    'newSound',
    'inProgressSound',
    'submittedSound',
    'Features',
    'CampaignVideo',
    'newRelease',
    'TikTokMinBudget',
    'TrackFeedDetails',
    'FeedTracks',
    'ReviewedFeedTracks',
    'SmartTracks',
    'ArtistNotifications',
    'CuratorNotifications',
    'Subscription',
    'SpotifyCampaignDetails',
    'Recipient',
    'WiseCurrencies',
  ],
  endpoints: () => ({}),
})
