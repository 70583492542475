import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

export interface ArtistReviewFeedbackBody {
  reviewId: number
  feedbackText?: string
  feedback: boolean | null
}

const artistReviewFeedbackApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    postArtistReviewFeedback: builder.mutation<void, ArtistReviewFeedbackBody>({
      query: ({ reviewId, feedback, feedbackText }) => ({
        url: `${APP.TIKTOK_SERVER}/artist/reviews/${reviewId}/feedback`,
        method: 'POST',
        body: {
          feedback,
          feedbackText,
        },
      }),
      invalidatesTags: ['SpotifyCampaignDetails'],
    }),
  }),
})
export const { usePostArtistReviewFeedbackMutation } = artistReviewFeedbackApi
