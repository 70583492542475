import styled from 'styled-components'

export const BlockOfCards = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  gap: 16px;
  @media (max-width: 1023px) {
    overflow-x: scroll;
  }
  @media (max-width: 575px) {
    flex-direction: row-reverse;
  }
  width: 100%;
  padding: 16px 0;
  border-radius: 16px;
`
