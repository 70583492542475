import React, { FC } from 'react'

import styled from 'styled-components'

import SecureIcon from 'src/Assets/Svg/pages-icons/Secure.svg?react'
import { CampaignPlatformTypeEnum } from 'src/Types'

const StyledPlatformArtistProtectionProgram = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 8px;
  max-width: 400px;

  background: ${({ theme }) => theme.common.primaryBg};
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

const TextTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Text = styled(TextTitle)`
  font-weight: 400;
`

const StyledIcon = styled.div`
  grid-row: span 2;
  align-self: start;
  margin-top: 4px;
`
export interface PlatformArtistProtectionProgramProps {
  currentPlatform: CampaignPlatformTypeEnum
}

export const PlatformArtistProtectionProgram: FC<
  PlatformArtistProtectionProgramProps
> = ({ currentPlatform }) => {
  const artistProtectionText =
    currentPlatform === CampaignPlatformTypeEnum.TIKTOK
      ? `If TikTok creators do not provide videos or if
          the videos violate our policies, you will be automatically credited.`
      : `If playlist curators do not review your track, you will be
          automatically credited.`

  return (
    <StyledPlatformArtistProtectionProgram>
      <StyledIcon>
        <SecureIcon />
      </StyledIcon>
      <TextContainer>
        <TextTitle>Artist protection program</TextTitle>
        <Text>{artistProtectionText}</Text>
      </TextContainer>
    </StyledPlatformArtistProtectionProgram>
  )
}
