import { FC } from 'react'

import styled from 'styled-components'

import { PaymentMethodEnum } from 'src/Constants/enums'

import { SecondaryScheduleSummaryDetailsHeader } from './SecondaryScheduleSummaryDetailsHeader'
import { SecondaryScheduleSummaryDetails } from './SecondaryScheduleSummaryDetails'
import { SecondaryScheduleSummaryPaymentMethod } from './SecondaryScheduleSummaryPaymentMethod'
import { SecondaryScheduleSummaryPromocode } from './SecondaryScheduleSummaryPromocode'
import { SecondaryScheduleSummaryDiscount } from './SecondaryScheduleSummaryDiscount'
import { ScheduleSummaryButton } from './ScheduleSummaryButton'

import { ScheduleSummaryProps } from '.'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (min-width: 768px) {
    max-width: 50%;
  }
  @media screen and (min-width: 1024px) {
    max-width: 60%;
  }
`

export type SecondaryScheduleSummaryProps = ScheduleSummaryProps & {
  cover?: string
  paymentMethod: PaymentMethodEnum
  paypalEmail?: string
  onPaymentMethodChange?: () => void
}

export const SecondaryScheduleSummary: FC<SecondaryScheduleSummaryProps> = (
  props,
) => {
  const {
    trackName,
    artistName,
    platform,
    cover,
    price,
    balance,
    discount,
    discountPercentage,
    vat,
    total,
    onPaymentMethodChange,
    onApplyPromocode,
    paymentMethod,
    paypalEmail,
    showPromocode,
    paymentLoading,
    countdown,
    showTooltip,
    onConfirm,
  } = props
  return (
    <Wrapper>
      <SecondaryScheduleSummaryDetailsHeader
        artistName={artistName}
        trackName={trackName}
        platform={platform}
        cover={cover}
      />
      <SecondaryScheduleSummaryDetails
        discount={discount}
        discountPercentage={discountPercentage}
        price={price}
        balance={balance}
        vat={vat}
        total={total}
      />
      <SecondaryScheduleSummaryPaymentMethod
        paypalEmail={paypalEmail}
        paymentMethod={paymentMethod}
        onPaymentMethodChange={onPaymentMethodChange}
      />
      {showPromocode && (
        <SecondaryScheduleSummaryPromocode
          onApplyPromocode={onApplyPromocode}
        />
      )}
      {discountPercentage && (
        <SecondaryScheduleSummaryDiscount
          discountPercentage={discountPercentage}
        />
      )}
      <ScheduleSummaryButton
        paymentLoading={paymentLoading}
        total={total}
        countdown={countdown}
        showTooltip={showTooltip}
        onConfirm={onConfirm}
        hasFixedPosition
      />
    </Wrapper>
  )
}
