import { FC } from 'react'

import { useAppSelector } from 'src/Hooks/redux'
import { getFilteredPlaylists } from 'src/Redux/playlists-process/selectors'
import { useNewBonusSystem } from 'src/Hooks/useNewBonusSystem'

import PlayListCard from '../../PlayListCard'
import { PlaylistsTabsEnum } from '../PlaylistTabs'

interface IProps {
  active: PlaylistsTabsEnum
  onGenresEdit: (id: number, settingUpNewPlaylist: boolean) => void
}

export const PlaylistsList: FC<IProps> = ({ active, onGenresEdit }) => {
  const playlists = useAppSelector(getFilteredPlaylists(active))
  const { isNewBonusSystem } = useNewBonusSystem()

  return (
    <>
      {playlists.map(
        (item) =>
          item && (
            <PlayListCard
              followers={item.followers}
              key={item.id}
              playListName={item.playlist_name}
              status={item.status}
              playlistID={Number(item.id)}
              meetConditions={item.meets_conditions}
              selectedGenres={item.PlaylistGenres}
              playlistCover={item.playlist_cover}
              score={item.score}
              stats={item.ArtistStats}
              extraScore={item.extra_score}
              onGenresEdit={onGenresEdit}
              isNewBonusSystem={isNewBonusSystem}
            />
          ),
      )}
    </>
  )
}
