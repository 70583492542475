import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { RewardPlaylistCard } from '../RewardPlaylistCard'
import { RewardPlaylist } from '../../types'

import { NoPlaylistsMessage, PlaylistsContainer } from './styles'

interface RewardPlaylistsBlockProps {
  playlists: Array<RewardPlaylist>
}

export const RewardPlaylistsBlock: FC<RewardPlaylistsBlockProps> = ({
  playlists,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 })
  const isPlaylistsExisted = playlists.length > 0

  const showMorePlaylistsCount = playlists.length

  if (!isPlaylistsExisted && isDesktop) {
    return <NoPlaylistsMessage>-</NoPlaylistsMessage>
  }

  const showCount = playlists.length

  return (
    <div>
      <PlaylistsContainer isPlaylistsExisted={isPlaylistsExisted}>
        {playlists.map((item, index) => {
          const isOpener =
            index === showCount - 1 && index !== playlists.length - 1
          return (
            <RewardPlaylistCard
              key={item.id}
              playlist={item}
              isOpener={isOpener}
              showMorePlaylistsCount={showMorePlaylistsCount}
            />
          )
        })}
      </PlaylistsContainer>
    </div>
  )
}
