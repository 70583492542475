import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { ANY_LANGUAGE_ID } from 'src/Constants/constants'

import { RootState } from '../../index'

import { CampaignState } from '.'
const getState = (state: RootState): CampaignState => state.campaigns.main

export const getCampaignToSchedule = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) => campaignToSchedule?.campaign,
)
export const getIsSelectedLanguagesWithoutAny = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    Boolean(
      campaignToSchedule?.campaign?.languages?.filter(
        (lang) => lang.id !== ANY_LANGUAGE_ID,
      ).length,
    ),
)

export const getCampaignToScheduleLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)

export const getSchedulePlatform = createDraftSafeSelector(
  getState,
  ({ schedulePlatform }) => schedulePlatform,
)

export const getGenresListSelector = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    campaignToSchedule.campaign.genres.map((genre) => genre.name).join(),
)

export const getLanguagesListSelector = createDraftSafeSelector(
  getState,
  ({ campaignToSchedule }) =>
    campaignToSchedule.campaign.languages
      .map((language) => language.name)
      .join(),
)
