import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { TiktokCampaignStatus } from 'src/Types'
import { campaignsList } from 'src/Router/routes'
import { useAppDispatch } from 'src/Hooks/redux'
import { getCampaignTikTokCampaignToScheduleDetails } from 'src/Redux/schedule-process/tiktokCampaign/api-actions'
import { PaymentStatusEnum } from 'src/Pages/Artist/SpotifySchedulePage/helpers/useCheckSpotifyCampaignStatus'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { removeCoupon } from 'src/Redux/schedule-process/couponSlice'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

export const useCheckTikTokCampaignStatus = (): void => {
  const dispatch = useAppDispatch()
  const { search } = useCustomSearchParams()
  const params = useParams()
  const navigate = useNavigate()
  const { data } = useGetSubscriptionQuery()
  const hasPlan = Boolean(
    data?.status === 'active' || data?.status === 'canceled',
  )

  const paymentStatus = search.get('paymentStatus')
  const campaignId = Number(params.number)

  const campaignDetailsUrl = `campaigns/details/${campaignId}`
  const isSuccessPayedCampaign = paymentStatus === PaymentStatusEnum.SUCCESS

  useEffect(() => {
    void dispatch(
      getCampaignTikTokCampaignToScheduleDetails({
        tiktokCampaignId: campaignId,
        hasPlan,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(removeCoupon())
        if (!isSuccessPayedCampaign) {
          res.status === TiktokCampaignStatus.SCHEDULED &&
            navigate(campaignsList)
          res.status !== TiktokCampaignStatus.PENDING &&
            navigate(campaignDetailsUrl)
        }
        return res
      })
  }, [
    campaignDetailsUrl,
    campaignId,
    dispatch,
    hasPlan,
    isSuccessPayedCampaign,
    navigate,
  ])
}
