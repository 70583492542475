import { useNavigate } from 'react-router-dom'

import {
  getCookieByName,
  getUtmParameters,
  getValueOrZero,
} from 'src/Constants/functions'
import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getAccessToken } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { setIsFirstCampaign } from 'src/Redux/auth-process/userSlice'
import {
  useCreateNewSpotifyCampaignMutation,
  useCreateNewTiktokCampaignMutation,
} from 'src/Redux/createNewCampaignApi'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import {
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { setTrackToInitialState } from 'src/Redux/track-process'
import {
  campaignsList,
  createCampaign,
  scheduleCampaign,
  scheduleCampaignTikTok,
} from 'src/Router/routes'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  ScheduleSteps,
  TiktokCampaignStatus,
} from 'src/Types'
import {
  SpotifyTrack,
  TikTokTrack,
} from 'src/Pages/Artist/CreateCampaignPage/AuthPages/SelectedTrackAuth/useSelectedTrackAuth'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'

interface ICreateNewCampaign {
  createNewSpotifyCampaign: (track: SpotifyTrack) => void
  createNewTiktokCampaign: (track: TikTokTrack) => void
  loading: boolean
}

export const useCreateNewCampaign = (): ICreateNewCampaign => {
  const token = useAppSelector(getAccessToken)

  const [createSpotifyCampaign, { isLoading: isSpotifyLoading }] =
    useCreateNewSpotifyCampaignMutation()
  const [createTiktokCampaign, { isLoading: isTiktokLoading }] =
    useCreateNewTiktokCampaignMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    createCampaignEventHandler,
    createdTikTokCampaignEventHandler,
    createTiktokCampaignEventHandler,
    campaignDetailsEventHandler,
    createdSpotifyCampaignEventHandler,
  } = useCreateCampaignEvents()

  const sendSourceBusterData = (
    id: number,
    campaignPlatform: CampaignPlatformTypeEnum,
  ): void => {
    const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

    void dispatch(
      sendSourceBusterEvent({
        token,
        campaignPlatform,
        campaignId: id,
        eventName: 'create_basic_campaign',
        gclid: utmObj.gclid || '',
      }),
    )
  }

  const createNewSpotifyCampaign = async (
    spotifyTrack: SpotifyTrack,
  ): Promise<void> => {
    dispatch(setIsFirstCampaign(false))
    if (!spotifyTrack) {
      return
    }
    await createSpotifyCampaign({
      trackName: getValueOrEmptyString(spotifyTrack?.trackName),
      trackImage: getValueOrEmptyString(spotifyTrack?.trackCoverUrl),
      trackLength: getValueOrZero(spotifyTrack?.trackDurationInMs),
      artistName: getValueOrEmptyString(spotifyTrack?.artistName),
      artistSpotifyId: getValueOrEmptyString(spotifyTrack?.artistSpotifyId),
      trackSpotifyId: getValueOrEmptyString(spotifyTrack?.trackId),
      withApprovalStep: false,
      pageUrl: window.location.href,
      trackUrl: `https://api.spotify.com/v1/tracks/${spotifyTrack.trackId}`,
      artistUrl: `https://open.spotify.com/artist/${spotifyTrack.artistSpotifyId}`,
    })
      .unwrap()
      .then((response) => {
        createCampaignEventHandler({ campaignId: response.id })

        createdSpotifyCampaignEventHandler({
          campaignId: response.id,
          isFeedCampaign: response.is_feed,
          spotifyTrackId: response.Track.track_id,
        })

        campaignDetailsEventHandler({
          campaignType: response.type,
          platformType: CampaignPlatformTypeEnum.SPOTIFY,
        })

        sendSourceBusterData(response.id, CampaignPlatformTypeEnum.SPOTIFY)

        dispatch(setTracksToInitialState())

        if (response.status === CampaignStatusEnum.APPROVED) {
          navigate(
            `${campaignsList}${scheduleCampaign}/${response.id}?step=${ScheduleSteps.SETTINGS}`,
          )
        } else {
          navigate(
            `${campaignsList}${createCampaign}/${CampaignPlatformTypeEnum.SPOTIFY}?successCreatedCampaign=true`,
          )
        }
        dispatch(setTrackToInitialState())
        return response
      })
  }

  const createNewTiktokCampaign = async (
    tikTokTrack: TikTokTrack,
  ): Promise<void> => {
    await createTiktokCampaign({
      initialVideoCount: getValueOrZero(tikTokTrack.initialVideoCount),
      trackAuthorName: getValueOrEmptyString(tikTokTrack.artistName),
      trackCoverUrl: getValueOrEmptyString(tikTokTrack.trackCoverUrl),
      trackDuration: getValueOrZero(tikTokTrack.trackDurationInMs),
      trackPlayUrl: getValueOrEmptyString(tikTokTrack.trackPreviewUrl),
      trackTiktokId: getValueOrEmptyString(tikTokTrack.trackId),
      trackTitle: getValueOrEmptyString(tikTokTrack.trackName),
      trackUrl: getValueOrEmptyString(tikTokTrack.trackUrl),
    })
      .unwrap()
      .then((response) => {
        createCampaignEventHandler({ campaignId: response.id })
        createdTikTokCampaignEventHandler({
          campaignId: response.id,
          trackTiktokId: response.trackTiktokId,
        })
        createTiktokCampaignEventHandler({ campaignId: response.id })
        campaignDetailsEventHandler({
          campaignType: response.type,
          platformType: CampaignPlatformTypeEnum.TIKTOK,
        })

        sendSourceBusterData(
          Number(response.id),
          CampaignPlatformTypeEnum.TIKTOK,
        )

        dispatch(setTiktokTracksToInitialState())
        dispatch(setTiktokTrackToInitialState())
        if (response.status === TiktokCampaignStatus.PENDING) {
          navigate(
            `${campaignsList}${scheduleCampaignTikTok}/${response.id}?step=${ScheduleSteps.SETTINGS}`,
          )
        } else {
          navigate(
            `${campaignsList}${createCampaign}/${CampaignPlatformTypeEnum.TIKTOK}?successCreatedCampaign=true`,
          )
        }

        return response
      })
  }
  return {
    createNewSpotifyCampaign,
    createNewTiktokCampaign,
    loading: isSpotifyLoading || isTiktokLoading,
  }
}
