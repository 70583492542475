import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { NameSpace } from 'src/Redux/types'

export interface GetPremiumState {
  closedEventIds: Array<number>
}
const initialState: GetPremiumState = {
  closedEventIds: [],
}

export const canceledCreatorAlerts = createSlice({
  initialState,
  name: NameSpace.CANCELED_CREATOR_ALERTS,
  reducers: {
    setClosedAlertEventId(state, { payload }: PayloadAction<number>) {
      state.closedEventIds = [...state.closedEventIds, payload]
    },
  },
})

export const { setClosedAlertEventId } = canceledCreatorAlerts.actions
