import React, { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import IconSuccessfully from 'src/Assets/Svg/successfully.svg?react'
import { colors, variables } from 'src/Styled/variables'
import Button from 'src/Components/Buttons/Button'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { CrossButton } from 'src/Components/Buttons/CrossButton'
import { sounds } from 'src/Router/routes'

import { TabKeysEnum } from '../../types'

import { DateInfo } from './components/DateInfo'

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.white};
  padding: 124px 16px 40px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 560px;
  max-width: 560px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`
export const StyledButton = styled(Button)`
  width: 210px;
`
export const CloseCrossButton = styled(CrossButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`

interface SuccessSubmitModalProps {
  soundId: number
}

export const SuccessSubmitModal: FC<SuccessSubmitModalProps> = ({
  soundId,
}) => {
  const { t } = useTranslation()
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()
  const creditedDate = search.get('creditedDate')

  const onTrackProgressButtonClick = (): void => {
    navigate(`${sounds}/${TabKeysEnum.SUBMITTED}/${soundId}`)
  }
  const onExploreButtonClick = (): void => {
    navigate(`${sounds}/${TabKeysEnum.NEW}`)
  }
  const closeClick = (): void => {
    navigate(`${sounds}/${TabKeysEnum.IN_PROGRESS}`)
  }

  return (
    <Container>
      <ContentWrapper>
        <IconSuccessfully width={40} height={40} />

        <DescriptionWrapper>
          <Title>{t('soundsPage.successfullySubmitted')}</Title>
          <span>
            <span>{t('soundsPage.successfullySubmittedDescription')}</span>{' '}
            <DateInfo date={creditedDate} />
          </span>
        </DescriptionWrapper>

        <ButtonsWrapper>
          <StyledButton type={'white'} onClick={onTrackProgressButtonClick}>
            {t('soundsPage.trackProgressButton')}
          </StyledButton>
          <StyledButton type={'green'} onClick={onExploreButtonClick}>
            {t('soundsPage.exploreButton')}
          </StyledButton>
        </ButtonsWrapper>
      </ContentWrapper>

      <CloseCrossButton onClick={closeClick} />
    </Container>
  )
}
