import { Roles } from 'src/Constants/enums'
import i18n from 'src/Localization/i18n'
import {
  subscription,
  campaignsList,
  referAndEarn,
  balance,
} from 'src/Router/routes'
import Campaign from 'src/Assets/Svg/sidebar-icons/Campaign.svg?react'
import Money from 'src/Assets/Svg/sidebar-icons/Money.svg?react'
import Question from 'src/Assets/Svg/sidebar-icons/Question.svg?react'
import ReferAndEarn from 'src/Assets/Svg/sidebar-icons/ReferAndEarn.svg?react'
import Playlists from 'src/Assets/Svg/sidebar-icons/Playlists.svg?react'
import Listening from 'src/Assets/Svg/sidebar-icons/Listening.svg?react'
import Rank from 'src/Assets/Svg/sidebar-icons/Rank.svg?react'
import Reward from 'src/Assets/Svg/sidebar-icons/Reward.svg?react'
import Sounds from 'src/Assets/Svg/sidebar-icons/Sounds.svg?react'
import Monetization from 'src/Assets/Svg/sidebar-icons/Monetization.svg?react'
import { CuratorsRouteEnum } from 'src/Router/CuratorComponentsNewUI'
import { CreatorsRouteEnum } from 'src/Router/CreatorComponentsNewUI'

import { SidebarMenuItemProps } from '../SidebarMenuItem'
const BALANCE = 'sidebar.balance'

type MenuItem = Omit<
  SidebarMenuItemProps,
  'children' | 'onClick' | 'collapsed' | 'active' | 'target'
> & {
  name: string
}

export class MenuItemsFactory {
  menuItems: MenuItem[] = []

  helpCenterMenuItem = {
    name: 'Help center',
    icon: Question,
    path: 'https://help.soundcamps.com/en/',
    isFeatured: false,
    target: '_blank',
  }
  balanceMenuItem = {
    name: i18n.t(BALANCE),
    icon: Money,
    path: balance,
    isFeatured: false,
  }
  artistMenuItems: MenuItem[] = [
    {
      name: i18n.t('sidebar.campaigns'),
      icon: Campaign,
      path: campaignsList,
      isFeatured: false,
    },
    {
      name: i18n.t('sidebar.referAndEarn'),
      icon: ReferAndEarn,
      path: referAndEarn,
      isFeatured: false,
    },

    {
      name: i18n.t(BALANCE),
      icon: Money,
      path: balance,
      isFeatured: false,
    },
  ]

  curatorMenuItemsWithOldBonusSystem: MenuItem[] = [
    {
      name: i18n.t('sidebar.tracksFeed'),
      icon: Listening,
      path: CuratorsRouteEnum.Tracks,
      isFeatured: false,
    },
    {
      name: i18n.t('sidebar.rank'),
      icon: Rank,
      path: CuratorsRouteEnum.Rank,
      isFeatured: false,
    },
    this.balanceMenuItem,
    {
      name: i18n.t('sidebar.playlists'),
      icon: Playlists,
      path: CuratorsRouteEnum.Playlists,
      isFeatured: false,
    },
    this.helpCenterMenuItem,
  ]

  curatorMenuItemsWithNewBonusSystem: MenuItem[] = [
    {
      name: i18n.t('sidebar.tracksFeed'),
      icon: Listening,
      path: CuratorsRouteEnum.Tracks,
      isFeatured: false,
    },

    this.balanceMenuItem,
    {
      name: i18n.t('sidebar.playlists'),
      icon: Playlists,
      path: CuratorsRouteEnum.Playlists,
      isFeatured: false,
    },
    {
      name: i18n.t('sidebar.rewards'),
      icon: Reward,
      path: CuratorsRouteEnum.Reward,
      isFeatured: false,
    },
    this.helpCenterMenuItem,
  ]

  creatorMenuItems: MenuItem[] = [
    {
      name: i18n.t('sidebar.sounds'),
      icon: Sounds,
      path: CreatorsRouteEnum.Sounds,
      isFeatured: false,
    },

    this.balanceMenuItem,
    {
      name: i18n.t('sidebar.monetization'),
      icon: Monetization,
      path: CreatorsRouteEnum.MonetizationRoute,
      isFeatured: false,
    },
    this.helpCenterMenuItem,
  ]

  role: Roles = Roles.artist
  hasPlan = false
  isNewBonusSystem: boolean

  constructor(role: Roles, hasPlan: boolean, isNewBonusSystem: boolean) {
    this.role = role
    this.hasPlan = hasPlan
    this.isNewBonusSystem = isNewBonusSystem || false
    this.buildMenuItems()
  }

  public getItems(): MenuItem[] {
    return this.menuItems
  }

  private getArtistNavigationList(): MenuItem[] {
    return this.hasPlan
      ? this.getArtistNavigationListWithPlan()
      : this.artistMenuItems
  }

  private getArtistNavigationListWithPlan(): MenuItem[] {
    return this.artistMenuItems.filter((item) => item.path !== subscription)
  }

  private getCuratorNavigationList(): MenuItem[] {
    return this.isNewBonusSystem
      ? this.curatorMenuItemsWithNewBonusSystem
      : this.curatorMenuItemsWithOldBonusSystem
  }

  private buildMenuItems(): void {
    switch (this.role) {
      case Roles.artist: {
        const items = this.getArtistNavigationList()
        this.menuItems = [...items, this.helpCenterMenuItem]
        break
      }
      case Roles.curator:
        this.menuItems = this.getCuratorNavigationList()
        break
      case Roles.influencer:
        this.menuItems = this.creatorMenuItems
        break
      default:
        this.menuItems = []
    }
  }
}
