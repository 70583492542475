import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from '..'

import { WiseState } from '.'

const getState = (state: RootState): WiseState => state.transferwise

export const getRequirements = createDraftSafeSelector(
  getState,
  ({ requirements }) => requirements,
)
export const getWiseLoading = createDraftSafeSelector(
  getState,
  ({ loading }) => loading,
)
