import { combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer'

import { subscriptionFromLocationSlice } from './subscriptionFromLocation'

const subscriptions = combineReducers({
  subscriptionFromLocationSlice: subscriptionFromLocationSlice.reducer,
})
const persistConfig = {
  storage,
  key: 'subscriptionFromLocation',
}
export const subscriptionsProcess = persistReducer(persistConfig, subscriptions)
