import { FC } from 'react'

import styled from 'styled-components'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import SuccessIcon from 'src/Assets/Svg/pages-icons/Success.svg?react'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'

const SuccessContainer = styled.div`
  display: flex;
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 0 auto;
  height: 100vh;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 0 16px;
  }
`

const SuccessTitle = styled.h2`
  font-family: 'Segoe UI';
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const SuccessDescription = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
`

const StyledButton = styled(PrimaryButton)`
  max-width: 225px;
  width: 100%;
`

interface SuccessModalProps {
  open: boolean
  onSuccessCloseClick: () => void
}

export const ReviewSuccessModal: FC<SuccessModalProps> = ({
  open,
  onSuccessCloseClick,
}) => (
  <FullScreenModal onCancel={onSuccessCloseClick} open={open} showCloseButton>
    <SuccessContainer>
      <SuccessIcon width={40} height={40} />

      <TextContainer>
        <SuccessTitle>Thank you for your feedback!</SuccessTitle>
        <SuccessDescription>
          We appreciate you taking the time to share your thoughts. Your input
          helps us improve and create a better experience for you.
        </SuccessDescription>
      </TextContainer>

      <StyledButton onClick={onSuccessCloseClick}>
        Go to Campaign details
      </StyledButton>
    </SuccessContainer>
  </FullScreenModal>
)
