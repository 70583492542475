import {
  FC,
  HTMLAttributeAnchorTarget,
  PropsWithChildren,
  SVGProps,
} from 'react'

import { SidebarMenuItemContent } from './SidebarMenuItemContent'
import { SidebarMenuItemPopover } from './SidebarMenuItemPopover'
import { SidebarMenuItemTag } from './SidebarMenuItemTag'

export interface SidebarMenuItemProps extends PropsWithChildren {
  icon: FC<SVGProps<SVGSVGElement>>
  path?: string
  collapsed: boolean
  active?: boolean
  target?: HTMLAttributeAnchorTarget
  isFeatured?: boolean
  disabled?: boolean
  type?: 'button' | 'link' | 'text'
  onClick?: () => void
}

export const SidebarMenuItem: FC<SidebarMenuItemProps> = (props) => {
  const {
    icon,
    children,
    path,
    onClick,
    collapsed,
    active,
    disabled,
    isFeatured,
    type = 'link',
    target = '_self',
  } = props
  return (
    <SidebarMenuItemPopover
      disabled={disabled}
      collapsed={collapsed}
      isFeatured={isFeatured}
      content={children}
      active={active}
    >
      <SidebarMenuItemTag
        type={type}
        collapsed={collapsed}
        disabled={disabled}
        isFeatured={isFeatured}
        path={path}
        active={active}
        target={target}
        onClick={onClick}
      >
        <SidebarMenuItemContent icon={icon} collapsed={collapsed}>
          {children}
        </SidebarMenuItemContent>
      </SidebarMenuItemTag>
    </SidebarMenuItemPopover>
  )
}
