import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import {
  getInfluencerId,
  getUser,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCookieByName } from 'src/Constants/functions'
import { setGcidToUser } from 'src/Redux/auth-process/userSlice/api-actions'
import {
  getIsArtist,
  getIsAuth,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { useAppDispatch } from 'src/Hooks/redux'

const SLICE_NUMBER = 6

export const useAnalyticsCookies = (): null => {
  const dispatch = useAppDispatch()

  const userId = useSelector(getUserId)
  const userCreatorId = useSelector(getInfluencerId)
  const isArtist = useSelector(getIsArtist)
  const user = useSelector(getUser)
  const isAuth = useSelector(getIsAuth)

  useEffect(() => {
    if (userId) {
      Cookies.set('sondcsrd', String(userId))
    }
    userCreatorId && Cookies.set('sondcsrd', String(userCreatorId))
  }, [userId, userCreatorId])

  useEffect(() => {
    if (!user.gcid) {
      const fullGcid = getCookieByName('_ga')
      const gcid = fullGcid ? fullGcid.slice(SLICE_NUMBER) : null

      if (gcid && isAuth && isArtist !== null) {
        void dispatch(setGcidToUser({ gcid, isArtist }))
      }
    }
  }, [dispatch, isArtist, isAuth, user.gcid])

  return null
}
