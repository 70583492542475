import { FC } from 'react'

import styled from 'styled-components'
import { Radio, RadioChangeEvent } from 'antd'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { PaymentMethodEnum } from 'src/Constants/enums'

import { StripeRadioButton } from '../SchedulePaymentMethod/StripeRadioButton'
import { PaypalRadioButton } from '../SchedulePaymentMethod/PaypalRadioButton'

export const StyledPaymentMethodBlock = styled.div`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.thirdText};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  gap: 24px;
`
const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 40px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 24px;
`

const BottomBlock = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

export interface PaymentMethodBlockProps {
  paymentMethod: PaymentMethodEnum
  paypalEmail?: string
  hasSavePaypal: boolean
  onChangeHasSavePaypal: (value: boolean) => void
  onChange: (paymentMethod: PaymentMethodEnum) => void
  onContinueClick: () => void
}

export const PaymentMethodBlock: FC<PaymentMethodBlockProps> = ({
  paymentMethod,
  paypalEmail,
  hasSavePaypal,
  onChange,
  onChangeHasSavePaypal,
  onContinueClick,
}) => {
  const onChangePaymentOption = (e: RadioChangeEvent): void => {
    const currentPaymentMethod = e?.target?.value as PaymentMethodEnum
    onChange(currentPaymentMethod)
  }

  return (
    <StyledPaymentMethodBlock>
      <Title>Select payment method</Title>

      <StyledRadioGroup value={paymentMethod} onChange={onChangePaymentOption}>
        {paypalEmail && (
          <PaypalRadioButton
            value={PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT}
            paypalEmail={paypalEmail}
            paymentMethod={paymentMethod}
            hasSavePaypal={hasSavePaypal}
            onChangeHasSavePaypal={onChangeHasSavePaypal}
          />
        )}

        <StripeRadioButton />

        <PaypalRadioButton
          value={PaymentMethodEnum.PAYPAL}
          paymentMethod={paymentMethod}
          hasSavePaypal={hasSavePaypal}
          onChangeHasSavePaypal={onChangeHasSavePaypal}
        />
      </StyledRadioGroup>

      <BottomBlock>
        <StyledPrimaryButton onClick={onContinueClick}>
          Continue
        </StyledPrimaryButton>
        <div>You won’t be charged yet</div>
      </BottomBlock>
    </StyledPaymentMethodBlock>
  )
}
