import { FC } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { TopConfirmEmailBlock } from './TopConfirmEmailBlock'
import { PlatformArtistProtectionProgram } from './PlatformArtistProtectionProgram'

const StyledPurchasingBlock = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  line-height: 150%;
  padding: 24px;
  border-radius: 16px;
  font-size: ${variables.fontSize14};
  font-weight: ${variables.fontWeight400};
  border: 1px solid ${({ theme }) => theme.common.thirdText};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  display: grid;
  row-gap: 16px;
  max-width: 400px;
`

const StyledPurchasingBlockTitle = styled.div`
  font-size: ${variables.fontSize18};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export interface PurchasingBlockProps {
  currentPlatform: CampaignPlatformTypeEnum
}

export const PurchasingBlock: FC<PurchasingBlockProps> = ({
  currentPlatform,
}) => (
  <StyledPurchasingBlock>
    <StyledPurchasingBlockTitle>
      Purchasing on SoundCampaign
    </StyledPurchasingBlockTitle>

    <TopConfirmEmailBlock />

    <PlatformArtistProtectionProgram currentPlatform={currentPlatform} />
  </StyledPurchasingBlock>
)
