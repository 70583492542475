import { FC, PropsWithChildren } from 'react'

import { useMediaQuery } from 'react-responsive'

import { PageLayout } from 'src/Components/PageLayout'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getSidebarVisibility,
  toggleSidebarVisibility,
} from 'src/Redux/pageLayout-process'
import {
  closeNewBonusPopup,
  getNewBonusPopupState,
  getNewUIPopupState,
  setNewUIPopupState,
} from 'src/Redux/alert-process'
import { Roles } from 'src/Constants/enums'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { useNewBonusSystem } from 'src/Hooks/useNewBonusSystem'

import { HeaderContainer } from '../Components/HeaderContainer'

import { CuratorSidebarContainer } from './Components/CuratorSidebarContainer'

export const CuratorPageLayoutContainer: FC<PropsWithChildren> = ({
  children,
}) => {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })
  const dispatch = useAppDispatch()
  const isSidebarCollapsed = useAppSelector(getSidebarVisibility)
  const newUiPopup = useAppSelector(getNewUIPopupState)
  const showNewBonusesPopup = useAppSelector(getNewBonusPopupState)
  const { isNewBonusSystem, isSuccess } = useNewBonusSystem()
  const onSidebarToggleClick = (): void => {
    dispatch(toggleSidebarVisibility())
  }

  const handleClickNewUIPopup = (): void => {
    dispatch(setNewUIPopupState(false))
  }

  const onMenuItemClick = (): void => {
    if (isMobile) {
      dispatch(toggleSidebarVisibility())
    }
  }

  const handleCloseNewBonusesPopup = (): void => {
    dispatch(closeNewBonusPopup())
  }

  return (
    <PageLayout
      Header={HeaderContainer}
      Sidebar={CuratorSidebarContainer}
      isHeaderHidden={false}
      onSidebarToggleClick={onSidebarToggleClick}
      isSidebarCollapsed={isSidebarCollapsed}
      newUIPopup={newUiPopup && isSuccess && !isNewBonusSystem}
      handleClickNewUIPopup={handleClickNewUIPopup}
      role={Roles.curator}
      onMenuItemClick={onMenuItemClick}
      onCloseNewBonusesPopup={handleCloseNewBonusesPopup}
      showNewBonusesPopup={isNewBonusSystem && showNewBonusesPopup}
    >
      {children}
    </PageLayout>
  )
}
