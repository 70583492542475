import React from 'react'

import styled from 'styled-components'

import { SmartImage } from 'src/Components/SmartImage'
import trackCoverDefault from 'src/Assets/Svg/common/track-cover.svg'

import { AccentText, ReviewPlaylist } from '../ReviewBlock'

const StyledImage = styled(SmartImage)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
`

const PlacementContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.common.primaryText};
`

export const AddedPlaylistInfo: React.FC<ReviewPlaylist> = ({
  playlistName,
  playlistUrl,
  playlistCoverUrl,
}) => (
  <PlacementContainer
    href={playlistUrl}
    target='_blank'
    rel='noopener noreferrer'
  >
    <StyledImage fallbackSrc={trackCoverDefault} src={playlistCoverUrl} />
    <AccentText>{playlistName}</AccentText>
  </PlacementContainer>
)
