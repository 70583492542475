import { useState } from 'react'

import { DislikeModal } from 'src/Pages/Artist/SpotifyCampaignDetailsPage/Components/DislikeModal'
import { RemoveDislikeModal } from 'src/Pages/Artist/SpotifyCampaignDetailsPage/Components/RemoveDislikeModal'
import { Review } from 'src/Types'
import { ArtistReviewFeedbackBody } from 'src/Redux/artistReviewFeedbackApi'

import { LikeDislikeButtons } from './LikeDislikeButtons'

export interface LikeDislikeButtonProps {
  reviewId: number
  reviewComplain: Review['artist_feedback_status']
  onSubmitLikeDislike: (feedbackBody: ArtistReviewFeedbackBody) => void
}

export const LikeDislikeBlock: React.FC<LikeDislikeButtonProps> = ({
  reviewId,
  reviewComplain,
  onSubmitLikeDislike,
}) => {
  const [isDislikeModalOpened, setIsDislikeMenuOpened] = useState(false)
  const [isChangeModalOpened, setIsChangeModalOpened] = useState(false)
  const [isSuccessDislikeModalOpened, setIsSuccessDislikeModalOpened] =
    useState(false)

  const isLiked = reviewComplain === true
  const isDisliked = reviewComplain === false

  const onLike = (): void => {
    if (isLiked) {
      onSubmitLikeDislike({
        feedback: null,
        reviewId,
      })
      return
    }

    if (isDisliked && !isChangeModalOpened) {
      setIsChangeModalOpened(true)
      return
    }

    if (isDisliked && isChangeModalOpened) {
      onSubmitLikeDislike({
        feedback: true,
        reviewId,
      })
      setIsChangeModalOpened(false)
      return
    }

    onSubmitLikeDislike({
      feedback: true,
      reviewId,
    })
  }

  const onDislike = (): void => {
    if (isDisliked) {
      onSubmitLikeDislike({
        feedback: null,
        reviewId,
      })
      return
    }
    setIsDislikeMenuOpened(true)
  }

  const onDislikeModalClose = (): void => {
    setIsDislikeMenuOpened(false)
  }

  const onChangeModalClose = (): void => {
    setIsChangeModalOpened(false)
  }

  const onSubmitDislike = (
    answerValue: ArtistReviewFeedbackBody['feedbackText'],
  ): void => {
    onSubmitLikeDislike({
      feedbackText: answerValue,
      feedback: false,
      reviewId,
    })

    setIsSuccessDislikeModalOpened(true)
  }

  const onCloseSuccessDislikeModal = (): void => {
    setIsDislikeMenuOpened(false)
    setIsSuccessDislikeModalOpened(false)
  }

  return (
    <>
      <LikeDislikeButtons
        isLiked={isLiked}
        onLike={onLike}
        onDislike={onDislike}
        isDisliked={isDisliked}
      />

      <DislikeModal
        onCloseClick={onDislikeModalClose}
        open={isDislikeModalOpened}
        onSubmit={onSubmitDislike}
        isSuccessDislikeModalOpened={isSuccessDislikeModalOpened}
        onCloseSuccessDislikeModal={onCloseSuccessDislikeModal}
      />

      <RemoveDislikeModal
        onCancel={onChangeModalClose}
        onLike={onLike}
        open={isChangeModalOpened}
      />
    </>
  )
}
