import { useCallback } from 'react'

import { useSelector } from 'react-redux'

import { useAppDispatch } from 'src/Hooks/redux'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import { getSourceBusterData } from 'src/Redux/sourcebuster-process'
import { getAccessToken } from 'src/Redux/auth-process/credentialsSlice/selectors'

interface SourceBusterEvents {
  sourceBusterLoginEvent: (token?: string) => void
}

export const useSourceBusterEvents = (): SourceBusterEvents => {
  const dispatch = useAppDispatch()

  const sourceBusterData = useSelector(getSourceBusterData)
  const savedToken = useSelector(getAccessToken)

  const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

  const sourceBusterLoginEvent = useCallback(
    (token?: string) => {
      if (token || savedToken) {
        void dispatch(
          sendSourceBusterEvent({
            token: token || savedToken,
            gclid: utmObj.gclid || '',
            savedData: sourceBusterData,
            eventName: 'login',
          }),
        )
      }
    },
    [dispatch, savedToken, sourceBusterData, utmObj.gclid],
  )
  
  return {
    sourceBusterLoginEvent,
  }
}
