import React, { FC } from 'react'

import { RadioProps } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PaymentMethodEnum } from 'src/Constants/enums'
import PayPalLogoSmallIcon from 'src/Assets/Svg/pages-icons/paypall-small-logo.svg?react'
import { obfuscateEmail } from 'src/Helpers/layoutHelpers'

import { PremiumCheckbox } from '../PremiumCheckbox'

import { PrimaryRadio } from './PrimaryRadio'

import { SchedulePaymentMethodProps } from '.'

const SubText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const StyledPrimaryRadio = styled(PrimaryRadio)`
  &.ant-radio-wrapper-disabled ${SubText} {
    display: none;
  }
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 4px;
`
const SavedContainer = styled.div`
  display: flex;
  height: 21px;
  padding-left: 6px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

type Props = RadioProps &
  Pick<
    SchedulePaymentMethodProps,
    'hasSavePaypal' | 'onChangeHasSavePaypal' | 'paymentMethod' | 'paypalEmail'
  >
export const PaypalRadioButton: FC<Props> = (props) => {
  const {
    hasSavePaypal,
    paymentMethod,
    paypalEmail,
    value,
    onChangeHasSavePaypal,
    ...restProps
  } = props
  const { t } = useTranslation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (typeof onChangeHasSavePaypal !== 'function') {
      return
    }
    onChangeHasSavePaypal(e.target.checked)
  }

  const showSaveCheckbox =
    paymentMethod === PaymentMethodEnum.PAYPAL &&
    !paypalEmail &&
    typeof onChangeHasSavePaypal === 'function'

  const subText = paypalEmail && obfuscateEmail(paypalEmail)

  return (
    <Container data-testid={'paypal-button'}>
      <StyledPrimaryRadio {...restProps} value={value}>
        <LabelContainer>
          <Text>
            <PayPalLogoSmallIcon />
          </Text>
          <SubText>{subText}</SubText>
        </LabelContainer>
      </StyledPrimaryRadio>
      {showSaveCheckbox && (
        <SavedContainer>
          <PremiumCheckbox
            name='save-paypal'
            onChange={handleChange}
            checked={hasSavePaypal}
          >
            {t('schedulePaymentMethod.remember')}
          </PremiumCheckbox>
        </SavedContainer>
      )}
    </Container>
  )
}
