import { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'
import { MAX_TABLET_WIDTH } from 'src/Styled/variables'
import RightArrowIcon from 'src/Assets/Svg/pages-icons/RightArrow.svg?react'

import { PrimaryButton } from '../Buttons/PrimaryButton'
import { IconButton } from '../Buttons/IconButton'

import { CampaignListItemProps } from '.'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: 200px;
    justify-content: flex-end;
    flex-shrink: 0;
  }
`

const DetailsButton = styled(PrimaryButton)`
  color: ${({ theme }) => theme.button.thirdText};
  max-width: 160px;
  width: 100%;
`
const ActionButton = styled(PrimaryButton)<{ $newRelease?: boolean }>`
  max-width: 160px;
  width: 100%;
  border-color: ${({ theme, $newRelease }) =>
    $newRelease ? theme.button.thirdBg : theme.button.primaryBorder};
`

const EmptyButton = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    display: none;
  }
`

type CampaignListItemButtonsProps = Pick<
  CampaignListItemProps,
  | 'status'
  | 'platform'
  | 'onRelaunchCampaignClick'
  | 'onStartCampaignClick'
  | 'onDetailsClick'
  | 'newRelease'
  | 'startLoading'
  | 'relaunchLoading'
  | 'relaunched'
>

const finishedStatuses = [CampaignStatusEnum.ENDED, CampaignStatusEnum.STOPPED]
export const CampaignListItemButtons: FC<CampaignListItemButtonsProps> = (
  props,
) => {
  const {
    status,
    platform,
    newRelease,
    relaunchLoading,
    relaunched,
    startLoading,
    onRelaunchCampaignClick,
    onStartCampaignClick,
    onDetailsClick,
  } = props
  const isDesktop = useMediaQuery({ minWidth: MAX_TABLET_WIDTH })
  const { t } = useTranslation()

  const detailsButton = useMemo(() => {
    if (newRelease) {
      return <EmptyButton />
    }
    if (isDesktop) {
      return (
        <IconButton data-testid='details-button' onClick={onDetailsClick}>
          <RightArrowIcon />
        </IconButton>
      )
    }
    return (
      <DetailsButton onClick={onDetailsClick}>
        {t('campaignsListPage.details')}
      </DetailsButton>
    )
  }, [isDesktop, newRelease, onDetailsClick, t])

  if (
    status === CampaignStatusEnum.APPROVED ||
    status === CampaignStatusEnum.PENDING
  ) {
    return (
      <Container>
        <ActionButton
          $newRelease={newRelease}
          loading={startLoading}
          onClick={onStartCampaignClick}
        >
          {t('campaignsListPage.startCampaign')}
        </ActionButton>
        {detailsButton}
      </Container>
    )
  }
  const isSpotify = platform === CampaignPlatformTypeEnum.SPOTIFY && !relaunched
  const isEnded = finishedStatuses.includes(status)
  if (isSpotify && isEnded) {
    return (
      <Container>
        <ActionButton
          loading={relaunchLoading}
          onClick={onRelaunchCampaignClick}
        >
          {t('campaignsListPage.relaunch')}
        </ActionButton>
        {detailsButton}
      </Container>
    )
  }
  return <Container>{detailsButton}</Container>
}
