import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import DangerIcon from 'src/Assets/Svg/Danger.svg?react'
import { variables } from 'src/Styled/variables'
import dayJs from 'src/Helpers/dayjs'

import { PlanStatusEnum } from '../../../helpers'

interface Props {
  status: PlanStatusEnum
  date?: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
const Text = styled.span`
  font-size: ${variables.fontSize14};
`

export const PlanNotification: FC<Props> = (props) => {
  const { status, date } = props
  const { t } = useTranslation()

  if (status === PlanStatusEnum.DEACTIVATED) {
    return (
      <Container>
        <DangerIcon />
        <Text>{t('getPremiumPage.deactivated')}</Text>
      </Container>
    )
  }

  if (date && status !== PlanStatusEnum.ACTIVE) {
    const dateFormatted = dayJs(date).format('DD MMM, YYYY')
    const text = `${t('getPremiumPage.activeUntil')} ${dateFormatted}`
    return (
      <Container>
        <Text>{text}</Text>
      </Container>
    )
  }
  return null
}
