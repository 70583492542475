import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'

import { ErrorCode, NameSpace } from '../types'

interface GetSpotifyCampaignMatchingRequest {
  campaignId: number
  budget: number
}

interface GetSpotifyCampaignMatchingResponse {
  minCuratorsCount: number
  maxCuratorsCount: number
  maxPlaylistsCount: number
  totalPrice: number
}

export const getSpotifyCampaignMatchingData = createAsyncThunk(
  `${NameSpace.SPOTIFY_CAMPAIGN_MATCHING}/getSpotifyCampaignMatchingData`,
  async (
    { campaignId, budget }: GetSpotifyCampaignMatchingRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.get<GetSpotifyCampaignMatchingResponse>(
        `${APP.TIKTOK_SERVER}/artist/spotify-campaigns/${campaignId}/matching-by-budget/${budget}`,
      )
      return data
    } catch (error) {
      const { message, response } = error as AxiosError
      return rejectWithValue({
        message,
        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
