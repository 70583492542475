import { FC, useEffect, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'
import {
  getIsLoadingSpotifyTracks,
  getTracks,
} from 'src/Redux/spotifyTracks-process/selectors'
import { useTrackSearchForm } from 'src/Hooks/useTrackSearchForm'
import { getAllSearchErrorSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { PublicRoutesEnum } from 'src/Router/PublicComponentsNewUI'

import { NoAuthPageLayout } from '../../Components/NoAuthPageLayout'
import { HelmetTitle } from '../../Components/HelmetTitle'
import { WhatArtistsThink } from '../../Components/WhatArtistsThink'
import { CreateCampaignBreadcrumbsNoAuth } from '../../Components/CreateCampaignBreadcrumbsNoAuth'
import { SearchedTrack, TrackSearch } from '../../Components/TrackSearch'
import { TrackSearchError } from '../../Components/TrackSearchError'
import { mapSpotifyTrack } from '../../helper'

const ContentContainer = styled.div`
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  gap: 80px;
  align-items: center;
`

const TopContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 960px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

export const SearchSpotifyTrackNoAuth: FC = () => {
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()

  const spotifyTracks = useSelector(getTracks)
  const isLoadingSpotify = useSelector(getIsLoadingSpotifyTracks)
  const searchError = useSelector(getAllSearchErrorSelector)

  const { debouncedFindTrack, setTracksToInitialStateHandler } =
    useTrackSearchForm(CampaignPlatformTypeEnum.SPOTIFY)

  useEffect(() => {
    toggleActivatedEvent({
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      campaignType: CampaignTypeEnum.FIRST,
      campaignNumber: 1,
    })
  }, [])

  const formattedTracks = useMemo<SearchedTrack[]>(
    () => spotifyTracks?.map(mapSpotifyTrack) || [],
    [spotifyTracks],
  )

  const onOutsideClick = (): void => {
    setTracksToInitialStateHandler()
  }

  const onSearch = (searchInput: string): void => {
    debouncedFindTrack(searchInput)
  }

  const onItemClick = (trackId: string): void => {
    navigate(
      `${PublicRoutesEnum.CreateSpotifyCampaign}/${trackId}?${search.toString()}`,
    )
  }

  return (
    <NoAuthPageLayout>
      <HelmetTitle />

      <ContentContainer>
        <TopContentContainer>
          <CreateCampaignBreadcrumbsNoAuth
            platform={CampaignPlatformTypeEnum.SPOTIFY}
          />
          <TrackSearch
            platform={CampaignPlatformTypeEnum.SPOTIFY}
            isLoading={isLoadingSpotify}
            onItemClick={onItemClick}
            tracks={formattedTracks}
            onSearch={onSearch}
            onOutsideClick={onOutsideClick}
            error={
              searchError && <TrackSearchError>{searchError}</TrackSearchError>
            }
          />
        </TopContentContainer>

        <WhatArtistsThink />
      </ContentContainer>
    </NoAuthPageLayout>
  )
}
