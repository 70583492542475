import { FunctionComponent, Suspense } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Route, Routes } from 'react-router-dom'

import { Roles } from 'src/Constants/enums'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import TiktokWidgetSearch from 'src/Containers/Widgets/TiktokWidgetSearch'
import { SpotifyWidgetSearch } from 'src/Containers/Widgets/SpotifyWidgetSearch'
import { CuratorPageLayoutContainer } from 'src/Containers/PageLayoutContainer/CuratorPageLayoutContainer'
import { CreatorPageLayoutContainer } from 'src/Containers/PageLayoutContainer/CreatorPageLayoutContainer'
import { BonusesGuide } from 'src/Components/BonusesGuide'

import { ArtistPageLayoutContainer } from '../Containers/PageLayoutContainer'

import { widgets, tiktokSearch, spotifySearch } from './routes'
import { ArtistComponentsNewUI } from './ArtistComponentsNewUI'
import { PublicComponentsNewUI } from './PublicComponentsNewUI'
import { CuratorComponentsNewUI } from './CuratorComponentsNewUI'
import { CreatorComponentsNewUI } from './CreatorComponentsNewUI'

const PublicContainer = styled.main``

const Router: FunctionComponent = () => {
  const role = useSelector(getUserRole)

  if (window.location.pathname.includes(`${widgets}`)) {
    return (
      <PublicContainer>
        <Routes>
          <Route
            element={
              <Suspense>
                <TiktokWidgetSearch />
              </Suspense>
            }
            path={`${widgets}${tiktokSearch}`}
          />
          <Route
            element={
              <Suspense>
                <SpotifyWidgetSearch />
              </Suspense>
            }
            path={`${widgets}${spotifySearch}`}
          />
        </Routes>
      </PublicContainer>
    )
  }

  if (role && role === Roles.artist) {
    return (
      <ArtistPageLayoutContainer>
        <ArtistComponentsNewUI />
      </ArtistPageLayoutContainer>
    )
  }
  if (role && role === Roles.curator) {
    return (
      <CuratorPageLayoutContainer>
        <CuratorComponentsNewUI />
        <BonusesGuide />
      </CuratorPageLayoutContainer>
    )
  }

  if (role && role === Roles.influencer) {
    return (
      <CreatorPageLayoutContainer>
        <CreatorComponentsNewUI />
      </CreatorPageLayoutContainer>
    )
  }

  return <PublicComponentsNewUI />
}

export default Router
