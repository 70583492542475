import { FC, useCallback, useEffect, useMemo } from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { onPlaySearchedTrack } from 'src/Helpers/TagManager/TrackEvent'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getIsArtist,
  getUserRole,
} from 'src/Redux/auth-process/credentialsSlice/selectors'
import { useSearchTrackAuth } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/SearchTrackAuth/useSearchTrackAuth'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useSearchTrackEvents } from 'src/Hooks/Analytics/useSearchTrackEvents'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { rtkApi } from 'src/Services/rtkApi'

import { mapSpotifyTrack, mapTikTokTrack } from '../../helper'
import { SearchedTrack, TrackSearch } from '../../Components/TrackSearch'
import { CreateCampaignBreadcrumbsAuth } from '../../Components/CreateCampaignBreadcrumbsAuth'
import { TikTokTrackSearchError } from '../../Components/TikTokTrackSearchError'
import { FaqComponent } from '../../Components/FaqComponent'

import { SuccessCreatedCampaign } from './SuccessCreatedCampaign'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 40px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    gap: 16px;
  }
`

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  gap: 80px;
  align-items: center;
`

const StyledFaq = styled(FaqComponent)`
  align-self: center;
`

export const SearchTrackAuth: FC = () => {
  const { platform } = useParams<{ platform: CampaignPlatformTypeEnum }>()
  const { search } = useCustomSearchParams()

  const isSuccessCreatedCampaign =
    search.get('successCreatedCampaign') === 'true'

  const dispatch = useAppDispatch()
  const userRole = useAppSelector(getUserRole)
  const isArtist = useAppSelector(getIsArtist)
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)

  const defaultSearchValue = search.get('trackName')

  const isTikTok = platform === CampaignPlatformTypeEnum.TIKTOK

  useSearchTrackEvents(CampaignTypeEnum.BASIC, platform)

  const {
    onTrackSearchHandler,
    loading,
    tracks,
    onClearTracks,
    onCheckTrack,
    error: listSearchError,
    tikTokSearchErrorMessage,
    tikTokExistingCampaign,
  } = useSearchTrackAuth(platform)

  const onSearch = useCallback(
    (searchInput: string): void => {
      onTrackSearchHandler({ searchInput })
    },
    [onTrackSearchHandler],
  )

  // in strict mode, useEffect cleanup function is called before
  // for local creation of campaign please remove strict mode
  useEffect(
    () => (): void => {
      onClearTracks()
    },
    [onClearTracks],
  )

  useEffect(() => {
    void dispatch(getSpotifyAccessToken()).unwrap()
  }, [dispatch, isArtist, userRole])

  const formattedTracks = useMemo<SearchedTrack[]>(() => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      return tracks.map(mapSpotifyTrack)
    }

    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      return tracks.map(mapTikTokTrack)
    }

    return []
  }, [platform, tracks])

  if (!platform) {
    return null
  }

  const onPlayClick = (): void => {
    onPlaySearchedTrack(CampaignTypeEnum.BASIC, role, platform, userId)
  }

  const onItemClick = (trackId: string): void => {
    onCheckTrack(trackId)
  }

  const onOutsideClick = (): void => {
    onClearTracks()
    dispatch(rtkApi.util.resetApiState())
  }

  const error = listSearchError || tikTokSearchErrorMessage

  return (
    <Container>
      <CreateCampaignBreadcrumbsAuth />
      <SearchContainer>
        <TrackSearch
          platform={platform}
          isLoading={loading}
          onPlayClick={onPlayClick}
          onItemClick={onItemClick}
          tracks={formattedTracks}
          onSearch={onSearch}
          onOutsideClick={onOutsideClick}
          defaultSearchValue={defaultSearchValue}
          error={
            error && (
              <TikTokTrackSearchError
                error={error}
                existingTikTokCampaign={tikTokExistingCampaign}
              />
            )
          }
        />

        {isTikTok && <StyledFaq />}
      </SearchContainer>

      {isSuccessCreatedCampaign && <SuccessCreatedCampaign />}
    </Container>
  )
}
