import styled from 'styled-components'

export const RewardTableHeaderContainer = styled.div`
  display: grid;
  grid-column-gap: 16px;
  padding: 0 16px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 48px;
`

export const RewardTableList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.common.primaryText};
`

export const RewardTableHeaderCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
