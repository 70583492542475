import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { withErrorBoundary } from '@sentry/react'

import { MAX_MOBILE_WIDTH, device } from 'src/Styled/variables'
import { ErrorComponent } from 'src/Components/ErrorBoundary/ErrorComponent'

import LevelsTable from './components/LevelsTable'
import { useLevels } from './useLevels'

const Title = styled.div`
  color: #646877;
  font-family: 'Segoe UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 32px;
  }
`

export const PageContentWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  padding: 24px;
  @media ${device.mobileMax} {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
`

const Rank: FC = () => {
  const { t } = useTranslation()

  useLevels()

  return (
    <Container>
      <Title>{t('rankPage.pageTitle')}</Title>
      <PageContentWrapper>
        <LevelsTable />
      </PageContentWrapper>
    </Container>
  )
}

export default withErrorBoundary(Rank, {
  fallback: <ErrorComponent />,
})
