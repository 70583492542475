import React, { FunctionComponent } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Badge, BadgeType } from 'src/Components/Badge'
import { MAX_MOBILE_WIDTH, colors, variables } from 'src/Styled/variables'
import ArrowLeftIcon from 'src/Assets/Svg/arrow-left.svg?react'
interface DetailsTitleProps {
  title: string
  badgeTitle: string
  badgeType: BadgeType
}

export const TitleWrapper = styled.div`
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const MainTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: ${colors.mainText};
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const DetailsTitle: FunctionComponent<DetailsTitleProps> = ({
  title,
  badgeTitle,
  badgeType,
}) => {
  const navigate = useNavigate()

  const onClose = (): void => {
    navigate('/')
  }
  return (
    <TitleWrapper>
      <Container>
        <StyledArrowLeftIcon width={12} height={12} onClick={onClose} />
        <MainTitle>{title}</MainTitle>
      </Container>
      <Badge title={badgeTitle} type={badgeType} />
    </TitleWrapper>
  )
}
