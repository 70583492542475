import React from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

import { AccentText, ReviewPlaylist } from '../ReviewBlock'

const PlaylistInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLink = styled.a`
  width: 100%;
  color: ${({ theme }) => theme.button.thirdText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.button.thirdText};

    &:active {
      color: ${({ theme }) => theme.button.thirdText};
    }
  }

  &:active {
    color: ${({ theme }) => theme.button.thirdText};
  }

  &:visited {
    color: ${({ theme }) => theme.button.thirdText};
    text-decoration: none;
  }
`

export const PlaylistInfo: React.FC<ReviewPlaylist> = ({
  playlistName,
  playlistUrl,
}) => (
  <PlaylistInfoContainer>
    {playlistUrl ? (
      <StyledLink href={playlistUrl}>{playlistName}</StyledLink>
    ) : (
      <AccentText>{playlistName}</AccentText>
    )}
  </PlaylistInfoContainer>
)
