import { useEffect, useState } from 'react'

import { useGrowthBook } from '@growthbook/growthbook-react'

import { getIsNewUserForSimplifiedCheckoutExperiment } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppSelector } from '../redux'

export const useSimplifiedCheckoutExperiment = (): boolean => {
  const [value, setValue] = useState<number>(0)
  const growthbook = useGrowthBook()

  const isNewUser = useAppSelector(getIsNewUserForSimplifiedCheckoutExperiment)
  useEffect(() => {
    if (isNewUser) {
      const featureValue = growthbook.getFeatureValue('simplified_checkout', 0)
      setValue(featureValue)
    }
  }, [isNewUser, growthbook])
  return Boolean(value)
}
