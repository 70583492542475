import { PlaylistData } from '../playlists-process/api-actions'

import { CuratorLevelResponse } from './api-actions'

import { Level } from '.'

export const mapLevelsWithPlaylists = (
  levelsData: Array<CuratorLevelResponse>,
  playlistsData: PlaylistData,
): Array<Level> =>
  levelsData.map((item) => {
    const rankPlaylists = playlistsData.Playlists.filter(
      (playlist) => playlist.level === item.rank,
    )
      .map((playlistItem) => ({
        id: Number(playlistItem.id),
        imageUrl: playlistItem.playlist_cover,
        linkUrl: playlistItem.playlist_url,
        playlistName: playlistItem.playlist_name,
        score: playlistItem.score + playlistItem.extra_score,
      }))
      .sort((a, b) => b.score - a.score)

    return {
      position: item.rank,
      score: item.score_required,
      reward: item.reward_per_review,
      playlists: rankPlaylists,
    }
  })
