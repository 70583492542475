export const campaignsList = '/campaigns'
export const widgets = '/widgets'
export const tiktokSearch = '/tiktok-search'
export const spotifySearch = '/spotify-search'
export const createdFirst = '/created-first'
export const feedback = '/feedback'
export const referAndEarn = '/refer-and-earn'
export const createCampaign = '/new'
export const monetization = '/monetization'
export const playlists = '/playlists'
export const scheduleCampaign = '/schedule'
export const scheduleCampaignTikTok = '/scheduleTikTok'
export const tracksFeed = '/tracksFeed'
export const reviewed = '/reviewed'
export const balance = '/balance'
export const subscription = '/subscription'
export const login = '/login'
export const sounds = '/sounds'
export const signUpArtist = '/signup-artist'
export const forgotPass = '/forgot-password'
export const settings = '/settings'
export const rank = '/rank'
export const details = '/details'
export const create = '/create'
export const review = '/review'
export const influencerBoard = '/influencer'
export const referralLinkNotExist = '/referral-link-not-exist'
