import { useSelector } from 'react-redux'

import { upSellEvent } from 'src/Helpers/TagManager'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsFirstCampaign,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getAllCampaignsCount, useMetadataQuery } from 'src/Redux/metadataApi'
import { CampaignTypeEnum } from 'src/Types'

export const useUpsellButtonEvent = (): {
  onDetailsTikTokUpsellButtonClickEvent: () => void
  onScheduleSubscriptionUpsellButtonClickEvent: () => void
} => {
  const { data } = useMetadataQuery(null)

  const userId = useAppSelector(getUserId)
  const isFirstCampaign = useSelector(getIsFirstCampaign)
  const amountOfCampaigns = getAllCampaignsCount(data)

  const campaignType =
    (isFirstCampaign && amountOfCampaigns === 1) || !userId
      ? CampaignTypeEnum.FIRST
      : CampaignTypeEnum.NEW

  const onDetailsTikTokUpsellButtonClickEvent = (): void => {
    upSellEvent({
      userId,
      campaignType,
      campaignNumber: amountOfCampaigns,
      buttonType: 'details_tiktok',
    })
  }

  const onScheduleSubscriptionUpsellButtonClickEvent = (): void => {
    upSellEvent({
      userId,
      campaignType,
      campaignNumber: amountOfCampaigns,
      buttonType: 'schedule_subscription',
    })
  }

  return {
    onDetailsTikTokUpsellButtonClickEvent,
    onScheduleSubscriptionUpsellButtonClickEvent,
  }
}
