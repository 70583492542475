import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;

  @media ${device.mobileMax} {
    display: flex;
    flex-direction: row-reverse;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
  }
`

export const MinRequestText = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 78px;
  @media screen and (min-width: 576px) {
    max-width: 100%;
  }
`

export const RequestButtonWrapper = styled.div`
  margin-bottom: 5px;
  width: 100%;
  min-width: 120px;
  max-width: 176px;

  @media ${device.mobileMax} {
    margin-bottom: 0;
  }
`

export const MobileRequestPayout = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`
