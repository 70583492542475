import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { colors } from 'src/Styled/variables'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.greyStroke};
`

const StyledButton = styled(PrimaryButton)`
  width: 140px;
  border-color: ${({ theme }) => theme.button.thirdBg};
`

interface SurveyButtonsProps {
  disabled?: boolean
  loading?: boolean
  onCloseModal?: () => void
}

export const SurveyButtons: FC<SurveyButtonsProps> = ({
  disabled,
  loading,
  onCloseModal,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <PrimaryDottedButton onClick={onCloseModal}>
        {t('feedBackPage.skipSurvey')}
      </PrimaryDottedButton>
      <StyledButton disabled={disabled} loading={loading}>
        {t('feedBackPage.submit')}
      </StyledButton>
    </Wrapper>
  )
}
