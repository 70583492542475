import React, { FC } from 'react'

import styled from 'styled-components'

import { ScheduleSummaryPriceDetailsItem } from '../ScheduleSummaryDetails/ScheduleSummaryPriceDetails/ScheduleSummaryPriceDetailsItem'
import { SecondaryScheduleSummaryProps } from '../SecondaryScheduleSummary'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`

const Header = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const Divider = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.common.primaryBorder};
  height: 1px;
`

const Total = styled(ScheduleSummaryPriceDetailsItem)`
  div {
    color: ${({ theme }) => theme.common.primaryText};
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`

type Props = Pick<
  SecondaryScheduleSummaryProps,
  'price' | 'balance' | 'discount' | 'discountPercentage' | 'vat' | 'total'
>

export const SecondaryScheduleSummaryDetails: FC<Props> = (props) => {
  const { price, balance, discount, discountPercentage, vat, total } = props
  return (
    <Wrapper>
      <Header>Summary</Header>
      <ScheduleSummaryPriceDetailsItem title='Subtotal' price={price} />
      {balance && (
        <ScheduleSummaryPriceDetailsItem
          title='Credits from Balance'
          price={balance}
        />
      )}
      {discountPercentage && (
        <ScheduleSummaryPriceDetailsItem
          title={`Discount (${discountPercentage}%)`}
          price={discount || '$0.00'}
        />
      )}
      {vat && <ScheduleSummaryPriceDetailsItem title='VAT (18%)' price={vat} />}
      <Divider />
      <Total title='Total' price={total} />
    </Wrapper>
  )
}
