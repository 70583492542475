import React, { FC, useEffect } from 'react'

import { useAppDispatch } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getAllGenres } from 'src/Redux/genres-process/api-actions'

import { ModalType } from '../../../../../Types'

import { GenresModalSpotifyWrapper } from './GenresModalSpotifyWrapper'
import { LanguagesModalSpotifyWrapper } from './LanguagesModalSpotifyWrapper'

export const SpotifyScheduleModals: FC = () => {
  const { search } = useCustomSearchParams()

  const dispatch = useAppDispatch()

  useEffect(() => {
    void dispatch(getAllGenres())
  }, [dispatch])

  if (search.get('modal') === ModalType.Genres) {
    return <GenresModalSpotifyWrapper />
  }
  if (search.get('modal') === ModalType.Languages) {
    return <LanguagesModalSpotifyWrapper />
  }
  return null
}
