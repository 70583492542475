import { FC, Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'
import { withErrorBoundary } from '@sentry/react'

import { SettingsPage } from 'src/Pages/Artist/SettingsPage'
import { MonetizationPage } from 'src/Pages/Creator/MonetizationPage'
import { SoundsPage } from 'src/Pages/Creator/SoundsPage'
import { NewSoundDetailsModal } from 'src/Pages/Creator/SoundsPage/components/SoundDetailsModal/components/NewSoundDetailsModal'
import { InProgressSoundDetailsModal } from 'src/Pages/Creator/SoundsPage/components/SoundDetailsModal/components/InProgressSoundDetailsModal'
import { SubmittedSoundDetailsModal } from 'src/Pages/Creator/SoundsPage/components/SoundDetailsModal/components/SubmittedSoundDetailsModal'
import { InfluencerBalancePage } from 'src/Pages/Creator/InfluencerBalancePage'
import { ErrorComponent } from 'src/Components/ErrorBoundary/ErrorComponent'

const SoundsPageWithErrorBoundary = withErrorBoundary(SoundsPage, {
  fallback: <ErrorComponent />,
})
const SettingsPageWithErrorBoundary = withErrorBoundary(SettingsPage, {
  fallback: <ErrorComponent />,
})
const InfluencerBalancePageWithErrorBoundary = withErrorBoundary(
  InfluencerBalancePage,
  {
    fallback: <ErrorComponent />,
  },
)
const MonetizationPageWithErrorBoundary = withErrorBoundary(MonetizationPage, {
  fallback: <ErrorComponent />,
})
const NewSoundDetailsModalWithErrorBoundary = withErrorBoundary(
  NewSoundDetailsModal,
  {
    fallback: <ErrorComponent />,
  },
)
const InProgressSoundDetailsModalWithErrorBoundary = withErrorBoundary(
  InProgressSoundDetailsModal,
  {
    fallback: <ErrorComponent />,
  },
)
const SubmittedSoundDetailsModalWithErrorBoundary = withErrorBoundary(
  SubmittedSoundDetailsModal,
  {
    fallback: <ErrorComponent />,
  },
)

export enum CreatorsRouteEnum {
  Root = '/',
  Influencer = '/influencer',
  Settings = '/settings',
  Balance = '/balance',
  Sounds = '/sounds',
  NewSounds = '/sounds/new',
  InProgressSounds = '/sounds/inProgress',
  SubmittedSounds = '/sounds/submitted',
  MonetizationRoute = '/monetization',
}

export const CreatorComponentsNewUI: FC = () => (
  <Suspense fallback={<div></div>}>
    <Routes>
      <Route
        Component={SoundsPageWithErrorBoundary}
        path={CreatorsRouteEnum.Influencer}
      />
      <Route
        Component={SettingsPageWithErrorBoundary}
        path={CreatorsRouteEnum.Settings}
      />
      <Route
        Component={InfluencerBalancePageWithErrorBoundary}
        path={CreatorsRouteEnum.Balance}
      />
      <Route
        Component={MonetizationPageWithErrorBoundary}
        path={CreatorsRouteEnum.MonetizationRoute}
      />
      <Route Component={SoundsPageWithErrorBoundary} path='*' />
      <Route
        Component={SoundsPageWithErrorBoundary}
        path={CreatorsRouteEnum.Sounds}
      >
        <Route
          Component={SoundsPageWithErrorBoundary}
          path={':activeTab'}
        ></Route>
      </Route>
      <Route
        Component={NewSoundDetailsModalWithErrorBoundary}
        path={`${CreatorsRouteEnum.NewSounds}/:soundId`}
      />
      <Route
        Component={InProgressSoundDetailsModalWithErrorBoundary}
        path={`${CreatorsRouteEnum.InProgressSounds}/:soundId`}
      />
      <Route
        Component={SubmittedSoundDetailsModalWithErrorBoundary}
        path={`${CreatorsRouteEnum.SubmittedSounds}/:soundId`}
      />
    </Routes>
  </Suspense>
)
