import styled from 'styled-components'

import { device, variables } from 'src/Styled/variables'

export const PlaylistsContainer = styled.div<{ isPlaylistsExisted: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: ${({ isPlaylistsExisted }) =>
    isPlaylistsExisted ? '16px' : '0'};

  @media ${device.smallScreenMax} {
    flex-direction: column;
    gap: 16px;
  }
`
export const HideButtonWrapper = styled.div`
  width: fit-content;
  margin: 30px auto 0;

  @media ${device.tabletMax} {
    margin: 12px auto 0;
  }
`
export const NoPlaylistsMessage = styled.div`
  display: block;
  font-size: ${variables.fontSize14};
  text-align: center;
`
