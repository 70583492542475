import { useCallback, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import {
  getHasPaidCampaignMetadata,
  selectPaidCampaignsAmount,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import {
  EXPIRED_TIMER_VALUE,
  getCheckoutTimer,
  getIsTimerExpired,
  startCountdown,
  updateCountdown,
} from 'src/Redux/checkoutCountdown-process'
import { useAddFeatureForUserMutation } from 'src/Redux/A-BFeaturesApi'
import { getIsUserReferral } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppDispatch } from './redux'
import {
  EXPIRED_TIMER_FEATURE_VALUE,
  QUICK_CHECKOUT_FEATURE_NAME,
} from './useCheckoutTimerFeratureFlag'

const TIMEOUT = 1000
const COUNTDOWN_VALUE = 420

interface UseBonusTooltipTimerInterface {
  isNextTimeBonus: boolean
  isNextTimeBonusReceived: boolean
  startNextTimeBonusCountdown: () => void
}

export const useBonusTooltipTimer = (): UseBonusTooltipTimerInterface => {
  const dispatch = useAppDispatch()

  const { data, isSuccess } = useMetadataQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const paidCampaignsAmount = useSelector(selectPaidCampaignsAmount)
  const isUserReferral = useSelector(getIsUserReferral)
  const countdown = useSelector(getCheckoutTimer)
  const isTimerExpired = useSelector(getIsTimerExpired)

  const [addFeatureForUser] = useAddFeatureForUserMutation()

  const isNextTimeBonus = useMemo(
    () => !isUserReferral && !isFirstPaid && isSuccess,
    [isUserReferral, isFirstPaid, isSuccess],
  )

  const isNextTimeBonusVisible =
    isNextTimeBonus && countdown !== null && !isTimerExpired

  const isNextTimeBonusReceived = paidCampaignsAmount === 1 && !isUserReferral

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isNextTimeBonus && !isTimerExpired) {
      timer = setInterval(() => {
        if (countdown === 0 && !isTimerExpired) {
          addFeatureForUser({
            featureName: QUICK_CHECKOUT_FEATURE_NAME,
            variation: EXPIRED_TIMER_FEATURE_VALUE,
          })
          clearInterval(timer)
          dispatch(updateCountdown(EXPIRED_TIMER_VALUE))
        } else {
          countdown &&
            !isTimerExpired &&
            dispatch(updateCountdown(countdown - 1))
        }
      }, TIMEOUT)
    }

    return () => clearInterval(timer)
  }, [addFeatureForUser, countdown, dispatch, isNextTimeBonus, isTimerExpired])

  const startNextTimeBonusCountdown = useCallback((): void => {
    isNextTimeBonus && dispatch(startCountdown(COUNTDOWN_VALUE))
  }, [dispatch, isNextTimeBonus])

  return {
    isNextTimeBonus: isNextTimeBonusVisible,
    isNextTimeBonusReceived,
    startNextTimeBonusCountdown,
  }
}
