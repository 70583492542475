import TagManager from 'react-gtm-module'

import { ID } from 'src/Types'

import { EventNameEnum, EventTypeEnum, PaymentMethod } from '../tagManagerEnums'

export const changePlanEvent = ({
  userId,
  planName,
}: {
  userId?: ID | null
  planName: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.ChangedPlan,
        subscription_plan: planName,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscriptionConfirmedPaymentEvent = ({
  userId,
  planName,
  paymentMethod,
}: {
  userId?: ID | null
  planName: string
  paymentMethod: PaymentMethod
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.SubscriptionConfirmedPayment,
        subscription_plan: planName,
        payment_method: paymentMethod,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscriptionPaymentCompletedEvent = ({
  userId,
  planName,
  paymentMethod,
}: {
  userId?: ID | null
  planName: string
  paymentMethod: PaymentMethod
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,
      event_params: {
        event_name: EventNameEnum.SubscriptionPaymentCompleted,
        subscription_plan: planName,
        payment_method: paymentMethod,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}

export const subscriptionSelectedEvent = ({
  userId,
  planName,
}: {
  userId?: ID | null
  planName: string
}): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTypeEnum.GA_EVENT,

      event_params: {
        event_name: EventNameEnum.SubscriptionSelected,
        subscription_plan: planName,
      },
      user_properties: {
        ...(userId ? { user_id: userId } : {}),
      },
    },
  })
}
