import React from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'
import CheckIcon from 'src/Assets/Svg/common/check-mark.svg?react'

const Container = styled.div<{
  $withPlacement: boolean
}>`
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;

  background-color: ${({ theme, $withPlacement }) =>
    $withPlacement ? theme.button.fifthBg : theme.button.secondaryBg};

  svg {
    path {
      fill: ${({ theme }) => theme.button.primaryText};
    }
  }
`

const Text = styled.span<{
  $withPlacement: boolean
}>`
  color: ${({ theme, $withPlacement }) =>
    $withPlacement ? theme.button.primaryText : theme.button.thirdText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  word-break: break-word;
`

interface ReviewLabelProps {
  withPlacement: boolean
  className?: string
}

export const ReviewLabel: React.FC<ReviewLabelProps> = ({
  withPlacement,
  className,
}) => {
  const text = withPlacement ? 'Placement' : 'Feedback'

  return (
    <Container $withPlacement={withPlacement} className={className}>
      {withPlacement && <CheckIcon />}

      <Text $withPlacement={withPlacement}>{text}</Text>
    </Container>
  )
}
