import { FC } from 'react'

import styled from 'styled-components'
import { useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'

import { useNewBonusSystem } from 'src/Hooks/useNewBonusSystem'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getUserFirstNameSelector,
  getUserLastNameSelector,
  getUserPhotoUrl,
  getUserRoleSelector,
  getUserEmail,
} from 'src/Redux/auth-process/userSlice/selectors'
import { Sidebar, SidebarProps } from 'src/Components/Sidebar'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

import { useShowFeedback } from './useShowFeedback'
import { useRequestFeature } from './useRequestFeature'

const StyledSidebar = styled(Sidebar)`
  height: 100%;
`

export type SidebarContainerProps = Pick<
  SidebarProps,
  'collapsed' | 'onLogoClick' | 'onMenuItemClick'
>

export const SidebarContainer: FC<SidebarContainerProps> = ({
  collapsed,
  onLogoClick,
  onMenuItemClick,
}) => {
  const firstName = useAppSelector(getUserFirstNameSelector)
  const lastName = useAppSelector(getUserLastNameSelector)
  const role = useAppSelector(getUserRoleSelector)
  const { data: subscription } = useGetSubscriptionQuery()
  const email = useSelector(getUserEmail)
  const photoUrl = useSelector(getUserPhotoUrl)

  const { show } = useIntercom()
  const { isNewBonusSystem } = useNewBonusSystem()
  const onChatClick = (): void => {
    show()
  }

  const planName = subscription?.package?.name
    ? `${subscription?.package?.name} Plan`
    : ''

  const hasActivePlan = Boolean(
    subscription?.status === 'active' || subscription?.status === 'canceled',
  )
  const showFeedback = useShowFeedback()
  const { showFeatureRequest, attributeValue, onFeatureClick } =
    useRequestFeature()

  return (
    <StyledSidebar
      planName={planName}
      hasActivePlan={hasActivePlan}
      firstName={firstName}
      lastName={lastName}
      role={role}
      collapsed={collapsed}
      onLogoClick={onLogoClick}
      onChatClick={onChatClick}
      showFeatureRequest={showFeatureRequest}
      attributeValue={attributeValue}
      onFeatureClick={onFeatureClick}
      showFeedback={showFeedback}
      email={email}
      userCover={photoUrl}
      onMenuItemClick={onMenuItemClick}
      isNewBonusSystem={isNewBonusSystem}
    />
  )
}
