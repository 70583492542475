import { FC } from 'react'

import { Route, Routes } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import SignUp from 'src/Containers/SignUp/SignUp'
import ForgotPassword from 'src/Containers/ForgotPasswordPage'
import ResetPassword from 'src/Containers/ResetPassword'
import ExpiredLinkPage from 'src/Pages/Public/ExpiredLinkPage'
import InternalServerError from 'src/Containers/ui/InternalServerError'
import Registration from 'src/Containers/Registration'
import ReferralLinkNotExist from 'src/Containers/ReferralLinkNotExist/index'
import LogIn from 'src/Containers/LogIn/Login'
import { ChoosePlatformNoAuthPage } from 'src/Pages/Artist/CreateCampaignPage/NoAuthPages/ChoosePlatformNoAuthPage'
import { SearchSpotifyTrackNoAuth } from 'src/Pages/Artist/CreateCampaignPage/NoAuthPages/SearchSpotifyTrackNoAuth'
import { SelectedTrackNoAuth } from 'src/Pages/Artist/CreateCampaignPage/NoAuthPages/SelectedTrackNoAuth'
import { ArtistRegisterPage } from 'src/Pages/Artist/CreateCampaignPage/NoAuthPages/ArtistRegisterPage'
import { SearchTikTokTrackNoAuth } from 'src/Pages/Artist/CreateCampaignPage/NoAuthPages/SearchTikTokTrackNoAuth'

export enum PublicRoutesEnum {
  Root = '/',
  Login = '/login',
  SignUpCurator = '/signup-curator',
  ForgotUserPassword = '/forgot-password',
  ResetUserPassword = '/reset-password',
  UserErrors = '/errors',
  InternalError = '/internal-server-error',
  CreateCampaign = '/create',
  CreateSpotifyCampaign = '/create/spotify',
  CreateTikTokCampaign = '/create/tiktok',
  SignUpArtist = '/signup-artist',
  SignUpCreator = '/signup-creator',
  ReferralNotExist = '/referral-link-not-exist',
  Spotify = '/spotify',
}

export const PublicComponentsNewUI: FC = () => (
  <Routes>
    <Route Component={LogIn} path={PublicRoutesEnum.Root} />
    <Route Component={LogIn} path={PublicRoutesEnum.Login} />
    <Route Component={SignUp} path={PublicRoutesEnum.SignUpCurator} />
    <Route
      Component={ForgotPassword}
      path={PublicRoutesEnum.ForgotUserPassword}
    />

    <Route
      Component={ResetPassword}
      path={`${PublicRoutesEnum.ResetUserPassword}/:uuid`}
    />
    <Route Component={ExpiredLinkPage} path={PublicRoutesEnum.UserErrors} />
    <Route
      Component={InternalServerError}
      path={PublicRoutesEnum.InternalError}
    />
    <Route
      Component={ChoosePlatformNoAuthPage}
      path={PublicRoutesEnum.CreateCampaign}
    />
    <Route
      Component={SearchSpotifyTrackNoAuth}
      path={PublicRoutesEnum.CreateSpotifyCampaign}
    />
    <Route
      Component={SearchTikTokTrackNoAuth}
      path={PublicRoutesEnum.CreateTikTokCampaign}
    />
    <Route
      Component={SelectedTrackNoAuth}
      path={`${PublicRoutesEnum.CreateCampaign}/:platform/:trackId`}
    />
    <Route
      Component={ArtistRegisterPage}
      path={`${PublicRoutesEnum.CreateCampaign}/:platform/:trackId${PublicRoutesEnum.SignUpArtist}`}
    />
    <Route Component={Registration} path={PublicRoutesEnum.SignUpCreator} />
    <Route
      Component={ReferralLinkNotExist}
      path={PublicRoutesEnum.ReferralNotExist}
    />
    <Route
      Component={() => {
        window.location.href = APP.SPOTIFY_AUTH_API
        return null
      }}
      path={PublicRoutesEnum.Spotify}
    />
    <Route Component={LogIn} path='*' />
  </Routes>
)
