import React, { FC, PropsWithChildren } from 'react'

import styled from 'styled-components'

import PauseIcon from '/src/Assets/Svg/pause-button.svg?react'

import PlayIcon from 'src/Assets/Svg/play-button.svg?react'

import { PrimaryLoading } from '../PrimaryLoading'

const CoverWrapper = styled.div`
  position: relative;
  margin-right: 16px;
  cursor: pointer;
`

const StyledPauseIcon = styled(PauseIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const StyledPlayIcon = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const Loader = styled(PrimaryLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

type TiktokTrackPlayerProps = PropsWithChildren & {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  loading: boolean
  isPlaying: boolean
  loaderSize?: string
  iconSize?: string
  className?: string
  show?: boolean
}

export const TrackCoverWrapper: FC<TiktokTrackPlayerProps> = ({
  children,
  loading,
  isPlaying,
  onClick,
  loaderSize = '30px',
  iconSize = '16px',
  className,
  show = true,
}) => {
  if (!show) {
    return (
      <CoverWrapper onClick={onClick} className={className}>
        {children}
      </CoverWrapper>
    )
  }
  return (
    <CoverWrapper className={className} onClick={onClick}>
      {children}

      {loading && <Loader color='#fff' fontSize={loaderSize} />}

      {/* Adjusted Logic for Displaying Play/Pause Icons */}
      {isPlaying ? (
        <StyledPauseIcon width={iconSize} height={iconSize} />
      ) : (
        <StyledPlayIcon width={iconSize} height={iconSize} />
      )}
    </CoverWrapper>
  )
}
