import { FC, useState } from 'react'

import styled from 'styled-components'
import { Radio, RadioChangeEvent } from 'antd'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import { PrimaryRadio } from 'src/Components/SchedulePaymentMethod/PrimaryRadio'
import { TextArea } from 'src/Components/TextArea'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { ArtistReviewFeedbackBody } from 'src/Redux/artistReviewFeedbackApi'

import { ReviewSuccessModal } from '../ReviewSuccessModal'

const Container = styled.div`
  display: flex;
  max-width: 600px;
  padding: 64px 0px 80px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin: 0 auto;
  height: 100vh;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 24px 16px 16px;
  }
`

const Title = styled.h2`
  font-family: 'Segoe UI';
  margin: 0;
  padding: 0;
  font-size: ${variables.fontSize20};
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-right: 40px;
  }
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`

const StyledPrimaryRadio = styled(PrimaryRadio)`
  &.ant-radio-wrapper {
    margin-inline-end: 0px;
  }
  &.ant-radio-wrapper span.ant-radio + * {
    padding-inline-end: 0px;
  }
`

const ActionButton = styled(PrimaryButton)`
  max-width: 184px;
  width: 100%;
  border-color: ${({ theme }) => theme.button.thirdBg};
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  align-self: stretch;
  height: 64px;
  border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};
  width: 100%;
`

export enum AnswerValues {
  ItDoesntHelp = 1,
  TheLanguageFeels = 2,
  TheCuratorDoesnt = 3,
  ItsTooVague = 4,
  Other = 5,
}

const radioValues = [
  {
    value: AnswerValues.ItDoesntHelp,
    text: 'It doesn’t help me understand how to improve my music.',
  },
  {
    value: AnswerValues.TheLanguageFeels,
    text: 'The language feels unprofessional or disrespectful.',
  },
  {
    value: AnswerValues.TheCuratorDoesnt,
    text: 'The curator doesn’t seem like a good fit for my genre.',
  },
  {
    value: AnswerValues.ItsTooVague,
    text: 'It’s too vague or doesn’t provide enough detail.',
  },
  {
    value: AnswerValues.Other,
    text: 'Other',
  },
]

interface DislikeModalProps {
  onCloseClick: () => void
  onSubmit: (answerValue: ArtistReviewFeedbackBody['feedbackText']) => void
  open: boolean
  isSuccessDislikeModalOpened: boolean
  onCloseSuccessDislikeModal: () => void
}

const TEXTAREA_MAX_LENGTH = 500

export const DislikeModal: FC<DislikeModalProps> = ({
  onCloseClick,
  onSubmit,
  open,
  isSuccessDislikeModalOpened,
  onCloseSuccessDislikeModal,
}) => {
  const [answerValue, setAnswerValue] = useState<null | AnswerValues>(null)
  const [textAnswer, setTextAnswer] = useState<string>('')

  const handleChange = (e: RadioChangeEvent): void => {
    setAnswerValue(e.target.value)
  }

  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setTextAnswer(e.target.value)
  }

  const onSubmitInner = (): void => {
    const answerValueToSubmit =
      answerValue === AnswerValues.Other
        ? textAnswer
        : radioValues.find((radioValue) => radioValue.value === answerValue)
            ?.text || ''

    onSubmit(answerValueToSubmit)
  }

  const onSuccessCloseClick = (): void => {
    onCloseSuccessDislikeModal()
  }

  const isDisabled =
    answerValue === null || (answerValue === AnswerValues.Other && !textAnswer)

  if (isSuccessDislikeModalOpened) {
    return (
      <ReviewSuccessModal
        open={open}
        onSuccessCloseClick={onSuccessCloseClick}
      />
    )
  }

  return (
    <FullScreenModal onCancel={onCloseClick} open={open} showCloseButton>
      <Container>
        <Title>What didn’t feel right about this review?</Title>
        <StyledRadioGroup value={answerValue} onChange={handleChange}>
          {radioValues.map((radioValue) => (
            <StyledPrimaryRadio key={radioValue.value} value={radioValue.value}>
              {radioValue.text}
            </StyledPrimaryRadio>
          ))}
        </StyledRadioGroup>
        {answerValue === AnswerValues.Other && (
          <TextArea
            value={textAnswer}
            onChange={handleTextChange}
            placeholder={'What bothered you about the review?'}
            style={{ height: 180, resize: 'none' }}
            count={{
              show: (count) =>
                count.value ? `${count.count}/${TEXTAREA_MAX_LENGTH}` : '',
            }}
            maxLength={TEXTAREA_MAX_LENGTH}
          />
        )}
      </Container>
      <Footer>
        <PrimaryDottedButton onClick={onCloseClick}>
          Go back
        </PrimaryDottedButton>
        <ActionButton disabled={isDisabled} onClick={onSubmitInner}>
          Submit feedback
        </ActionButton>
      </Footer>
    </FullScreenModal>
  )
}
