import { object as yupObject, string as yupString } from 'yup'

import instagram from 'src/Assets/Webp/Survey/instagram.webp'
import google from 'src/Assets/Webp/Survey/google.webp'
import facebook from 'src/Assets/Webp/Survey/facebook.webp'
import tikTok from 'src/Assets/Webp/Survey/tikTok.webp'
import youTube from 'src/Assets/Webp/Survey/youTube.webp'
import twitter from 'src/Assets/Webp/Survey/twitter.webp'
import reddit from 'src/Assets/Webp/Survey/reddit.webp'
import gpt from 'src/Assets/Webp/Survey/GPT.webp'
import friends from 'src/Assets/Webp/Survey/friends.webp'
import other from 'src/Assets/Webp/Survey/other.webp'

import { SurveyItemProps } from './SurveyItem'

export interface SurveyFormValues {
  resource: string
  description?: string
}

const OTHER_VARIANT = 'Other'

const VALUES = [OTHER_VARIANT]

const WHAT_PROFILE_PLACEHOLDER = 'What profile recommended us? (Optional)'

export const SURVEY_VARIANTS: SurveyItemProps[] = [
  {
    title: 'Instagram',
    image: instagram,
    placeholder: WHAT_PROFILE_PLACEHOLDER,
    inputType: 'input',
  },
  {
    title: 'Google Search',
    image: google,
  },
  {
    title: 'Facebook',
    image: facebook,
  },
  {
    title: 'TikTok',
    image: tikTok,
    placeholder: WHAT_PROFILE_PLACEHOLDER,
    inputType: 'input',
  },
  {
    title: 'YouTube',
    image: youTube,
    placeholder: WHAT_PROFILE_PLACEHOLDER,
    inputType: 'input',
  },
  {
    title: 'X (Twitter)',
    image: twitter,
    placeholder: WHAT_PROFILE_PLACEHOLDER,
    inputType: 'input',
  },
  {
    title: 'Reddit',
    image: reddit,
  },
  {
    title: 'ChatGPT',
    image: gpt,
  },
  {
    title: 'Friend / Word of Mouth',
    image: friends,
  },
  {
    title: OTHER_VARIANT,
    image: other,
    placeholder: 'Please specify how you heard about us',
    inputType: 'textarea',
  },
]

export const surveyFormSchema = yupObject().shape({
  resource: yupString().required(),
  description: yupString()
    .trim()
    .when('resource', (value: string[], schema) => {
      if (VALUES.includes(value[0] || '')) {
        return schema.required('Please add specify where you heard about us')
      }
      return schema
    }),
})
