import React, { FC, useMemo } from 'react'

import styled from 'styled-components'

import { PaymentMethodEnum } from 'src/Constants/enums'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { obfuscateEmail } from 'src/Helpers/layoutHelpers'
import PayPalLogoSmallIcon from 'src/Assets/Svg/pages-icons/paypall-small-logo.svg?react'

import { SecondaryScheduleSummaryProps } from '../SecondaryScheduleSummary'

type Props = Pick<
  SecondaryScheduleSummaryProps,
  'paymentMethod' | 'onPaymentMethodChange' | 'paypalEmail'
>

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const PaymentMethod = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
const BillingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
const Text = styled.div<{ $autoWidth?: boolean }>`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 768px) {
    width: ${({ $autoWidth }) => ($autoWidth ? 'auto' : '200px')};
  }
`
export const SecondaryScheduleSummaryPaymentMethod: FC<Props> = (props) => {
  const { paymentMethod, onPaymentMethodChange, paypalEmail } = props

  const paymentMethodText = useMemo(() => {
    if (
      paymentMethod === PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT &&
      paypalEmail
    ) {
      return (
        <BillingContainer>
          <PayPalLogoSmallIcon />
          <Text>{obfuscateEmail(paypalEmail)}</Text>
        </BillingContainer>
      )
    }
    if (paymentMethod === PaymentMethodEnum.STRIPE) {
      return <Text $autoWidth>Credit & debit cards</Text>
    }
    return <PayPalLogoSmallIcon />
  }, [paymentMethod, paypalEmail])

  if (paymentMethod === PaymentMethodEnum.Credits) {
    return null
  }
  return (
    <Wrapper>
      <Title>Payment method</Title>
      <PaymentMethod>
        <div>{paymentMethodText}</div>
        <PrimaryDottedButton onClick={onPaymentMethodChange}>
          Edit
        </PrimaryDottedButton>
      </PaymentMethod>
    </Wrapper>
  )
}
