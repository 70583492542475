import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import {
  CampaignStatusEnum,
  CampaignTypeEnum,
  Payment,
  Review,
  Track,
} from 'src/Types/index'

export interface CampaignDetails {
  curators_count_max: number
  Payment: Payment
  Reviews: Array<Review>

  Track: Track
  budget: string
  curators_count: number
  end_date: string
  id: number
  is_feed: boolean
  is_sfa_connected: boolean
  listeners_count: number
  metadata: null
  missed_count: number
  playlists_adds: number
  playlists_count: number
  playlists_followers_result: number
  refund: string
  reviewed_count: number
  reviews_count: number
  start_date: string
  status: CampaignStatusEnum
  streams_count: number
  type: CampaignTypeEnum
  playlists_count_max: number
  spent: string
  trackActiveCampaignsCount?: number
}

const campaignDetailsApi = rtkApi.injectEndpoints({
  endpoints(build) {
    return {
      getCampaignDetails: build.query<CampaignDetails, number>({
        query: (campaignId) => `${APP.SERVER}/campaign/details/${campaignId}`,
        providesTags: ['SpotifyCampaignDetails'],
      }),
    }
  },
})

export const { useGetCampaignDetailsQuery } = campaignDetailsApi
