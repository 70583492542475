import { FC, PropsWithChildren } from 'react'

import { PrimaryLoading } from 'src/Components/PrimaryLoading'

interface ShowLoadingProps {
  loading: boolean
}

type Props = PropsWithChildren & ShowLoadingProps
export const ShowLoading: FC<Props> = ({ loading, children }) => {
  if (loading) {
    return <PrimaryLoading fontSize={24} />
  }
  return children
}
