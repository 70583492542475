import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { lowercaseFirstLetter } from 'src/Helpers/layoutHelpers'
import { colors } from 'src/Styled/variables'
import { useAppSelector } from 'src/Hooks/redux'
import { getTransactionsLoading } from 'src/Redux/transactions-process/selectors'
import { MobileBalanceItems, TransactionTypeEnum } from 'src/Types'
import { LoadingSpinner } from 'src/Containers/ui'
import dayJs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { StatusCell } from 'src/Pages/Curator/BalancePage/components/BalanceTable/StatusCell/StatusCell'

import { useBalancePage } from '../helpers'

import {
  MobileHeaderTitle,
  InfoText,
  MobileCard,
  MobileCardHeaderWrapper,
  MobileItemsWrapper,
  MobileLoadingWrapper,
  MobileNoItemsTextWrapper,
  MobileInfoText,
  Amount,
  Container,
  DateWrapper,
  Title,
  StyledDate,
  AmountWrapper,
  StatusWrapper,
  AmountContainer,
} from './styles'

interface Props {
  items: Array<MobileBalanceItems>
}
export const MobileBalanceList: FC<Props> = ({ items }) => {
  const { t } = useTranslation()
  const isBalanceHistoryLoading = useAppSelector(getTransactionsLoading)
  const { getAmountValue } = useBalancePage()

  if (!items || (items && items.length === 0)) {
    return (
      <MobileNoItemsTextWrapper>
        <MobileInfoText>{t('balancePage.youHaveNoRewardsYet')}</MobileInfoText>
      </MobileNoItemsTextWrapper>
    )
  }

  if (isBalanceHistoryLoading) {
    return (
      <MobileLoadingWrapper>
        <LoadingSpinner color={colors.green} size='large' />
      </MobileLoadingWrapper>
    )
  }
  return (
    <MobileItemsWrapper>
      {items.length === 0 && !isBalanceHistoryLoading && (
        <MobileInfoText>{t('balancePage.youHaveNoRewardsYet')}</MobileInfoText>
      )}
      {items.map((item) => (
        <MobileCard key={item.id}>
          <Container>
            <MobileCardHeaderWrapper>
              <MobileHeaderTitle>
                <TikTokIcon />
                {`${item.type} ${lowercaseFirstLetter(item.description.title)}`}
              </MobileHeaderTitle>
            </MobileCardHeaderWrapper>

            {item.amount.type === 'canceled' && (
              <MobileCardHeaderWrapper>
                <InfoText>Payout has been returned and canceled</InfoText>
              </MobileCardHeaderWrapper>
            )}
            <DateWrapper>
              <Title>Date</Title>
              <StyledDate>
                {dayJs(item.date).format(formatDate.formatDateMonthFull)}
              </StyledDate>
            </DateWrapper>

            <AmountWrapper>
              <StatusWrapper>
                <Title>Status</Title>
                <StatusCell type={item.amount.type} />
              </StatusWrapper>
              <AmountContainer>
                <Title>Amount</Title>
                <Amount green={item.amount.type === 'payment'}>
                  {getAmountValue(
                    item.amount.type as TransactionTypeEnum,
                    item.amount.amount,
                  )}
                </Amount>
              </AmountContainer>
            </AmountWrapper>
          </Container>
        </MobileCard>
      ))}
    </MobileItemsWrapper>
  )
}
