import { useTranslation } from 'react-i18next'

import { useSubscriptionPackagesQuery } from 'src/Redux/subscriptionPackagesApi'
import { useGetSubscriptionQuery } from 'src/Redux/subscription-process/subscriptionApi'

import { Plan, getPlanStatus } from './helpers'

export const useSubscriptionPlan = (): { plans: Array<Plan> } => {
  const { t } = useTranslation()
  const { data } = useSubscriptionPackagesQuery()
  const { data: subscription } = useGetSubscriptionQuery()

  const getPlanDate = (id: number): string => {
    if (subscription?.packageId === id && subscription?.status !== 'ended') {
      return subscription.nextPaymentDate
    }
    return ''
  }

  const campaignQuickStart = t('getPremiumPage.campaignStart')
  const premiumSupport = t('getPremiumPage.premiumSupport')
  const cashback = t('getPremiumPage.cashback')

  const plans: Plan[] = (data || []).map((plan, index) => ({
    name: plan.name,
    price: plan.paymentAmount,
    cashback: plan.cashbackPercentage,
    features: [
      `${plan.cashbackPercentage}% ${cashback}`,
      campaignQuickStart,
      premiumSupport,
    ],
    isRecommended: subscription?.status !== 'active' && index === 1,
    status: getPlanStatus({
      id: plan.id,
      packageId: Number(subscription?.packageId),
      status: subscription?.status,
    }),
    date: getPlanDate(plan.id),
    value: plan.id,
  }))

  return { plans }
}
