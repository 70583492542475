import styled from 'styled-components'

export const CoverImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  max-width: 320px;
  color: ${({ theme }) => theme.common.primaryText};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
`

export const ScoreInfo = styled.div`
  font-size: 10px;
  font-weight: 400;
`
