import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { ScheduleModalsTitle } from 'src/Components/ScheduleModalsTitle'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { ProperGenreOrLanguage, SelectedLanguage } from 'src/Types'

import { LanguagesModalSpotifyButton } from './LanguagesModalSpotifyButton'
import { LanguagesModalSpotifyWrapperList } from './LanguagesModalSpotifyList'

const ListWrapper = styled.div`
  padding: 0px 16px 16px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow-x: scroll;
  height: calc(100vh - 214px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    padding: 0px 0px 16px;
    height: calc(100vh - 255px);
  }
`

const convertLanguages = (
  languages: ProperGenreOrLanguage[],
): SelectedLanguage | null => {
  const selected = languages.find((language) => language.id !== ANY_LANGUAGE_ID)

  if (!selected) {
    return null
  }
  return {
    value: Number(selected.id),
    label: selected.name,
  }
}

export const LanguagesModalSpotifyWrapper: FC = () => {
  const { t } = useTranslation()
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const [value, setValue] = useState<string>('')
  const [targetLanguage, setTargetLanguage] = useState<number>()
  const [selectedLanguage, setSelectedLanguage] =
    useState<SelectedLanguage | null>(null)
  const { setSearch } = useCustomSearchParams()

  const handleClose = (): void => {
    setSearch({ modal: null })
  }
  useEffect(() => {
    if (campaignDetails?.languages.length) {
      setSelectedLanguage(convertLanguages(campaignDetails?.languages))
      const anyLanguage = campaignDetails?.languages.find(
        (item) => item.id === ANY_LANGUAGE_ID,
      )?.id as number
      setTargetLanguage(anyLanguage || 0)
    } else {
      setTargetLanguage(ANY_LANGUAGE_ID)
    }
  }, [campaignDetails?.languages, campaignDetails?.languages.length])

  const handleClick = (item: SelectedLanguage): void => {
    if (selectedLanguage?.value === item.value) {
      setSelectedLanguage(null)
      return
    }
    setSelectedLanguage(item)
  }

  return (
    <FullScreenModal
      showCloseButton
      onCancel={handleClose}
      prefixCls='languages'
      open
    >
      <ScheduleModalsTitle
        title={t('scheduleCampaignPage.vocalLanguage')}
        placeholder={t('scheduleCampaignPage.enterLanguageName')}
        setValue={setValue}
        value={value}
      />
      <ListWrapper>
        <LanguagesModalSpotifyWrapperList
          searchValue={value}
          setValue={setValue}
          selectedLanguage={selectedLanguage}
          targetLanguage={targetLanguage}
          onClick={handleClick}
          onChange={(id: number) => setTargetLanguage(id)}
        />
      </ListWrapper>
      <LanguagesModalSpotifyButton
        targetLanguage={targetLanguage}
        selectedLanguage={selectedLanguage}
      />
    </FullScreenModal>
  )
}
