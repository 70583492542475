export enum TabKeys {
  payout = 'payout',
  password = 'password',
  email = 'email',
  delete = 'delete',
}

export const tabs = [
  {
    showForArtist: false,
    showForCurator: true,
    key: TabKeys.payout,
    title: 'Payout',
  },
  {
    showForArtist: true,
    showForCurator: false,
    key: TabKeys.password,
    title: 'Change password',
  },
  {
    showForArtist: true,
    showForCurator: false,
    key: TabKeys.email,
    title: 'Change email',
  },
  {
    showForArtist: true,
    showForCurator: true,
    key: TabKeys.delete,
    title: 'Delete account',
  },
]

export const getTabs = (isArtist: boolean): typeof tabs =>
  tabs.filter((item) => {
    if (isArtist && item.showForArtist) {
      return true
    }
    return Boolean(!isArtist && item.showForCurator)
  })
