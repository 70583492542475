import { FunctionComponent, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

export const InfluencerBalanceTitle: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()
  return <Title>{t('balancePage.pageTitle')}</Title>
}
