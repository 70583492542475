import { useAppSelector } from 'src/Hooks/redux'
import { getInfluencerId } from 'src/Redux/auth-process/userSlice/selectors'
import { useInProgressSoundsQuery } from 'src/Redux/creatorInProgressSoundsApi'
import { useNewSoundsQuery } from 'src/Redux/creatorNewSoundsApi'
import { useSubmittedSoundsQuery } from 'src/Redux/creatorSubmittedSoundsApi'

export const useRefreshData = (): [
  onRefreshClick: () => void,
  loading: boolean,
] => {
  const influencerId = useAppSelector(getInfluencerId)

  const { refetch: refetchNewSounds, isFetching: isNewSoundsFetching } =
    useNewSoundsQuery(
      {
        creatorId: influencerId,
        pageNumber: 1,
      },
      {
        skip: !influencerId,
      },
    )

  const {
    refetch: refetchInProgressSounds,
    isFetching: isInProgressSoundsFetching,
  } = useInProgressSoundsQuery(1)

  const {
    refetch: refetchSubmittedSounds,
    isFetching: isSubmittedSoundsFetching,
  } = useSubmittedSoundsQuery(1)
  const onRefreshClick = (): void => {
    void refetchNewSounds()
    void refetchInProgressSounds()
    void refetchSubmittedSounds()
  }
  const loading =
    isNewSoundsFetching ||
    isInProgressSoundsFetching ||
    isSubmittedSoundsFetching
  return [onRefreshClick, loading]
}
